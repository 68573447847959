import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type DealsAdminRouteProps = AdminRouteBaseProps;
const Deals = lazy(() => import('.'));
const DealsRoutes: Route<DealsAdminRouteProps>[] = [
  {
    path: 'deals',
    id: 'DealsAdmin',
    element: <Deals />,
  },
];

export const toDeals = (): string => '/admin/deals';

export default DealsRoutes;
