import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';
const View = React.lazy(() => import('./View/index'));
const Edit = React.lazy(() => import('./Edit'));

export type LeaseAgreementRouteProps = MakeGenerics<
  {
    Params: {
      leaseAgreementId?: string;
      searchRequestReferenceId?: string;
    };
    LoaderData: {
      leaseAgreementId: string;
      searchRequestReferenceId?: string;
    };
  } & BasicRouteProps
>;

const LeaseAgreementRoute: Route<LeaseAgreementRouteProps>[] = [
  {
    path: '/lease-agreement/:leaseAgreementId',
    id: 'LeaseAgreementView',
    meta: {
      whiteBackground: true,
    },
    children: [
      {
        path: '/',
        meta: {
          whiteBackgroundPhone: true,
          noPadding: true,
        },
        loader: ({ params }) => ({
          leaseAgreementId: params.leaseAgreementId,
        }),
        element: <View />,
      },
    ],
  },
  {
    path: '/s/:searchRequestReferenceId/lease-agreement/:leaseAgreementId',
    id: 'LeaseAgreementSearchRequest',
    loader: ({ params }) => ({
      leaseAgreementId: params.leaseAgreementId,
      searchRequestReferenceId: params.searchRequestReferenceId,
    }),
    meta: {
      whiteBackground: true,
      whiteBackgroundPhone: true,
      noPadding: true,
    },
    element: <View />,
  },
];

export const toLeaseAgreement = (id: string): string =>
  `/lease-agreement/${id}`;

interface LeaseAgreementSearchRequestProps {
  leaseAgreementId: string;
  searchRequestReferenceId: string;
}

export const toLeaseAgreementSearchRequest = ({
  leaseAgreementId,
  searchRequestReferenceId,
}: LeaseAgreementSearchRequestProps): string =>
  `/s/${searchRequestReferenceId}/lease-agreement/${leaseAgreementId}`;

export const toLeaseAgreementEdit = (id: string): string =>
  `/lease-agreement/${id}/edit`;

export default LeaseAgreementRoute;
