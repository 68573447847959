import { ShareAltOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import Mail from '@/components/SearchRequest/shared/ShareSearchRequestButton/Mail';
import useUser from '@/hooks/useUser';

import Link from './Link';

interface Props {
  searchRequestReferenceId: string;
  isAdmin?: boolean;
}

function ShareSearchRequestButton({
  searchRequestReferenceId,
  isAdmin = false,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const [visible, setVisible] = useState<boolean>(false);

  const onCancel = (): void => {
    setVisible(false);
  };

  return (
    <Popover
      placement={'topRight'}
      trigger="click"
      visible={visible}
      className={'prevent-request-drawer-close'}
      overlayClassName={'prevent-request-drawer-close'}
      onVisibleChange={change => {
        setVisible(change);
        if (!change) {
          onCancel();
        }
      }}
      overlayStyle={{
        minWidth: '250px',
        maxWidth: '100vw',
      }}
      content={
        <Box flex flexDirection={'column'} verticalSpacing={1}>
          {!isAdmin && (
            <Mail
              clientId={user?.activeClientId}
              searchRequestReferenceId={searchRequestReferenceId}
              onCancel={onCancel}
            />
          )}
          <Link
            searchRequestReferenceId={searchRequestReferenceId}
            onCancel={onCancel}
          />
        </Box>
      }
    >
      <Button icon={<ShareAltOutlined />}>{t('Share')}</Button>
    </Popover>
  );
}

export default ShareSearchRequestButton;
