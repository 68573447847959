import { Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

const Terms = React.lazy(() => import('.'));

export type TermsOfServiceRouteProps = BasicRouteProps;

const TermsOfServiceRoute: Route<TermsOfServiceRouteProps>[] = [
  {
    path: '/terms',
    id: 'Terms of service',
    element: <Terms />,
    meta: {
      noPaddingPhone: true,
      whiteBackgroundPhone: true,
    },
  },
];

export const toTermsOfService = (): string => '/terms';

export default TermsOfServiceRoute;
