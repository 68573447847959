import { Form, Input, InputNumber } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import SelectAccess from '@/components/SearchFormComponents/SelectAccess';
import SelectUsageCategories from '@/components/SelectUsageCategories';
import UploadFile from '@/components/UploadFile';
import useUser from '@/hooks/useUser';

const TextArea = Input.TextArea;

interface Props {
  visible: boolean;
}

const FormItemText = styled(Form.Item)`
  max-width: 4000px;
`;

function Description({ visible }: Props): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();

  return (
    <Box style={{ display: visible ? 'block' : 'none' }}>
      <Form.Item style={{ display: 'none' }} name={'regionIds'} />

      <Box maxW={400}>
        <Form.Item
          name="title"
          label={t('Header')}
          rules={[{ required: true, message: t('Header is required') }]}
        >
          <Input
            placeholder={t('searchRequestTitlePlaceholder', {
              company: user?.activeClient.name,
            })}
            allowClear
            maxLength={75}
          />
        </Form.Item>
        <Form.Item
          label={t('usageCategory')}
          name={'usageCategories'}
          rules={[
            {
              required: true,
              message: t('usageCategoryRequired'),
            },
          ]}
        >
          <SelectUsageCategories
            allowClear
            placeholder={t('Choose')}
            mode={'multiple'}
            showSearch={false}
            showArrow
          />
        </Form.Item>
        <Form.Item dependencies={['usageCategories']} noStyle>
          {({ getFieldValue }) => {
            const usageCategories = getFieldValue('usageCategories') ?? [];

            if (
              usageCategories.some(category =>
                ['office', 'coWork'].includes(category),
              )
            ) {
              return (
                <Form.Item
                  name={'employees'}
                  label={t('employees')}
                  help={t('employeesHelp')}
                >
                  <InputNumber
                    placeholder={t('employees')}
                    style={{ width: '50%' }}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    }
                    parser={value => value?.replace(/\$\s?|( *)/g, '')}
                  />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Box flex horizontalSpacing={1}>
          <Form.Item
            name={['size', 'min']}
            normalize={value => (value ? parseInt(value) : null)}
            label={t('size')}
            rules={[
              {
                required: true,
                message: t('sizeMinRequired'),
              },
            ]}
          >
            <InputNumber<number>
              min={0}
              placeholder="Min"
              formatter={value =>
                value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
              parser={value => parseInt(value?.replace(/\$\s?|( *)/g, ''))}
              addonAfter={'m²'}
            />
          </Form.Item>

          <Form.Item
            required={false}
            name={['size', 'max']}
            normalize={value => (value ? parseInt(value) : null)}
            label={' '}
            rules={[
              {
                type: 'number',
                min: 0,
                message: t('sizeMin'),
              },
              {
                required: true,
                message: t('sizeMaxRequired'),
              },
            ]}
          >
            <InputNumber<number>
              min={0}
              placeholder="Max"
              formatter={value =>
                value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
              parser={value => parseInt(value?.replace(/\$\s?|( *)/g, ''))}
              addonAfter={'m²'}
            />
          </Form.Item>
        </Box>

        <SelectAccess />

        <Form.Item
          required={false}
          name={['budget']}
          normalize={value => (value ? parseInt(value) : null)}
          label={'Budget'}
          rules={[
            {
              type: 'number',
              min: 0,
            },
          ]}
        >
          <InputNumber<number>
            placeholder="Budget"
            formatter={value =>
              value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={value => parseInt(value?.replace(/\$\s?|( *)/g, ''))}
            min={0}
            addonAfter={t('pricePerMonth')}
          />
        </Form.Item>
      </Box>

      <Box maxW={700}>
        <FormItemText name="description" label={t('description')}>
          <TextArea
            placeholder={`${t('describe placeholder')}...`}
            allowClear
            autoSize={{ minRows: 3 }}
          />
        </FormItemText>

        <FormItemText
          name="specificNeedsInformation"
          label={t('special requirements')}
        >
          <TextArea
            placeholder={`${t('special requirements placeholder')}...`}
            allowClear
            autoSize={{ minRows: 3 }}
          />
        </FormItemText>

        <FormItemText name="areaInformation" label={t('search area')}>
          <TextArea
            placeholder={`${t('search area placeholder')}...`}
            allowClear
            autoSize={{ minRows: 3 }}
          />
        </FormItemText>

        <FormItemText
          name="businessInformation"
          label={t('Additional information')}
        >
          <TextArea
            placeholder={`${t('additionalInformationPlaceholder')}...`}
            allowClear
            autoSize={{ minRows: 3 }}
          />
        </FormItemText>

        <Form.Item name="documentIds" label={t('documents')} initialValue={[]}>
          <UploadFile multiple accept={'*'} listType={'text'} />
        </Form.Item>
      </Box>
    </Box>
  );
}

export default Description;
