import React from 'react';
import styled, { css } from 'styled-components';

import Box from '@/components/Box';
import VideoPlayer from '@/components/VideoPlayer';
import { GetPortallyPartnerQuery } from '@/generated/graphql';
interface DisplayResourceProps {
  displayResource: GetPortallyPartnerQuery['portallyPartner']['displayResource'];
  shadow?: boolean;
}

export const MediaDimensions = css`
  height: 499px;
  width: 888px;
  @media (max-width: 1650px) {
    height: 450px;
    width: 800px;
  }
  @media (max-width: 1550px) {
    height: 399px;
    width: 709px;
  }
  @media (max-width: ${p => p.theme.breakpoints.desktop}) {
    height: 288px;
    width: 512px;
  }
  @media (max-width: ${p => p.theme.breakpoints.laptop}) {
    height: 56.26%;
    width: 100%;
  }
`;

const Wrapper = styled.div<{ $shadow: boolean }>`
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 8px;
  ${p => p.$shadow && 'box-shadow:  2px 2px 12px 2px #673ab730'};
  outline: none;
  height: 360px;
  width: 638px;
  ${MediaDimensions};
`;

export default function DisplayResource({
  displayResource,
  shadow = true,
}: DisplayResourceProps): JSX.Element {
  if (!displayResource) {
    return null;
  }

  const { mimetype, url } = displayResource;

  if (!mimetype && !url) {
    return null;
  }

  if (mimetype.includes('video')) {
    return (
      <Box
        ml={1}
        style={{
          borderRadius: '6px',
          overflow: 'hidden',
          height: 'auto',
        }}
      >
        <Wrapper $shadow={shadow}>
          <VideoPlayer
            height={'400px'}
            width={'100%'}
            style={{
              height: 'auto',
              borderRadius: '6px',
            }}
            url={`${url}`}
          />
        </Wrapper>
      </Box>
    );
  }
  return (
    <Wrapper $shadow={shadow}>
      <img
        style={{
          width: '100%',
          height: 'auto',
          maxHeight: '400px',
          borderRadius: '6px',
        }}
        src={url}
        alt={'display image'}
      />
    </Wrapper>
  );
}
