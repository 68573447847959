import { Drawer, Form, message, Modal } from 'antd';
import { UploadFile } from 'antd/lib';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';

import Box from '@/components/Box';
import Buttons from '@/components/SearchRequest/Landlord/Buttons';
import Steps from '@/components/SearchRequest/Landlord/Steps';
import View from '@/components/SearchRequest/shared/View';
import { SelectedLeaseAgreement } from '@/components/SelectLeaseAgreements';
import {
  DocumentValuesFragment,
  StatusEnum,
  useAnswerSearchRequestMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useScrollPrevention from '@/hooks/useScrollPrevention';
import useUser from '@/hooks/useUser';
import apollo from '@/main/apollo';

const { useForm } = Form;

interface Props {
  open: boolean;
  id: string;
  onCancel: () => void;
  sharedSearchRequest?: boolean;
  withMask?: boolean;
  flat?: boolean;
}

const ScrollContainer = styled.div`
  overflow-y: auto;
  flex: 1;
`;

function SearchRequestLandlord({
  open,
  id,
  onCancel,
  sharedSearchRequest = false,
  flat = false,
  withMask = false,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  const user = useUser();
  const [searchRequestStatus, setSearchRequestStatus] = useState<
    StatusEnum | 'disabled' | undefined
  >(undefined);

  useScrollPrevention(open);
  const ref = useRef();
  useOnClickOutside(ref, e => {
    if (
      !e.composedPath().some(e => {
        const htmlDiv = e as HTMLDivElement;
        if ('className' in htmlDiv) {
          if (
            typeof htmlDiv.className?.includes === 'function' &&
            htmlDiv.className.includes('prevent-request-drawer-close')
          ) {
            return true;
          }
        }
      })
    ) {
      onCancel();
    }
  });

  const [answerSearchRequest, { loading: answerLoading }] =
    useAnswerSearchRequestMutation({
      onError: error => {
        message.error(
          t(error.message, {
            ns: 'errors',
          }),
        );
        setStep(step - 1);
      },
      onCompleted: async () => {
        message.success(t('Inquiry answered'), 2.5);
        setMessage('');
        onCancel();
        await apollo.reFetchObservableQueries();
      },
    });

  const [requestForm] = useForm();

  const [step, setStep] = useState<number>(0);
  const [inquiryMessage, setMessage] = React.useState('');
  const [selectedLeaseAgreements, setSelectedLeaseAgreements] = useState<
    SelectedLeaseAgreement[]
  >([]);

  const [selectedAttachments, setSelectedAttachments] = useState<
    UploadFile<DocumentValuesFragment>[]
  >([]);
  const resetForm = (): void => {
    setStep(0);
    setMessage('');
    setSelectedLeaseAgreements([]);
    requestForm.resetFields();
  };

  useEffect(() => {
    resetForm();
    if (open) {
      const drawerContainers = document.getElementsByClassName(
        'prevent-request-drawer-close',
      );
      for (let i = 0; i < drawerContainers.length; i++) {
        const drawers =
          drawerContainers[i].getElementsByClassName('ant-drawer-body');
        for (let i = 0; i < drawers.length; i++) {
          drawers[i].scrollTop = 0;
        }
      }
    }
  }, [open, id]);

  const handleSubmit = async (): Promise<void> => {
    if (step === 0) {
      setStep(1);
    }
    if (step === 1) {
      setStep(step + 1);
      await answerSearchRequest({
        variables: {
          message: inquiryMessage,
          searchRequestReferenceId: id,
          status: StatusEnum.Accepted,
          leaseAgreementIds: selectedLeaseAgreements.map(lease => lease.id),
          attachmentIds: selectedAttachments.map(
            attachment => attachment.response.id,
          ),
        },
      });
    }
  };

  const showButtons =
    searchRequestStatus !== StatusEnum.Accepted &&
    searchRequestStatus !== 'disabled';

  if (flat) {
    return (
      <>
        <Box w={'100%'} mb={2}>
          <Steps step={step} setStep={setStep} />
        </Box>
        <ScrollContainer>
          <View
            id={id}
            step={step}
            message={inquiryMessage}
            setMessage={setMessage}
            selectedLeaseAgreements={selectedLeaseAgreements}
            setSelectedLeaseAgreements={setSelectedLeaseAgreements}
            setSelectedAttachments={setSelectedAttachments}
            selectedAttachments={selectedAttachments}
            setSearchRequestStatus={setSearchRequestStatus}
            sharedSearchRequest={sharedSearchRequest}
          />
        </ScrollContainer>
        <Box mt={2}>
          {user && showButtons && (
            <Buttons
              searchRequestReferenceId={id}
              step={step}
              onCancel={onCancel}
              answerLoading={answerLoading}
              handleSubmit={handleSubmit}
              message={inquiryMessage}
              onGoBack={() => setStep(step - 1)}
              declined={searchRequestStatus === StatusEnum.Declined}
              sharedSearchRequest={sharedSearchRequest}
            />
          )}
        </Box>
      </>
    );
  }

  if (sharedSearchRequest) {
    return (
      <Modal
        width="800px"
        open={open}
        title={
          showButtons && (
            <Box w={'100%'} mt={1}>
              <Steps step={step} setStep={setStep} />
            </Box>
          )
        }
        closable={false}
        footer={
          user && showButtons ? (
            <Buttons
              searchRequestReferenceId={id}
              step={step}
              onCancel={onCancel}
              answerLoading={answerLoading}
              handleSubmit={handleSubmit}
              message={inquiryMessage}
              onGoBack={() => setStep(step - 1)}
              declined={searchRequestStatus === StatusEnum.Declined}
              sharedSearchRequest={sharedSearchRequest}
            />
          ) : null
        }
        onCancel={() => {
          !sharedSearchRequest && onCancel();
        }}
        maskStyle={{ zIndex: 1000 }}
        zIndex={1002}
      >
        <View
          id={id}
          step={step}
          message={inquiryMessage}
          setMessage={setMessage}
          selectedLeaseAgreements={selectedLeaseAgreements}
          setSelectedLeaseAgreements={setSelectedLeaseAgreements}
          setSelectedAttachments={setSelectedAttachments}
          selectedAttachments={selectedAttachments}
          setSearchRequestStatus={setSearchRequestStatus}
          sharedSearchRequest={sharedSearchRequest}
        />
      </Modal>
    );
  }

  return (
    <Drawer
      placement={isMobile ? 'bottom' : 'right'}
      height={'100%'}
      width={'650px'}
      push={false}
      open={open}
      className={'prevent-request-drawer-close'}
      onClose={onCancel}
      title={
        <Box w={'100%'} className={'prevent-request-drawer-close'}>
          <Steps step={step} setStep={setStep} />
        </Box>
      }
      footer={
        <Buttons
          searchRequestReferenceId={id}
          step={step}
          onCancel={onCancel}
          answerLoading={answerLoading}
          handleSubmit={handleSubmit}
          message={inquiryMessage}
          onGoBack={() => setStep(step - 1)}
          sharedSearchRequest={sharedSearchRequest}
          declined={searchRequestStatus === StatusEnum.Declined}
        />
      }
      mask={withMask}
      maskClosable
      closable={false}
    >
      <div className={'w-full h-full'} ref={ref} key={id}>
        <View
          id={id}
          step={step}
          message={inquiryMessage}
          setMessage={setMessage}
          selectedLeaseAgreements={selectedLeaseAgreements}
          setSelectedLeaseAgreements={setSelectedLeaseAgreements}
          setSearchRequestStatus={setSearchRequestStatus}
          sharedSearchRequest={sharedSearchRequest}
          setSelectedAttachments={setSelectedAttachments}
          selectedAttachments={selectedAttachments}
        />
      </div>
    </Drawer>
  );
}

export default SearchRequestLandlord;
