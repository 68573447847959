import { MailOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AccountCreationType,
  useGetNewLinkMutation,
} from '@/generated/graphql';

interface Props extends Omit<ButtonProps, 'type'> {
  type: AccountCreationType;
  token: string;
  searchRequestReferenceId?: string;
}

function NewLinkButton({
  type,
  token,
  searchRequestReferenceId,
  ...rest
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [linkSent, setLinkSent] = React.useState(false);
  const [getNewLoginLink, { loading }] = useGetNewLinkMutation({
    variables: {
      type,
      token,
      searchRequestReferenceId,
    },
    onCompleted: () => {
      setLinkSent(true);
    },
  });
  return (
    <Button
      {...rest}
      type={'primary'}
      onClick={() => getNewLoginLink()}
      icon={<MailOutlined />}
      loading={loading}
      disabled={linkSent || loading}
    >
      {t('Send me a link')}
    </Button>
  );
}

export default NewLinkButton;
