import { useApolloClient } from '@apollo/client';
import { useNavigate } from '@tanstack/react-location';
import { Button, Input, Modal } from 'antd';
import { ButtonProps } from 'antd/lib';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Message from '@/components/Message';
import { MessageType, useAddNewMessageMutation } from '@/generated/graphql';
import useUser, { User } from '@/hooks/useUser';
import { toMessages } from '@/views/Messages/routes';

interface Reciver {
  id: string;
  name?: string;
  rating?: number;
}

interface Props extends ButtonProps {
  leaseAgreementId: string;
  user: User;
  reciver: Reciver;
  messageType?: MessageType;
  searchRequestReferenceId?: string;
  onFinish?: () => void;
}

const Text = styled.div`
  cursor: pointer;
  &:hover {
    color: #40a9ff;
  }
  color: #1890ff;
`;

const ConversationButton = ({
  leaseAgreementId,
  user,
  reciver,
  messageType = MessageType.OnNewMessage,
  onFinish,
  searchRequestReferenceId,
  ...rest
}: Props): JSX.Element => {
  const [state, setState] = useState({
    open: false,
    message: '',
    reciver: undefined,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const client = useApolloClient();

  const [action, { error, loading }] = useAddNewMessageMutation({
    onCompleted: async data => {
      if (onFinish) {
        onFinish();
      } else {
        data.newMessage
          ? navigate({
              to: toMessages(data.newMessage.conversationId),
            })
          : setState({ ...state, open: false });
        await client.reFetchObservableQueries();
      }
      setState({ ...state, open: false });
    },
  });

  const handleOk = async (): Promise<void> => {
    await action({
      variables: {
        messageType,
        input: {
          reciverId: state.reciver?.id,
          senderId: user.activeClientId,
          leaseAgreementId,
          leaseAgreementIds: [leaseAgreementId],
          message: state.message,
          searchRequestReferenceId,
        },
      },
    });
  };

  return (
    <>
      <Button
        {...rest}
        onClick={() =>
          setState(prev => ({
            ...prev,
            open: true,
            reciver: reciver,
          }))
        }
      />

      <Modal
        title={
          state.reciver?.name
            ? `${t('To')}: ${state.reciver.name}`
            : t('message')
        }
        open={state.open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={() => setState(prev => ({ ...prev, open: false }))}
        okText={t('Send')}
        cancelText={t('Cancel')}
        okButtonProps={{
          disabled: !state.message,
        }}
      >
        <Input.TextArea
          placeholder={t('Write a message')}
          rows={5}
          onChange={e => {
            const { target } = e;
            setState(prev => ({ ...prev, message: target.value }));
          }}
          value={state.message}
        />
        {error && <Message error={error} />}
      </Modal>
    </>
  );
};

const StartConversationButton = (
  props: Omit<Props, 'user'>,
): JSX.Element | null => {
  const user = useUser();
  if (!user?.activeClientId) {
    return null;
  }
  return <ConversationButton user={user} {...props} />;
};

export default StartConversationButton;
