import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type EstatesAdminRouteProps = AdminRouteBaseProps;
const EstatesAdmin = lazy(() => import('.'));

const EstatesAdminRoutes: Route<EstatesAdminRouteProps>[] = [
  {
    path: '/properties',
    id: 'EstatesAdmin',
    element: <EstatesAdmin />,
  },
];

export const toEstatesAdmin = (): string => '/admin/properties';

export default EstatesAdminRoutes;
