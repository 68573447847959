import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col as AntCol, Row as AntRow } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import { GetClientQuery } from '@/generated/graphql';
import { formatNumber } from '@/utils';

const Row = styled(AntRow)`
  font-size: 14px;
`;

const RowBorderBottom = styled(AntRow)`
  font-size: 14px;
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
`;

const Header = styled.div`
  font-size: 14px;
  color: #000;
  font-weight: 600;
`;

const Col = styled(AntCol)`
  text-align: left;
`;

const ColRightAligned = styled(AntCol)`
  text-align: right;
`;

type option = keyof DataListProps['values'];

interface DataListProps {
  values: GetClientQuery['client'] & { gateCustomer?: boolean };
  options: option[];
  title?: string;
}

function DisplayValues(
  values: DataListProps['values'],
  option: option,
): string | JSX.Element | null | false | number {
  const value = values[option];
  const { t } = useTranslation();

  if (!value) {
    return null;
  }

  if (typeof value === 'number') {
    return formatNumber(value);
  }

  if (typeof value === 'boolean') {
    return value ? (
      <CheckOutlined style={{ color: 'green' }} />
    ) : (
      <CloseOutlined />
    );
  }

  if (typeof value == 'object') {
    switch (option) {
      default:
        return (
          <>
            {Object.keys(value)
              .filter(
                key => !['__typename', 'street', 'streetNumber'].includes(key),
              )
              .map(key => (
                <Row key={key}>
                  <Col offset={4} span={8}>
                    {t(key)}
                  </Col>
                  <ColRightAligned span={12}>{value[key]}</ColRightAligned>
                </Row>
              ))}
          </>
        );
    }
  }
  if (typeof value === 'string') {
    return t<string>(value);
  }

  return null;
}

export default function ClientDataList({
  values,
  options,
  title,
}: DataListProps): JSX.Element | null {
  const { t } = useTranslation();
  const data = options
    .filter(
      key =>
        ({}.hasOwnProperty.call(values, key) &&
        values[key] !== null &&
        values[key] !== undefined),
    )
    .map(option => {
      const val = DisplayValues(values, option);
      return (
        val && (
          <Box mb={1} key={option}>
            <RowBorderBottom>
              <Col span={12}>{t(option)}:</Col>
              <ColRightAligned span={12}>
                {DisplayValues(values, option)}
              </ColRightAligned>
            </RowBorderBottom>
          </Box>
        )
      );
    })
    .filter(Boolean);

  return data.length > 0 ? (
    <div>
      <Box mb={1} mt={2}>
        {title && <Header>{t(title)}</Header>}
      </Box>
      {data}
    </div>
  ) : null;
}
