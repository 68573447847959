import { useEffect, useState } from 'react';

const useScrollPrevention = (isScrollPrevented: boolean): void => {
  const [scrollDistance, setScrollDistance] = useState(0);
  const [previouslyOpen, setPreviouslyOpen] = useState(false);
  useEffect(() => {
    const $window = window;
    const $body = document.querySelector('body');
    if (isScrollPrevented && $body && $window) {
      setScrollDistance($window.scrollY);
      // Pull the top of the body up by that amount
      $body.style.top = `-${$window.scrollY}px`;

      document.body.classList.add('drawer-open');
      setPreviouslyOpen(true);
    } else if (previouslyOpen) {
      document.body.classList.remove('drawer-open');
      if (!$body || !$window) {
        return;
      }
      $body.style.top = '';
      $window.scrollTo(0, scrollDistance);
      setPreviouslyOpen(false);
      //document.body.scrollTop = scrollPosition;
    }
  }, [isScrollPrevented]);
};

export default useScrollPrevention;
