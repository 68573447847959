import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type PortallyPartnersAdminRouteProps = AdminRouteBaseProps;

const PortallyPartners = lazy(() => import('.'));

const PortallyPartnersAdminRoutes: Route<PortallyPartnersAdminRouteProps>[] = [
  {
    path: 'partners',
    id: 'Partners',
    element: <PortallyPartners />,
  },
];

export const toPortallyPartnersAdmin = (): string => '/admin/partners';

export default PortallyPartnersAdminRoutes;
