import styled from 'styled-components';

interface Props {
  p?: number;
  px?: number;
  py?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  m?: number;
  mx?: number;
  my?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  w?: string | number;
  minW?: number;
  maxW?: number;
  h?: string | number;
  minH?: number;
  maxH?: number;
  oY?: string;
  oX?: string;
  verticalSpacing?: number;
  horizontalSpacing?: number;
  justifyContent?: string;
  alignItems?: string;
  flex?: boolean;
  flexCol?: boolean;
  center?: boolean;
  flexWrap?: string;
  flexDirection?: string;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: string | number;
  gap?: number;
  color?: string;
  fontSize?: number;
  fontWeight?: number;
}

const Box = styled.div<Props>`
  &&& {
    ${({
      verticalSpacing,
      horizontalSpacing,
      flex,
      center,
      alignItems,
      justifyContent,
      flexWrap,
      flexDirection,
      w,
      minW,
      maxW,
      h,
      minH,
      maxH,
      oX,
      oY,
      flexGrow,
      flexBasis,
      flexShrink,
      gap,
      flexCol,
      color,
      fontSize,
      fontWeight,
      ...props
    }: Props) => {
      const map = {
        all: ['all'],
        x: ['left', 'right'],
        y: ['bottom', 'top'],
        l: ['left'],
        r: ['right'],
        t: ['top'],
        b: ['bottom'],
      };

      const styles: string[] = Object.keys(props)
        .filter(key => ['m', 'p'].includes(key[0]) && key.length <= 2)
        .map((key: string) => {
          const type = ['m', 'p'].includes(key) ? 'all' : key[1];

          if (
            {}.hasOwnProperty.call(map, type) &&
            ['m', 'p'].includes(key[0])
          ) {
            const prop = key[0] === 'm' ? 'margin' : 'padding';

            const style = map[type]
              .map(dir =>
                dir === 'all'
                  ? `${prop}: ${props[key] * 8}px;`
                  : `${prop}-${dir}: ${props[key] * 8}px;`,
              )
              .join('\n');

            return style;
          }
          return null;
        })
        .filter(Boolean);

      if (verticalSpacing) {
        styles.push(`> * + * { margin-top: ${verticalSpacing * 8}px;}`);
      }

      if (horizontalSpacing) {
        styles.push(`> * + * { margin-left: ${horizontalSpacing * 8}px;}`);
      }

      if (flex) {
        styles.push(`display: flex;`);
      }

      if (flexCol) {
        styles.push(`display: flex;`);
        styles.push(`flex-direction: column;`);
      }

      if (center) {
        styles.push(`text-align: center;`);
      }

      if (alignItems) {
        styles.push(`align-items: ${alignItems};`);
      }

      if (flexDirection) {
        styles.push(`flex-direction: ${flexDirection};`);
      }

      if (justifyContent) {
        styles.push(`justify-content: ${justifyContent};`);
      }

      if (flexWrap) {
        styles.push(`flex-wrap: ${flexWrap};`);
      }

      if (w) {
        const width = typeof w === 'string' ? w : `${w}px`;
        styles.push(`width: ${width};`);
      }

      if (minW) {
        styles.push(`min-width: ${minW}px;`);
      }
      if (fontSize) {
        styles.push(`font-size: ${fontSize}px;`);
      }
      if (gap) {
        styles.push(`--gap: ${gap * 8}px;
                    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
                    width: calc(100% + var(--gap));

                      > * {
                      margin: var(--gap) 0 0 var(--gap);
                      `);
      }

      if (maxW) {
        styles.push(`max-width: ${maxW}px;`);
      }

      if (h) {
        const height = typeof h === 'string' ? h : `${h}px`;
        styles.push(`height: ${height};`);
      }

      if (minH) {
        styles.push(`min-height: ${minH}px;`);
      }

      if (maxH) {
        styles.push(`max-height: ${maxH}px;`);
      }

      if (oY) {
        styles.push(`overflow-y: ${oY};`);
      }

      if (oX) {
        styles.push(`overflow-x: ${oX};`);
      }
      if (flexGrow) {
        styles.push(`flex-grow: ${flexGrow};`);
      }
      if (flexBasis) {
        const fb = typeof flexBasis === 'string' ? flexBasis : `${flexBasis}px`;
        styles.push(`flex-basis: ${fb};`);
      }

      if (flexShrink !== undefined) {
        styles.push(`flex-shrink: ${flexShrink};`);
      }
      if (color) {
        styles.push(`color: ${color};`);
      }
      if (fontWeight) {
        styles.push(`font-weight: ${fontWeight};`);
      }

      return styles.join('\n');
    }}
`;

export const SpaceBetween = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const FlexEnd = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const Ellipsis = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const WordBreak = styled(Box)`
  overflow: hidden;
  white-space: pre-line;
  word-break: break-word;
`;

export default Box;
