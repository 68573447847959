import { Divider } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import Box from '@/components/Box';
import theme from '@/main/theme';
interface Props {
  date: Date;
}

function TimeStamp({ date }: Props): JSX.Element {
  const now = dayjs(new Date());
  const messageDate = dayjs(date);

  return (
    <Box pt={2}>
      <Divider key={`divider${date}`}>
        <span style={{ color: theme.colors.typographySecondary }}>
          {messageDate.isBefore(now.subtract(1, 'year')) &&
            messageDate.format('YYYY ')}
          {messageDate.format('MMM D')}
        </span>
      </Divider>
    </Box>
  );
}

export default TimeStamp;
