import { SearchRequestReferenceInformationValuesFragment } from '@/generated/graphql';

export type Geometry =
  SearchRequestReferenceInformationValuesFragment['searchParameters']['geometry'];

interface Region {
  geometry?: Geometry;
}

export const getPolygonValues = (
  regions?: Region[],
  geometry?: Geometry,
): Geometry[] => {
  let polygonValues =
    geometry?.coordinates?.flatMap(paths =>
      paths.map(coords =>
        coords.map(lngLat => ({ lat: lngLat[1], lng: lngLat[0] })),
      ),
    ) || [];
  if (regions) {
    const regionPolygonValues = regions
      .filter(region => !!region.geometry)
      .flatMap(elem => elem.geometry);
    polygonValues = [...regionPolygonValues, ...polygonValues];
  }
  return polygonValues;
};
