import { Image } from 'antd';
import clsx from 'clsx';
import React from 'react';

import { getLeaseAgreementSizeString } from '@/components/LeaseAgreementSize';

interface Props {
  title: string;
  fee?: string;
  size?: number;
  sizeSpan?: {
    from: number;
    to: number;
  };
  description: string;
  image: string;
  mine?: boolean;
}

function CompactLinkPreview({
  title,
  size,
  image,
  sizeSpan,
  description,
  mine,
  fee,
}: Props) {
  return (
    <div
      className={clsx(
        'mobile:flex-col flex items-start justify-between p-3 border border-solid border-white rounded-xl transition-colors cursor-pointer hover:text-purple-600',
        'text-black bg-gradient-to-br from-white to-gray-100 border border-gray-200',
      )}
    >
      <div className={'pr-1 m-mobile:basis-[170px] flex-grow text-sm'}>
        <div className={'font-semibold text-base'}>{title}</div>

        {size && (
          <div>
            {getLeaseAgreementSizeString({
              size,
              sizeSpan,
            })}
          </div>
        )}
        <div className={'text-zinc-600 break-words'}>{fee}</div>
        <div className={clsx('pb-1 text-zinc-500 break-words')}>
          {description}
        </div>
      </div>
      <div
        className={clsx(
          'flex-grow m-mobile:basis-[200px] mobile:flex-shrink-0',
        )}
      >
        <Image
          src={image}
          preview={false}
          className={'rounded-xl flex-shrink-0 object-cover'}
          alt={title}
        />
      </div>
    </div>
  );
}

export default CompactLinkPreview;
