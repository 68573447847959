import { Button, Form, FormInstance, Input, Typography } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import Message from '@/components/Message';
import { useGetInvoiceDetailsQuery } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';

import InvoiceDetailsForm from './InvoiceDetailsForm';
const { Title } = Typography;

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
  form: FormInstance;
}

export default function StepOne({ setStep, step, form }: Props): JSX.Element {
  const { t } = useTranslation();

  const user = useUser();
  const { data, loading, error } = useGetInvoiceDetailsQuery({
    variables: {
      id: user?.activeClientId,
    },
  });
  if (error) {
    return <Message error={error} />;
  }

  const { isMobile } = useWidthAndHeight();

  return (
    <>
      {isMobile && (
        <Box flex justifyContent={'center'}>
          <Title level={5}>{t('invoiceDetails')}</Title>
        </Box>
      )}
      {!loading && (
        <Box p={4}>
          <InvoiceDetailsForm
            required
            invoiceDetails={data.client.invoiceDetails}
          />
          <Form.Item
            help={
              'Upplysningar och övrig information kring era faktureringsrutiner'
            }
            label={t('Comment')}
            name={'comment'}
          >
            <Input.TextArea />
          </Form.Item>
        </Box>
      )}
      <Box
        flex
        justifyContent={'flex-end'}
        alignItems={'flex-end'}
        horizontalSpacing={1}
      >
        <Button
          onClick={() => {
            setStep(step - 1);
          }}
        >
          {t('goBack')}
        </Button>
        <Button
          type={'primary'}
          onClick={async () => {
            await form
              .validateFields([
                ['invoiceDetails', 'billing'],
                ['invoiceDetails', 'marking'],
                ['invoiceDetails', 'email'],
                ['invoiceDetails', 'reference'],
              ])
              .then(fd => setStep(step + 1))
              .catch(err => err);
          }}
        >
          {t('Next')}
        </Button>
      </Box>
    </>
  );
}
