import dayjs from 'dayjs';

import {
  UPDATE_CLIENT_SEARCH_FILTER,
  UPDATE_SORT_FILTER,
} from '@/state/client/actions';

const initialState = {
  searchValues: {
    types: [],
    lastActiveUserDate: dayjs().subtract(1, 'year'),
  },
  sortValues: {},
};

export interface ClientState {
  searchValues: {
    [key: string]: any;
  };
  sortValues: {
    [key: string]: any;
  };
}

export default function clientPanelReducer(
  state = initialState,
  action,
): ClientState {
  switch (action.type) {
    case UPDATE_CLIENT_SEARCH_FILTER:
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          [action.name]: action.value,
        },
      };
    case UPDATE_SORT_FILTER:
      return {
        ...state,
        sortValues: {
          ...state.sortValues,
          order: action.order,
        },
      };
    default:
      return state;
  }
}
