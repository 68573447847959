import React from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';

import {
  areaGraphData,
  GraphData,
  lineGraphData,
  lineOptions,
} from './Options';
const Title = styled.h2`
  font-size: 14px;
  color: ${p => p.theme.colors.typographySecondary};
  padding: 12px;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  title?: string;
  data: GraphData[];
  filter?: React.ReactNode;
  chartType?: 'line' | 'area';
  wrapper?: string;
}

function Graph({
  title,
  data,
  filter,
  chartType = 'line',
}: Props): JSX.Element {
  const hasNoData = data[0].labels.length === 0;
  const graphTitle = data[0].title;
  if (hasNoData) {
    return (
      <div className="p-3 flex ">{`Det finns ingen data än för ${graphTitle}`}</div>
    );
  }
  return (
    <div>
      <Title>
        {title} {filter}
      </Title>
      <Line
        data={chartType === 'line' ? lineGraphData(data) : areaGraphData(data)}
        width={100}
        height={50}
        options={lineOptions}
      />
    </div>
  );
}

export default React.memo(Graph);
