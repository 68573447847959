import { Steps as AntSteps } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import StyledSteps from '@/components/StyledSteps';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
const { Step } = AntSteps;

interface Props {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

function Steps({ step, setStep }: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMini } = useWidthAndHeight();
  const steps = [
    {
      title: t('Inquiry'),
    },
    {
      title: t('Answer'),
    },
  ];

  return (
    <StyledSteps>
      <AntSteps
        current={step}
        onChange={step => setStep(step)}
        responsive={false}
        direction={'horizontal'}
      >
        {steps.map(item => (
          <Step key={item.title} title={!isMini && item.title} />
        ))}
      </AntSteps>
    </StyledSteps>
  );
}

export default Steps;
