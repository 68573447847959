import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { checkMaxUploadSize, uploadFile } from '@/components/UploadFile/util';
import { useUploadFileMutation } from '@/generated/graphql';

import { Props, State } from './types';

export function getBase64(file): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result);
      }
    };
    reader.onerror = error => reject(error);
  });
}

const UploadFile = ({
  initialValues = undefined,
  accept,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  maxLength,
  multiple = false,
  listType = 'picture-card',
}: Props): JSX.Element => {
  const [uploadFileMutation] = useUploadFileMutation();

  const initial = initialValues
    ? Array.isArray(initialValues)
      ? initialValues
      : [initialValues]
    : [];

  const [state, setState] = React.useState<State>({
    previewVisible: false,
    previewImage: '',
    fileList: initial.map(v => ({
      ...v,
      status: 'done',
      size: v.size,
      type: 'image',
      thumbUrl: v.url,
      name: v.name,
      uid: v.id,
      originFileObj: v as any,
      response: {
        id: v.id,
      },
      url: v.url,
    })),
  });

  const { t } = useTranslation();

  const { previewVisible, previewImage, fileList } = state;

  const handleCancel = (): void =>
    setState({ ...state, previewVisible: false });

  const handlePreview = async (file): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const handleChange = ({ fileList, file }: UploadChangeParam): void => {
    const { status } = file;
    setState({
      ...state,
      fileList: fileList.map(file => ({
        ...file,
        url: file.response?.url ? file.response.url : file.url,
      })),
    });
    if (status === 'done' || status === 'removed') {
      const items = fileList
        .filter(file => file.status === 'done')
        .map(file => file.response.id);

      onChange(multiple ? items : items.length > 0 ? items[0] : null);
    }
  };

  const max = !multiple ? 1 : maxLength;

  const PictureContainer = (): JSX.Element => (
    <>
      <PlusOutlined />
      <div className="ant-upload-text">{t('uploadImage')}</div>
    </>
  );

  const DocContainer = (): JSX.Element => (
    <Button>
      <UploadOutlined /> {t('Upload')}
    </Button>
  );

  return (
    <div className="clearfix">
      <Upload
        customRequest={args => {
          const { file, onError, onSuccess, onProgress } = args;

          uploadFile(file, uploadFileMutation, percent =>
            onProgress({ percent } as any),
          )
            .then(async upload => {
              onSuccess(
                {
                  id: upload.data.file.id,
                  url: upload.data.file.url,
                },
                file as any,
              );
            })
            .catch(onError);
        }}
        listType={listType}
        fileList={fileList}
        beforeUpload={file => checkMaxUploadSize(file, t)}
        onPreview={listType !== 'text' ? handlePreview : undefined}
        onChange={handleChange}
        accept={accept}
        multiple
      >
        {(!max || fileList.length < max) && (
          <div>
            {listType === 'text' ? <DocContainer /> : <PictureContainer />}
          </div>
        )}
      </Upload>
      {listType !== 'text' && (
        <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="preview" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      )}
    </div>
  );
};

export default UploadFile;
