import { MessageOutlined } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-location';
import { Button, ButtonProps, Drawer } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import ConversationPreview from '@/components/ConversationPreview/index';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import { toMessages } from '@/views/Messages/routes';

interface Props {
  conversationId: string;
  onClose: () => void;
}
function ConversationPreviewModal({
  conversationId,
  onClose,
}: Props): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Drawer
        className={'prevent-request-drawer-close'}
        maskClassName={'prevent-request-drawer-close'}
        title={null}
        width={640}
        height={'100%'}
        closable={false}
        push={false}
        footer={
          <Box flex justifyContent={'flex-end'} horizontalSpacing={1}>
            <Button
              type={'link'}
              icon={<MessageOutlined />}
              onClick={() =>
                navigate({
                  to: toMessages(conversationId),
                })
              }
            >
              {t('Go to conversation')}
            </Button>
            <Button onClick={() => onClose()} key={'close'}>
              {t('Close')}
            </Button>
          </Box>
        }
        onClose={e => {
          e.stopPropagation();
          e.preventDefault();
          onClose();
        }}
        open={!!conversationId}
        placement={isMobile ? 'bottom' : 'left'}
      >
        <Box
          style={{
            overscrollBehavior: 'contain',
          }}
        >
          {conversationId && (
            <ConversationPreview conversationId={conversationId} />
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default ConversationPreviewModal;
