import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import styled from 'styled-components';

import { Polygon } from '@/components/Map/types';

export function addPolygon(
  polygon: Polygon,
  polygons: google.maps.LatLngLiteral[][],
  updatePolygons: (polygon: google.maps.LatLngLiteral[][]) => void,
): void {
  polygon.setVisible(false);
  const path = polygon
    .getPath()
    .getArray()
    .map(polygon => ({ lat: polygon.lat(), lng: polygon.lng() }));

  updatePolygons([...polygons, path]);
}

export function clearPolygon(
  i: number,
  polygons: google.maps.LatLngLiteral[][],
  updatePolygons: (polygon: google.maps.LatLngLiteral[][]) => void,
): void {
  updatePolygons(polygons.filter((_, index) => index !== i));
}

export const Right = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  max-height: 200px;
  z-index: 999;
`;

export const Left = styled.div`
  position: absolute;
  left: 4px;
  display: flex;
  top: 4px;
  max-height: 200px;
  z-index: 999;
`;

export const RelativeContainer = styled.div`
  width: 0;
  position: relative;
`;

export const libraries: Libraries = ['drawing'];
