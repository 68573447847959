import { HandDraw } from '@styled-icons/fluentui-system-regular/HandDraw';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledIconButton } from '@/components/Buttons';

interface Props {
  drawing: boolean;
  setDrawing: Dispatch<SetStateAction<boolean>>;
}

function ToggleDrawing({ drawing, setDrawing }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <StyledIconButton
      onClick={() => setDrawing(!drawing)}
      $withText
      icon={<HandDraw size={24} />}
    >
      {drawing ? t('Stop drawing') : t('Draw')}
    </StyledIconButton>
  );
}

export default ToggleDrawing;
