import { BellFilled, BellOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import {
  useGetNotificationsQuery,
  useSubscribeToNotificationsSubscription,
} from '@/generated/graphql';
import { NavLinkMobile } from '@/layout/Customer/LeftMenu/index.styled';
import { toNotifications } from '@/views/Notifications/routes';

interface Props {
  menuOpen?: boolean;
}
export default function MobileNotificationsLink({
  menuOpen,
}: Props): JSX.Element {
  const { data, refetch, previousData } = useGetNotificationsQuery({
    variables: {
      limit: 6,
      skip: 0,
    },
  });

  useSubscribeToNotificationsSubscription({
    onSubscriptionData: async () => {
      await refetch();
    },
  });

  const notificationsCount =
    (data ?? previousData)?.notifications?.notifications.filter(
      item => !item.read,
    ).length || 0;
  return (
    <NavLinkMobile $menuOpen={menuOpen} to={toNotifications()}>
      <Badge count={notificationsCount} overflowCount={5} offset={[2, 2]}>
        <BellOutlined className={'inactive-icon'} />
        <BellFilled className={'active-icon'} />
      </Badge>
    </NavLinkMobile>
  );
}
