import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { reactionsList } from '@/components/MessageReaction';
import { ReactionEnum } from '@/generated/graphql';
import useUser from '@/hooks/useUser';

interface Props {
  messageId: string;
  reactions: {
    __typename?: 'Reaction';
    userId: string;
    reaction: ReactionEnum;
    user: { __typename?: 'User'; name: string };
  }[];
}
const Container = styled.span`
  cursor: default;
  position: absolute;
  bottom: -20px;
  z-index: 25;
  left: 0;
`;
const Reaction = styled.span`
  font-size: 25px;
  margin-left: 4px;
`;
export default function Reactions({
  reactions,
  messageId,
}: Props): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();
  if (reactions.length === 0) {
    return null;
  }
  return (
    <Container key={`message${messageId}reactions`} className="container">
      {reactions.map(
        reaction =>
          (
            <Tooltip
              key={`${reaction.userId}${reaction.reaction}`}
              title={
                user?.id === reaction.userId ? t('You') : reaction.user.name
              }
            >
              <Reaction key={reaction.userId}>
                {reactionsList.find(r => r.enum === reaction.reaction).icon}
              </Reaction>
            </Tooltip>
          ) || null,
      )}
    </Container>
  );
}
