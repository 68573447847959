import { Cascader, Form } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

const getQuarter = (month: number, isCurrentYear: boolean): string[] => {
  if (month < 3 || !isCurrentYear) {
    return ['Q1', 'Q2', 'Q3', 'Q4'];
  }
  if (month < 6) {
    return ['Q2', 'Q3', 'Q4'];
  }
  if (month < 9) {
    return ['Q3', 'Q4'];
  }
  return ['Q4'];
};

interface Props {
  label?: boolean;
}

function SelectAccess({ label = true }: Props): JSX.Element {
  const { t } = useTranslation();
  const now = dayjs(new Date()).get('year');
  const monthNow = dayjs(new Date()).get('month');

  const years = [];
  for (let i = now; i < now + 5; i++) {
    years.push((now + (i - now)).toString());
  }

  const options = years.map(year => ({
    value: year,
    label: year,
    children: getQuarter(monthNow, now.toString() === year).map(key => ({
      value: key,
      label: key,
    })),
  }));

  return (
    <Form.Item name={'access'} label={label && t('Desired access date')}>
      <Cascader options={options} placeholder={t('Select access date')} />
    </Form.Item>
  );
}

export default SelectAccess;
