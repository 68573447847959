import { useNavigate } from '@tanstack/react-location';
import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { toHome } from '@/views/Home/routes';

function NotFound(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //TODO specifika errors beroende på händelse
  return (
    <Box p={3} style={{ width: '100%' }} flex justifyContent={'center'}>
      <Result
        status="404"
        title="Oops!"
        subTitle={t('notFound')}
        extra={
          <Button
            onClick={() =>
              navigate({
                to: toHome(),
              })
            }
            type="primary"
          >
            {t('Back home')}
          </Button>
        }
      />
    </Box>
  );
}

export default NotFound;
