import { MessageOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import ConversationPreviewModal from '@/components/ConversationPreview/ConversationPreviewModal';
import { AnsweredByValuesFragment } from '@/generated/graphql';
import theme from '@/main/theme';

interface Props {
  searchRequests: AnsweredByValuesFragment['searchRequests'];
}

function AnsweredBy({ searchRequests }: Props): JSX.Element {
  const { t } = useTranslation();

  const [activeConversationId, setActiveConversationId] = useState('');
  const answeredBy = searchRequests.filter(
    searchRequest => !!searchRequest.responsibleUser,
  );

  if (answeredBy.length === 0) {
    return <></>;
  }

  return (
    <div>
      <Box
        style={{ color: theme.colors.typographySecondary, fontSize: '14px' }}
        mb={1}
      >
        {t('Previous answers')}
      </Box>
      <Box flex gap={1} flexWrap={'wrap'} pb={2}>
        {answeredBy.map(({ responsibleUser, conversationId }) => (
          <Box key={conversationId}>
            <Box flex alignItems={'center'} gap={1}>
              <div>{responsibleUser!.name}</div>
              {conversationId && (
                <Button
                  size={'small'}
                  type={'link'}
                  onClick={() => setActiveConversationId(conversationId)}
                  icon={<MessageOutlined />}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <ConversationPreviewModal
        onClose={() => setActiveConversationId('')}
        conversationId={activeConversationId}
      />
    </div>
  );
}

export default AnsweredBy;
