import {
  CheckOutlined,
  HomeOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { PaperPlane } from '@styled-icons/boxicons-regular/PaperPlane';
import { Button, Drawer, Popover, Tooltip } from 'antd';
import { UploadFile } from 'antd/lib';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import styled from 'styled-components';

import Box from '@/components/Box';
import { StyledIconButton } from '@/components/Buttons';
import ChatTextArea from '@/components/ChatTextArea';
import Message from '@/components/Message';
import SelectLeaseAgreements, {
  SelectedLeaseAgreement,
} from '@/components/SelectLeaseAgreements';
import UploadToConversation from '@/components/UploadFile/UploadToConversation';
import {
  DocumentValuesFragment,
  GetConversationQuery,
  useAddMessageMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useUser from '@/hooks/useUser';
import { updateMessageDraft } from '@/state/customer/actions';

interface Props {
  conversationId: string;
  answered: boolean;
  searchRequestReference?: GetConversationQuery['conversation']['searchRequestReference'];

  isMobile?: boolean;
}

const SendIcon = styled(PaperPlane)`
  margin-bottom: 5px;
  margin-right: 4px;
`;

const SendButton = styled(StyledIconButton).attrs({ $withText: true })`
  align-self: flex-end;
`;

const SelectLeaseAgreementsContainer = styled.div`
  width: 600px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const ChatForm = ({
  conversationId,
  answered,
  searchRequestReference,
  isMobile = false,
}: Props): JSX.Element => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const [fileList, setFileList] = useState<
    UploadFile<DocumentValuesFragment>[]
  >([]);

  const latestDraft =
    useAppSelector(state => state.customer.messageDrafts[conversationId]) || '';
  const [selectLeaseAgreementsDrawerOpen, setSelectLeaseAgreementsDrawerOpen] =
    useState(false);
  const [selectedLeaseAgreements, setSelectedLeaseAgreements] = useState<
    SelectedLeaseAgreement[]
  >([]);

  const [uploadedAttachments, setUploadedAttachments] = useState<
    UploadFile<DocumentValuesFragment>[]
  >([]);

  const [addMessage, { error, loading }] = useAddMessageMutation({});

  const { t } = useTranslation();

  const isLandlord =
    user?.activeClient?.type === 'landlord' && !user?.isPartner;

  const updateMessage = (message: string): AnyAction =>
    dispatch(updateMessageDraft(conversationId, message));

  const messageDisabled =
    latestDraft === '' &&
    selectedLeaseAgreements.length === 0 &&
    uploadedAttachments.length === 0;

  const handleSubmit = async (): Promise<void> => {
    if (messageDisabled) {
      return;
    }
    const message = latestDraft;
    updateMessage('');
    const leaseAgreementIds = selectedLeaseAgreements.map(lease => lease.id);
    setSelectedLeaseAgreements([]);
    setUploadedAttachments([]);
    await addMessage({
      variables: {
        input: {
          conversationId,
          message,
          leaseAgreementIds,
          attachmentIds: uploadedAttachments.map(a => a.response.id),
        },
      },
    });
  };

  return (
    <Box
      w={'100%'}
      px={isMobile ? 0 : 3}
      py={isMobile ? 0 : 2}
      verticalSpacing={1}
      className={'chat-form-container'}
    >
      {error && <Message error={error} />}
      <div>
        <ChatTextArea
          setMessage={message => updateMessage(message)}
          message={latestDraft}
          selectedLeaseAgreements={selectedLeaseAgreements}
          setSelectedLeaseAgreements={setSelectedLeaseAgreements}
          maxRows={5}
          minRows={2}
          selectedAttachments={uploadedAttachments}
          setSelectedAttachments={setUploadedAttachments}
          onChange={list => setUploadedAttachments(list)}
          fileList={fileList}
          setFileList={setFileList}
        />
      </div>

      <Box flex horizontalSpacing={1} justifyContent={'flex-end'}>
        <UploadToConversation
          values={uploadedAttachments}
          onChange={list => setUploadedAttachments(list)}
          fileList={fileList}
          setFileList={setFileList}
        />
        <Drawer
          open={selectLeaseAgreementsDrawerOpen}
          closable={false}
          height={'100%'}
          placement="bottom"
          footer={
            <div className="flex justify-end">
              <Button
                icon={<CheckOutlined />}
                onClick={() => setSelectLeaseAgreementsDrawerOpen(false)}
              >
                OK
              </Button>
            </div>
          }
        >
          <SelectLeaseAgreements
            searchRequestReference={searchRequestReference}
            setSelectedLeaseAgreements={setSelectedLeaseAgreements}
            selectedLeaseAgreements={selectedLeaseAgreements}
          />
        </Drawer>
        {isLandlord && (
          <Popover
            open={isMobile ? false : undefined}
            trigger={'click'}
            overlayClassName={'prevent-active-request-drawer-close'}
            content={
              <SelectLeaseAgreementsContainer
                className={'prevent-active-request-drawer-close h-[300px]'}
              >
                <SelectLeaseAgreements
                  searchRequestReference={searchRequestReference}
                  setSelectedLeaseAgreements={setSelectedLeaseAgreements}
                  selectedLeaseAgreements={selectedLeaseAgreements}
                />
              </SelectLeaseAgreementsContainer>
            }
            placement={'topRight'}
          >
            <Button
              icon={<HomeOutlined />}
              onClick={
                isMobile
                  ? () => setSelectLeaseAgreementsDrawerOpen(true)
                  : undefined
              }
            >
              {t('Suggest space')}{' '}
            </Button>
          </Popover>
        )}
        {messageDisabled && !isLandlord && !answered && (
          <Tooltip open={isMobile ? undefined : false} title={t('Archive')}>
            <StyledIconButton
              icon={<MessageOutlined />}
              loading={loading}
              danger
              onClick={() => updateMessage(t('turnDownMessage'))}
              $withText={!isMobile}
            >
              {t('turnDown/notInterested')}{' '}
            </StyledIconButton>
          </Tooltip>
        )}

        <SendButton
          type={'primary'}
          onClick={handleSubmit}
          loading={loading}
          icon={<SendIcon size={'17'} style={{ marginTop: '2px' }} />}
          disabled={messageDisabled}
        >
          {t('Send')}
        </SendButton>
      </Box>
    </Box>
  );
};

export default ChatForm;
