import { Drawer, DrawerProps, List, Spin, Tabs } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match, P } from 'ts-pattern';

import {
  StatusEnum,
  useGetMatchingSearchRequestsQuery,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

import Message from '../Message';
import SearchRequestLandlord from '../SearchRequest/Landlord';
import { SearchRequestListItem } from '../SearchRequestListComponents';

interface SearchRequestDrawerProps extends DrawerProps {
  leaseAgreementId: string;
  title: string;
}
const { TabPane } = Tabs;
export default function SearchRequestDrawer({
  leaseAgreementId,
  title,
  ...rest
}: SearchRequestDrawerProps): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const [selectedSearchRequest, setSelectedSearchRequest] =
    useState<string>('');
  const [activeTab, setActiveTab] = useState('1');
  const { t } = useTranslation();
  // eslint-disable-next-line
  const onDecline = () => {};

  const { data, loading, error, previousData } =
    useGetMatchingSearchRequestsQuery({
      variables: {
        leaseAgreementId,
      },
      skip: !rest.open,
    });

  if (error) {
    return <Message error={error} />;
  }

  return (
    <div>
      <Drawer
        width={isMobile ? '100%' : '40%'}
        title={'Matchande Lokalförfrågningar'}
        placement="left"
        {...rest}
      >
        {match(data ?? previousData)
          .with(
            {
              leaseAgreement: P.select(),
            },
            data => {
              const searchRequests = data.matchingSearchRequests.searchRequests;
              return (
                <Tabs onChange={key => setActiveTab(key)} defaultActiveKey="1">
                  <TabPane
                    tab={`${t('Unanswered')} (${
                      searchRequests.filter(
                        item =>
                          item.myStatus !== StatusEnum.Declined &&
                          item.myStatus !== StatusEnum.Accepted,
                      ).length
                    })`}
                    key="1"
                  >
                    <List
                      size="small"
                      itemLayout="vertical"
                      dataSource={searchRequests.filter(
                        item =>
                          item.myStatus !== StatusEnum.Declined &&
                          item.myStatus !== StatusEnum.Accepted,
                      )}
                      locale={{
                        emptyText: <h3>{t('dashboardInquiries')}</h3>,
                      }}
                      renderItem={item => (
                        <div onClick={() => setActiveTab('2')}>
                          <SearchRequestListItem
                            onDecline={onDecline}
                            key={item.id}
                            item={item}
                            t={t}
                            selectSearchRequest={id =>
                              setSelectedSearchRequest(id)
                            }
                            selectedSearchRequest={selectedSearchRequest}
                          />
                        </div>
                      )}
                    />
                  </TabPane>

                  <TabPane
                    tab={`${t('pluralRejected')} (${
                      searchRequests.filter(
                        item => item.myStatus === StatusEnum.Declined,
                      ).length
                    })`}
                    key="2"
                  >
                    <List
                      size="small"
                      itemLayout="vertical"
                      dataSource={searchRequests.filter(
                        item => item.myStatus === StatusEnum.Declined,
                      )}
                      locale={{
                        emptyText: <h3>{t('dashboardInquiries')}</h3>,
                      }}
                      renderItem={item => (
                        <SearchRequestListItem
                          onDecline={onDecline}
                          key={item.id}
                          item={item}
                          t={t}
                          selectSearchRequest={id =>
                            setSelectedSearchRequest(id)
                          }
                          selectedSearchRequest={selectedSearchRequest}
                        />
                      )}
                    />
                  </TabPane>
                  <TabPane
                    tab={`${t('Answered')} (${
                      searchRequests.filter(
                        item => item.myStatus === StatusEnum.Accepted,
                      ).length
                    })`}
                    key="3"
                  >
                    <List
                      size="small"
                      itemLayout="vertical"
                      dataSource={searchRequests.filter(
                        item => item.myStatus === StatusEnum.Accepted,
                      )}
                      locale={{
                        emptyText: <h3>{t('dashboardInquiries')}</h3>,
                      }}
                      renderItem={item => (
                        <div onClick={() => setActiveTab('2')}>
                          <SearchRequestListItem
                            onDecline={onDecline}
                            key={item.id}
                            item={item}
                            t={t}
                            selectSearchRequest={id =>
                              setSelectedSearchRequest(id)
                            }
                            selectedSearchRequest={selectedSearchRequest}
                          />
                        </div>
                      )}
                    />
                  </TabPane>
                </Tabs>
              );
            },
          )
          .otherwise(() =>
            loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <Spin />
              </div>
            ) : (
              <Message error={error} />
            ),
          )}
        <></>
      </Drawer>
      <SearchRequestLandlord
        withMask
        open={!!selectedSearchRequest}
        id={selectedSearchRequest}
        onCancel={() => setSelectedSearchRequest('')}
      />
    </div>
  );
}
