import { useNavigate } from '@tanstack/react-location';
import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { toHome } from '@/views/Home/routes';
interface Props {
  extra?: React.ReactNode;
}

function Page404({ extra }: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box p={3}>
      <Result
        status="404"
        title="404"
        subTitle={t(`notFound`)}
        extra={
          extra ? (
            extra
          ) : (
            <Button
              onClick={() =>
                navigate({
                  to: toHome(),
                })
              }
              type="primary"
            >
              {t('Back home')}
            </Button>
          )
        }
      />
    </Box>
  );
}

export default Page404;
