import { Checkbox, Col, Form, Row, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';

interface LabelProps {
  title: string;
  icon?: JSX.Element;
  tooltipText?: string;
}
const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = ({
  title,
  icon,
  tooltipText,
}: LabelProps): JSX.Element => (
  <Flex>
    <span>{title}</span>
    <Tooltip title={tooltipText}>
      <span style={{ marginLeft: '10px', fontSize: '18px', color: '#50C878' }}>
        {' '}
        {icon}
      </span>
    </Tooltip>
  </Flex>
);

function StatusFilter(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box p={2} verticalSpacing={3}>
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item name="status">
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                {['vacant', 'rental', 'settlement', 'verified'].map(value => (
                  <Col key={value} span={12}>
                    <Checkbox value={value}>
                      {value == 'rental' ? t('duringRental') : t(value)}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
    </Box>
  );
}

export default StatusFilter;
