import styled from 'styled-components';

export default styled.div`
  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(20deg);
    }
    95% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  display: inline-block;
  animation: wiggle 1.5s infinite;
  font-size: 18px;
  margin-right: 4px;
`;
