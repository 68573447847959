import { useApolloClient } from '@apollo/client';
import {
  Button,
  ButtonProps,
  Checkbox,
  Drawer,
  Form,
  message,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import {
  useEditLeaseAgreementMutation,
  useGetLeaseAgreementEditQuery,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';
import BasicData from '@/views/LeaseAgreement/Form/BasicData';
import FormData from '@/views/LeaseAgreement/Form/FormData';
import Media from '@/views/LeaseAgreement/Form/Media';
import { treatLeaseData } from '@/views/LeaseAgreement/Form/util';

const { TabPane } = Tabs;
const { Title } = Typography;

export interface AddLeaseAgreementButtonProps {
  onClick: () => void;
}

interface Props extends ButtonProps {
  refetch?: boolean;
  id: string;
}

function EditLeaseAgreementModal({ id, refetch, ...rest }: Props): JSX.Element {
  const { t } = useTranslation();

  const { isMobile } = useWidthAndHeight();
  const apollo = useApolloClient();
  const [visible, setVisible] = React.useState(false);
  const user = useUser();
  const [editLeaseAgreement, { loading: editLoading }] =
    useEditLeaseAgreementMutation({
      onCompleted: () => {
        message.success(t('Saved'), 2.5);
        if (refetch) {
          apollo.reFetchObservableQueries();
        }
      },
      onError: () => {
        message.error(t('error'));
      },
    });
  const [form] = useForm();
  const {
    data,
    loading,
    previousData,
    refetch: refetchData,
  } = useGetLeaseAgreementEditQuery({
    variables: { id },
    skip: !visible,

    onCompleted: data => {
      console.log('completed', data);
    },
    onError: () => {
      message.error(t('error'));
      form.resetFields();
    },
  });
  useEffect(() => {
    setTimeout(() => {
      if (!data && visible) {
        refetchData();
      }
    }, 1000);
  }, [visible]);
  return (
    <>
      <Button {...rest} onClick={() => setVisible(true)} />
      <Drawer
        open={visible}
        onClose={() => setVisible(false)}
        size={'large'}
        placement={isMobile ? 'bottom' : 'right'}
        title={t('Edit premises')}
        height={'100%'}
        footer={
          <Box flex justifyContent={'flex-end'} horizontalSpacing={1}>
            <Button
              onClick={() => {
                setVisible(false);
              }}
            >
              {t('Close')}
            </Button>
            <Button
              type={'primary'}
              onClick={() => {
                form.submit();
              }}
              loading={editLoading}
              disabled={editLoading}
            >
              {t('Save')}
            </Button>
          </Box>
        }
      >
        <Box maxW={600} w={'100%'}>
          <Form
            form={form}
            layout={'vertical'}
            onFinish={fd => {
              const values = treatLeaseData(fd);

              return editLeaseAgreement({
                variables: {
                  id,
                  input: {
                    ...values,
                    landlordId: (data ?? previousData)?.leaseAgreement
                      .landlordId,
                    tenantId: (data ?? previousData)?.leaseAgreement.tenantId,
                  },
                },
              });
            }}
          >
            {loading || (!data && !previousData) ? (
              <Spin />
            ) : (
              <Tabs
                tabPosition={isMobile ? 'top' : 'left'}
                defaultActiveKey={'0'}
              >
                <TabPane tab={t('basicData')} key={'0'} forceRender>
                  <Title level={4}>{t('basicData')}</Title>
                  <BasicData
                    form={form}
                    values={(data ?? previousData)?.leaseAgreement}
                  />
                  {user?.role === 'admin' && (
                    <Form.Item
                      initialValue={
                        (data ?? previousData)?.leaseAgreement.prioritized
                      }
                      name="prioritized"
                      valuePropName="checked"
                      label="Prioriterad"
                    >
                      <Checkbox />
                    </Form.Item>
                  )}
                </TabPane>
                <TabPane tab={t('Premises description')} key={'1'} forceRender>
                  <Title level={4}>{t('Premises description')}</Title>
                  <FormData
                    form={form}
                    values={(data ?? previousData)?.leaseAgreement}
                  />
                </TabPane>

                <TabPane tab={t('Media')} key={'2'} forceRender>
                  <Title level={4}>{t('Media')}</Title>
                  <Media
                    values={(data ?? previousData)?.leaseAgreement}
                    form={form}
                  />
                </TabPane>
              </Tabs>
            )}
          </Form>
        </Box>
      </Drawer>
    </>
  );
}

export default EditLeaseAgreementModal;
