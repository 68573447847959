import { Form, Input, Typography } from 'antd';
import React from 'react';

import Box from '@/components/Box';
import theme from '@/main/theme';

interface Props {
  name: string | string[];
  value: string;
  title: string;
  editing?: boolean;
  icon?: JSX.Element;
}

function EditableTextField({
  name,
  value,
  title,
  editing,
  icon,
}: Props): JSX.Element {
  if (!value && !editing) {
    return <></>;
  }

  return (
    <Box maxW={400}>
      <Box
        style={{ color: theme.colors.typographySecondary, fontSize: '14px' }}
      >
        {icon} {title}
      </Box>
      {editing ? (
        <Form.Item name={name} initialValue={value}>
          <Input defaultValue={value} />
        </Form.Item>
      ) : (
        <Typography.Text style={{ whiteSpace: 'pre-line' }}>
          {value}
        </Typography.Text>
      )}
    </Box>
  );
}

export default EditableTextField;
