import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';
import { ReactionEnum, useReactOnMessageMutation } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';
import theme from '@/main/theme';

const Icon = styled.div<{ hasReaction: boolean }>`
  display: flex;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;

  transition: all 150ms;
  &:hover {
    margin-bottom: ${({ hasReaction }) => !hasReaction && '2px'};
  }
  border-bottom: ${({ hasReaction }) =>
    hasReaction ? `1px solid ${theme.colors.primaryPurple}` : ''};
  &:active {
    font-size: 20px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
`;

const Container = styled(Box)`
  position: absolute;
  left: 4px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 18%) 1px 2px 4px;
  border: 1px solid lightgray;
  padding: 0 8px;
`;
const DesktopContainer = styled(Container)`
  top: -40px;
  border-radius: 12px;
  height: 40px;
  z-index: 10;
`;
const MobileContainer = styled(Container)`
  justify-content: space-between;
  left: 0;
  bottom: -20px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  width: 230px;
  border-radius: 12px;

  height: 40px;
`;
export const reactionsList = [
  {
    enum: ReactionEnum.Like,
    icon: '👍',
  },
  {
    enum: ReactionEnum.Heart,
    icon: '❤️',
  },
  {
    enum: ReactionEnum.Smile,
    icon: '😀',
  },
  {
    enum: ReactionEnum.Surprised,
    icon: '😮',
  },
  {
    enum: ReactionEnum.Sad,
    icon: '😞',
  },
];
interface Props {
  setHover: Dispatch<SetStateAction<boolean>>;
  messageId: string;
  reactions: {
    __typename?: 'Reaction';
    userId: string;
    reaction: ReactionEnum;
    user: { __typename?: 'User'; name: string };
  }[];
}
export default function MessageReaction({
  messageId,
  reactions,
  setHover,
}: Props): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const user = useUser();

  const [reactOnMessage, { loading, error }] = useReactOnMessageMutation({});

  const setReaction = async (
    reaction: ReactionEnum,
    messageId: string,
  ): Promise<void> => {
    !loading &&
      (await reactOnMessage({
        variables: {
          input: {
            reaction,
            messageId,
          },
        },
      }));
    setHover(false);
  };

  const myReaction = reactions.find(elem => elem.userId === user.id)?.reaction;

  if (isMobile) {
    return (
      <MobileContainer
        key={`message${messageId}react`}
        flex
        alignItems={'center'}
      >
        {reactionsList.map(reaction => (
          <Icon
            key={reaction.enum}
            hasReaction={
              reactions.length > 0 ? reaction.enum === myReaction : false
            }
            onClick={() => setReaction(reaction.enum, messageId)}
          >
            {reaction.icon}
          </Icon>
        ))}
      </MobileContainer>
    );
  }

  return (
    <DesktopContainer key={`message${messageId}react`} flex alignItems="center">
      {reactionsList.map(reaction => (
        <IconWrapper key={reaction.enum}>
          <Icon
            hasReaction={
              reactions.length > 0 ? reaction.enum === myReaction : false
            }
            onClick={() => setReaction(reaction.enum, messageId)}
          >
            {reaction.icon}
          </Icon>
        </IconWrapper>
      ))}
    </DesktopContainer>
  );
}
