import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, message as antmessage, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import PresetAnswerForm from '@/components/SearchRequest/shared/DeclineSearchRequestButton/PresetAnswer/PresetAnswerForm';
import {
  GetMyPresetAnswersDocument,
  useAddPresetAnswerMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

const AddButton = styled(Button)`
  padding: 0;
  width: 100%;
  height: 52px;
  cursor: pointer;
`;
function AddPresetAnswer(): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const { t } = useTranslation();
  const [form] = useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [addPresetMessage] = useAddPresetAnswerMutation({
    onCompleted: () => {
      setVisible(false);
      form.resetFields();
      antmessage.success(t('Saved'));
    },
    onError: () => antmessage.error(t('error')),
    refetchQueries: [
      {
        query: GetMyPresetAnswersDocument,
      },
    ],
  });
  if (isMobile) {
    return null;
  }

  return (
    <div>
      <AddButton type={'dashed'}>
        <Box
          flex
          flexDirection={'column'}
          py={0.5}
          px={2}
          onClick={() => setVisible(true)}
        >
          <Box flex>
            <PlusCircleOutlined
              style={{
                marginTop: '4px',
                fontSize: '16px',
                marginLeft: '-1px',
              }}
            />
            <Box ml={1} style={{ textAlign: 'justify' }}>
              <Box>{t('Add')}</Box>
              <Typography.Text type={'secondary'}>
                {t('Add preset message')}
              </Typography.Text>
            </Box>
          </Box>
        </Box>
      </AddButton>

      <Drawer
        placement="right"
        title={t('Add preset message')}
        width={400}
        onClose={() => setVisible(false)}
        visible={visible}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <Form
          form={form}
          layout={'vertical'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
          onFinish={async fd => {
            const { name, message } = fd;
            await addPresetMessage({
              variables: {
                name,
                message,
              },
            });
          }}
        >
          <PresetAnswerForm />
        </Form>
      </Drawer>
    </div>
  );
}

export default AddPresetAnswer;
