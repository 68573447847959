import { Typography } from 'antd';
import React from 'react';

import Box from '@/components/Box';
import PortallyIcon from '@/components/PortallyLogo/PortallyIcon';

const { Text } = Typography;
import { Paragraph, SubTitle, Title } from '../V2/styled';

function CommissionLandlord({ lng }: { lng: string }): JSX.Element {
  return lng === 'sv' ? (
    <>
      <Title>Förmedlingsvillkor Portally</Title>
      <Paragraph>
        Dessa förmedlingsvillkor gäller för dig som medlem i Portally, nedan
        kallad <Text strong>Användaren</Text>, för fortsatt tillgång till
        Portallys tjänster.
      </Paragraph>
      <Paragraph>
        Portally är kostnadsfritt att använda enligt gällande användarvillkor.
        Arvode utgår enbart då hyresavtal tecknats med en hyresgäst där kontakt
        förmedlats via Portally.
      </Paragraph>
      <div>
        Kontakt med en ny hyresgäst anses ha förmedlats via Portally om:
        <ul>
          <li>En lokalförfrågan mottagits i Användarens panel,</li>
          <li>Kontakt initierats via direktmeddelanden, eller</li>
          <li>Kontakt initierats via en annonserad lokal</li>
        </ul>
      </div>
      <Paragraph>
        En förmedling är arvodesgrundande om Användaren ej kan påvisa att
        förfrågan mottagits dessförinnan.
      </Paragraph>
      <Paragraph>
        Det åligger Användaren att meddela Portally när nya hyresavtal tecknats.
        Samtliga transaktioner kontrolleras både hos de medlemmar som söker och
        de som hyr ut lokaler.
      </Paragraph>
      <SubTitle>Arvode</SubTitle>
      <Paragraph>
        Arvode utgår med 3 % av en genomsnittlig årshyra exkl. eventuella
        rabatter samt hyrestillägg som fastighetsskatt och värme/kyla per avtal
        som Användaren tecknar med hyresgäst eller annan avseende nyttjanderätt
        till lokal.
      </Paragraph>
      <SubTitle>Betalningsvillkor</SubTitle>
      <Paragraph>
        Fakturering av arvodet sker efter det att Användaren tecknat hyresavtal
        med hyresgästen.
      </Paragraph>
      <Paragraph>
        Betalningsvillkor är 30 dagar netto. Mervärdesskatt tillkommer på
        samtliga belopp enligt dessa villkor.
      </Paragraph>
      <SubTitle>Övrigt</SubTitle>
      <Paragraph>
        Dessa villkor gäller tills vidare. Genom att godkänna villkoren
        accepteras dessa för samtliga Användare tillhörande samma organisation.
      </Paragraph>
      <Paragraph>
        I det fall Portally uppdaterar villkoren kommer nya villkor träda i
        kraft först när de godkänts av Användaren.
      </Paragraph>
      <Box flex justifyContent={'center'} p={2}>
        <PortallyIcon />
      </Box>
    </>
  ) : (
    <>
      <Title style={{ textAlign: 'center' }}>Förmedlingsvillkor Portally</Title>
      <Paragraph>
        Dessa förmedlingsvillkor gäller för dig som medlem i Portally, nedan
        kallad <Text strong>Användaren</Text>, för fortsatt tillgång till
        Portallys tjänster.
      </Paragraph>
      <Paragraph>
        Portally är kostnadsfritt att använda enligt gällande användarvillkor.
        Arvode utgår enbart då hyresavtal tecknats med en hyresgäst där kontakt
        förmedlats via Portally.
      </Paragraph>
      <Paragraph>
        Kontakt med en ny hyresgäst anses ha förmedlats via Portally om:
        <ul>
          <li>En lokalförfrågan mottagits i Användarens panel,</li>
          <li>Kontakt initierats via direktmeddelanden, eller</li>
          <li>Kontakt initierats via en annonserad lokal</li>
        </ul>
      </Paragraph>
      <Paragraph>
        En förmedling är arvodesgrundande om Användaren ej kan påvisa att
        förfrågan mottagits dessförinnan.
      </Paragraph>
      <Paragraph>
        Det åligger Användaren att meddela Portally när nya hyresavtal tecknats.
        Samtliga transaktioner kontrolleras både hos de medlemmar som söker och
        de som hyr ut lokaler.
      </Paragraph>
      <SubTitle>Arvode</SubTitle>
      <Paragraph>
        Arvode utgår med 3 % av en genomsnittlig årshyra exkl. eventuella
        rabatter samt hyrestillägg som fastighetsskatt och värme/kyla per avtal
        som Användaren tecknar med hyresgäst eller annan avseende nyttjanderätt
        till lokal.
      </Paragraph>
      <SubTitle>Betalningsvillkor</SubTitle>
      <Paragraph>
        Fakturering av arvodet sker efter det att Användaren tecknat hyresavtal
        med hyresgästen.
      </Paragraph>
      <Paragraph>
        Betalningsvillkor är 30 dagar netto. Mervärdesskatt tillkommer på
        samtliga belopp enligt dessa villkor.
      </Paragraph>
      <SubTitle>Övrigt</SubTitle>
      <Paragraph>
        Dessa villkor gäller tills vidare. Genom att godkänna villkoren
        accepteras dessa för samtliga Användare tillhörande samma organisation.
      </Paragraph>
      <Paragraph>
        I det fall Portally uppdaterar villkoren kommer nya villkor träda i
        kraft först när de godkänts av Användaren.
      </Paragraph>
      <Box flex justifyContent={'center'} p={2}>
        <PortallyIcon />
      </Box>
    </>
  );
}

export default CommissionLandlord;
