import { DrawerProps, Spin } from 'antd';
import React from 'react';

import { GetLeaseAgreementViewQuery } from '@/generated/graphql';
import LeaseAgreement from '@/views/LeaseAgreement/View';

interface Props {
  loading: boolean;
  data: GetLeaseAgreementViewQuery | undefined;
  drawerPlacement?: DrawerProps['placement'];
}

function SingleMarkerPreview({
  data,
  loading,
  drawerPlacement,
}: Props): JSX.Element {
  return (
    <>
      {loading || !data ? (
        <div className={'h-full w-full flex justify-center items-center'}>
          <Spin />
        </div>
      ) : (
        <LeaseAgreement
          preview
          id={data.leaseAgreement.id}
          drawerPlacement={drawerPlacement}
        />
      )}
    </>
  );
}

export default SingleMarkerPreview;
