import { Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

export type EstablishmentRouteProps = BasicRouteProps;

const Establishment = React.lazy(() => import('.'));

const EstablishmentRoute: Route<EstablishmentRouteProps>[] = [
  {
    path: '/establishment',
    id: 'Establishment',
    element: <Establishment />,
    meta: {
      noPadding: true,
      whiteBackgroundPhone: true,
    },
  },
];

export const toEstablishment = (): string => '/establishment';

export default EstablishmentRoute;
