import { Button, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

function PresetAnswerForm(): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        label={t('Template')}
        name={'name'}
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <Input placeholder={t('Template')} />
      </Form.Item>
      <Form.Item
        label={t('message')}
        name={'message'}
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <TextArea
          autoSize={{ minRows: 5, maxRows: 9 }}
          placeholder={`${t('Message to tenant')}...`}
        />
      </Form.Item>
      <Form.Item style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}>
        <Button type={'primary'} htmlType={'submit'}>
          {t('Save')}
        </Button>
      </Form.Item>
    </>
  );
}

export default PresetAnswerForm;
