import { MakeGenerics, Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type ProjectsAdminRouteProps = MakeGenerics<AdminRouteBaseProps>;

const Projects = lazy(() => import('./index'));

const ProjectsAdminRoutes: Route<ProjectsAdminRouteProps>[] = [
  {
    path: 'project',
    id: 'project',
    element: <Projects />,
  },
];

export const toProjectsAdmin = (): string => '/admin/project';

export default ProjectsAdminRoutes;
