import { Select, SelectProps } from 'antd';
import React from 'react';

import { innerCity, outerCity } from '@/components/SearchRequestFilter/util';
import { RegionTypeEnum, useGetFilterCountiesQuery } from '@/generated/graphql';

const { Option, OptGroup } = Select;

function SelectCounties(props: SelectProps): JSX.Element {
  const { data, loading } = useGetFilterCountiesQuery({});
  if (loading) {
    return <Select {...props} loading />;
  }

  return (
    <Select {...props} showSearch optionFilterProp={'children'}>
      <OptGroup label={'Län'}>
        {data?.getRegions.regions.map(({ id, name }) => (
          <Option value={id} key={id}>
            {name}
          </Option>
        ))}
      </OptGroup>
      <OptGroup label={'Malmö'}>
        {data?.getRegions.regions
          .find(({ name }) => name === 'Skåne')
          .children.filter(
            region => region.regionType === RegionTypeEnum.DistrictArea,
          )
          .map(region => (
            <Option value={region.id} key={region.id}>
              {region.name}
            </Option>
          ))}
      </OptGroup>

      <OptGroup label={'Göteborg'}>
        {data?.getRegions.regions
          .find(({ name }) => name === 'Västra Götaland')
          .children.filter(
            region => region.regionType === RegionTypeEnum.DistrictArea,
          )
          .map(region => (
            <Option value={region.id} key={region.id}>
              {region.name}
            </Option>
          ))}
      </OptGroup>
      <OptGroup label={'Innanför tull'}>
        {innerCity.map(({ id, name }) => (
          <Option value={id} key={id}>
            {name}
          </Option>
        ))}
      </OptGroup>
      <OptGroup label={'Utanför tull'}>
        {outerCity.map(({ id, name }) => (
          <Option value={id} key={id}>
            {name}
          </Option>
        ))}
      </OptGroup>
      <OptGroup label={'Skåne kommuner'}>
        {data?.getRegions.regions
          .find(({ name }) => name === 'Skåne')
          .children.filter(
            region => region.regionType === RegionTypeEnum.Municipality,
          )
          .map(region => (
            <Option value={region.id} key={region.id}>
              {region.name}
            </Option>
          ))}
      </OptGroup>
      <OptGroup label={'Västra Götaland kommuner'}>
        {data?.getRegions.regions
          .find(({ name }) => name === 'Västra Götaland')
          .children.filter(
            region => region.regionType === RegionTypeEnum.Municipality,
          )
          .map(region => (
            <Option value={region.id} key={region.id}>
              {region.name}
            </Option>
          ))}
      </OptGroup>
    </Select>
  );
}

export default SelectCounties;
