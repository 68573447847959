import { Select, SelectProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { categoryIcons } from '@/components/Tags/UsageCategoryTags';
import { usageCategories } from '@/shared';

const { Option } = Select;

function SelectUsageCategories(props: SelectProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Select {...props}>
      {usageCategories.map(category => (
        <Option value={category} key={category}>
          <Box flex horizontalSpacing={0.5} alignItems={'center'}>
            {categoryIcons('small')[category]}
            <div>{t(category)}</div>
          </Box>
        </Option>
      ))}
    </Select>
  );
}

export default SelectUsageCategories;
