import { Button, message, Typography } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import PortallyIcon from '@/components/PortallyLogo/PortallyIcon';
import { useRemoveDealMutation } from '@/generated/graphql';
const { Title, Text } = Typography;
const Buttons = styled.div`
  display: flex;
  justify-content: center;

  > Button {
    margin: 12px 8px;
    padding: 24px 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
interface Props {
  isLandlord: boolean;
  setAcceptedDealRequest: Dispatch<SetStateAction<boolean>>;
  setShowDealRequest: Dispatch<SetStateAction<boolean>>;
}
export default function Landing({
  isLandlord,
  setAcceptedDealRequest,
  setShowDealRequest,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [removeDeal, { loading: rLoading }] = useRemoveDealMutation({
    onCompleted: () => {
      message.success(
        isLandlord
          ? 'Tack för ditt svar! Kontakta oss gärna på support@portally.com så hjälper vi dig att hitta nya hyresgäster'
          : 'Tack för ditt svar! Kontakta oss gärna på support@portally.com så hjälper vi dig med din sökning.',
        5,
      );
      setShowDealRequest(false);
      setAcceptedDealRequest(true);
    },
  });
  return (
    <Box flex flexDirection={'column'} p={4}>
      <Box mb={2} flex justifyContent={'center'}>
        <PortallyIcon />
      </Box>
      {isLandlord ? (
        <Box p={1} flex justifyContent={'center'} flexDirection={'column'}>
          <Title style={{ margin: '0 auto' }} level={5}>
            Hej!
          </Title>
          <Text>
            Vi vill följa upp hur det har gått med din prospektering i Portally.
            Har du kommit i kontakt med någon hyresgäst som lett till ett
            tecknat hyresavtal?
          </Text>
        </Box>
      ) : (
        <Box p={1} flex justifyContent={'center'} flexDirection={'column'}>
          <Title style={{ margin: '0 auto' }} level={5}>
            Hej!
          </Title>
          <Text>
            Vi vill följa upp hur det har gått med dina lokalsökningar i
            Portally. Har du fått någon kontakt via Portally som lett till ett
            tecknat hyresavtal?
          </Text>
        </Box>
      )}

      <Buttons>
        <Button onClick={() => setAcceptedDealRequest(true)} type={'primary'}>
          {t('yes')}
        </Button>
        <Button loading={rLoading} onClick={() => removeDeal()}>
          {t('no')}
        </Button>
      </Buttons>
    </Box>
  );
}
