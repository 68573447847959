import { Form, FormInstance, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { GetLeaseAgreementEditQuery } from '@/generated/graphql';
import useUser from '@/hooks/useUser';

import NearbyServicesForm from './NearbyServicesForm';

const { TextArea } = Input;

interface Props {
  values: GetLeaseAgreementEditQuery['leaseAgreement'] | Record<string, never>;
  form: FormInstance;
}

const descriptionLayout = {
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 16 },
  },
};

export default function FormData({ values, form }: Props): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        label={t('Header')}
        initialValue={values['title'] || undefined}
        name={'title'}
      >
        <TextArea
          autoSize={{ minRows: 2 }}
          placeholder={t('Header')}
          showCount
          autoFocus
          maxLength={150}
        />
      </Form.Item>
      <Form.Item
        label={t('description')}
        initialValue={values['description'] || undefined}
        name={`${user?.activeClient?.type}Description`}
      >
        <TextArea
          autoSize={{ minRows: 3 }}
          placeholder={t('Describe your premises')}
        />
      </Form.Item>
      <Form.Item
        label={t('areaDescription')}
        initialValue={values['areaDescription'] || undefined}
        name={`areaDescription`}
      >
        <TextArea
          autoSize={{ minRows: 3 }}
          placeholder={t('areaDescription placeholder')}
        />
      </Form.Item>
      <NearbyServicesForm values={values} form={form} />
    </>
  );
}
