import { Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

const Home = React.lazy(() => import('.'));

const HomeRoutes: Route<BasicRouteProps>[] = [
  {
    path: '/',
    id: 'Home',
    element: <Home />,
    meta: {
      whiteBackgroundPhone: true,
    },
  },
];

export const toHome = (): string => '/';

export default HomeRoutes;
