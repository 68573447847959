import React from 'react';

import SearchRequestInformation from '@/components/SearchRequest/shared/Information';
import Spinner from '@/components/Spinner';
import { useGetSearchRequestLandlordQuery } from '@/generated/graphql';

interface Props {
  searchRequestReferenceId: string;
}

function Information({ searchRequestReferenceId }: Props): JSX.Element {
  const { data, loading } = useGetSearchRequestLandlordQuery({
    variables: {
      id: searchRequestReferenceId,
    },
    skip: !searchRequestReferenceId,
  });

  if (loading) {
    return <Spinner minHeight={'300px'} />;
  }

  if (!data) {
    return null;
  }
  const { searchRequestReference } = data;
  return (
    <SearchRequestInformation
      type={'flat'}
      searchRequestReference={searchRequestReference}
      withTitle={false}
    />
  );
}

export default Information;
