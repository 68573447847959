import { Form, Input, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

const { Title: AntTitle } = Typography;

interface Props {
  title: string;
  editing: boolean;
  required?: boolean;
}
function Title({ title, editing, required }: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  if (editing) {
    return (
      <Box {...(isMobile ? { w: '100%' } : {})}>
        <AntTitle level={5}>{t('Header')}</AntTitle>
        <Form.Item
          name={'title'}
          initialValue={title}
          rules={[
            {
              required,
              message: t('required'),
            },
          ]}
        >
          <Input style={!isMobile ? { minWidth: '500px' } : {}} />
        </Form.Item>
      </Box>
    );
  }

  return <h3 className="mb-0 text-2xl font-semibold">{title}</h3>;
}

export default Title;
