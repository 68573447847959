import { ApolloError } from '@apollo/client';
import { Button, Spin, UploadFile } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Message from '@/components/Message';
import Information from '@/components/SearchRequest/shared/Information';
import { SelectedLeaseAgreement } from '@/components/SelectLeaseAgreements';
import {
  DocumentValuesFragment,
  GetSearchRequestLandlordQuery,
} from '@/generated/graphql';

import PromoteAndAnswer from './PromoteAndAnswer';

interface Props {
  step: number;
  loading: boolean;
  error: ApolloError | undefined;
  searchRequest: GetSearchRequestLandlordQuery['searchRequestReference'];
  message?: string;
  setMessage?: Dispatch<SetStateAction<string>>;
  selectedLeaseAgreements?: SelectedLeaseAgreement[];
  setSelectedLeaseAgreements?: Dispatch<
    SetStateAction<SelectedLeaseAgreement[]>
  >;
  setSelectedAttachments?: Dispatch<
    SetStateAction<UploadFile<DocumentValuesFragment>[]>
  >;
  selectedAttachments?: UploadFile<DocumentValuesFragment>[];
  sharedSearchRequest?: boolean;
}

function SearchRequestInformation({
  error,
  step,
  searchRequest,
  loading,
  message,
  setMessage,
  selectedLeaseAgreements,
  setSelectedLeaseAgreements,
  setSelectedAttachments,
  selectedAttachments,
  sharedSearchRequest,
}: Props): JSX.Element {
  const step1 = useRef<HTMLDivElement>(null);
  const step0 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (step === 1) {
      step1.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      step0.current?.scrollIntoView();
    }
  }, [step]);

  if (error) {
    return <Message error={error} />;
  }

  return (
    <Spin spinning={loading} delay={300}>
      <div style={{ display: step < 2 ? 'block' : 'none' }} ref={step0}>
        <Information
          searchRequestReference={searchRequest}
          type={'flat'}
          answeredBy={searchRequest.searchRequests}
        />
      </div>
      <div
        ref={step1}
        className="h-screen"
        style={{ display: step > 0 ? '' : 'none' }}
      >
        <PromoteAndAnswer
          visible={step === 1}
          searchRequestReference={searchRequest}
          setSelectedLeaseAgreements={setSelectedLeaseAgreements}
          selectedLeaseAgreements={selectedLeaseAgreements}
          message={message}
          setMessage={setMessage}
          setSelectedAttachments={setSelectedAttachments}
          selectedAttachments={selectedAttachments}
          sharedSearchRequest={sharedSearchRequest}
          scrollRef={step0.current}
        />
      </div>
    </Spin>
  );
}

export default SearchRequestInformation;
