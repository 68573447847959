import dayjs from 'dayjs';
import ellipsis from 'html-ellipsis';
import React from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';
import Comment from '@/components/Comment';
interface Props {
  avatarUrl?: string;
  author: string;
  message: string;
  created?: Date;
}

const CommentContainer = styled(Box)`
  .ant-comment-inner {
    padding: 4px 0;
  }
`;

function MessagePreview({
  avatarUrl,
  author,
  message,
  created,
}: Props): JSX.Element {
  return (
    <CommentContainer>
      <Comment
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: ellipsis(
                message.replace('h1', 'h3').replace('h2', 'h3'),
                60,
                true,
              ),
            }}
          />
        }
        author={author}
        avatarUrl={avatarUrl}
        datetime={dayjs(created).fromNow()}
      />
    </CommentContainer>
  );
}

export default MessagePreview;
