import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';
const Filter = React.lazy(() => import('./Filter'));

const CRM = React.lazy(() => import('./List'));
const View = React.lazy(() => import('./View'));
const Edit = React.lazy(() => import('./EditClient'));
const Add = React.lazy(() => import('./AddClient'));

export type ClientAdminRouteProps = MakeGenerics<{
  Params: {
    clientId?: string;
  };
  LoaderData: {
    clientId: string;
  };
}> &
  AdminRouteBaseProps;

const ClientAdminRoutes: Route<ClientAdminRouteProps>[] = [
  {
    path: 'crm',
    id: 'Clients',
    meta: {
      breadcrumbs: () => ({
        breadcrumb: 'CRM',
      }),
    },
    children: [
      {
        path: '/',
        id: 'Client List',
        element: <CRM />,
        meta: {
          sider: <Filter />,
        },
      },
      {
        path: '/add',
        id: 'Client Add',
        element: <Add />,

        meta: {
          breadcrumbs: () => ({
            breadcrumb: 'Lägg till kund',
          }),
        },
      },
      {
        path: ':clientId',
        id: 'Client',
        children: [
          {
            path: '/',
            id: 'Client View',
            element: <View />,
            loader: ({ params }) => ({
              clientId: params.clientId,
            }),
            meta: {
              breadcrumbs: () => ({
                breadcrumb: 'Kund',
              }),
            },
          },
          {
            path: 'edit',
            id: 'Client Edit',
            element: <Edit />,
            loader: ({ params }) => ({
              clientId: params.clientId,
            }),
            meta: {
              breadcrumbs: ({ clientId }) => ({
                breadcrumb: `Redigera`,
                parent: {
                  breadcrumb: 'Kund',
                  pathname: `/admin/crm/${clientId}`,
                },
              }),
            },
          },
        ],
      },
    ],
  },
];

export const toClientList = (): string => '/admin/crm/';
export const toClientAdd = (): string => '/admin/crm/add';
export const toClientEdit = (clientId: string): string =>
  `/admin/crm/${clientId}/edit`;
export const toClient = (clientId: string): string => `/admin/crm/${clientId}`;

export default ClientAdminRoutes;
