import { PersonFill } from '@styled-icons/bootstrap/PersonFill';
import { Avatar } from 'antd';
import React from 'react';

import Box from '@/components/Box';

interface Props {
  avatarUrl?: string;
  content: JSX.Element | string;

  author: string;
  datetime?: string;
  actions?: JSX.Element[];
}

function Comment({
  avatarUrl,
  content,
  datetime,
  author,
  actions = [],
}: Props): JSX.Element {
  return (
    <Box flex horizontalSpacing={1} alignItems={'flex-start'} pb={1}>
      {avatarUrl && (
        <Avatar
          src={avatarUrl}
          icon={<PersonFill />}
          style={{ flexShrink: 0 }}
        />
      )}
      <Box flex flexDirection={'column'}>
        <div className={'gap-1 flex flex-wrap text-xs'}>
          <Box color={'rgba(0,0,0,0.8)'} flexShrink={0}>
            {author}
          </Box>
          {datetime && <span className={'break-words'}>{datetime}</span>}
        </div>
        {content}
        <Box mt={1} style={{ fontSize: '12px' }}>
          {actions}
        </Box>
      </Box>
    </Box>
  );
}

export default Comment;
