import { Image } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
interface Props {
  imageUrls: string[];
  selectedImage: string;
  visible: boolean;
  onVisibleChange: Dispatch<SetStateAction<boolean>>;
}

function HiddenImagePreview({
  imageUrls,
  selectedImage,
  visible,
  onVisibleChange,
}: Props): JSX.Element {
  return (
    <div style={{ display: 'none' }}>
      <Image.PreviewGroup
        preview={{
          visible,
          onVisibleChange,
          current: imageUrls.indexOf(selectedImage),
          className: 'prevent-active-request-drawer-close rounded-xl',
          rootClassName: 'prevent-active-request-drawer-close',
        }}
        key={selectedImage}
      >
        {imageUrls.map(src => (
          <Image src={src} key={src} />
        ))}
      </Image.PreviewGroup>
    </div>
  );
}

export default HiddenImagePreview;
