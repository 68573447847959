import { LinkOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  searchRequestReferenceId: string;
  onCancel: () => void;
}

function Link({ searchRequestReferenceId, onCancel }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button
      icon={<LinkOutlined />}
      data-clipboard-action="copy"
      onClick={async e => {
        e?.preventDefault();
        e?.stopPropagation();
        copy(
          `${import.meta.env.VITE_WEB_URL}/shared/${searchRequestReferenceId}`,
        );
        message.success(t('Copied'));
        onCancel();
      }}
    >
      {t('Copy link')}
    </Button>
  );
}

export default Link;
