import { RedoOutlined } from '@ant-design/icons';
import {
  GoogleMap,
  Polygon as MapPolygon,
  useLoadScript,
} from '@react-google-maps/api';
import { Button, Spin } from 'antd';
import React, { CSSProperties, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ClearPolygons from '@/components/Map/shared/ClearPolygons';
import Drawing from '@/components/Map/shared/Drawing';
import Container from '@/components/Map/shared/MapContainer';
import ToggleDrawing from '@/components/Map/shared/ToggleDrawing';
import { LatLngLiteral } from '@/components/Map/types';
import {
  Left,
  libraries,
  RelativeContainer,
  Right,
} from '@/components/Map/util';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { theme } from '@/main/theme';
import {
  updateBounds,
  updateSearchCenter,
  updateZoom,
} from '@/state/customer/actions';

import { mapStyle, polygonColors } from './mapstyle';
import Panner from './Panner';

declare global {
  interface Window {
    google: any;
  }
}

interface LatLngBoundsLiteral {
  east: number;

  north: number;

  south: number;

  west: number;
}

export type mapKeys =
  | 'adminSearch'
  | 'search'
  | 'establishment'
  | 'estates'
  | 'management';

interface Props {
  drawing?: boolean;
  style: CSSProperties;
  children: ReactElement;
  initialCenter?: LatLngLiteral;
  updateBounds?: boolean;
  panner?: boolean;
  mapKey: mapKeys;
  polygons?: LatLngLiteral[][];
  setPolygons?: (polygons: LatLngLiteral[][]) => void;
  regionPolygons?: google.maps.LatLngLiteral[][];
  height: string;
}

interface MapControlState {
  map?: google.maps.Map;
  timeout?: number;
}

export const initialCenter = {
  lng: 16.811799,
  lat: 62.641262,
};

function MapContainer({
  style = {},
  updateBounds: shouldUpdateBounds = false,
  children,
  panner = true,
  mapKey,
  polygons = [],
  setPolygons,
  regionPolygons = [],
  height,
}: Props): JSX.Element {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [drawing, setDrawing] = useState(false);
  const [state, setState] = useState<MapControlState>({
    map: undefined,
    timeout: undefined,
  });
  const defaultZoom = mapKey === 'search' ? 4 : 5;
  const zoom = useAppSelector(state => state.customer.zoom[mapKey]);
  const center = useAppSelector(state => state.customer.center[mapKey]);
  if (!isLoaded) {
    return (
      <Container
        height={height}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size={'large'} />
      </Container>
    );
  }
  return (
    <Container height={height}>
      <RelativeContainer>
        <Left>
          {setPolygons && (
            <ToggleDrawing drawing={drawing} setDrawing={setDrawing} />
          )}
          {setPolygons && zoom != defaultZoom && (
            <Button
              onClick={() => {
                dispatch(updateZoom(defaultZoom, mapKey));
                dispatch(updateSearchCenter(initialCenter, mapKey));
              }}
              style={{ marginLeft: '4px' }}
              icon={<RedoOutlined />}
            >
              {t('Reset map')}
            </Button>
          )}
        </Left>
      </RelativeContainer>
      <GoogleMap
        id="map"
        key={mapKey}
        zoom={zoom}
        center={center}
        mapContainerStyle={{ ...style, height }}
        onCenterChanged={() => {
          if (state.map) {
            const center = state.map.getCenter();
            setState(prev => ({
              ...prev,
              center,
            }));

            dispatch(updateSearchCenter(center, mapKey));
          }
        }}
        onLoad={map => {
          setState(prev => ({
            ...prev,
            map,
          }));
          dispatch(updateZoom(map.getZoom(), mapKey));
        }}
        onBoundsChanged={() => {
          if (state.map) {
            const z = state.map.getZoom();
            z != zoom && dispatch(updateZoom(z, mapKey));
          }

          if (state.map && shouldUpdateBounds) {
            const later = (): void => {
              setState(prev => ({ ...prev, timeout: undefined }));
              const bounds: LatLngBoundsLiteral = state?.map
                .getBounds()
                .toJSON();
              dispatch(updateBounds(bounds));
            };
            if (state.timeout) {
              clearTimeout(state.timeout);
            }

            setState(prev => ({
              ...prev,
              timeout: window.setTimeout(later, 200),
            }));
          }
        }}
        onIdle={() => {
          /*
          if (state.map && shouldUpdateBounds) {
            const later = (): void => {
              setState(prev => ({ ...prev, timeout: undefined }));
              const bounds: LatLngBoundsLiteral = state.map
                .getBounds()
                .toJSON();

              dispatch(updateBounds(bounds));
            };
            if (state.timeout) {
              clearTimeout(state.timeout);
            }

            setState(prev => ({
              ...prev,
              timeout: window.setTimeout(later, 200),
            }));
          }
           */
        }}
        options={{
          fullscreenControl: false,
          mapTypeControl: !setPolygons,
          mapTypeControlOptions: {
            mapTypeIds: [
              window['google']?.maps.MapTypeId.ROADMAP,
              window['google']?.maps.MapTypeId.SATELLITE,
            ],
          },
          styles: mapStyle,
        }}
      >
        {children}

        {regionPolygons.map((polygon, i) => (
          <MapPolygon
            paths={polygon}
            key={i}
            options={{
              fillColor: theme.colors.primaryPurple,
              fillOpacity: 0.2,
              strokeColor: theme.colors.primaryPurple,
              strokeOpacity: 1,
              strokeWeight: 3,
            }}
          />
        ))}

        {polygons.map((polygon, i) => (
          <MapPolygon
            path={polygon}
            key={i}
            options={{
              fillColor: polygonColors[i],
              fillOpacity: 0.4,
              strokeColor: '#000',
              strokeOpacity: 1,
              strokeWeight: 1,
            }}
          />
        ))}
        {setPolygons && polygons && (
          <Drawing
            drawing={drawing}
            polygons={polygons}
            setPolygons={setPolygons}
          />
        )}
        {panner && <Panner />}
      </GoogleMap>

      <RelativeContainer>
        <Right>
          {!setPolygons && zoom != defaultZoom && (
            <Button
              onClick={() => {
                dispatch(updateZoom(defaultZoom, mapKey));
                dispatch(updateSearchCenter(initialCenter, mapKey));
              }}
              icon={<RedoOutlined />}
            >
              {t('Reset map')}
            </Button>
          )}
          {setPolygons && polygons && (
            <ClearPolygons setPolygons={setPolygons} polygons={polygons} />
          )}
        </Right>
      </RelativeContainer>
    </Container>
  );
}

export default React.memo(MapContainer);
