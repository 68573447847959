import { useApolloClient } from '@apollo/client';
import { Form, message } from 'antd';
import React from 'react';

import { AdInput, AdPlacement, useAddAdMutation } from '@/generated/graphql';
import AdForm from '@/views/PortallyPartner/Ads/AdForm';

interface Props {
  partnerId: string;
}

export default function AddAd({ partnerId }: Props): JSX.Element {
  const apollo = useApolloClient();

  const [form] = Form.useForm<AdInput>();
  const [addAd, { loading, error }] = useAddAdMutation({
    onCompleted: () => {
      form.resetFields();
      apollo.reFetchObservableQueries();
      message.success('Annons uppladdad');
    },
    onError: () => {
      message.error(error.message);
    },
  });

  return (
    <AdForm
      loading={loading}
      onSubmit={values => {
        addAd({
          variables: {
            input: {
              partnerId,
              imageId: values.imageId,
              placement: values.placement as AdPlacement,
              link: values.link,
              mobileImageId: values.mobileImageId,
            },
          },
        });
      }}
      form={form}
    />
  );
}
