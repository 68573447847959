import { Alert } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
}

function InquiryInfo({ visible }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: visible ? 'block' : 'none',
      }}
    >
      <Alert
        style={{ marginTop: '10px' }}
        type={'info'}
        showIcon
        message={<div>{t('searchRequestInfo')}</div>}
      />
    </div>
  );
}

export default InquiryInfo;
