import React from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';
import { GetPortallyPartnerQuery } from '@/generated/graphql';
import Description from '@/views/PortallyPartner/Section/Description';
import DisplayResource from '@/views/PortallyPartner/Section/DisplayResource';

interface Props {
  header: string;
  text: string;
  displayResource: GetPortallyPartnerQuery['portallyPartner']['displayResource'];
}

export const SectionContainer = styled.div`
  @media (max-width: ${p => p.theme.breakpoints.mobile}) {
    padding: 48px 24px;
  }
  img {
    border-radius: 6px;
  }
  width: 100%;
`;
const Cont = styled.div`
  display: flex;
  align-items: center;
  img {
    border-radius: 0;
  }
  justify-content: flex-start;
  flex-direction: column;
`;
const Container = styled(SectionContainer)`
  padding: 48px 0px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: column-reverse;
  } ;
`;

const ContentContainer = styled(Box)`
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 100%;
    flex-direction: column-reverse;
  }
  margin: 0 auto;
`;

function Section({ header, text, displayResource }: Props): JSX.Element {
  return (
    <Container>
      <ContentContainer gap={2}>
        <Description header={header} text={text} />
        <Cont>
          <DisplayResource displayResource={displayResource} />
        </Cont>
      </ContentContainer>
    </Container>
  );
}
export default React.memo(Section);
