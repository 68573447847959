import React from 'react';

import { Maybe } from '@/generated/graphql';
import { formatNumber } from '@/utils';

interface Props {
  min?: Maybe<number>;
  max?: Maybe<number>;
}

function MinMax({ min, max }: Props): JSX.Element {
  if (min && !max) {
    return <>{min} m²</>;
  }
  if (min && max) {
    return <>{`${formatNumber(min)} - ${formatNumber(max)} m²`}</>;
  }
  if (!min && max) {
    return <>{`0 - ${formatNumber(max)} m²`}</>;
  }
  return <div></div>;
}

export default MinMax;
