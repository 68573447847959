import {
  GetLoggedInUserQuery,
  useGetLoggedInUserQuery,
} from '@/generated/graphql';

export type User = GetLoggedInUserQuery['me'];

const useUser = (): User | undefined => {
  const { data, error, previousData } = useGetLoggedInUserQuery();

  if ((!data && !previousData) || error) {
    return undefined;
  }

  if (data) {
    return data.me;
  }
  if (previousData) {
    return previousData.me;
  }

  return undefined;
};

export default useUser;
