import { FormInstance, message, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import StaticMap from '@/components/Map/StaticMap';
import RegionAutocomplete from '@/components/RegionAutocomplete';
import { useGetBoundsAndPolygonsQuery } from '@/generated/graphql';
import translatePolygonCoordinates from '@/main/translatePolygonToInput';
import { compareBoundsEqual } from '@/utils/bounds';

const { Title } = Typography;

interface Props {
  visible: boolean;
  polygons: google.maps.LatLngLiteral[][];
  setPolygons: (polygons: google.maps.LatLngLiteral[][]) => void;
  regionPolygons: google.maps.LatLngLiteral[][];
  setRegionPolygons: (polygons: google.maps.LatLngLiteral[][]) => void;
  form: FormInstance;
  isMobile: boolean;
}

function Area({
  visible,
  regionPolygons,
  setRegionPolygons,
  polygons,
  setPolygons,
  form,
  isMobile,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [bounds, setBounds] = useState(undefined);
  const regionIds = form.getFieldValue('regionIds')?.map(obj => obj.key) ?? [];

  useGetBoundsAndPolygonsQuery({
    variables: {
      search: {
        regionIds,
        geometry:
          polygons.length > 0
            ? {
                type: 'MultiPolygon',
                coordinates: [
                  polygons.map(polygon => translatePolygonCoordinates(polygon)),
                ],
              }
            : [],
      },
    },
    onCompleted: data => {
      message.destroy('loading');
      if (data && data.leaseAgreements) {
        const { regionBounds: newBounds, regionPolygons: newRegionPolygons } =
          data.leaseAgreements;

        if (newBounds && !compareBoundsEqual(newBounds, bounds)) {
          setBounds(newBounds);
        }

        if (newRegionPolygons.length !== regionPolygons.length) {
          setRegionPolygons(newRegionPolygons);
        }
      }
    },
    onError: async error => {
      if (error.message.startsWith('Loop')) {
        setPolygons(polygons.slice(0, -1));
        message.error({
          content: t('polygonError'),
          key: 'polygonError',
        });
      }
    },
  });
  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      <Box flex alignItems={'center'} flexDirection={'column'}>
        <Title level={4}>
          {isMobile
            ? t('searchAreaDescriptionMobile')
            : t('searchAreaDescription')}
        </Title>
        <Box
          minW={isMobile ? undefined : 400}
          w={isMobile ? '100%' : undefined}
        >
          {/*eslint-disable-next-line @typescript-eslint/no-empty-function*/}
          <RegionAutocomplete handleSearch={() => {}} />
        </Box>
      </Box>

      <StaticMap
        height={isMobile ? '325px' : '400px'}
        style={{ width: '100%', borderRadius: '6px' }}
        polygons={polygons}
        zoom={4}
        setPolygons={isMobile ? undefined : polygons => setPolygons(polygons)}
        regionPolygons={regionPolygons}
        bounds={bounds}
        mapTypeControl={false}
        streetView={false}
        allowReload
      />
    </div>
  );
}

export default Area;
