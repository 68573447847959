import { MessageOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Avatar, Button, Input, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { useAddSearchRequestCommentLandlordMutation } from '@/generated/graphql';
import useUser from '@/hooks/useUser';

interface Props {
  searchRequestId: string;
}

function AddComment({ searchRequestId }: Props): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();

  const apollo = useApolloClient();
  const [comment, setComment] = useState('');
  const [addComment] = useAddSearchRequestCommentLandlordMutation({
    onCompleted: async () => {
      message.success(`${t('Comment added')}`);
      setComment('');
      await apollo.reFetchObservableQueries();
    },
    onError: err => message.error(err),
  });
  return (
    <Box flex flexDirection={'column'} verticalSpacing={2}>
      <Box flex horizontalSpacing={1}>
        <Box flexGrow={1}>
          <Avatar src={user?.image?.url} />
        </Box>
        <Input
          bordered={false}
          placeholder={t('Add comment')}
          value={comment}
          onKeyDown={e => {
            const correctKey = e.key === 'Enter' || e.keyCode === 13;
            if (correctKey && comment.length > 0) {
              addComment({
                variables: {
                  searchRequestId,
                  input: {
                    comment,
                  },
                },
              });
            }
          }}
          onChange={e => setComment(e.target.value)}
        />
        <Button
          type={'text'}
          onClick={() =>
            addComment({
              variables: {
                searchRequestId,
                input: {
                  comment,
                },
              },
            })
          }
          disabled={comment.length === 0}
          icon={<MessageOutlined />}
        />
      </Box>
    </Box>
  );
}

export default AddComment;
