import { DeleteOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Button, ButtonProps, Popconfirm } from 'antd';
import React from 'react';

import Message from '@/components/Message';
import { useDeleteAdMutation } from '@/generated/graphql';
interface Props extends ButtonProps {
  adId: string;
}
export default function DeleteAdButton({ adId, ...rest }: Props): JSX.Element {
  const apollo = useApolloClient();

  const [deleteAd, { loading, error }] = useDeleteAdMutation({
    onCompleted: () => apollo.reFetchObservableQueries(),
  });
  return (
    <>
      <Popconfirm
        title={
          'Om du raderar en annons kommer all data försvinna, du kan inaktivera annonsen istället'
        }
        onConfirm={async () => deleteAd({ variables: { id: adId } })}
        okText={'Radera'}
        cancelText={'Avbryt'}
      >
        <Button
          danger
          loading={loading}
          icon={<DeleteOutlined />}
          {...rest}
        ></Button>
      </Popconfirm>
      {error && <Message error={error} />}
    </>
  );
}
