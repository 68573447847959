import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';

import searchReducer from '@/state/admin/reducers';
import clientPanelReducer from '@/state/client/reducers';
import customerReducer from '@/state/customer/reducers';

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any;
  }
}

export default function configureStore(): Store {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

  const rootReducer = combineReducers({
    customer: customerReducer,
    admin: searchReducer,
    client: clientPanelReducer,
  });

  const middlewares = applyMiddleware();

  const store = createStore(rootReducer, composeEnhancers(middlewares));

  return store;
}
