import { Col, Input, Row } from 'antd';
import { Rule } from 'antd/es/form';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Address } from '@/generated/graphql';

export const addressValidationRules = (t: TFunction): Rule[] => [
  {
    message: t('streetRequired'),
    validator: async (rule, value) =>
      value?.street
        ? Promise.resolve()
        : Promise.reject('Du måste fylla i gatuadress.'),
  },
  {
    message: t('cityRequired'),
    validator: (rule, value) =>
      value?.city
        ? Promise.resolve()
        : Promise.reject('Du måste ange en postaddress.'),
  },
];

// (rule, value, cb) => {
//   if (!value.street || !value.streetNumber || !value.city || !value.zipCode)
//     return false;

//   if (value.zipCode.match(/^[0-9\s]+$/) == null) {
//     return false;
//   }

//   return true;
// };

// [
//   {
//     message: 'You need to agree to our terms to sign up.',
//     validator: (rule, value, cb) => value === true,
//   },
// ]

interface Props {
  deleteButton?: React.ReactNode;
  value?: Address;
  onChange?: (address: Address) => void;
}

const AddressField = ({
  deleteButton,
  value,
  onChange = null,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const addressObject = value || {};
  return (
    <>
      <Row gutter={6} style={{ marginBottom: '6px' }}>
        <Col span={18}>
          <Input
            value={addressObject.street}
            placeholder={t('street')}
            onChange={event => {
              onChange({ ...addressObject, street: event.target.value });
            }}
          />
        </Col>
        <Col span={6}>
          <Input
            value={addressObject.streetNumber}
            placeholder={'Nr'}
            onChange={event => {
              onChange({
                ...addressObject,
                streetNumber: event.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={10}>
          <Input
            value={addressObject.zipCode}
            placeholder={t('zipCode')}
            onChange={event => {
              onChange({ ...addressObject, zipCode: event.target.value });
            }}
          />
        </Col>
        <Col span={deleteButton ? 12 : 14}>
          <Input
            value={addressObject.city}
            placeholder={t('city')}
            onChange={event => {
              onChange({ ...addressObject, city: event.target.value });
            }}
          />
        </Col>
        {deleteButton && <Col span={2}>{deleteButton}</Col>}
      </Row>
    </>
  );
};

export default AddressField;
