import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';
const LeaseAgreements = lazy(() => import('.'));
const Filter = lazy(() => import('./Filter'));
export type LeaseaAgreementsAdminRouteProps = AdminRouteBaseProps;

const LeaseAgreementsAdminRoutes: Route<LeaseaAgreementsAdminRouteProps>[] = [
  {
    path: '/lease-agreements',
    id: 'LeaseAgreementsAdmin',
    element: <LeaseAgreements />,
    meta: {
      sider: <Filter />,
    },
  },
  {
    path: '/lease-agreement',
    id: 'LeaseAgreementsAdmin',
    element: <LeaseAgreements />,
    meta: {
      sider: <Filter />,
    },
  },
];

export const toLeaseAgreementsAdmin = (): string => '/admin/lease-agreements';

export default LeaseAgreementsAdminRoutes;
