import { MakeGenerics, Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type EmailLogRouteProps = MakeGenerics<{
  Params: {
    emailLogId?: string;
  };
  LoaderData: {
    emailLogId: string;
  };
}> &
  AdminRouteBaseProps;

const MailLog = lazy(() => import('.'));
const Filter = lazy(() => import('./Filter'));
const View = lazy(() => import('./View'));

const EmailLogRoutes: Route<EmailLogRouteProps>[] = [
  {
    path: 'email',
    id: 'EmailLog',
    meta: {
      breadcrumbs: () => ({
        breadcrumb: 'Email',
      }),
    },
    children: [
      {
        id: 'EmailLogList',
        path: '/',
        element: <MailLog />,
        meta: {
          sider: <Filter />,
        },
      },
      {
        path: ':emailLogId',
        id: 'EmailLogView',
        children: [
          {
            path: '/',
            element: <View />,
            loader: ({ params }) => ({
              emailLogId: params.emailLogId,
            }),
            meta: {
              breadcrumbs: () => ({
                breadcrumb: 'Mail',
              }),
            },
          },
        ],
      },
    ],
  },
];

export const toEmailLog = (): string => '/admin/email/';
export const toEmailLogView = (emailLogId: string): string =>
  `/admin/email/${emailLogId}`;

export default EmailLogRoutes;
