import dayjs from 'dayjs';

export default function formatDate(
  date: string,
  format = 'YYYY-MM-DD',
): string {
  return dayjs(new Date(date)).format(format);
}

export function formatDateTime(
  date: string,
  format = 'YYYY-MM-DD HH:mm',
): string {
  return dayjs(new Date(date)).format(format);
}
