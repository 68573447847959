import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

const SearchRequests = lazy(() => import('.'));
const Filter = lazy(() => import('./Filter'));

export type SearchRequestsRouteProps = AdminRouteBaseProps;

const SearchRequestsAdminRoutes: Route<SearchRequestsRouteProps>[] = [
  {
    path: 'search-requests',
    id: 'SearchRequestsAdmin',
    element: <SearchRequests />,
    meta: {
      sider: <Filter />,
    },
  },
];

export const toSearchRequestsAdmin = (): string => '/admin/search-requests';

export default SearchRequestsAdminRoutes;
