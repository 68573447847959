import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { SizeFilter } from '@/generated/graphql';
import theme from '@/main/theme';

import { Container, FilterProps, Label, StyledTag } from './shared';
const searchRequestRanges = [
  { name: SizeFilter.Xs, range: '0-150m²' },
  { name: SizeFilter.Sm, range: '151-300m²' },
  { name: SizeFilter.Md, range: '301-1000m²' },
  { name: SizeFilter.Lg, range: '1000-5000m²' },
  { name: SizeFilter.Xl, range: '5000m² +' },
];
const { Text } = Typography;

function Size({
  searchRequestSettings,
  setSearchRequestSettings,
  label = true,
}: FilterProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div style={{ width: '100%' }}>
      {label && <Label>{t('size')}</Label>}
      <Container>
        {searchRequestRanges.map(({ name, range }) => {
          const active = searchRequestSettings.size.includes(name);
          return (
            <StyledTag
              key={name}
              checked={active}
              onChange={checked => {
                const { size } = searchRequestSettings;
                const next = checked
                  ? [...size, name]
                  : size.filter(t => t !== name);
                setSearchRequestSettings({
                  ...searchRequestSettings,
                  size: next,
                });
              }}
              $centered
            >
              <Box>{t(name)}</Box>
              <Text
                style={{
                  color: active
                    ? theme.colors.white
                    : theme.colors.typographySecondary,
                  transition: 'all 0.15s ease-in',
                }}
              >
                {range}
              </Text>
            </StyledTag>
          );
        })}
      </Container>
    </div>
  );
}

export default Size;
