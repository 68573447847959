import { Result, Typography } from 'antd';
import React from 'react';
import { getI18n } from 'react-i18next';

import { AccountCreationType } from '@/generated/graphql';
import NewLinkButton from '@/views/Unauthenticated/NewLinkButton';

const { Text } = Typography;

interface Props {
  token?: string;
  exists?: boolean;
  type?: AccountCreationType;
  searchRequestReferenceId?: string;
}

function ErrorText({ token, exists }: Props): JSX.Element {
  const i18n = getI18n();
  const lng = i18n.language;
  if (exists && token) {
    return lng == 'en' ? (
      <Text>
        The link is expired. Press the button below to get a new link.
      </Text>
    ) : (
      <Text>Länken har gått ut. Klicka på knappen nedan för att få en ny.</Text>
    );
  }
  return lng == 'en' ? (
    <Text>
      The link is either expired or invalid. <br />
      Contact us at{' '}
      <a href={'mailto:support@portally.com'}>support@portally.com</a> and{' '}
      {"we'll"}
      help you.
    </Text>
  ) : (
    <Text>
      Länken har gått ut eller är felaktig. <br />
      Kontakta oss på{' '}
      <a href={'mailto:support@portally.com'}>support@portally.com</a> så
      hjälper vi dig.
    </Text>
  );
}
export default function InvalidLinkError({
  token,
  exists,
  type,
  searchRequestReferenceId,
}: Props): JSX.Element {
  return (
    <Result
      status="404"
      subTitle={<ErrorText token={token} exists={exists} />}
      extra={
        exists &&
        type &&
        token && (
          <NewLinkButton
            type={type}
            token={token}
            searchRequestReferenceId={searchRequestReferenceId}
          />
        )
      }
    />
  );
}
