import { TeamOutlined, WalletOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import React from 'react';

import Box from '@/components/Box';
import ListItem from '@/components/ClickableListItem';
import MinMax from '@/components/MinMax';
import DeclineSearchRequestButton from '@/components/SearchRequest/shared/DeclineSearchRequestButton';
import { AnswerRequestedIcon } from '@/components/SearchRequestListComponents';
import AnsweredBy from '@/components/SearchRequestListComponents/SearchRequestListItem/AnsweredBy';
import SizeTag from '@/components/Tags/SizeTag';
import UsageCategoryTag from '@/components/Tags/UsageCategoryTags';
import {
  DisableSearchRequestReasonEnum,
  SearchRequestLandlordValuesFragment,
  SearchRequestReferenceListValuesFragment,
  StatusEnum,
} from '@/generated/graphql';
import theme from '@/main/theme';
import { formatNumber } from '@/utils';
import { formatDateTime } from '@/utils/formatDate';

const { Text } = Typography;

interface Props {
  className?: string;
  item:
    | SearchRequestLandlordValuesFragment
    | SearchRequestReferenceListValuesFragment;
  selectSearchRequest: (string: string | null) => void;
  selectedSearchRequest?: string;
  t: TFunction;
  onDecline: () => void;
}

export function formatSpan(min: number, max: number): string {
  return `${formatNumber(min)}${max ? ` - ${formatNumber(max)}` : '+'}`;
}
function SearchRequestListItem({
  className = '',
  item,
  selectSearchRequest,
  selectedSearchRequest,
  t,
  onDecline,
}: Props): JSX.Element {
  const {
    myStatus,
    searchRequestReferenceId,
    created,
    title,
    searchParameters,
    searchRequests,
    budget,
    budgetSpan,
    employees,
    disabledReason,
    disabledDate,
    client,
    employeesSpan,
  } = 'searchRequestReference' in item ? item.searchRequestReference : item;
  const isFoundPremisesTab = ![
    DisableSearchRequestReasonEnum.Success,
    DisableSearchRequestReasonEnum.Elsewhere,
  ].includes(disabledReason?.reason);
  return (
    <ListItem
      key={`${searchRequestReferenceId}list`}
      bordered
      $unread={myStatus === 'unopened'}
      $active={selectedSearchRequest == searchRequestReferenceId}
      className={clsx(`prevent-request-drawer-close ${className}`, {
        'ring-2 ring-purple-300 ring-opacity-80':
          selectedSearchRequest === searchRequestReferenceId,
      })}
      extra={
        isFoundPremisesTab &&
        ![StatusEnum.Declined, StatusEnum.Accepted].includes(myStatus) && (
          <DeclineSearchRequestButton
            quick
            danger={false}
            searchRequestReferenceId={searchRequestReferenceId}
            onCompleted={() => {
              onDecline();
            }}
          >
            {t('Reject')}
          </DeclineSearchRequestButton>
        )
      }
      onClick={() => selectSearchRequest(searchRequestReferenceId)}
    >
      <ListItem.Meta
        title={
          <Box verticalSpacing={0.5}>
            <div>
              {title} <AnswerRequestedIcon status={myStatus} t={t} />
            </div>
            {myStatus !== StatusEnum.Unopened && (
              <div className="text-zinc-500 font-normal text-[14px]">
                {client.name}
              </div>
            )}
            {disabledDate && (
              <span className="text-base text-red-500 font-normal">{`${t(
                'Search inquiry disabled',
              )}: ${dayjs(disabledDate).format('DD/MM/YYYY')}`}</span>
            )}
            <Box
              color={theme.colors.typographySecondary}
              style={{ fontSize: '14px', fontWeight: 400 }}
              horizontalSpacing={1}
            >
              {formatDateTime(created)}
            </Box>
          </Box>
        }
        description={
          <>
            <Box flex gap={1} pb={2}>
              <AnsweredBy
                status={StatusEnum.Accepted}
                searchRequests={searchRequests}
              />
              <AnsweredBy
                status={StatusEnum.Declined}
                searchRequests={searchRequests}
              />
            </Box>

            <Box flex flexWrap={'wrap'} gap={1}>
              {searchParameters.usageCategories.map(usageCategory => (
                <UsageCategoryTag
                  key={usageCategory + searchRequestReferenceId}
                  usageCategory={usageCategory}
                  size={'small'}
                />
              ))}
              <SizeTag size={'small'}>
                <MinMax
                  min={searchParameters?.size?.min}
                  max={searchParameters?.size?.max}
                />
              </SizeTag>
            </Box>
            {(employees ||
              typeof employeesSpan?.min === 'number' ||
              budget ||
              typeof budgetSpan?.min === 'number') && (
              <Box flex gap={2} pt={2} flexWrap={'wrap'}>
                {budget && (
                  <Box flex horizontalSpacing={1}>
                    <WalletOutlined />
                    <Text>
                      {formatNumber(budget)} {t('pricePerMonth')}
                    </Text>
                  </Box>
                )}{' '}
                {typeof budgetSpan?.min === 'number' && !budget && (
                  <Box flex horizontalSpacing={1}>
                    <WalletOutlined />
                    <Text>
                      {formatSpan(budgetSpan.min, budgetSpan.max)}{' '}
                      {t('pricePerMonth')}
                    </Text>
                  </Box>
                )}
                {typeof employeesSpan?.min === 'number' && !employees && (
                  <Box flex horizontalSpacing={1}>
                    <TeamOutlined />
                    <Text>
                      {formatSpan(employeesSpan.min, employeesSpan.max)}{' '}
                      {t('employees_other')}
                    </Text>
                  </Box>
                )}
                {employees && (
                  <Box flex horizontalSpacing={1}>
                    <TeamOutlined />
                    <Text>
                      {formatNumber(employees)}{' '}
                      {employees == 1
                        ? t('employees_one')
                        : t('employees_other')}
                    </Text>
                  </Box>
                )}
              </Box>
            )}
            {/*<Box flex horizontalSpacing={0.5} mt={2} alignItems={'center'}>
              <MiniPortallyIcon color={theme.colors.coral} />

              {searchParameters.regions.length ? (
                <Regions breakpoint={2} regions={searchParameters.regions} />
              ) : (
                <div>{t('View search')}</div>
              )}
            </Box>*/}
          </>
        }
      />
    </ListItem>
  );
}

export default SearchRequestListItem;
