import { Navigate, Outlet } from '@tanstack/react-location';
import { Modal, Spin } from 'antd';
import React, { Suspense } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BackgroundImage from '@/assets/img/login-background-min.jpg';
import Box from '@/components/Box';
import ErrorBoundary from '@/components/ErrorBoundary';
import SmallLocaleChanger from '@/components/SmallLocaleChanger';
import Spinner from '@/components/Spinner';
import useAuth from '@/hooks/useAuth';
import { toHome } from '@/views/Home/routes';
import Page500 from '@/views/Page500';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PortallyLogoCoral from '@/views/Unauthenticated/Portally_logo_coral.png?url';

const Bg = styled.div`
  background-image: url('${BackgroundImage}');
  background-size: cover;
  background-position: center;

  height: 100vh;
  padding: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  background-image: url('${PortallyLogoCoral}');
  width: 100px;
  background-size: 100px auto;
  height: 100px;
  background-repeat: no-repeat;
  z-index: 1001;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    position: relative;
    left: unset;
    top: unset;
  }
`;

const ConnectingSpace = styled.div`
  position: absolute;
  left: 24px;
  bottom: 24px;
  z-index: 1001;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    line-height: 0;
  }
`;

interface Props {
  redirect?: boolean;
  modal?: boolean;
}

export default function Unauthenticated({
  redirect = true,
  modal = true,
}: Props): JSX.Element {
  const { authenticated, loading, error } = useAuth();

  if (error) {
    return <Page500 />;
  }

  if (loading && redirect) {
    return (
      <Box flex justifyContent={'center'}>
        <Spin />
      </Box>
    );
  }

  if (authenticated && redirect) {
    return <Navigate to={toHome()} />;
  }

  return (
    <Bg>
      <Box flex alignItems={'center'} flexDirection={'column'}>
        <Logo />
        <ConnectingSpace>connecting space</ConnectingSpace>
      </Box>

      {modal ? (
        <Modal
          open
          footer={null}
          closable={false}
          centered
          width={450}
          transitionName={'none'}
          maskTransitionName={'none'}
          zIndex={1002}
          maskStyle={{ zIndex: 1000 }}
        >
          <Suspense fallback={<Spinner minHeight={'300px'} />}>
            <ErrorBoundary>
              <SmallLocaleChanger />
              <Outlet />
            </ErrorBoundary>
          </Suspense>
        </Modal>
      ) : (
        <Suspense fallback={<></>}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Suspense>
      )}
    </Bg>
  );
}
