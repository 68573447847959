import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

import MiniPortallyIcon from '@/components/PortallyLogo/MiniPortallyIcon';
import theme from '@/main/theme';

interface Props {
  expand?: boolean;
  minHeight?: string;
}

const Container = styled.div<{ $expand: boolean; $minHeight?: string }>`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  &.expand {
    height: calc(100vh - 100px);
  }
  ${({ $expand }) => $expand && `height: calc(100vh - 100px);`};
  ${({ $minHeight }) => $minHeight && `min-height: ${$minHeight}`};
`;

const SpinContainer = styled.div`
  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.colors.primaryPurple};
  }
`;

export default function Spinner({
  expand = false,
  minHeight,
}: Props): JSX.Element {
  return (
    <Container $expand={expand} $minHeight={minHeight}>
      <SpinContainer>
        <Spin size={'large'} style={{ color: theme.colors.primaryPurple }} />
      </SpinContainer>
      <div
        style={{
          marginLeft: '8px',
          color: theme.colors.primaryPurple,
          opacity: 0.9,
        }}
      >
        Portally <MiniPortallyIcon />
      </div>
    </Container>
  );
}
