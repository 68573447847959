import { useApolloClient } from '@apollo/client';
import { message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import LeaseForm from '@/components/ModalForm/Forms/LeaseForm';
import { useAddLeaseAgreementMutation } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';
import { treatLeaseData } from '@/views/LeaseAgreement/Form/util';

import ModalForm from '../ModalForm';
import ButtonCard from './ButtonCard';
import Steps from './Steps';

export interface AddLeaseAgreementButtonProps {
  onClick: () => void;
}

interface Props {
  button?: React.FunctionComponent<AddLeaseAgreementButtonProps>;
}

function AddLeaseAgreementModal({ button: Button }: Props): JSX.Element {
  const [resetKey, setResetKey] = React.useState('initial');
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  const [step, setStep] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const apollo = useApolloClient();
  const user = useUser();

  const [action, { loading }] = useAddLeaseAgreementMutation({
    onCompleted: async () => {
      message.success(t('Saved'), 2.5);
      setStep(0);
      await apollo.reFetchObservableQueries();
    },

    onError: () => {
      message.error(t('error'));
      setStep(0);
      setResetKey(`${resetKey}+`);
    },
  });

  const title = t('addLeaseAgreement');

  const ModalButton = Button
    ? () => <Button onClick={() => setVisible(true)} />
    : () => (
        <ButtonCard
          text={title}
          onClick={() => setVisible(true)}
          minHeight={'380px'}
        />
      );

  return (
    <ModalForm
      key={resetKey}
      vertical
      steps={Steps}
      title={!isMobile && t('addLeaseAgreement')}
      button={ModalButton}
      render={({ form }) => (
        <Box maxW={400}>
          <LeaseForm form={form} step={step} values={{}} add />
        </Box>
      )}
      setStep={setStep}
      handleOk={async fd => {
        const values = treatLeaseData(fd);
        const tenantOrLandlord =
          user.activeClient.type === 'tenant'
            ? { tenantId: user.activeClientId }
            : { landlordId: user.activeClientId };

        return action({
          variables: {
            input: {
              ...values,
              ...tenantOrLandlord,
            },
          },
        });
      }}
      loading={loading}
      step={step}
      finalStepInfo={() => null}
      finalStep={2}
      submitText={t('Save')}
      visible={visible}
      onCancel={() => setVisible(false)}
    />
  );
}

export default AddLeaseAgreementModal;
