import React, { useEffect } from 'react';
import InfiniteScroller from 'react-infinite-scroller';

import sleep from '@/utils/sleep';

interface Props {
  children: React.ReactNode;
  loadMore: () => void;
  hasMore: boolean;
  useWindow?: boolean;
  loader: React.ReactNode;
  loading: boolean;
  threshold?: number;
  initialLoad?: boolean;
}

function InfiniteScroll({
  children,
  loadMore,
  hasMore,
  loader,
  loading,
  useWindow = false,
  threshold = 250,
  initialLoad = false,
}: Props): JSX.Element {
  const [timeOut, setTimeout] = React.useState(false);
  const wait = async (): Promise<void> => {
    setTimeout(true);
    await sleep(5);
    setTimeout(false);
  };
  useEffect(() => {
    !loading && wait();
  }, [loading]);
  return (
    <InfiniteScroller
      initialLoad={initialLoad}
      pageStart={0}
      useWindow={useWindow}
      loadMore={() => !loading && loadMore()}
      hasMore={!timeOut && hasMore}
      loader={loader}
      threshold={threshold}
    >
      {children}
    </InfiniteScroller>
  );
}

export default InfiniteScroll;
