import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Radio, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetUsersByClientQuery,
  useShareSearchRequestMutation,
} from '@/generated/graphql';
import useUser from '@/hooks/useUser';
const { Option } = Select;

interface Props {
  clientId: string;
  searchRequestReferenceId: string;
  onCancel: () => void;
}

function Mail({
  clientId,
  searchRequestReferenceId,
  onCancel,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [form] = useForm();
  const user = useUser();
  const [visible, setVisible] = useState(false);
  const { data } = useGetUsersByClientQuery({
    variables: {
      clientId,
    },
    skip: !visible,
  });
  const [shareSearchRequest, { loading }] = useShareSearchRequestMutation({
    onCompleted: () => {
      setVisible(false);
      message.success(t('SentSingular'));
      form.resetFields();
    },
    onError: error => {
      message.error(error.message);
    },
  });
  return (
    <>
      <Button
        icon={<MailOutlined />}
        block
        onClick={() => {
          onCancel();
          setVisible(true);
        }}
      >
        {t('Email')}
      </Button>
      <Modal
        open={visible}
        footer={null}
        title={t('Share search request')}
        className={'prevent-request-drawer-close'}
        mask
        wrapClassName={'prevent-request-drawer-close'}
        onCancel={() => setVisible(false)}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={async fd => {
            const { email, userId } = fd;

            await shareSearchRequest({
              variables: {
                input: {
                  searchRequestReferenceId,
                  email,
                  userId,
                },
                sendEmail: true,
              },
            });
          }}
        >
          <Form.Item name={'shareType'} initialValue={'user'}>
            <Radio.Group>
              <Radio value={'user'}>{t('user')}</Radio>
              <Radio value={'email'}>Email</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle dependencies={['shareType']}>
            {({ getFieldValue }) =>
              getFieldValue('shareType') === 'email' ? (
                <Form.Item
                  label={t('Email')}
                  name={'email'}
                  rules={[
                    {
                      type: 'email',
                      message: t('emailValid'),
                    },
                    {
                      required: true,
                      message: t('required'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item
                  label={t('Choose')}
                  name={'userId'}
                  rules={[
                    {
                      required: true,
                      message: t('required'),
                    },
                  ]}
                >
                  <Select
                    allowClear
                    dropdownClassName={'prevent-request-drawer-close'}
                    showSearch={false}
                    showArrow
                  >
                    {data?.users?.users
                      .filter(({ id }) => id != user?.id)
                      .map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )
            }
          </Form.Item>

          <Form.Item>
            <Button
              style={{ float: 'right' }}
              type={'primary'}
              icon={<MailOutlined />}
              htmlType={'submit'}
              loading={loading}
              disabled={loading}
            >
              {t('Send')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Mail;
