import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import React from 'react';

import { useDeleteSearchRequestCommentLandlordMutation } from '@/generated/graphql';
import theme from '@/main/theme';

interface Props {
  id: string;
}

function DeleteComment({ id }: Props): JSX.Element {
  const apollo = useApolloClient();
  const [action, { loading }] = useDeleteSearchRequestCommentLandlordMutation({
    onCompleted: async () => {
      await apollo.reFetchObservableQueries();
    },
    variables: {
      id,
    },
  });

  if (loading) {
    return <LoadingOutlined spin />;
  }
  return (
    <DeleteOutlined
      onClick={() => action()}
      style={{ color: theme.colors.red }}
    />
  );
}

export default DeleteComment;
