import { Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

export type AdminHomeRouteProps = BasicRouteProps;
const AdminHome = React.lazy(() => import('.'));
const AdminHomeRoutes: Route<AdminHomeRouteProps>[] = [
  {
    path: '/',
    id: 'AdminHome',
    element: <AdminHome />,
  },
];

export const toAdminHome = (): string => '/admin/';

export default AdminHomeRoutes;
