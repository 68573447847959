import { message, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import { TFunction } from 'i18next';

import { UploadFileMutationFn } from '@/generated/graphql';

export const checkMaxUploadSize = (file, t: TFunction): boolean | string => {
  const maxUploadSize = 100;
  const isLtM = file.size / 1024 / 1024 < maxUploadSize;
  if (!isLtM) {
    message.error(t('fileSizeError', { size: maxUploadSize }));
  }
  return isLtM ? true : Upload.LIST_IGNORE;
};

export const translateUrl = (url: string): string => {
  if (!url) {
    return '';
  }
  if (url.includes('cloudinary')) {
    return url;
  }
  return encodeURI(url);
};

interface CloudinaryUpload {
  // eslint-disable-next-line camelcase
  public_id: string;
  // eslint-disable-next-line camelcase
  secure_url: string;
  format: string;
  // eslint-disable-next-line camelcase
  original_filename: string;
  mimetype: string;
  // eslint-disable-next-line camelcase
  resource_type: string;
}

export const uploadFile = async (
  file: string | Blob | RcFile,
  uploadFileMutation: UploadFileMutationFn,
  onUploadProgress: (percent: number) => void,
) => {
  if (typeof file === 'string') {
    return;
  }

  let url = 'https://api.cloudinary.com/v1_1/portally/image/upload';
  const cloudinaryUploadPreset = 'ekpuufeu';

  const formData = new FormData();
  formData.append('file', file);
  formData.append('api_key', import.meta.env.VITE_CLOUDINARY_API_KEY);
  formData.append('timestamp', String(Date.now() / 1000));
  formData.append('upload_preset', cloudinaryUploadPreset);

  if (
    ['image', 'pdf', 'video', 'gif'].every(type => !file.type.includes(type))
  ) {
    url = 'https://api.cloudinary.com/v1_1/portally/raw/upload';
    formData.append('resource_type', 'raw');
  }
  if (file.type.includes('video')) {
    url = 'https://api.cloudinary.com/v1_1/portally/video/upload';
    formData.append('resource_type', 'auto');
  }

  const res = await axios.post<CloudinaryUpload>(url, formData, {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: ({ progress }) => {
      onUploadProgress(progress * 100);
    },
  });

  const upload = res.data;

  return uploadFileMutation({
    variables: {
      file: {
        cloudinaryPublicId: upload.public_id,
        name: upload.original_filename,
        mimetype:
          'type' in file
            ? file.type
            : upload.format === 'pdf'
            ? 'application/pdf'
            : upload.resource_type,
      },
    },
  });
};
