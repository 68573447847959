import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort';
import { useNavigate } from '@tanstack/react-location';
import { ButtonProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledIconButton } from '@/components/Buttons';
import browserHistory from '@/utils/browserHistory';

interface Props {
  to?: string;
}

export function GoBackArrowButton(props: ButtonProps): JSX.Element {
  return (
    <StyledIconButton
      {...props}
      $withText
      icon={<ArrowLeftShort size={28} />}
      type={'link'}
      style={{ marginLeft: 0, paddingLeft: 0 }}
    />
  );
}

function GoBackArrow({ to }: Props): JSX.Element {
  const { t } = useTranslation();

  const navigate = useNavigate();
  if (to) {
    return (
      <GoBackArrowButton onClick={() => navigate({ to })}>
        {t('goBack')}
      </GoBackArrowButton>
    );
  }
  if (window.history.state?.idx === 0) {
    return null;
  }
  return (
    <GoBackArrowButton onClick={() => browserHistory.back()}>
      {t('goBack')}
    </GoBackArrowButton>
  );
}

export default GoBackArrow;
