import { SearchRequestStep } from '@/generated/graphql';

export const activityStatusColors = {
  superuser: 'volcano',
  skilled: 'gold',
  active: 'green',
  passive_active: 'purple',
  inactive: 'cyan',
  never_logged_in: 'red',
};

const colors = {
  coral: '#ff7369',
  dark: '#29203B',
  purple: '#5443ad',
  light: '#f7f6fc',
  //light: '#fff',
  yellow: '#ffd49f',
  lightBlue: '#9cd4ef',
  white: '#fff',
  black: 'rgba(0,0,0,0.85)',
  red: '#f5222d',
  green: '#52c41a',
  websitePurple: '#a08cee',
  primaryPurple: '#885ae4', //#7c3aed
  primaryPurpleDark: '#6640bd',
  commerce: '#5443ad',
  link: '#1890ff',
  typographySecondary: '#8c8c8c',
  tableSelectHover: '#eee6ff',
  tableSelect: '#f5efff',
  inputBorder: '#d9d9d9',
  inputBorderHover: '#aba1cf',
  inputFocusBoxShadow: '0 0 0 2px rgb(134 120 195 / 20%)',
  listItemHover: 'rgba(245, 245, 245, 1)',
  messageColor: '#f1f2f6',
  mineMessageColor: '#885ae4',
  steps: {
    [SearchRequestStep.Todo]: '#13c2c2',
    [SearchRequestStep.Interesting]: '#1890ff',
    [SearchRequestStep.InProgress]: '#722ed1',
    [SearchRequestStep.Negotiation]: '#eb2f96',
    [SearchRequestStep.Success]: '#52c41a',
    [SearchRequestStep.Archived]: '#f5222d',
  },
};

const breakpoints = {
  mobile: '768px',
  tablet: '968px',
  laptop: '1024px',
  desktop: '1200px',
};

const navbarHeight = 76;
const navbarBottom = 0;
const dimensions = {
  leaseAgreementListCardImageHeight: `${200}px`,
  navbarHeight: `${navbarHeight}px`,
  navbarBottom: `${navbarBottom}px`,
  mobileNavOffset: `${navbarHeight + navbarBottom + 0}px`,
  leftMenu: '120px',
  leftMenuCollapsed: '80px',
  landlordCardMargin: '12px',
  affixWidth: '375px',
  borderRadius: '6px',
  borderRadiusCard: '12px',
  padding: '24px',
};

const metaTitlePrefix = 'Portally | ';
const metaTitle = 'connecting space';

const header = {
  metaTitlePrefix: 'Portally | ',
  defaultMetaTitle: metaTitlePrefix + metaTitle,
};

interface Theme {
  colors: typeof colors;
  breakpoints: typeof breakpoints;
  dimensions: typeof dimensions;
  header: typeof header;
}

export const theme: Theme = {
  colors,
  breakpoints,
  dimensions,
  header,
};

export default theme;
//rgb(160, 140, 238)
