import { CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import React from 'react';
import styled from 'styled-components';

import theme from '@/main/theme';

const ReadContainer = styled.div<{ $mine?: boolean }>`
  color: ${theme.colors.primaryPurple};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
  font-size: 14px;
  margin-right: 40px;
`;

interface Props {
  timeStamp: Date;
  t: TFunction;
}

function Read({ timeStamp, t }: Props): JSX.Element {
  const readDate = dayjs(timeStamp);
  const now = dayjs();
  return (
    <ReadContainer>
      <CheckCircleOutlined
        style={{
          marginRight: '4px',
        }}
      />
      <div>
        {t('Read')}{' '}
        {readDate.isBefore(now.clone().subtract(1, 'day'))
          ? `${readDate.format('MM/DD')} ${readDate.format('HH:mm')}`
          : dayjs(timeStamp).fromNow()}
      </div>
    </ReadContainer>
  );
}

export default Read;
