import { Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

const Settings = React.lazy(() => import('./Settings'));

export type ClientRoutes = BasicRouteProps;

const ClientRoutes: Route<ClientRoutes>[] = [
  {
    path: '/client-settings',
    id: 'Client settings',
    element: <Settings />,
    meta: {
      whiteBackgroundPhone: true,
      noPaddingPhone: true,
    },
  },
];

export const toClientSettings = (): string => `/client-settings`;

export default ClientRoutes;
