import React from 'react';

import Flat, { FlatProps } from '@/components/Chat/Flat';
import {
  useGetConversationQuery,
  useSubscribeToConversationSubscription,
} from '@/generated/graphql';

import Drawer, { DrawerProps } from './Drawer';

type ChatType =
  | {
      type: 'flat';
      props: FlatProps;
    }
  | {
      type: 'drawer';
      props: DrawerProps;
    };

interface Props {
  type: ChatType;
  conversationId: string;
}

export default function Chat({ type, conversationId }: Props): JSX.Element {
  const { data, loading, error, refetch, previousData } =
    useGetConversationQuery({
      variables: {
        id: conversationId,
      },
      skip: !conversationId,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    });

  useSubscribeToConversationSubscription({
    variables: {
      conversationId,
    },
    onSubscriptionData: () => {
      refetch();
    },
  });

  if (type.type === 'drawer') {
    return (
      <Drawer
        conversationId={conversationId}
        loading={loading}
        error={error}
        data={data}
        previousData={previousData}
        chatProps={type.props}
      />
    );
  }

  return (
    <Flat
      loading={loading}
      data={data}
      previousData={previousData}
      error={error}
      chatProps={type.props}
    />
  );
}
