import { ReloadOutlined } from '@ant-design/icons';
import { Button, Modal, Result } from 'antd';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';

import Bugsnag from '@/main/bugsnag';

interface ErrorViewProps {
  error?: Error;
}

function ErrorView({ error }: ErrorViewProps): JSX.Element | null {
  const [modal, contextProvider] = Modal.useModal();
  useEffect(() => {
    if (
      error?.name === 'ChunkLoadError' ||
      error?.message?.includes('Failed to fetch dynamically imported module')
    ) {
      modal.info({
        title: <Trans>newVersionTitle</Trans>,
        content: (
          <div>
            <p>
              <Trans>newVersionText</Trans>
            </p>
          </div>
        ),
        onOk: () => window.location.reload(),
      });
    }
  }, [error?.name]);

  if (
    error?.name === 'ChunkLoadError' ||
    error?.message?.includes('Failed to fetch dynamically imported module')
  ) {
    console.info('New version. Reloading');
    return <div>{contextProvider}</div>;

    // Auto reload?
    // window.location.reload();
  }

  return (
    <Result
      status="warning"
      title={<Trans>unexpectedError</Trans>}
      extra={
        <Button
          type="primary"
          onClick={() => window.location.reload()}
          icon={<ReloadOutlined style={{ marginRight: '4px' }} />}
        >
          <Trans>Reload page</Trans>
        </Button>
      }
    />
  );
}

const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React as any,
);

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export default function ErrorBoundary({
  children,
}: ErrorBoundaryProps): JSX.Element {
  return (
    <BugsnagErrorBoundary FallbackComponent={ErrorView}>
      {children}
    </BugsnagErrorBoundary>
  );
}
