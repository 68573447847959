import { MakeGenerics, Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type SaleClientAdminRouteProps = MakeGenerics<AdminRouteBaseProps>;

const SaleClientAdmin = lazy(() => import('./index'));

const SaleClientAdminRoutes: Route<SaleClientAdminRouteProps>[] = [
  {
    path: 'sale-client',
    id: 'SaleClient',
    element: <SaleClientAdmin />,
  },
];

export const toSaleClientsAdmin = (): string => '/admin/sale-client';

export default SaleClientAdminRoutes;
