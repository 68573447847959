import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';
import AdminHomeRoutes from '@/views/AdminHome/routes';
import ClientAdminRoutes, {
  ClientAdminRouteProps,
} from '@/views/Client/adminRoutes';
import ContentWheelRoutes from '@/views/ContentWheel/routes';
import DealsRoutes from '@/views/Deals/routes';
import EmailLogRoutes, { EmailLogRouteProps } from '@/views/EmailLog/routes';
import EstatesAdminRoutes from '@/views/EstatesAdmin/routes';
import IntegrationProviderRoutes, {
  IntegrationProviderRouteProps,
} from '@/views/IntegrationProvider/routes';
import LeaseAgreementProspectsAdminRoutes from '@/views/LeaseAgreementProspectsAdmin/routes';
import LeaseAgreementsAdminRoutes from '@/views/LeaseAgreementsAdmin/routes';
import LeaseAgreementViewsAdminRoutes from '@/views/LeaseAgreementViewsAdmin/routes';
import AdminOverviewRoutes from '@/views/Overview/routes';
import Page404 from '@/views/Page404';
import PortallyPartnersAdminRoutes from '@/views/PortallyPartners/routes';
import PreliminarySearchRequestRoutes from '@/views/PreliminarySearchRequests/routes';
import ProjectsAdminRoutes from '@/views/Projects/routes';
import ProspectsRoutes from '@/views/Prospects/routes';
import RegionsAdminRoutes, {
  RegionsAdminRouteProps,
} from '@/views/RegionsAdmin/routes';
import SaleClientAdminRoutes from '@/views/SaleClientsAdmin/routes';
import SearchRequestsAdminRoutes from '@/views/SearchRequestsAdmin/routes';
import StatisticsRoutes from '@/views/Statistics/routes';
import SubscriptionRequestRoutes from '@/views/SubscriptionRequest/routes';
import TermsOfServiceAdminRoutes, {
  TermsOfServiceAdminRouteProps,
} from '@/views/TermsOfServiceAdmin/routes';
import UserAdminRoutes, {
  UserAdminRouteProps,
} from '@/views/Users/adminRoutes';

import Admin from '.';

export type AdminRouteBaseProps = MakeGenerics<{
  RouteMeta: {
    breadcrumbs?: (params?: AdminRouteProps['Params']) => {
      breadcrumb: string;
      parent?: {
        breadcrumb: string;
        pathname: string;
      };
    };
    noFrame?: boolean;
    sider?: React.ReactNode;
  };
}> &
  BasicRouteProps;

export type AdminRouteProps = UserAdminRouteProps &
  ClientAdminRouteProps &
  AdminRouteBaseProps &
  RegionsAdminRouteProps &
  EmailLogRouteProps &
  TermsOfServiceAdminRouteProps &
  IntegrationProviderRouteProps;

const AdminRoutes: Route<AdminRouteProps>[] = [
  {
    path: '/admin',
    id: 'Admin',
    element: <Admin />,
    children: [
      ...AdminHomeRoutes,
      ...ClientAdminRoutes,
      ...UserAdminRoutes,
      ...AdminOverviewRoutes,
      ...LeaseAgreementsAdminRoutes,
      ...DealsRoutes,
      ...SearchRequestsAdminRoutes,
      ...PreliminarySearchRequestRoutes,
      ...SubscriptionRequestRoutes,
      ...EstatesAdminRoutes,
      ...RegionsAdminRoutes,
      ...PortallyPartnersAdminRoutes,
      ...StatisticsRoutes,
      ...EmailLogRoutes,
      ...TermsOfServiceAdminRoutes,
      ...ContentWheelRoutes,
      ...ProspectsRoutes,
      ...IntegrationProviderRoutes,
      ...SaleClientAdminRoutes,
      ...LeaseAgreementViewsAdminRoutes,
      ...ProjectsAdminRoutes,
      ...LeaseAgreementProspectsAdminRoutes,
      {
        path: '*',
        id: 'NotFound',
        element: <Page404 />,
      },
    ],
  },
];

export const toAdmin = (): string => '';

export default AdminRoutes;
