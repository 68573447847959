import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { AccountCreationType } from '@/generated/graphql';
import { BasicRouteProps } from '@/layout/routes';
import IntegrationApiKeyRoutes, {
  IntegrationApiKeyRouteProps,
} from '@/views/IntegrationProvider/unauthenticatedRoutes';
import EndSearchRequestRoutes, {
  EndSearchRequestRouteProps,
} from '@/views/Unauthenticated/EndSearchRequest/routes';
import SharedSearchRequestRoutes, {
  SharedSearchRequestRouteProps,
} from '@/views/Unauthenticated/SharedSearchRequest/routes';

import Container from '.';

export type UnauthenticatedRouteProps = MakeGenerics<{
  Params: {
    token?: string;
    searchRequestReferenceId?: string;
  };
}> &
  BasicRouteProps &
  SharedSearchRequestRouteProps &
  EndSearchRequestRouteProps &
  IntegrationApiKeyRouteProps;

const ResetPassword = React.lazy(
  () => import('@/views/Unauthenticated/ResetPassword'),
);
const NewUser = React.lazy(() => import('@/views/Unauthenticated/NewUser'));
const LoginForm = React.lazy(
  () => import('@/views/Unauthenticated/Login/LoginForm'),
);
const Unsubscribe = React.lazy(
  () => import('@/views/Unauthenticated/Unsubscribe'),
);
const ForgotPassword = React.lazy(
  () => import('@/views/Unauthenticated/Login/ForgotPassword'),
);
const Signup = React.lazy(() => import('@/views/Unauthenticated/Signup'));

const UnauthenticatedRoutes: Route<UnauthenticatedRouteProps>[] = [
  {
    path: '/login',
    element: <Container redirect />,
    children: [
      {
        path: '/',
        element: <LoginForm />,
      },
      {
        path: 'reset',
        id: 'Reset',
        element: <ForgotPassword />,
      },
    ],
  },
  {
    path: '/signup',
    id: 'Signup',
    element: <Container />,
    children: [
      {
        path: '/',
        element: <Signup />,
      },
    ],
  },
  {
    path: '/unsubscribe',
    id: 'Unsubscribe',
    element: <Container redirect={false} />,
    children: [
      {
        path: '/',
        element: <Unsubscribe />,
      },
    ],
  },
  {
    path: '/reset/:token',
    element: <Container />,
    children: [
      {
        path: '/',
        element: <ResetPassword />,
      },
    ],
    loader: ({ params }) => ({
      token: params.token,
    }),
  },
  {
    path: '/create-account/:token',
    element: <Container />,
    children: [
      {
        path: '/',
        element: <NewUser type={AccountCreationType.CreateAccount} />,
      },
    ],
    loader: ({ params }) => ({
      token: params.token,
    }),
  },
  {
    path: '/verify/:token',
    element: <Container redirect={false} />,
    children: [
      {
        path: '/',
        element: <NewUser type={AccountCreationType.VerifyAccount} />,
      },
    ],
    loader: ({ params }) => ({
      token: params.token,
    }),
  },
  {
    path: '/new-user/:token',
    element: <Container />,
    children: [
      {
        path: '/',
        element: <NewUser type={AccountCreationType.NewUser} />,
      },
    ],
    loader: ({ params }) => ({
      token: params.token,
    }),
  },
  ...EndSearchRequestRoutes,
  ...SharedSearchRequestRoutes,
  ...IntegrationApiKeyRoutes,
];
export const toLogin = (): string => '/login';
export const toSignup = (): string => '/signup';
export const toForgotPassword = (): string => '/login/reset';

export default UnauthenticatedRoutes;
