import styled from 'styled-components';

import Box from '../Box';

export default styled(Box)<{ concealed?: boolean }>`
  .ant-steps-item-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  * {
    font-weight: 400;
  }
`;
