import { AnyAction } from 'redux';

import { mapKeys } from '@/components/Map';
import {
  ActiveSearchRequestFilter,
  GetSearchResultQuery,
} from '@/generated/graphql';
import {
  ConversationFilter,
  SearchRequestState,
  SearchRequestViewType,
} from '@/state/customer/reducers';
import { Bounds, MapObject } from '@/utils/misc';
import { SearchValue } from '@/utils/types';

export const SET_REGION_POLYGONS = 'Customer/SET_REGION_POLYGONS';

export const UPDATE_BOUNDS = 'Customer/UPDATE_BOUNDS';
export const SET_LEASEAGREEMENT_MARKERS = 'Customer/SET_LEASEAGREEMENT_MARKERS';
export const SET_COMMERCE = 'Customer/SET_COMMERCE';
export const UPDATE_CENTER = 'Customer/UPDATE_CENTER';
export const UPDATE_ZOOM = 'Customer/UPDATE_ZOOM';
export const UPDATE_SEARCH_VALUE = 'Customer/UPDATE_SEARCH_VALUE';
export const SET_POLYGONS = 'Customer/SET_POLYGONS';

export const CENTER_REGIONS = 'Customer/CENTER_REGIONS';
export const SET_LIMIT = 'Customer/SET_LIMIT';
export const UPDATE_MESSAGE_DRAFT = 'Customer/UPDATE_MESSAGE_DRAFT';

export const SET_SHOW_RESULTS = 'Customer/SET_SHOW_RESULTS';
export const SET_SEARCH_STEP = 'Customer/SET_SEARCH_STEP';
export const UPDATE_SEARCH_CENTER = 'Customer/UPDATE_SEARCH_CENTER';
export const SET_SELECTED_MARKER = 'Customer/SET_SELECTED_MARKER';
export const UPDATE_USAGE_CATEGORY = 'Customer/UPDATE_USAGE_CATEGORY';
export const UPDATE_CONVERSATION_FILTER =
  'Customer/UPDATE_SHOW_MY_CONVERSATIONS';

export const UPDATE_MY_RENTALS_FILTER = 'Customer/UPDATE_MY_RENTALS_FILTER';

export const UPDATE_ACTIVE_SEARCH_REQUEST_FILTER =
  'Customer/UPDATE_ACTIVE_SEARCH_REQUEST_FILTER';

export const UPDATE_DASHBOARD_SELECTED_SEARCH_REQUEST =
  'Customer/UPDATE_DASHBOARD_SELECTED_SEARCH_REQUEST';

export const UPDATE_DASHBOARD_NAVIGATION_VIEW =
  'Customer/UPDATE_DASHBOARD_SELECTED_SEARCH_REQUEST';
export const UPDATE_DASHBOARD_SEARCH_REQUEST_TAB_KEY =
  'Customer/UPDATE_DASHBOARD_SEARCH_REQUEST_TAB_KEY';

export const UPDATE_SEARCH_REQUEST_STATE =
  'Customer/UPDATE_SEARCH_REQUEST_STATE';

export const UPDATE_SEARCH_REQUEST_SCROLL_POSITION =
  'Customer/UPDATE_SEARCH_REQUEST_SCROLL_POSITION';

export const UPDATE_UNREAD_NOTIFICATIONS =
  'Customer/UPDATE_UNREAD_NOTIFICATIONS';
export const UPDATE_SHOW_ARCHIVED = 'Customer/UPDATE_SHOW_ARCHIVED';

export const UPDATE_SHOW_ALL_SEARCH_REQUESTS =
  'Customer/UPDATE_SHOW_ALL_SEARCH_REQUESTS';

export const setLimit = (limit: number): AnyAction => ({
  type: SET_LIMIT,
  limit,
});

export const setPolygons = (
  polygons: google.maps.LatLngLiteral[][],
): AnyAction => ({
  type: SET_POLYGONS,
  polygons,
});
export const setRegionPolygons = (
  regionPolygons: google.maps.LatLngLiteral[][],
): AnyAction => ({
  type: SET_REGION_POLYGONS,
  regionPolygons,
});

export const updateBounds = (bounds: Bounds): AnyAction => ({
  type: UPDATE_BOUNDS,
  bounds,
});

export const updateSearchValue = (
  value: SearchValue | undefined,
): AnyAction => ({
  type: UPDATE_SEARCH_VALUE,
  value,
});

export const updateZoom = (zoom: number, key: mapKeys): AnyAction => ({
  type: UPDATE_ZOOM,
  zoom,
  key,
});

export const setLeaseAgreementMarkers = <T extends MapObject>(
  name: string,
  items: T[],
): AnyAction => ({
  type: SET_LEASEAGREEMENT_MARKERS,
  name,
  items,
});

export const setCommerce = (
  commerce: GetSearchResultQuery['leaseAgreements']['commerce'],
): AnyAction => ({
  type: SET_COMMERCE,
  commerce,
});

export const updateCenter = (value: boolean): AnyAction => ({
  type: UPDATE_CENTER,
  value,
});

export const centerRegions = (bounds: Bounds | undefined): AnyAction => ({
  type: CENTER_REGIONS,
  bounds,
});

export const setSearchStep = (step: number): AnyAction => ({
  type: SET_SEARCH_STEP,
  step,
});
export const setShowResults = (showResults: boolean): AnyAction => ({
  type: SET_SHOW_RESULTS,
  showResults,
});

export const setSelectedMarker = (selectedMarker: string): AnyAction => ({
  type: SET_SELECTED_MARKER,
  selectedMarker,
});

export const updateMessageDraft = (
  conversationId: string,
  message: string,
): AnyAction => ({
  type: UPDATE_MESSAGE_DRAFT,
  conversationId,
  message,
});

export const updateSearchCenter = (
  center: google.maps.LatLngLiteral | google.maps.LatLng,
  key: mapKeys,
): AnyAction => ({
  type: UPDATE_SEARCH_CENTER,
  center,
  key,
});

export const setUsageCategory = (usageCategory: string): AnyAction => ({
  type: UPDATE_USAGE_CATEGORY,
  usageCategory,
});

export const updateConversationFilter = ({
  personalized,
  search,
}: ConversationFilter): AnyAction => ({
  type: UPDATE_CONVERSATION_FILTER,
  conversationFilter: {
    personalized,
    search,
  },
});

export const updateMyRentalsFilter = ({
  personalized,
  rentals,
}): AnyAction => ({
  type: UPDATE_MY_RENTALS_FILTER,
  rentalsFilter: {
    personalized,
    rentals,
  },
});

export const updateDashboardSelectedSearchRequest = (
  selectedSearchRequest: string,
): AnyAction => ({
  type: UPDATE_DASHBOARD_SELECTED_SEARCH_REQUEST,
  selectedSearchRequest,
});
export const updateDashboardView = (
  navigationView: SearchRequestViewType,
): AnyAction => ({
  type: UPDATE_DASHBOARD_NAVIGATION_VIEW,
  navigationView,
});
export const updateSearchRequestTab = (tabKey: string): AnyAction => ({
  type: UPDATE_DASHBOARD_SEARCH_REQUEST_TAB_KEY,
  tabKey,
});

export const updateUnreadNotifications = (
  unreadNotifications: number,
): AnyAction => ({
  type: UPDATE_UNREAD_NOTIFICATIONS,
  unreadNotifications,
});

export const updateSearchRequestState = (
  searchRequestReferenceId: string,
  state: SearchRequestState,
): AnyAction => ({
  type: UPDATE_SEARCH_REQUEST_STATE,
  searchRequestReferenceId,
  state,
});
export const updateSearchRequestScrollPosition = (
  searchRequestReferenceId: string,
  scrollPosition: number,
): AnyAction => ({
  type: UPDATE_SEARCH_REQUEST_SCROLL_POSITION,
  searchRequestReferenceId,
  scrollPosition,
});

export const updateShowArchived = (showArchived: boolean): AnyAction => ({
  type: UPDATE_SHOW_ARCHIVED,
  showArchived,
});

export const updateActiveSearchRequestFilter = (
  activeSearchRequestFilter: ActiveSearchRequestFilter,
): AnyAction => ({
  type: UPDATE_ACTIVE_SEARCH_REQUEST_FILTER,
  activeSearchRequestFilter,
});

export const updateShowAllSearchRequests = (
  showAllSearchRequests: boolean,
): AnyAction => ({
  type: UPDATE_SHOW_ALL_SEARCH_REQUESTS,
  showAllSearchRequests,
});
