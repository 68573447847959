import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetMyPresetAnswersDocument,
  useDeletePresetAnswerMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

interface Props {
  id: string;
}

function DeletePresetAnswer({ id }: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  const [deletePresetAnswer, { loading }] = useDeletePresetAnswerMutation({
    onCompleted: async () => {
      message.success(t('Deleted'));
    },
    refetchQueries: [
      {
        query: GetMyPresetAnswersDocument,
      },
    ],
    onError: () => {
      message.error(t('error'));
    },
  });

  if (isMobile) {
    return null;
  }
  return (
    <Popconfirm
      title={t('Are you sure?')}
      okText={t('Yes')}
      cancelText={t('No')}
      overlayClassName={'prevent-request-drawer-close'}
      onConfirm={() =>
        deletePresetAnswer({
          variables: {
            id,
          },
        })
      }
    >
      <Button
        danger
        icon={<DeleteOutlined />}
        loading={loading}
        type={'text'}
        size={'small'}
      />
    </Popconfirm>
  );
}

export default DeletePresetAnswer;
