import { useNavigate } from '@tanstack/react-location';
import { Badge, Button, Dropdown, Menu as AntMenu, notification } from 'antd';
import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider, useStore } from 'react-redux';
import styled from 'styled-components';

import {
  SubscribeToNotificationsDocument,
  SubscribeToNotificationsSubscription,
  SubscribeToNotificationsSubscriptionVariables,
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from '@/generated/graphql';
import useAppDispatch from '@/hooks/useAppDispatch';
import { updateUnreadNotifications } from '@/state/customer/actions';
import { toNotifications } from '@/views/Notifications/routes';

//import Menu from './Menu';
import NotificationItem from './NotificationItem';

interface Props {
  children?: ReactNode;
}

const ButtonMenuItem = styled(AntMenu.Item)`
  &:hover {
    background-color: inherit;
  }
`;

const StyledMenu = styled(AntMenu)`
  .ant-dropdown-menu-item-group-list {
    margin: 0;
  }
`;
const StyledMenuItem = styled(AntMenu.Item)<{ $read: boolean }>`
  border-bottom: 1px solid #f0f0f0;
  background-color: ${props => (props.$read ? 'inherit' : '#f5f5f5')};
`;

function Notifications({ children }: Props): JSX.Element {
  const store = useStore();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, subscribeToMore, loading } = useGetNotificationsQuery({
    variables: {
      limit: 6,
      skip: 0,
    },
  });
  const [action] = useReadNotificationMutation();

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      SubscribeToNotificationsSubscription,
      SubscribeToNotificationsSubscriptionVariables
    >({
      document: SubscribeToNotificationsDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          (!subscriptionData.data && !subscriptionData.data.newNotification) ||
          prev.notifications.notifications?.filter(
            item => item.id === subscriptionData.data.newNotification.id,
          ).length > 0
        ) {
          return prev;
        }
        notification.open({
          message: t('New Notification'),
          description: (
            <Provider store={store}>
              <NotificationItem item={subscriptionData.data.newNotification} />
            </Provider>
          ),
          //onClose: close,
          placement: 'bottomRight',
        });

        const rval = {
          ...prev,
          notifications: {
            ...prev.notifications,
            notifications: [
              subscriptionData.data.newNotification,
              ...prev.notifications.notifications,
            ],
          },
        };
        return rval;
      },
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    dispatch(
      updateUnreadNotifications(
        data?.notifications.notifications.filter(note => !note.read).length ??
          0,
      ),
    );
  }, [data?.notifications.notifications.filter(note => !note.read).length]);

  if (loading) {
    return (
      <Badge count={0} overflowCount={5} offset={[-25, 12]}>
        {children}
      </Badge>
    );
  }

  if (!data || !data?.notifications) {
    return null;
  }
  const { notifications } = data.notifications;

  return (
    <div className={'tour-notifications'} style={{ width: '100%' }}>
      <Dropdown
        placement="topLeft"
        overlay={
          <StyledMenu style={{ margin: '0' }}>
            <AntMenu.ItemGroup title={t('Notifications')}>
              {notifications.slice(0, 5).map(item => (
                <StyledMenuItem key={item.id} $read={item.read}>
                  <NotificationItem item={item} key={item.id} />
                </StyledMenuItem>
              ))}
              <ButtonMenuItem key={'button'} title={''}>
                <Button
                  block
                  onClick={() =>
                    navigate({
                      to: toNotifications(),
                    })
                  }
                >
                  {t('showAll')}
                </Button>
              </ButtonMenuItem>
            </AntMenu.ItemGroup>
          </StyledMenu>
        }
        trigger={['click']}
        arrow
        align={{
          offset: [20, 10],
        }}
        forceRender
        onVisibleChange={() =>
          notifications
            .filter(note => !note.read)
            .map(item =>
              action({
                variables: {
                  id: item.id,
                },
              }),
            )
        }
      >
        <Badge
          count={notifications.filter(note => !note.read).length || 0}
          overflowCount={5}
          offset={[-25, 12]}
        >
          {children}
        </Badge>
      </Dropdown>
    </div>
  );
}

export default Notifications;
