import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

const MyLeaseAgreements = React.lazy(() => import('.'));

export type MyLeaseAgreementsRouteProps = MakeGenerics<
  {
    Params: {
      type?: string;
    };
  } & BasicRouteProps
>;

const MyLeaseAgreementsRoutes: Route<MyLeaseAgreementsRouteProps>[] = [
  {
    path: '/management',
    id: 'Management',
    children: [
      {
        path: '/',
        meta: {
          noPadding: true,
          whiteBackgroundPhone: true,
        },
        element: <MyLeaseAgreements />,
      },
    ],
  },
];

export const toMyLeaseAgreements = (): string => '/management';

export default MyLeaseAgreementsRoutes;
