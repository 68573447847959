import { PhoneOutlined } from '@ant-design/icons';
import { useMatch } from '@tanstack/react-location';
import { Button, Card, CardProps, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import kungsgatanUrl from '@/assets/img/kungsgatanCarden.jpg?url';
import Box, { WordBreak } from '@/components/Box';
import AdminInfo from '@/components/LeaseAgreementList/AdminInfo';
import SeeOnMap from '@/components/LeaseAgreementList/SeeOnMap';
import UsageCategories from '@/components/LeaseAgreementList/UsageCategories';
import LeaseAgreementSize from '@/components/LeaseAgreementSize';
import RateLeaseAgreement from '@/components/RateLeaseAgreement';
import {
  AdminLeaseAgreementListValuesFragment,
  LeaseAgreementListValuesFragment,
  LeaseAgreementManagementValuesFragment,
} from '@/generated/graphql';
import useUser from '@/hooks/useUser';
import { RouteProps } from '@/layout/routes';

import MatchIcon from '../MatchIcon';
import SearchRequestDrawer from './SearchRequestDrawer';

type BaseProps =
  | LeaseAgreementListValuesFragment
  | LeaseAgreementManagementValuesFragment;
export type LeaseAgreementCardProps =
  | BaseProps
  | (BaseProps & AdminLeaseAgreementListValuesFragment);

interface Props extends CardProps {
  leaseAgreement: LeaseAgreementCardProps;
  showRate?: boolean;
  seeOnMap?: boolean;
  isMobile: boolean;
  language?: string;
  showIntegrationProvider?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const StyledImage = styled.img`
  height: ${p => p.theme.dimensions.leaseAgreementListCardImageHeight};
  object-fit: cover;
  content-visibility: auto;
`;

const { Meta } = Card;

const StyledCard = styled(Card)`
  //cursor: pointer;
  && {
    display: flex;
    flex-direction: column;
    .ant-card-body {
      padding: 12px 0 16px 0;
      :hover {
        cursor: default;
      }
    }

    .ant-card-actions {
      border-radius: ${({ theme }) => theme.dimensions.borderRadius};
      margin-top: auto;
    }
    .ant-card-meta-title {
      margin-bottom: 0;
    }
    .ant-card-actions > li > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  height: 100%;
`;

function LeaseAgreementCard({
  leaseAgreement,
  showRate = false,
  seeOnMap = false,
  isMobile,
  onClick,
  showIntegrationProvider,
  children,
  ...rest
}: Props): JSX.Element {
  const [openDrawer, setOpenDrawer] = useState(false);

  const user = useUser();
  const match = useMatch<RouteProps>();
  const isAdmin = user.role === 'admin';

  const isAdminOrOwner =
    isAdmin || user.activeClientId === leaseAgreement.landlord?.id;
  const showAdminInfo = isAdmin && match.pathname.includes('admin');
  const { t } = useTranslation();
  const {
    id,
    name,
    title,
    location,
    usageCategory,
    image,
    size,
    sizeSpan,
    address,
    matchingSearchRequests,
  } = leaseAgreement;

  return (
    <div key={id} style={{ height: '100%' }}>
      <div
        style={{
          height: 0,
          position: 'relative',
        }}
      >
        {(showIntegrationProvider || showAdminInfo) &&
          'integrationProvider' in leaseAgreement &&
          leaseAgreement.integrationProvider?.image?.url && (
            <Tooltip title={leaseAgreement.integrationProvider.name}>
              <div className="flex space-x-2 items-center text-xs mt-auto absolute top-2 left-2 bg-white z-10 p-1 rounded border-zinc-200 border border-solid shadow">
                <img
                  src={leaseAgreement.integrationProvider.image?.url}
                  className="w-4 h-4"
                />
              </div>
            </Tooltip>
          )}
        {seeOnMap && (
          <SeeOnMap coordinates={location.coordinates} leaseAgreementId={id} />
        )}

        {showRate && !showAdminInfo && (
          <RateLeaseAgreement
            key={id}
            selectedLeaseAgreement={leaseAgreement}
          />
        )}
      </div>
      <StyledCard
        bordered={false}
        hoverable
        style={{
          width: '100%',
        }}
        {...rest}
        cover={
          image ? (
            <StyledImage alt={name} src={image.url} onClick={onClick} />
          ) : (
            <StyledImage alt={name} src={kungsgatanUrl} onClick={onClick} />
          )
        }
      >
        <Meta
          key={id}
          description={
            <Box px={1.5} verticalSpacing={1}>
              <Box flexCol>
                <WordBreak>
                  <Typography.Text
                    strong
                    style={{
                      fontSize: '16px',
                    }}
                  >
                    {name}
                  </Typography.Text>
                </WordBreak>
                <Typography.Text type={'secondary'}>
                  {address.city}
                </Typography.Text>
              </Box>

              <Box flexCol>
                <Typography.Text strong>
                  <LeaseAgreementSize size={size} sizeSpan={sizeSpan} />
                </Typography.Text>
                <UsageCategories inverted usageCategories={usageCategory} />
              </Box>
              {title && (
                <div>
                  {isMobile ? (
                    <div>{title}</div>
                  ) : (
                    <Typography.Text type={'secondary'}>
                      {title}
                    </Typography.Text>
                  )}
                </div>
              )}

              <div>
                {isAdminOrOwner && (
                  <>
                    <div className="flex">
                      <PhoneOutlined className="mr-2" />
                      <div className="flex flex-col">
                        {leaseAgreement.landlordContactPersons.length > 0 ? (
                          leaseAgreement.landlordContactPersons.map(
                            contactPerson => (
                              <div key={contactPerson.id}>
                                {contactPerson.name}
                              </div>
                            ),
                          )
                        ) : (
                          <div>{t('No contact person')}</div>
                        )}
                      </div>
                    </div>
                    {matchingSearchRequests.count !== 0 && isAdminOrOwner && (
                      <>
                        <div
                          className="hover:cursor-pointer text-xl text-gray-500 flex"
                          onClick={() => {
                            setOpenDrawer(true);
                          }}
                        >
                          <Button
                            className="pl-0"
                            type="link"
                            icon={<MatchIcon />}
                          >{`Matchande lokalförfrågningar (${matchingSearchRequests.count})`}</Button>
                        </div>

                        <SearchRequestDrawer
                          title={t('Matching search requests')}
                          leaseAgreementId={id}
                          onClose={() => {
                            setOpenDrawer(false);
                          }}
                          open={openDrawer}
                        />
                      </>
                    )}
                  </>
                )}

                {showAdminInfo && 'interestedUsers' in leaseAgreement && (
                  <AdminInfo
                    info={{
                      interestedUsers: leaseAgreement.interestedUsers,
                      fromVitec: leaseAgreement.fromVitec,
                      landlord: leaseAgreement.landlord,
                      views: leaseAgreement.views,
                      created: leaseAgreement.created,
                    }}
                  />
                )}
              </div>
              {children}
            </Box>
          }
        />
      </StyledCard>
    </div>
  );
}

export default LeaseAgreementCard;
