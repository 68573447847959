import { Typography } from 'antd';
import styled from 'styled-components';

export const Text = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 400;
`;

export const Paragraph = styled(Typography.Paragraph)`
  font-size: 16px;
  font-weight: 400;
  word-break: break-word;
`;
