import { DeleteOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { useMatch } from '@tanstack/react-location';
import { Button, ButtonProps, Popconfirm } from 'antd';
import React from 'react';

import { useRemovePartnerSectionMutation } from '@/generated/graphql';
import { PortallyPartnerRouteProps } from '@/views/PortallyPartner/routes';

interface Props extends ButtonProps {
  sectionId: string;
}
export default function RemoveSection({
  sectionId,
  ...rest
}: Props): JSX.Element {
  const apollo = useApolloClient();

  const { slug } = useMatch<PortallyPartnerRouteProps>().data;

  const [removeSection] = useRemovePartnerSectionMutation({
    onCompleted: () => apollo.reFetchObservableQueries(),
  });

  return (
    <Popconfirm
      title={'Är du säker?'}
      onConfirm={async () =>
        removeSection({
          variables: {
            sectionId,
            slug,
          },
        })
      }
    >
      <Button danger icon={<DeleteOutlined />} {...rest} />
    </Popconfirm>
  );
}
