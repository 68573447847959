import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Modal, Popover } from 'antd';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import Box from '@/components/Box';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import theme from '@/main/theme';

type SortField =
  | 'sentDate'
  | 'budget'
  | 'searchParameters.size.max'
  | 'searchParameters.size.min';

interface SearchRequestLandlordSort {
  field: SortField;
  direction: 'ascend' | 'descend';
}

const translateMap = (t: TFunction): Record<SortField, string> => ({
  sentDate: t('Date'),
  budget: 'Budget',
  'searchParameters.size.max': t('size'),
  'searchParameters.size.min': t('size'),
});

interface SearchRequestLandlordSortState {
  sort?: SearchRequestLandlordSort;
  setSort: (sort: SearchRequestLandlordSort | undefined) => void;
}

export const useSearchRequestLandlordSort =
  create<SearchRequestLandlordSortState>()(
    devtools(
      persist(
        set => ({
          sort: undefined,
          setSort: (sort: SearchRequestLandlordSort | undefined) =>
            set({ sort }),
        }),
        {
          name: 'search-request-landlord-sort',
        },
      ),
    ),
  );

const Grid = styled.div`
  min-width: 300px;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-gap: 8px;
`;

interface SortItemProps {
  sort: SearchRequestLandlordSort;
  currentSort?: SearchRequestLandlordSort;
  setSort: (sort: SearchRequestLandlordSort | undefined) => void;
  title: string;
  description: string;
}

const SortItemBox = styled(Box)<{ $selected: boolean }>`
  border: 1px solid
    ${p =>
      p.$selected ? theme.colors.primaryPurple : theme.colors.inputBorder};
  border-radius: ${theme.dimensions.borderRadiusCard};
  transition: 150ms ease-in;
  cursor: pointer;

  :hover {
    border: 1px solid
      ${p =>
        p.$selected
          ? theme.colors.primaryPurple
          : theme.colors.inputBorderHover};
  }
`;

function SortItem({
  sort,
  currentSort,
  setSort,
  title,
  description,
}: SortItemProps): JSX.Element {
  const isSelected =
    sort.field === currentSort?.field &&
    sort.direction === currentSort?.direction;

  return (
    <SortItemBox
      p={2}
      $selected={isSelected}
      onClick={() => (isSelected ? setSort(undefined) : setSort(sort))}
    >
      <Box>
        {title}{' '}
        {sort.direction === 'ascend' ? (
          <ArrowDownOutlined />
        ) : (
          <ArrowUpOutlined />
        )}
      </Box>
      <Box color={theme.colors.typographySecondary}>{description}</Box>
    </SortItemBox>
  );
}

interface Props {
  currentSort?: SearchRequestLandlordSort;
  setSort: (sort: SearchRequestLandlordSort | undefined) => void;
}

function Content({ setSort, currentSort }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Grid>
      <SortItem
        title={t('Date')}
        description={t('sort.ascend.date')}
        sort={{
          field: 'sentDate',
          direction: 'ascend',
        }}
        currentSort={currentSort}
        setSort={setSort}
      />
      <SortItem
        title={t('Date')}
        description={t('sort.descend.date')}
        sort={{
          field: 'sentDate',
          direction: 'descend',
        }}
        currentSort={currentSort}
        setSort={setSort}
      />
      <SortItem
        title={t('budget')}
        description={t('sort.ascend.budget')}
        sort={{
          field: 'budget',
          direction: 'ascend',
        }}
        currentSort={currentSort}
        setSort={setSort}
      />
      <SortItem
        title={t('budget')}
        description={t('sort.descend.budget')}
        sort={{
          field: 'budget',
          direction: 'descend',
        }}
        currentSort={currentSort}
        setSort={setSort}
      />
      <SortItem
        title={t('size')}
        description={t('sort.ascend.size')}
        sort={{
          field: 'searchParameters.size.max',
          direction: 'ascend',
        }}
        currentSort={currentSort}
        setSort={setSort}
      />
      <SortItem
        title={t('size')}
        description={t('sort.descend.size')}
        sort={{
          field: 'searchParameters.size.min',
          direction: 'descend',
        }}
        currentSort={currentSort}
        setSort={setSort}
      />
    </Grid>
  );
}

function Sort(props: ButtonProps): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  const sort = useSearchRequestLandlordSort(state => state.sort);
  const [open, setOpen] = useState(false);
  const setSort = useSearchRequestLandlordSort(state => state.setSort);

  if (isMobile) {
    return (
      <>
        <Modal
          title={t('Sort')}
          open={open}
          onCancel={() => setOpen(false)}
          footer={<Button onClick={() => setOpen(false)}>{t('Close')}</Button>}
        >
          <Content currentSort={sort} setSort={setSort} />
        </Modal>

        <Button onClick={() => setOpen(!open)} {...props}>
          {!sort ? (
            t('Sort')
          ) : (
            <>
              {translateMap(t)[sort.field]}{' '}
              {sort.direction === 'ascend' ? (
                <ArrowDownOutlined />
              ) : (
                <ArrowUpOutlined />
              )}
            </>
          )}
        </Button>
      </>
    );
  }
  return (
    <Popover
      trigger={'click'}
      placement={'bottom'}
      title={t('Sort')}
      content={<Content setSort={setSort} currentSort={sort} />}
    >
      <Button type={'text'} {...props}>
        {!sort ? (
          t('Sort')
        ) : (
          <>
            {translateMap(t)[sort.field]}{' '}
            {sort.direction === 'ascend' ? (
              <ArrowDownOutlined />
            ) : (
              <ArrowUpOutlined />
            )}
          </>
        )}
      </Button>
    </Popover>
  );
}

export default Sort;
