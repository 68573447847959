import { DesktopOutlined } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-location';
import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { toHome } from '@/views/Home/routes';

interface Props {
  type?: 'default' | 'search';
}

function Page403({ type = 'default' }: Props): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box p={3}>
      <Result
        status="403"
        title="403"
        subTitle={t(`notAuthorized.${type}`)}
        extra={
          <Button
            onClick={() =>
              navigate({
                to: toHome(),
              })
            }
            type="primary"
            icon={<DesktopOutlined />}
          >
            {t('Back home')}
          </Button>
        }
      />
    </Box>
  );
}

export default Page403;
