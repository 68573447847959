import { useApolloClient } from '@apollo/client';
import { Archive } from '@styled-icons/bootstrap/Archive';
import { ButtonProps, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledIconButton } from '@/components/Buttons';
import {
  LeaseAgreementProspectStatus,
  useEditLeaseAgreementProspectStatusMutation,
} from '@/generated/graphql';

interface Props extends ButtonProps {
  id: string;
  onClose: () => void;
}

export default function NotInterestingButton({
  id,
  onClose,
  ...rest
}: Props): JSX.Element {
  const { t } = useTranslation();
  const apollo = useApolloClient();
  const [editLeaseAgreementProspect, { loading }] =
    useEditLeaseAgreementProspectStatusMutation({
      onCompleted: () => {
        onClose();
        message.success(t('step_archived'));
        apollo.reFetchObservableQueries();
      },
    });
  return (
    <StyledIconButton
      icon={<Archive size={14} />}
      $withText
      loading={loading}
      onClick={async () =>
        await editLeaseAgreementProspect({
          variables: {
            id,
            status: LeaseAgreementProspectStatus.Declined,
          },
        })
      }
      {...rest}
    >
      {t('Archive')}
    </StyledIconButton>
  );
}
