import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

/*
   Usage:
   Översättning:
   const {t} = useTranslation(['loginpage']); <- Lägg till för att översätta
   const {t} = useTranslation(); <- Funkar också, osäker på om andra NS utom default inkluderas dock.

   <h1>t('loginpage:Example')<h1> => <h1>Exempel<h1>

   t('Example') letar i defaultNS.

   Byt språk:
   import i18n from 'pathtosrc/i18n'


   const changeLanguage: lng => {
      i18n.changeLanguage(lng);
   }
   (i18n.changeLanguage('sv') exempelvis.)
 */

const defaultLng = localStorage.getItem('lng') || 'sv';
i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'sv',
    debug: false,
    ns: ['common', 'errors'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    returnObjects: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: { v: import.meta.env.VITE_GITHUB_SHA ?? '' },
    },
    defaultLng,
    lng: defaultLng,
  });

export default i18n;
