import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

export type SearchRequestTenantRouteProps = MakeGenerics<{
  Params: {
    searchRequestReferenceId?: string;
    conversationId?: string;
  };
  LoaderData: {
    searchRequestReferenceId: string;
    conversationId?: string;
  };
}> &
  BasicRouteProps;

const SearchRequestTenant = React.lazy(() => import('.'));

const SearchRequestTenantRoutes: Route<SearchRequestTenantRouteProps>[] = [
  {
    path: '/s/:searchRequestReferenceId',
    id: 'Search request tenant',

    children: [
      {
        path: '/',
        element: <SearchRequestTenant />,
        meta: {
          whiteBackground: true,
        },
        loader: ({ params }) => ({
          searchRequestReferenceId: params.searchRequestReferenceId,
        }),
      },
      {
        path: '/:conversationId',
        element: <SearchRequestTenant />,
        loader: ({ params }) => ({
          searchRequestReferenceId: params.searchRequestReferenceId,
          conversationId: params.conversationId,
        }),
        meta: {
          whiteBackground: true,
        },
      },
    ],
  },
];

interface SearchRequestTenantProps {
  searchRequestReferenceId: string;
  conversationId?: string;
}

export const toSearchRequestTenant = ({
  searchRequestReferenceId,
  conversationId,
}: SearchRequestTenantProps): string => {
  if (conversationId) {
    return `/s/${searchRequestReferenceId}/${conversationId}`;
  }
  return `/s/${searchRequestReferenceId}`;
};

export default SearchRequestTenantRoutes;
