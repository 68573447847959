import { ApolloError } from '@apollo/client';
import { Alert } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  error?: ApolloError;
  message?: string;
  type?: 'error' | 'success' | 'info' | 'warning' | undefined;
}

export default function Message({
  error,
  type = 'error',
  message,
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  let description = '';

  if (message) {
    description = message;
  }

  if (error) {
    if (error.message) {
      description += `${error.message}\n`;
    }
    if (error.networkError) {
      const msg = error.networkError.toString();
      if (msg.startsWith('ServerParseError: Unexpected token')) {
        description = t('serverError');
      } else {
        description = msg;
      }
      error.graphQLErrors.forEach(({ message: msg }): void => {
        description += `${msg}\n`;
      });

      console.error(description);
    }
  }

  return (
    <div style={{ padding: '10px 0' }}>
      <Alert
        message={<span style={{ whiteSpace: 'pre-line' }}>{description}</span>}
        type={type}
      />
    </div>
  );
}
