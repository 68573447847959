import { groupBy, toArray } from 'lodash';

import { Agreement, Cluster } from '@/utils/types';

export const groupMarkers = (
  items: (Agreement | Cluster)[],
): (Cluster | Agreement)[] => {
  const grouped: (Agreement | Cluster)[][] = toArray(
    groupBy(items, items => items.location.coordinates.toString()),
  );

  return grouped
    .map(group =>
      group.reduce((prev, next) => ({
        ...prev,
        id: `${prev.id}:${next.id}`,
      })),
    )
    .map(obj =>
      obj.id.split(':').length > 1
        ? { ...obj, count: -1, location: obj?.location }
        : obj,
    );
};
