import { SendOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Form, Input, Modal, Typography } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import {
  ContactPerson,
  GetPortallyPartnerQuery,
  MessageToPartnerInput,
  useSendMessageToPartnerMutation,
} from '@/generated/graphql';
import useUser from '@/hooks/useUser';
const { Title } = Typography;
interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  slug: string;
  contactPerson: GetPortallyPartnerQuery['portallyPartner']['contactPerson'];
}
export default function ContactModal({
  visible,
  setVisible,
  slug,
  contactPerson,
}: Props): JSX.Element {
  const user = useUser();
  const [form] = Form.useForm<MessageToPartnerInput>();
  const { t } = useTranslation();

  const [send, { loading }] = useSendMessageToPartnerMutation({});
  const rules = [
    {
      required: true,
      message: t('required'),
    },
  ];
  const sendMessage = (): void => {
    form.validateFields().then(async fd => {
      await send({
        variables: {
          messageToPartnerInput: {
            ...(!user && {
              from: {
                ...fd.from,
              },
            }),
            slug,
            message: fd.message,
          },
        },
      });
      form.resetFields();
      setVisible(false);
    });
  };

  return (
    <Modal
      closable={false}
      cancelText={t('Cancel')}
      okText={t('Send')}
      onCancel={() => setVisible(false)}
      open={visible}
      footer={null}
    >
      <Box flex justifyContent="space-between" flexDirection="column">
        <Box flex justifyContent="center">
          <Title level={3}>{t('Contact')}</Title>
        </Box>
        <Box p={1}>
          <Box py={2} flex justifyContent="flex-start" alignItems="center">
            <Avatar size={96} src={contactPerson.image.url} />
            <Box
              h={'auto'}
              ml={2}
              flex
              flexDirection="column"
              justifyContent="space-around"
            >
              <h3>{`${contactPerson.firstName} ${contactPerson.lastName}`}</h3>
              <p className="secondary text">
                {contactPerson.professionalTitle}
              </p>
            </Box>
          </Box>
        </Box>
        <Form layout="vertical" form={form}>
          {!user && (
            <>
              <Form.Item
                label={t('Your information')}
                required
                rules={rules}
                name={['from', 'name']}
              >
                <Input placeholder={t('name')} required />
              </Form.Item>
              <Form.Item required rules={rules} name={['from', 'email']}>
                <Input placeholder={t('email')} required />
              </Form.Item>
            </>
          )}
          <Divider />
          <Form.Item required rules={rules} name={'message'}>
            <Input.TextArea placeholder={t('message')} required />
          </Form.Item>
        </Form>
        <Box flex justifyContent="flex-end">
          <Button
            onClick={() => sendMessage()}
            type={'primary'}
            loading={loading}
            disabled={loading}
            icon={<SendOutlined />}
          >
            {t('Send')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
