import { Input, Radio } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import AddPresetAnswer from '@/components/SearchRequest/shared/DeclineSearchRequestButton/PresetAnswer/AddPresetAnswer';
import PresetAnswers from '@/components/SearchRequest/shared/DeclineSearchRequestButton/PresetAnswer/PresetAnswers';

const { TextArea } = Input;

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

function Content({ value, setValue }: Props): JSX.Element {
  const { t } = useTranslation();
  const onChange = (e): void => setValue(e.target.value);
  return (
    <Box flex flexDirection={'column'} verticalSpacing={1} mb={1}>
      <AddPresetAnswer />
      <Radio.Group value={value} onChange={e => onChange(e)}>
        <PresetAnswers />
        <TextArea
          placeholder={`...${t('or write your own message')}`}
          autoSize={{
            minRows: 4,
          }}
          onClick={e => onChange(e)}
          onChange={e => onChange(e)}
        />
      </Radio.Group>
    </Box>
  );
}

export default Content;
