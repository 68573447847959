import { MailOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, message, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { useSendApiKeyLinkMutation } from '@/generated/graphql';
const { Title } = Typography;

function RequestApiKey(): JSX.Element {
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);
  const [sendApiKeyLink, { loading }] = useSendApiKeyLinkMutation({
    onCompleted: data => {
      setEmailSent(data.sendApiKeyLink);
    },
    onError: err => {
      if (err.message == 'No integration provider found for this email') {
        message.error(t('api.noIntegrationProviderFound'));
      } else {
        message.error(t('error'));
      }
    },
  });

  return (
    <>
      <Box mb={1}>
        {emailSent && <Alert type="success" message={t('emailSent')} />}
      </Box>
      <Title level={5}>{t('api.requestApiKey')}</Title>
      <Form<{ email: string }>
        layout={'vertical'}
        onFinish={values => {
          sendApiKeyLink({ variables: values });
        }}
      >
        <Form.Item label={t('email')}>
          <Form.Item
            name={'email'}
            rules={[
              {
                required: true,
                message: t('required'),
              },
              {
                type: 'email',
                message: t('emailValid'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              block
              type={'primary'}
              htmlType={'submit'}
              icon={<MailOutlined />}
              loading={loading}
              disabled={emailSent}
            >
              {t('Send me a link')}
            </Button>
          </Form.Item>
        </Form.Item>
      </Form>
    </>
  );
}

export default RequestApiKey;
