import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import React from 'react';
import styled from 'styled-components';

const StyledEditor = styled(CKEditor).attrs({
  editor: ClassicEditor,
})``;

interface EditorProps {
  value?: string;
  onChange?: (value: string) => void;
}

const EditorField: React.FC<EditorProps> = ({ value = '', onChange }) => (
  <span>
    <StyledEditor
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        if (onChange) {
          onChange(data);
        }
      }}
    />
  </span>
);

export default EditorField;
