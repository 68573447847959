import { Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import PortallyIcon from '@/components/PortallyLogo/PortallyIcon';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

export default function Page500(): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  return (
    <Box
      flex
      style={{ height: '100vh' }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Result
        icon={<PortallyIcon size={isMobile ? '64px' : '128px'} />}
        title={500}
        subTitle={
          <div style={{ whiteSpace: 'pre-line' }}>{t('connectionError')}</div>
        }
      />
    </Box>
  );
}
