import { useApolloClient } from '@apollo/client';
import { Badge } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Forum } from 'styled-icons/material-rounded';
import useSound from 'use-sound';
import create from 'zustand';

import {
  useGetConversationsMessageLinkQuery,
  useSubscribeToMessagesSubscription,
  useSubscribeToNotificationsSubscription,
  useSubscribeToReactionsSubscription,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useUser from '@/hooks/useUser';
import {
  NavLink,
  NavLinkMobile,
} from '@/layout/Customer/LeftMenu/index.styled';
import { toMessages } from '@/views/Messages/routes';

const sleep = (milliseconds: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, milliseconds));

interface Props {
  menuOpen?: boolean;
}

interface UnreadMessages {
  unreadConversations: number;
  unreadPartnerConversations: number;
}

export const useUnreadMessagesStore = create<
  UnreadMessages & {
    setUnreadConversations: ({
      unreadConversations,
      unreadPartnerConversations,
    }: UnreadMessages) => void;
  }
>(set => ({
  unreadConversations: 0,
  unreadPartnerConversations: 0,
  setUnreadConversations: ({
    unreadConversations,
    unreadPartnerConversations,
  }) =>
    set({
      unreadConversations,
      unreadPartnerConversations,
    }),
}));

function MessageLink({ menuOpen = false }: Props): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const { t } = useTranslation();

  const [play] = useSound(
    'https://res.cloudinary.com/portally/video/upload/v1654780503/sounds/portally-on-message_izfo2m.mp3',
    { volume: 0.15 },
  );
  const apollo = useApolloClient();
  const user = useUser();
  const { personalized } = useAppSelector(
    state => state.customer.conversationFilter,
  );
  const { data, previousData } = useGetConversationsMessageLinkQuery({
    variables: {
      filter: {
        personalized,
      },
    },
  });
  const { setUnreadConversations, ...unreadMessages } =
    useUnreadMessagesStore();
  const unreadConversations =
    (data ?? previousData)?.conversations.conversations.filter(
      item =>
        item.conversation.unread.count !== 0 && !item.conversation.isPartner,
    ).length || 0;
  const unreadPartnerConversations =
    (data ?? previousData)?.conversations.conversations.filter(
      item =>
        item.conversation.unread.count !== 0 && item.conversation.isPartner,
    ).length || 0;
  useEffect(() => {
    setUnreadConversations({
      unreadConversations,
      unreadPartnerConversations,
    });
  }, [unreadConversations, unreadPartnerConversations]);

  useSubscribeToNotificationsSubscription({
    onSubscriptionData: async ({ subscriptionData }) => {
      if (subscriptionData.data.newNotification.type === 'newMessage') {
        await apollo.reFetchObservableQueries();
      }
    },
  });

  useSubscribeToReactionsSubscription({});
  useSubscribeToMessagesSubscription({
    shouldResubscribe: true,
    onSubscriptionData: async ({ subscriptionData }) => {
      await apollo.reFetchObservableQueries();
      const newMessage = subscriptionData.data.newMessage;

      if (
        newMessage.sender.id !== user?.activeClientId &&
        data?.conversations.conversations
          .map(c => c.conversation.id)
          .includes(newMessage.conversationId)
      ) {
        const mutex = localStorage.getItem('mutex');
        if (!mutex) {
          localStorage.setItem('mutex', 'locked');
          try {
            play();
          } catch (e) {
            console.error(e);
          }

          sleep(5000).then(() => {
            localStorage.removeItem('mutex');
          });
        }
      }
    },
  });

  if (isMobile) {
    return (
      <NavLinkMobile
        $menuOpen={menuOpen}
        to={toMessages()}
        className="tour-messages"
      >
        <Badge
          count={
            unreadMessages.unreadConversations +
            unreadMessages.unreadPartnerConversations
          }
          offset={[2, 2]}
        >
          <Forum />
        </Badge>
      </NavLinkMobile>
    );
  }

  return (
    <NavLink to={toMessages()} className="tour-messages">
      <Badge
        count={
          unreadMessages.unreadConversations +
          unreadMessages.unreadPartnerConversations
        }
        offset={[2, 2]}
      >
        <Forum />
      </Badge>
      <span className="text">{t('messages')}</span>
    </NavLink>
  );
}

export default MessageLink;
