import React from 'react';

import Box from '@/components/Box';
import LeaseAgreementSize from '@/components/LeaseAgreementSize';
import Tag from '@/components/Tags/UsageCategoryTags';
import { LeaseAgreementListPreviewValuesFragment } from '@/generated/graphql';

interface Props {
  leaseAgreement: LeaseAgreementListPreviewValuesFragment;
  isMobile?: boolean;
  showSize?: boolean;
}

function LeaseAgreementListPreview({
  leaseAgreement,
  isMobile,
  showSize = false,
}: Props): JSX.Element {
  const { address, title, image, usageCategory } = leaseAgreement;
  return (
    <div className="flex items-start gap-2 justify-between">
      <div className="space-y-2 mr-2">
        <div className="font-semibold">
          {address.street} {address.streetNumber}
        </div>
        <div>
          {address.zipCode} {address.city}
        </div>

        <div className="text-zinc-500">{title}</div>
        {showSize && (
          <div>
            <LeaseAgreementSize
              size={leaseAgreement.size}
              sizeSpan={leaseAgreement.sizeSpan}
              showNotSpecified
            />
          </div>
        )}

        <div className="m-mobile:max-w-96 gap-1 flex flex-wrap">
          {usageCategory.map(category => (
            <Tag
              usageCategory={category}
              key={category}
              size={'small'}
              inverted
            />
          ))}
        </div>
      </div>

      <img className="rounded w-48 object-cover" src={image?.url} alt={''} />
    </div>
  );
}

export default LeaseAgreementListPreview;
