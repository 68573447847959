import { ApolloError } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';
import { ClientDataList } from '@/components/ClientViewComponents';
import Message from '@/components/Message';
import Spinner from '@/components/Spinner';
import { GetClientQuery } from '@/generated/graphql';

const Img = styled.img`
  max-width: 120px;
  height: auto;
  margin-bottom: 20px;
`;

interface Props {
  loading: boolean;
  error: ApolloError | undefined;
  data: GetClientQuery;

  maxHeight?: string;
}

const ClientModalView = ({
  loading,
  error,
  data,
  maxHeight,
}: Props): JSX.Element => {
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Message error={error} />;
  }

  const { client } = data;

  return (
    <Box
      verticalSpacing={3}
      style={maxHeight ? { maxHeight, overflow: 'auto' } : {}}
    >
      {client.description && (
        <div dangerouslySetInnerHTML={{ __html: client.description || '' }} />
      )}
      <Box verticalSpacing={2}>
        {client.image && <Img alt="example" src={client.image.url} />}
        <ClientDataList
          title="companyInfo"
          values={client}
          options={[
            'type',
            'orgNumber',
            'companyName',
            'parentCompany',
            'parentCompanyOrgNumber',
          ]}
        />
        <ClientDataList
          title="contactInfo"
          values={client}
          options={['telephone', 'website', 'postAddress', 'visitingAddress']}
        />
        {/* isTenant && (
          <ClientDataList
            title="community"
            values={client}
            options={['brandCategory', 'brandSubCategory', 'neighbours']}
          />
        ) */}
        {/* isTenant && (
          <ClientDataList
            title="targetGroup"
            values={client}
            options={[
              'targetGroupDescription',
              'incomeGroup',
              'levelOfEducation',
              'targetGroupWoman',
              'targetGroupWomanAge',
              'targetGroupMan',
              'targetGroupManAge',
              'targetGroupChild',
              'targetGroupChildAge',
            ]}
          />
        ) */}
        {/* isTenant && (
          <ClientDataList
            title="storeQuantity"
            values={client}
            options={['typesOfProperty', 'stores']}
          />
        ) */}
        {/* isTenant && (
          <ClientDataList
            title="distributionChannel"
            values={client}
            options={[
              'eCommerce',
              'eCommerceTurnover',
              'physicalStore',
              'typesOfPhysicalStores',
            ]}
          />
        ) */}
        {/* <ClientDataList
          title="economy"
          values={client}
          options={['turnover']}
        /> */}
      </Box>
    </Box>
  );
};

export default ClientModalView;
