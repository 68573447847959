import {
  FileTextOutlined,
  HomeFilled,
  HomeOutlined,
  LogoutOutlined,
  MoreOutlined,
  SearchOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { DashboardFilled, HeartOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Link, useMatches, useNavigate } from '@tanstack/react-location';
import { Divider, Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import LocaleChanger from '@/components/LocaleChanger';
import MessageLink from '@/components/MessageLink';
import MobileNotificationsLink from '@/components/Notifications/MobileNotificationsLink';
import { refetchUser } from '@/hooks/useAuth';
import { User } from '@/hooks/useUser';
import CreateSearchRequestLink from '@/layout/Customer/MobileNavigation/CreateSearchRequestLink';
import SubscriptionMenuItem from '@/layout/SubscriptionMenuItem';
import theme from '@/main/theme';
import { toAdminHome } from '@/views/AdminHome/routes';
import { toClientSettings } from '@/views/Client/routes';
import { toEstablishment } from '@/views/Establishment/routes';
import { toHome } from '@/views/Home/routes';
import { toMyLeaseAgreements } from '@/views/Management/routes';
import { toMessages } from '@/views/Messages/routes';
import { toSearchRequestLandlord } from '@/views/SearchRequestLandlord/route';
import { toSubscription } from '@/views/Subscription/routes';
import { toTermsOfService } from '@/views/TermsOfService/routes';
import { toUserSettings } from '@/views/Users/routes';

export const OverlayMenu = styled.div<{ open: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  opacity: ${p => (p.open ? 1 : 0)};
  transform: translate3d(${p => (p.open ? 0 : '100%')}, 0, 0);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 999;
`;
export const Mask = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: ${p => (p.open ? 1 : 0)};
  z-index: ${p => (p.open ? 999 : -10)};
  height: calc(100vh - 60px);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
`;

export const StyledMenu = styled(Menu)`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 24px;
  padding-bottom: ${({ theme }) => theme.dimensions.mobileNavOffset};
  border-top: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.95);
`;

const navStyle = css<{ $open?: boolean }>`
  width: 25%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  > * {
    color: ${p => getNavigationColor(p.$open)};
  }

  .active-icon {
    display: none;
  }
  &.active {
    .inactive-icon {
      display: none;
    }
    .active-icon {
      display: block;
    }
    > * {
      color: ${props => props.theme.colors.primaryPurpleDark};
    }

    .border {
      background: ${p =>
        p.$open ? p.theme.colors.websitePurple : p.theme.colors.white};
    }
  }
`;

const NavbarContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;

  height: 100vh;
  width: 100vw;
  padding-bottom: ${theme.dimensions.navbarBottom};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 999;
`;

export const Navbar = styled.div<{ $menuOpen: boolean }>`
  transition: all 0.3s;
  height: ${theme.dimensions.navbarHeight};
  padding-bottom: 8px;
  width: 100%;

  background: ${props =>
    props.$menuOpen
      ? props.theme.colors.white
      : props.theme.colors.primaryPurple};
  background: ${props => props.theme.colors.white};
  color: black;
  display: flex;
  border-top: 1px solid #ddd;
  justify-content: space-around;
  -webkit-backface-visibility: hidden;
  position: fixed;
  bottom: ${theme.dimensions.navbarBottom};
  overflow: hidden;
  left: 0px;
  z-index: 999;
`;

const Item = styled(Link)<{
  $open?: boolean;
}>`
  ${navStyle};
`;
export const Border = styled.div`
  width: 40%;
  height: 4px;
  border-radius: 0 0 10px 10px;
  position: relative;
  bottom: 16px;
`;

export const NoLinkItem = styled.div<{ $open?: boolean }>`
  ${navStyle};
`;

const More = styled.div<{ $open?: boolean }>`
  ${navStyle};
  border-left: solid 1px white;
  > * {
    color: ${p => p.$open && theme.colors.coral};
  }
`;

export const getNavigationColor = (menuOpen: boolean): string =>
  menuOpen ? 'rgba(0,0,0,0.60)' : 'rgba(0,0,0,0.60)';

interface Props {
  user: User;
}

export default function MobileNavigation({ user }: Props): JSX.Element {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [localeChangerVisible, setLocaleChangerVisible] = useState(false);

  const signOut = (): void => {
    localStorage.removeItem('auth-token');
    refetchUser(apolloClient);
  };

  const toggleMenu = (): void => {
    setMenuOpen(!menuOpen);
  };

  const isAdmin = user?.role === 'admin';
  const isLandlord = user?.activeClient?.type === 'landlord';
  const isTenant = user?.activeClient?.type === 'tenant';

  const mainMenu = [
    {
      icon: HomeOutlined,
      activeIcon: HomeFilled,
      text: t('Home'),
      to: toHome(),
      key: 'home',
    },

    ...(isLandlord
      ? [
          {
            icon: SearchOutlined,
            activeIcon: SearchOutlined,
            text: t('requests'),
            to: toSearchRequestLandlord(),
            key: 'landlord',
          },
        ]
      : [
          {
            icon: SearchOutlined,
            activeIcon: SearchOutlined,
            component: CreateSearchRequestLink,
            key: 'search',
          },
        ]),
    {
      component: MessageLink,
      to: toMessages(),
      key: 'messages',
    },
    {
      component: MobileNotificationsLink,
      key: 'notifications',
    },
  ];

  const secondaryMenu = [
    {
      icon: UserOutlined,
      text: t('Account settings'),
      to: toUserSettings(),
    },
    {
      icon: SettingOutlined,
      text: t('Client settings'),
      to: toClientSettings(),
    },
    {
      icon: null,
      text: 'subscription',
      action: null,
    },
    {
      icon: FileTextOutlined,
      text: t('Terms of service'),
      to: toTermsOfService(),
    },
    {
      icon: HomeOutlined,
      text: t('My facilities'),
      to: toMyLeaseAgreements(),
    },
    ...(isTenant
      ? [
          {
            icon: HeartOutlined,
            text: t('Favourites'),
            to: toEstablishment(),
          },
        ]
      : []),
    {
      icon: LogoutOutlined,
      text: t('Logout'),
      action: signOut,
    },
    ...(isAdmin
      ? [
          {
            icon: DashboardFilled,
            text: 'Admin',
            to: toAdminHome(),
            key: 'admin',
          },
        ]
      : []),
  ];
  const navbarItems = mainMenu.map(item => {
    if (item.component) {
      return <item.component menuOpen={menuOpen} key={item.key} />;
    }

    return (
      <Item
        $open={menuOpen}
        to={item.to}
        key={item.key}
        onClick={() => setMenuOpen(false)}
      >
        <item.icon className={'inactive-icon'} />
        <item.activeIcon className={'active-icon'} />
        <div />
      </Item>
    );
  });

  const matches = useMatches();
  const current = matches[matches.length - 1];

  return (
    <>
      <Mask open={menuOpen} />
      <OverlayMenu open={menuOpen}>
        <StyledMenu
          selectedKeys={
            current.pathname.includes('management')
              ? ['/management']
              : current.pathname.includes('properties')
              ? ['/properties']
              : [current.pathname]
          }
        >
          <LocaleChanger
            setVisible={setLocaleChangerVisible}
            visible={localeChangerVisible}
          />
          <Divider />
          {secondaryMenu.map(item =>
            item.text === 'subscription' ? (
              <SubscriptionMenuItem
                subscriptionType={user?.activeSubscription?.type}
                key={toSubscription()}
              />
            ) : (
              <Menu.Item
                style={{ borderRadius: '6px', marginTop: 0 }}
                key={item.to}
                onClick={() => {
                  if (item.action) {
                    item.action();
                  }
                  if (item.to) {
                    navigate({ to: item.to });
                  }
                  setMenuOpen(false);
                }}
                icon={<item.icon size={14} />}
                title={item.text}
              >
                {item.text}
              </Menu.Item>
            ),
          )}
        </StyledMenu>
      </OverlayMenu>

      <Navbar $menuOpen={menuOpen} key={'mobile-nav'}>
        {navbarItems}
        <More onClick={toggleMenu} $open={menuOpen}>
          <MoreOutlined size={22} />
        </More>
      </Navbar>
    </>
  );
}
