import {
  GoogleMap,
  Polygon as MapPolygon,
  useLoadScript,
} from '@react-google-maps/api';
import { Button, Spin, Typography } from 'antd';
import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { initialCenter } from '@/components/Map';
import { mapStyle, polygonColors } from '@/components/Map/mapstyle';
import ClearPolygons from '@/components/Map/shared/ClearPolygons';
import Drawing from '@/components/Map/shared/Drawing';
import MapContainer from '@/components/Map/shared/MapContainer';
import ToggleDrawing from '@/components/Map/shared/ToggleDrawing';
import FitBounds from '@/components/Map/StaticMap/FitBounds';
import { LatLngLiteral } from '@/components/Map/types';
import {
  Left,
  libraries,
  RelativeContainer,
  Right,
} from '@/components/Map/util';
import { ResultBounds } from '@/generated/graphql';
import useAppDispatch from '@/hooks/useAppDispatch';
import theme from '@/main/theme';
import { updateCenter } from '@/state/customer/actions';

interface Props {
  children?: ReactElement;
  style: CSSProperties;
  polygons: LatLngLiteral[][];
  regionPolygons?: LatLngLiteral[][];
  bounds?: ResultBounds;
  center?: { lat: number; lng: number };
  shouldUpdateCenter?: boolean;
  allowReload?: boolean;
  zoom?: number;
  streetView?: boolean;
  setPolygons?: (polygons: LatLngLiteral[][]) => void;
  mapTypeControl?: boolean;
  height: string;
  fitBoundsBeforeLoad?: boolean;
  draggable?: boolean;
}

function StaticMap({
  children,
  style,
  polygons,
  regionPolygons,
  bounds,
  center,
  shouldUpdateCenter = true,
  allowReload = false,
  zoom = 5,
  streetView = true,
  setPolygons,
  mapTypeControl = true,
  height,
  fitBoundsBeforeLoad = false,
  draggable = true,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  });
  useEffect(() => () => {
    shouldUpdateCenter && dispatch(updateCenter(false));
  });
  const [drawing, setDrawing] = useState(false);

  const [update, setUpdate] = useState(fitBoundsBeforeLoad);
  const [key, setKey] = useState('staticMap');
  const { t } = useTranslation();

  if (!isLoaded) {
    return (
      <MapContainer
        height={height}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size={'small'} />
      </MapContainer>
    );
  }
  return (
    <>
      <MapContainer key={isLoaded.toString()} height={height}>
        <RelativeContainer>
          <Left>
            {setPolygons && (
              <ToggleDrawing drawing={drawing} setDrawing={setDrawing} />
            )}
          </Left>
        </RelativeContainer>
        <GoogleMap
          id="staticMap"
          key={key}
          mapContainerStyle={{ ...style, height }}
          zoom={center ? 14 : zoom}
          onTilesLoaded={() => setUpdate(true)}
          center={center ?? initialCenter}
          options={{
            fullscreenControl: false,
            mapTypeControl,
            styles: mapStyle,
            maxZoom: 17,
            scrollwheel: false,
            draggable,
            minZoom: 2,
            streetViewControl: streetView,
          }}
        >
          {regionPolygons?.map((polygon, i) => (
            <MapPolygon
              path={polygon}
              key={i}
              options={{
                fillColor: theme.colors.primaryPurple,
                fillOpacity: 0.2,
                strokeColor: theme.colors.primaryPurple,
                strokeOpacity: 1,
                strokeWeight: 3,
              }}
            />
          ))}
          {polygons.map((polygon, i) => (
            <MapPolygon
              path={polygon}
              key={i}
              options={{
                fillColor: polygonColors[i],
                fillOpacity: 0.4,
                strokeColor: '#000',
                strokeOpacity: 1,
                strokeWeight: 1,
              }}
            />
          ))}
          {bounds && (
            <FitBounds
              bounds={{
                north: bounds.north,
                south: bounds.south,
                west: bounds.west,
                east: bounds.east,
              }}
              updateReady={update}
            />
          )}
          {setPolygons && window['google'] && window['google'].maps.drawing && (
            <Drawing
              setPolygons={setPolygons}
              polygons={polygons}
              drawing={drawing}
            />
          )}
          {children}
        </GoogleMap>
        <RelativeContainer>
          <Right>
            {setPolygons && (
              <ClearPolygons polygons={polygons} setPolygons={setPolygons} />
            )}
          </Right>
        </RelativeContainer>
      </MapContainer>
      {allowReload && (
        <Box flex>
          <Typography.Text style={{ fontSize: '12px' }} type={'secondary'}>
            {t('mapNotLoading')}{' '}
            <Button
              type={'link'}
              onClick={() => setKey(`${key}+`)}
              style={{ fontSize: '12px', paddingLeft: 0 }}
            >
              {t('Reload map')}
            </Button>
          </Typography.Text>
        </Box>
      )}
    </>
  );
}

export default StaticMap;
