import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, message, Select, Tooltip } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  useEditContactPersonMutation,
  useGetMyColleaguesQuery,
} from '@/generated/graphql';

const { Option } = Select;

interface LabelProps {
  t: TFunction;
}

const StyledSelect = styled(Select)`
  min-width: 100px;
`;

function Label({ t }: LabelProps): JSX.Element {
  return (
    <>
      <Tooltip title={t('The person in charge search request')}>
        <InfoCircleOutlined
          style={{ padding: '4px', color: 'rgba(0, 0, 0, 0.45' }}
        />
      </Tooltip>
      {t('responsible')}
    </>
  );
}

interface Props {
  contactPersonId?: string;
  searchRequestReferenceId: string;
  clientId: string;
}

function ChangeContactPerson({
  contactPersonId,
  clientId,
  searchRequestReferenceId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [editContactPerson, { loading: actionLoading }] =
    useEditContactPersonMutation({
      onError: async () => {
        message.error(t('error'));
      },
    });

  const { data, loading } = useGetMyColleaguesQuery({
    variables: { clientId },
  });

  if (loading || !data) {
    return (
      <Form>
        <Form.Item style={{ maxWidth: '300px' }} label={<Label t={t} />}>
          <StyledSelect loading key={'loading-select'} />
        </Form.Item>
      </Form>
    );
  }

  return (
    <Form>
      <Form.Item style={{ maxWidth: '300px' }} label={<Label t={t} />}>
        <Select
          key={'contactperson-select'}
          loading={actionLoading || loading}
          dropdownMatchSelectWidth
          defaultValue={contactPersonId}
          onChange={async userId => {
            await editContactPerson({
              variables: {
                userId: userId as string,
                searchRequestReferenceId: searchRequestReferenceId,
              },
            });
          }}
        >
          {data?.myColleagues.colleagues.map(colleague => (
            <Option value={colleague.id} key={colleague.id}>
              {colleague.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
}

export default ChangeContactPerson;
