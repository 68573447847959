import { Badge, Select, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import {
  GetMyActiveSearchRequestsQueryResult,
  GetMySearchRequestByReferenceIdLazyQueryHookResult,
  GetMySearchRequestByReferenceIdQueryResult,
  SearchRequestCardValuesFragment,
  SearchRequestStatusEnum,
  SearchRequestStep,
  useUpdateSearchRequestStepMutation,
} from '@/generated/graphql';
import { theme } from '@/main/theme';

const { Option } = Select;

const { Text } = Typography;

interface Props {
  selectedSearchRequest: SearchRequestCardValuesFragment | undefined;
  refetch?: GetMyActiveSearchRequestsQueryResult['refetch'];
}

interface InformationProps {
  data: React.ReactNode;
}

function Information({ data }: InformationProps): JSX.Element {
  return (
    <Box flex flexDirection={'column'} w={'45%'}>
      <Text>{data}</Text>
    </Box>
  );
}

function CommentsAndData({
  selectedSearchRequest,
  refetch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [updateSearchRequestStep, { loading: updateSearchRequestStepLoading }] =
    useUpdateSearchRequestStepMutation({
      onCompleted: async () => {
        if (refetch) {
          await refetch();
        }
      },
    });

  if (!selectedSearchRequest) {
    return null;
  }

  const { step } = selectedSearchRequest;
  return (
    <Box gap={2} justifyContent={'space-between'} flex flexWrap={'wrap'}>
      <Information
        data={
          <Select
            disabled={
              selectedSearchRequest?.searchRequestReference.status ===
              SearchRequestStatusEnum.Disabled
            }
            className={'prevent-active-request-drawer-close'}
            style={{ width: '100%' }}
            value={step}
            loading={updateSearchRequestStepLoading}
            popupClassName={'prevent-active-request-drawer-close'}
            onChange={(value: SearchRequestStep) =>
              updateSearchRequestStep({
                optimisticResponse: {
                  updateSearchRequestStep: {
                    id: selectedSearchRequest?.id,
                    __typename: 'SearchRequest',
                    step: value,
                  },
                },
                variables: {
                  id: selectedSearchRequest.id,
                  update: {
                    step: value,
                  },
                },
              })
            }
          >
            {[
              ...(step === SearchRequestStep.Archived
                ? [SearchRequestStep.Archived]
                : []),
              SearchRequestStep.Todo,
              SearchRequestStep.InProgress,
              SearchRequestStep.Interesting,
              SearchRequestStep.Negotiation,
              SearchRequestStep.Success,
            ].map(step => (
              <Option
                value={step}
                key={step}
                className={'prevent-active-request-drawer-close'}
              >
                <span>
                  <Badge color={theme.colors.steps[step]} className={'mr-2'} />
                  {t(`step_${step}`)}
                </span>
              </Option>
            ))}
          </Select>
        }
      />
    </Box>
  );
}

export default CommentsAndData;
