import { useApolloClient } from '@apollo/client';
import { Form, Switch } from 'antd';
import React from 'react';

import Message from '@/components/Message';
import { useToggleActivePartnerMutation } from '@/generated/graphql';
interface Props {
  slug: string;
  active?: boolean;
}
export default function ToggleActivePartner({
  slug,
  active,
}: Props): JSX.Element {
  const apollo = useApolloClient();
  const [toggleActivePartner, { loading, error }] =
    useToggleActivePartnerMutation({
      onCompleted: () => {
        apollo.reFetchObservableQueries();
      },
    });

  return (
    <Form.Item label={'Aktiverad'}>
      <Switch
        defaultChecked={active}
        onChange={async checked => {
          await toggleActivePartner({
            variables: {
              active: checked,
              slug: slug,
            },
          });
        }}
        loading={loading}
      />
      {error && <Message error={error} />}
    </Form.Item>
  );
}
