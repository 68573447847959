import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  appVersion: import.meta.env.VITE_GITHUB_SHA?.slice(0, 7),
  releaseStage: import.meta.env.VITE_ENVIRONMENT || 'development',
  enabledReleaseStages: ['production', 'staging'],
  maxBreadcrumbs: 30,
  plugins: [new BugsnagPluginReact()],
  onError: event =>
    !event.errors
      .map(err => err.errorMessage)
      .every(s => s.startsWith('Object Not Found Matching Id')),
});

export default Bugsnag;
