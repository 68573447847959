import { Navigate, Outlet, useMatches } from '@tanstack/react-location';
import React, { Suspense } from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';
import Breadcrumbs from '@/components/Breadcrumbs';
import ErrorBoundary from '@/components/ErrorBoundary';
import MetaTitle from '@/components/MetaTitle';
import Spinner from '@/components/Spinner';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useAuth from '@/hooks/useAuth';
import MobileNavigation from '@/layout/Admin/MobileNavigation';
import Navbar from '@/layout/Admin/Navbar';
import { AdminRouteProps } from '@/layout/Admin/routes';
import { toLogin } from '@/layout/Unauthenticated/routes';
import theme from '@/main/theme';
import Page403 from '@/views/Page403';
import Page500 from '@/views/Page500';
const Content = styled.div<{ $withSider: boolean }>`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${theme.breakpoints.mobile}) {
    padding-bottom: ${theme.dimensions.mobileNavOffset};
  }
  max-width: ${({ $withSider }) =>
    $withSider ? 'calc(100vw - 400px)' : '100%'};
`;

/*
    maxWidth: isMobile || !sider ? undefined : 'calc(100vw - 400px)',
 */

const AdminSidebar = styled.div`
  margin-left: 0;
  background: rgb(244, 245, 247);
  max-width: 400px;
  display: flex;
  color: rgb(66, 82, 110);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
    max-width: inherit;
  }
`;

interface CollapseProps {
  width?: number;
  collapsed?: boolean;
}

const Collapse = styled(Box)<CollapseProps>`
  width: ${p => p.width || 600}px;
  ${props => props.collapsed && 'width:0px;'};
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function Admin(): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const matches = useMatches<AdminRouteProps>();
  const meta = matches[matches.length - 1].route.meta;

  const { sider, noFrame } = meta ?? { sider: undefined };

  if (noFrame) {
    return (
      <Suspense fallback={<Spinner expand={true} />}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    );
  }
  return (
    <Box flex>
      <MetaTitle title={'Admin'} />

      <AdminSidebar>
        <Collapse collapsed={!sider} width={400}>
          <Suspense fallback={<Spinner expand={true} />}>
            <ErrorBoundary>
              <Box p={2} pt={4}>
                {sider ?? null}
              </Box>
            </ErrorBoundary>
          </Suspense>
        </Collapse>
      </AdminSidebar>

      <Box flex flexDirection={'column'} w={'100%'}>
        <Suspense fallback={<Spinner expand={true} />}>
          {!isMobile && <Navbar />}
        </Suspense>
        {isMobile && <MobileNavigation key={'admin-mobile'} />}
        <Breadcrumbs />
        <Content $withSider={!!sider && !isMobile}>
          <Suspense fallback={<Spinner expand={true} />}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Suspense>
        </Content>
      </Box>
    </Box>
  );
}

function Authenticated(): JSX.Element {
  const { authenticated, loading, error, isAdmin } = useAuth();
  if (authenticated) {
    if (isAdmin) {
      return <Admin />;
    }
    return <Page403 />;
  }

  if (loading) {
    return <Spinner expand />;
  }

  if (error) {
    return <Page500 />;
  }
  return <Navigate to={toLogin()} />;
}

export default Authenticated;
