import 'dayjs/locale/sv';

import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import { NotificationValuesFragment } from '@/generated/graphql';

import NotificationContent from './NotificationContent';

dayjs.locale('sv');

interface Props {
  item: NotificationValuesFragment;
}

const Container = styled.div`
  padding: 12px 24px;
`;

function NotificationItem({ item }: Props): JSX.Element {
  return (
    <Container>
      <NotificationContent notification={item} />
    </Container>
  );
}

export default NotificationItem;
