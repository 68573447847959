import React, { CSSProperties } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { getI18n, useTranslation } from 'react-i18next';

import Box from '@/components/Box';

interface Props {
  style?: CSSProperties;
}
function SmallLocaleChanger({ style = {} }: Props): JSX.Element {
  const i18n = getI18n();
  const { t } = useTranslation();
  const lng = t('lng');
  return (
    <Box flex justifyContent={'flex-end'} mb={1}>
      {lng === 'en' ? (
        <ReactCountryFlag
          countryCode="SE"
          style={{ cursor: 'pointer', ...style }}
          onClick={async () => {
            await i18n.changeLanguage('sv');
            localStorage.setItem('lng', 'sv');
            await i18n.reloadResources();
          }}
        />
      ) : (
        <ReactCountryFlag
          countryCode="GB"
          style={{ cursor: 'pointer', ...style }}
          onClick={async () => {
            await i18n.changeLanguage('en');
            localStorage.setItem('lng', 'en');
            await i18n.reloadResources();
          }}
        />
      )}
    </Box>
  );
}

export default SmallLocaleChanger;
