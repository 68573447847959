import { DeleteOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Checkbox, message, Popover } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  LeaseAgreementFieldsFragment,
  useDeleteLeaseAgreementMutation,
} from '@/generated/graphql';

interface Props extends ButtonProps {
  id: string;
  integrationProvider:
    | LeaseAgreementFieldsFragment['integrationProvider']
    | null;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  onCompleted: () => Promise<void>;
}

const DeleteLeaseAgreement = ({
  id,
  onCompleted,
  integrationProvider,
  tooltipPlacement = 'bottom',
  ...rest
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [blockFromIntegrationProvider, setBlockFromIntegrationProvider] =
    useState(false);
  const [deleteLeaseAgreement, { loading }] = useDeleteLeaseAgreementMutation({
    onCompleted: async () => {
      message.success(t('Deleted'));
      await onCompleted();
    },
    onError: () => {
      message.error(t('error'));
    },
  });

  return (
    <Popover
      trigger={'click'}
      open={open}
      onOpenChange={open => setOpen(open)}
      title={
        <div className="text-sm font-normal">
          <DeleteOutlined className="text-red-500 mr-2" />
          {t('Are you sure?')}
        </div>
      }
      placement={tooltipPlacement}
      content={
        <div className="flex flex-col">
          {integrationProvider && (
            <Checkbox
              className="text-xs mb-2"
              value={blockFromIntegrationProvider}
              onChange={e => setBlockFromIntegrationProvider(e.target.checked)}
            >
              {t('integrationProviderDelete', {
                name: integrationProvider.name,
              })}
            </Checkbox>
          )}
          <div className="flex gap-1 justify-end">
            <Button size="small" onClick={() => setOpen(false)}>
              {t('No')}
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setOpen(false);
                deleteLeaseAgreement({
                  variables: {
                    id,
                    blockFromIntegrationProvider,
                  },
                });
              }}
            >
              {t('Yes')}
            </Button>
          </div>
        </div>
      }
    >
      <Button loading={loading} disabled={loading} {...rest} />
    </Popover>
  );
};

export default DeleteLeaseAgreement;
