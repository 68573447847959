import { Collapse } from 'antd';
import React from 'react';

import { Paragraph, SubTitle, Title } from './styled';
const { Panel } = Collapse;
interface Props {
  lng: string;
  withCollapse?: boolean;
}

function Commission({ lng, withCollapse }: Props): JSX.Element {
  const Text = (
    <>
      <Paragraph>
        För dig som vill använda Portally utan fast kostnad och endast betala
        när du gör en ny uthyrning.
      </Paragraph>
      <Paragraph>
        Med Portally Commission betalar du ett förmedlingsarvode om 6% av en
        genomsnittlig årshyra när du tecknat hyresavtal med en ny hyresgäst.
        Inga fasta kostnader.
      </Paragraph>
      <SubTitle>Fullständiga villkor</SubTitle>
      <Paragraph>
        Dessa villkor gäller för dig som valt Portally Commission, nedan kallad
        Användaren, för fortsatt tillgång till Portallys tjänster. Portally är
        kostnadsfritt att använda enligt gällande användarvillkor. Arvode utgår
        enbart då hyresavtal tecknats med en hyresgäst där kontakt förmedlats
        via Portally.
      </Paragraph>
      <Paragraph>
        Kontakt med en ny hyresgäst anses ha förmedlats via Portally om:
        <ul>
          <li>En lokalförfrågan mottagits i Användarens panel,</li>
          <li>Kontakt initierats via direktmeddelanden, eller</li>
          <li>Kontakt initierats via en annonserad lokal</li>
        </ul>
      </Paragraph>
      <Paragraph>
        En förmedling är arvodesgrundande om Användaren ej kan påvisa att
        förfrågan mottagits dessförinnan. Det åligger Användaren att meddela
        Portally när nya hyresavtal tecknats. Samtliga transaktioner
        kontrolleras både hos de medlemmar som söker och de som hyr ut lokaler.
      </Paragraph>
      <SubTitle>Arvode</SubTitle>
      <Paragraph>
        Arvode utgår med 6% av en genomsnittlig årshyra exkl. eventuella
        rabatter samt hyrestillägg som fastighetsskatt och värme/kyla per avtal
        som Användaren tecknar med hyresgäst eller annan avseende nyttjanderätt
        till lokal.
      </Paragraph>
      <Paragraph>
        Minimiarvode per genomförd förmedling är det lägsta beloppet av 10 000
        SEK eller 10% av den genomsnittliga årshyran.
      </Paragraph>
      <SubTitle>Särskilda villkor korttidsuthyrning</SubTitle>
      <Paragraph>
        I det fall en förmedling genomförts och avtal tecknats för en period
        kortare än 12 månader erbjuds Användaren att betala arvode enligt ovan
        upplägg, alternativt löpande under den period hyresgästen förlänger
        hyresavtalet (dock längst under 12 månader).
      </Paragraph>
      <Paragraph>
        Vid löpande betalning utgår arvode om 12% av månadshyran. Fakturering
        sker kvartalsvis i förskott.
      </Paragraph>
      <SubTitle>Betalningsvillkor</SubTitle>
      <Paragraph>
        Fakturering av arvodet sker efter det att Användaren tecknat hyresavtal
        med hyresgästen. Betalningsvillkor är 30 dagar netto. Mervärdesskatt
        tillkommer på samtliga belopp enligt dessa villkor.
      </Paragraph>
      <SubTitle>Övrigt</SubTitle>
      <Paragraph>
        Dessa villkor gäller tills vidare. Genom att godkänna villkoren
        accepteras dessa för samtliga Användare tillhörande samma organisation.
        I det fall Portally uppdaterar villkoren kommer nya villkor träda i
        kraft först när de godkänts av Användaren.
      </Paragraph>
    </>
  );

  if (withCollapse) {
    return (
      <div>
        <Collapse expandIconPosition={'right'} ghost>
          <Panel
            key={'0'}
            header={<Title>Portally Commission - 6% förmedlingsarvode</Title>}
          >
            {Text}
          </Panel>
        </Collapse>
      </div>
    );
  }
  return (
    <>
      {/*
<Title>Portally Commission - 6% förmedlingsarvode</Title>
      <Paragraph>
        För dig som vill använda Portally utan fast kostnad och endast betala
        när du gör en ny uthyrning.
      </Paragraph>
      <Paragraph>
        Med Portally Commission betalar du ett förmedlingsarvode om 6% av en
        genomsnittlig årshyra när du tecknat hyresavtal med en ny hyresgäst.
        Inga fasta kostnader.
      </Paragraph><SubTitle>Fullständiga villkor</SubTitle>
       */}

      <Paragraph>
        Dessa villkor gäller för dig som valt Portally Commission, nedan kallad
        Användaren, för fortsatt tillgång till Portallys tjänster. Portally är
        kostnadsfritt att använda enligt gällande användarvillkor. Arvode utgår
        enbart då hyresavtal tecknats med en hyresgäst där kontakt förmedlats
        via Portally.
      </Paragraph>
      <Paragraph>
        Kontakt med en ny hyresgäst anses ha förmedlats via Portally om:
        <ul>
          <li>En lokalförfrågan mottagits i Användarens panel,</li>
          <li>Kontakt initierats via direktmeddelanden, eller</li>
          <li>Kontakt initierats via en annonserad lokal</li>
        </ul>
      </Paragraph>
      <Paragraph>
        En förmedling är arvodesgrundande om Användaren ej kan påvisa att
        förfrågan mottagits dessförinnan. Det åligger Användaren att meddela
        Portally när nya hyresavtal tecknats. Samtliga transaktioner
        kontrolleras både hos de medlemmar som söker och de som hyr ut lokaler.
      </Paragraph>
      <SubTitle>Arvode</SubTitle>
      <Paragraph>
        Arvode utgår med 6% av en genomsnittlig årshyra exkl. eventuella
        rabatter samt hyrestillägg som fastighetsskatt och värme/kyla per avtal
        som Användaren tecknar med hyresgäst eller annan avseende nyttjanderätt
        till lokal.
      </Paragraph>
      <Paragraph>
        Minimiarvode per genomförd förmedling är det lägsta beloppet av 10 000
        SEK eller 10% av den genomsnittliga årshyran.
      </Paragraph>
      <SubTitle>Särskilda villkor korttidsuthyrning</SubTitle>
      <Paragraph>
        I det fall en förmedling genomförts och avtal tecknats för en period
        kortare än 12 månader erbjuds Användaren att betala arvode enligt ovan
        upplägg, alternativt löpande under den period hyresgästen förlänger
        hyresavtalet (dock längst under 12 månader).
      </Paragraph>
      <Paragraph>
        Vid löpande betalning utgår arvode om 12% av månadshyran. Fakturering
        sker kvartalsvis i förskott.
      </Paragraph>
      <SubTitle>Betalningsvillkor</SubTitle>
      <Paragraph>
        Fakturering av arvodet sker efter det att Användaren tecknat hyresavtal
        med hyresgästen. Betalningsvillkor är 30 dagar netto. Mervärdesskatt
        tillkommer på samtliga belopp enligt dessa villkor.
      </Paragraph>
      <SubTitle>Övrigt</SubTitle>
      <Paragraph>
        Dessa villkor gäller tills vidare. Genom att godkänna villkoren
        accepteras dessa för samtliga Användare tillhörande samma organisation.
        I det fall Portally uppdaterar villkoren kommer nya villkor träda i
        kraft först när de godkänts av Användaren.
      </Paragraph>
    </>
  );
}

export default Commission;
