import { List } from 'antd';
import { ListItemProps } from 'antd/es/list';
import React from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';
import theme from '@/main/theme';

const ListItem = styled(List.Item)<{
  $unread?: boolean;
  $active?: boolean;
}>`
  border-radius: ${theme.dimensions.borderRadius};

  && {
    background-color: ${p =>
      p.$unread ? '#edf2fa' : 'rgba(245, 245, 245, 0)'};
    margin: ${p => (p.$unread ? '1px 0' : 0)};
    :hover {
      background-color: ${theme.colors.listItemHover};
    }
    ${p =>
      p.$active &&
      `
      background-color: #ebebeb;
      box-shadow: 2px 0 0 ${p.theme.colors.primaryPurple} inset, 0 -1px 0 #f3f6f8 inset;
    `};

    cursor: pointer;

    .ant-list-item-meta {
      border-radius: ${theme.dimensions.borderRadius};
    }
    .ant-list-item-meta-title {
      margin-bottom: 8px;
    }
  }
`;

const BorderedContainer = styled(Box)<{ $active: boolean }>`
  .ant-list-item-meta {
    margin-bottom: 0;
  }
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  border: 1px solid
    ${({ $active, theme }) =>
      $active ? theme.colors.primaryPurple : theme.colors.inputBorder};
`;

interface Props extends ListItemProps {
  bordered?: boolean;
  $unread?: boolean;
  $active?: boolean;
}

function ClickableListItem({
  bordered,
  $unread,
  $active,
  ...rest
}: Props): JSX.Element {
  if (bordered) {
    return (
      <BorderedContainer $active={$active} mb={0.5}>
        <ListItem {...rest} $unread={$unread} />
      </BorderedContainer>
    );
  }
  return <ListItem {...rest} $active={$active} $unread={$unread} />;
}
ClickableListItem.Meta = ListItem.Meta;

export default ClickableListItem;
