import { Select, SelectProps } from 'antd';
import React from 'react';

import Message from '@/components/Message';
import { useGetUsersByClientQuery } from '@/generated/graphql';

const { Option } = Select;

interface Props extends SelectProps {
  clientId: string;
  filterUsers?: string[];
}
function SelectContactPerson({
  clientId,
  filterUsers = [],
  allowClear = true,
  ...props
}: Props): JSX.Element {
  const { data, loading, error } = useGetUsersByClientQuery({
    variables: { clientId },
  });

  if (error) {
    return <Message error={error} />;
  }
  if (loading) {
    return null;
  }
  const { users } = data;

  return (
    <Select allowClear={allowClear} showSearch={false} showArrow {...props}>
      {users?.users
        ?.filter(user => !filterUsers.includes(user.id))
        .map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
    </Select>
  );
}

export default SelectContactPerson;
