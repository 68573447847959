import { GlobalOutlined, MailOutlined, ReadOutlined } from '@ant-design/icons';
import { ContactSupport } from '@styled-icons/material-rounded';
import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LocaleChanger from '@/components/LocaleChanger';
import Tour from '@/components/Tour';
import useUser from '@/hooks/useUser';

import { DropdownNav } from './index.styled';
export default function Support(): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const isLandlord = user?.activeClient.type === 'landlord';
  const [localeChangerVisible, setLocaleChangerVisible] = useState(false);
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const handleMenuClick = ({ key }): void => {
    if (key === 'localeChanger') {
      !localeChangerVisible && setLocaleChangerVisible(true);
    }
    if (key === 'tutorial') {
      !tutorialVisible && setTutorialVisible(true);
    }
  };

  const menu = [
    {
      key: 'localeChanger',
      icon: <GlobalOutlined style={{ fontSize: '12px' }} />,
      component: (
        <LocaleChanger
          visible={localeChangerVisible}
          setVisible={setLocaleChangerVisible}
        />
      ),
    },
    {
      key: 'tutorial',
      icon: <ReadOutlined />,
      component: (
        <Tour
          visible={tutorialVisible}
          setVisible={setTutorialVisible}
          name={isLandlord ? 'landlord' : 'tenant'}
          key="landlord2"
        />
      ),
    },
    {
      key: 'contact',
      icon: <MailOutlined />,
      component: (
        <a href={'mailto:support@portally.com'} style={{ color: 'inherit' }}>
          {t('Contact us presents')}
        </a>
      ),
    },
  ];

  return (
    <Dropdown
      overlay={
        <Menu onClick={e => handleMenuClick(e)}>
          {menu.map(item => (
            <Menu.Item key={item.key} icon={item.icon && item.icon}>
              {item.component}
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={['click']}
      placement="topLeft"
      arrow
      align={{
        offset: [20, 11],
      }}
    >
      <DropdownNav as="span" className="dropdown tour tour-support">
        <ContactSupport />
        <span className="text">{t('Help')}</span>
      </DropdownNav>
    </Dropdown>
  );
}
