import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import StaticMap from '@/components/Map/StaticMap';
import { RegionProps } from '@/components/SearchRequestFilter/shared';

const ReloadMap = styled.div`
  padding: 4px 4px 4px 8px;
  background-color: ${p => p.theme.colors.white};
  position: absolute;
  left: 8px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  flex-direction: row;
  width: 236px;
  top: 8px;
  z-index: 2;
  font-size: 12px;
`;

function Map({ regions }: RegionProps): JSX.Element {
  const { t } = useTranslation();
  const [mapKey, setMapKey] = useState('map');
  return (
    <>
      <div style={{ width: 0, position: 'relative' }}>
        <ReloadMap>
          <Typography.Text type={'secondary'} style={{ fontSize: '12px' }}>
            {t('mapNotLoading')}{' '}
            <Button
              type={'link'}
              onClick={() => setMapKey(`${mapKey}+`)}
              style={{ paddingLeft: 0, fontSize: '12px' }}
            >
              {t('Reload map')}
            </Button>
          </Typography.Text>
        </ReloadMap>
      </div>
      <StaticMap
        key={mapKey}
        shouldUpdateCenter={false}
        mapTypeControl={false}
        polygons={[]}
        regionPolygons={regions.flatMap(elem => elem.geometry)}
        allowReload={false}
        height={'100%'}
        style={{
          width: '100%',
          borderBottomRightRadius: '6px',
        }}
        zoom={4.75}
        streetView={false}
      />
    </>
  );
}

export default Map;
