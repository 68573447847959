import { Form, InputNumber, InputNumberProps, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';
import theme from '@/main/theme';
import { formatNumber } from '@/utils';
const { Title } = Typography;

interface Props {
  name?: string;
  title: string;
  value: number;
  props: InputNumberProps<number>;
  editing?: boolean;
  suffix?: string;
  icon: React.ReactNode;
}

export const Container = styled(Box)`
  font-size: 16px;
  .anticon {
    color: ${theme.colors.typographySecondary};
  }
`;

function EditableNumberField({
  name,
  title,
  editing,
  value,
  props,
  suffix = '',
  icon,
}: Props): JSX.Element {
  if (!value && !editing) {
    return null;
  }
  return (
    <Box maxW={400}>
      {editing && (
        <div>
          <Title level={5}>{title}</Title>
        </div>
      )}
      {editing ? (
        <Form.Item name={name} initialValue={value}>
          <InputNumber {...props} min={0} />
        </Form.Item>
      ) : (
        <Container flex horizontalSpacing={1} alignItems={'center'}>
          {icon}
          <Box>
            {formatNumber(value)} {suffix}
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default EditableNumberField;
