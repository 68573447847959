import { Form, Input, Segmented, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddressField, {
  addressValidationRules,
} from '@/components/AddressField';
import Box from '@/components/Box';
import SelectContactPerson from '@/components/SelectContactPerson';
import SelectUsageCategories from '@/components/SelectUsageCategories';
import { GetLeaseAgreementEditQuery } from '@/generated/graphql';
import useUser from '@/hooks/useUser';
import SelectProject from '@/views/LeaseAgreement/Form/SelectProject';

const { Option } = Select;

interface Props {
  form: FormInstance;
  values: GetLeaseAgreementEditQuery['leaseAgreement'] | Record<string, never>;
}

export default function BasicData({ form, values }: Props): JSX.Element {
  const user = useUser();
  const isAdmin = user?.role === 'admin';
  const [sizeType, setSizeType] = useState<'surface' | 'range'>(
    values.sizeSpan ? 'range' : 'surface',
  );
  const changeSizeType = (sizeType: 'surface' | 'range'): void => {
    if (sizeType === 'surface') {
      form.setFieldsValue({
        'sizeSpan.to': undefined,
        'sizeSpan.from': undefined,
        sizeSpan: null,
      });
    } else {
      form.setFieldsValue({
        size: undefined,
      });
    }
    setSizeType(sizeType);
  };
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        label={t('address')}
        initialValue={values['address']}
        rules={addressValidationRules(t)}
        validateFirst
        name={'address'}
      >
        <AddressField />
      </Form.Item>
      <Form.Item
        label={t('usageCategory')}
        initialValue={values['usageCategory'] || undefined}
        name={'usageCategory'}
        rules={[
          {
            required: true,
            message: t('usageCategoryRequired'),
          },
        ]}
      >
        <SelectUsageCategories
          allowClear
          placeholder={t('Choose')}
          mode={'multiple'}
          showSearch={false}
          showArrow
        />
      </Form.Item>
      <Form.Item label={t('size')} required>
        <Segmented
          options={['surface', 'range'].map(value => ({
            label: t(value),
            value,
          }))}
          value={sizeType}
          onChange={value => changeSizeType(value as 'surface' | 'range')}
        />
      </Form.Item>

      {sizeType === 'surface' && (
        <Box maxW={250}>
          <Form.Item
            initialValue={values['size'] || undefined}
            name={'size'}
            rules={[
              {
                required: true,
                message: t('sizeRequired'),
              },
            ]}
            normalize={value => (value ? parseInt(value) : null)}
          >
            <Input type="number" suffix="m²" />
          </Form.Item>
          <Form.Item
            name={'sizeSpan'}
            initialValue={null}
            style={{ display: 'none' }}
          />
        </Box>
      )}
      {sizeType === 'range' && (
        <>
          <Form.Item
            name={'size'}
            initialValue={null}
            style={{ display: 'none' }}
          >
            <Input type={'number'} />
          </Form.Item>
          <Box maxW={350} horizontalSpacing={1} flex>
            <Form.Item
              initialValue={values.sizeSpan?.from || undefined}
              name={['sizeSpan', 'from']}
              wrapperCol={{
                lg: {
                  span: 24,
                },
              }}
              rules={[
                {
                  required: true,
                  message: t('fromRequired'),
                },
              ]}
              normalize={value => (value ? parseInt(value) : null)}
            >
              <Input type="number" suffix="m²" placeholder={t('From')} />
            </Form.Item>
            <Form.Item
              initialValue={values.sizeSpan?.to || undefined}
              name={['sizeSpan', 'to']}
              wrapperCol={{
                lg: {
                  span: 24,
                },
              }}
              rules={[
                {
                  required: true,
                  message: t('toRequired'),
                },
              ]}
              normalize={value => (value ? parseInt(value) : null)}
            >
              <Input type="number" suffix="m²" placeholder={t('To')} />
            </Form.Item>
          </Box>
        </>
      )}
      <Box maxW={250}>
        <Form.Item
          label={t('rent')}
          initialValue={values?.fee?.amount || undefined}
          name={['fee', 'amount']}
          normalize={value => parseInt(value)}
        >
          <Input
            type={'number'}
            addonAfter={
              <Form.Item
                noStyle
                initialValue={values?.fee?.feeType || 'perSqm'}
                name={['fee', 'feeType']}
              >
                <Select defaultValue="perSqm">
                  <Option value="perSqm">{t('perSqm')}</Option>
                  <Option value="monthly">{t('monthly')}</Option>
                </Select>
              </Form.Item>
            }
          />
        </Form.Item>
      </Box>
      <Form.Item
        label={t('Access')}
        initialValue={values['access'] || undefined}
        name={'access'}
      >
        <Select allowClear>
          <Option value="immediately">{t('immediately')}</Option>
          <Option value="within_six_months">{t('within_six_months')}</Option>
          <Option value="according_to_agreement">
            {t('according_to_agreement')}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={t('responsible')}
        initialValue={
          values['landlordContactPersonIds'] || (!isAdmin && user.id) || []
        }
        name={'landlordContactPersonIds'}
      >
        <SelectContactPerson
          clientId={user?.activeClientId}
          mode={'multiple'}
        />
      </Form.Item>

      <SelectProject
        clientId={values['landlordId'] ?? user.activeClientId}
        projectId={values['projectId']}
      />
    </>
  );
}
