import { Drawer, Modal, Pagination } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

import Box from '@/components/Box';
import CardError from '@/components/Errors/CardError';
import Spinner from '@/components/Spinner';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

const StyledDocument = styled.div`
  canvas {
    max-width: 100%;
    width: 100% !important;
    height: auto !important;
  }
`;

interface Props {
  pdf: {
    url: string;
    name: string;
  } | null;
  onCancel: () => void;
  placement?: 'right' | 'bottom' | 'left' | 'top';
}

function PdfPreview({ pdf, onCancel, placement }: Props): JSX.Element {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { isMobile } = useWidthAndHeight();

  return (
    <Drawer
      open={!!pdf}
      placement={placement ? placement : isMobile ? 'bottom' : 'right'}
      title={
        pdf?.name
          ? pdf.name.endsWith('.pdf')
            ? pdf.name.slice(0, -4)
            : pdf.name
          : ''
      }
      bodyStyle={{ padding: 0 }}
      closable
      onClose={() => {
        onCancel();
        setPage(1);
        setLoading(true);
      }}
      size={'large'}
      height={'100%'}
      destroyOnClose
      push={false}
      className={
        'prevent-active-request-drawer-close prevent-request-drawer-close'
      }
      maskClassName={
        'prevent-active-request-drawer-close prevent-request-drawer-close'
      }
      footer={
        <>
          <Box
            pt={1}
            flex
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={1}
            flexWrap={'wrap'}
          >
            <a href={pdf?.url} target="_blank" rel="noopener noreferrer">
              {t('Open in new tab')}
            </a>
            {!loading && numPages > 1 && (
              <Pagination
                current={page}
                total={numPages}
                pageSize={1}
                onChange={page => setPage(page)}
              />
            )}
          </Box>
        </>
      }
    >
      <StyledDocument>
        {pdf && (
          <Document
            loading={<Spinner minHeight={'300px'} />}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
              setLoading(false);
            }}
            file={pdf.url}
            error={<CardError />}
            width={'100%'}
          >
            <Page pageNumber={page} />
          </Document>
        )}
      </StyledDocument>
    </Drawer>
  );
}

export default PdfPreview;
