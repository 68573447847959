import { Button, Drawer } from 'antd';
import React, { useEffect, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';

import Box from '@/components/Box';
import { SearchRequestCardValuesFragment } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

import View from './View';

interface Props {
  selectedSearchRequest: SearchRequestCardValuesFragment | undefined;
  onCancel: () => void;
  placement?: 'left' | 'right';
  onStepChange?: () => any;
}

function DetailedView({
  selectedSearchRequest,
  onCancel,
  placement = 'left',
  onStepChange,
}: Props): JSX.Element {
  const ref = useRef();
  const { isMobile } = useWidthAndHeight();

  useEffect(() => {
    if (selectedSearchRequest) {
      const drawers = document.getElementsByClassName('ant-drawer-body');
      for (let i = 0; i < drawers.length; i++) {
        drawers[i].scrollTop = 0;
      }
    }
  }, [selectedSearchRequest]);

  useOnClickOutside(ref, e => {
    if (
      !e.composedPath().some(e => {
        const htmlDiv = e as HTMLDivElement;
        if ('className' in htmlDiv) {
          if (
            typeof htmlDiv.className?.includes === 'function' &&
            (htmlDiv.className.includes(
              'prevent-active-request-drawer-close',
            ) ||
              htmlDiv.className.includes('ant-image-preview'))
          ) {
            return true;
          }
        }
      })
    ) {
      e.stopPropagation();
      onCancel();
    }
  });

  return (
    <Drawer
      size={'large'}
      placement={isMobile ? 'bottom' : placement}
      height={'100%'}
      closable={!isMobile}
      width={'650px'}
      open={!!selectedSearchRequest}
      mask={false}
      push={false}
      maskClosable={true}
      onClose={() => onCancel()}
      footer={
        isMobile ? (
          <Box flex justifyContent="flex-end">
            <Button onClick={() => onCancel()}>Stäng</Button>
          </Box>
        ) : null
      }
    >
      <Box
        w={'100%'}
        ref={ref}
        className={'prevent-active-request-drawer-close'}
      >
        <View
          selectedSearchRequest={selectedSearchRequest}
          refetch={onStepChange}
          onCancel={onCancel}
        />
      </Box>
    </Drawer>
  );
}

export default DetailedView;
