import {
  Marker as GMMarker,
  MarkerProps as BaseMarkerProps,
} from '@react-google-maps/api';
import React from 'react';

interface MarkerProps extends BaseMarkerProps {
  icon?: google.maps.Icon | google.maps.Symbol | string;
  label?: google.maps.MarkerLabel | string;
  id: string;
  onMarkerClick?: (props: { id: string; marker?: google.maps.Marker }) => void;
  onHover?: (id: string) => void;
  type: 'single' | 'cluster' | 'commerce';
}

interface State {
  marker?: google.maps.Marker;
}

class Marker extends React.Component<MarkerProps> {
  state: Readonly<State> = {
    marker: undefined,
  };

  shouldComponentUpdate(nextProps): boolean {
    const { position, icon } = this.props;
    // Without this hack, the markers re-render all the time

    if (typeof icon !== 'string' && 'url' in icon) {
      if (typeof nextProps.icon !== 'string' && 'url' in nextProps.icon) {
        return (
          position.lat !== nextProps.position.lat ||
          position.lng !== nextProps.position.lng ||
          icon.url !== nextProps.icon.url
        );
      }
    } else {
      return (
        position.lat !== nextProps.position.lat ||
        position.lng !== nextProps.position.lng
      );
    }
  }
  render(): JSX.Element {
    return (
      <GMMarker
        onLoad={marker => {
          this.setState({
            marker: marker,
          });
        }}
        onMouseOver={event => {
          event.stop();
          const { id } = this.props;
          if (this.props.onHover) {
            this.props.onHover(id);
          }
        }}
        onMouseOut={event => {
          event.stop();
          if (this.props.onHover) {
            this.props.onHover(undefined);
          }
        }}
        onClick={event => {
          event.stop();

          const { id, type } = this.props;
          const { marker } = this.state;
          if (id && marker && type === 'cluster') {
            // Zoom on click
            const map: google.maps.Map = marker.getMap() as google.maps.Map;
            if (map) {
              const currentZoom = map.getZoom();
              map.setZoom(map.getZoom() + (currentZoom < 12 ? 2 : 1));
              map.setCenter(marker.getPosition());
            }
          }
          if (id && marker && type === 'commerce') {
            // Zoom on click
            const map: google.maps.Map = marker.getMap() as google.maps.Map;
            if (map) {
              const currentZoom = map.getZoom();
              const zoom = currentZoom < 12 ? 2 : currentZoom < 15 ? 1 : 0;
              map.setZoom(map.getZoom() + zoom);
              map.setCenter(marker.getPosition());
            }
          }

          if (this.props.onMarkerClick) {
            this.props.onMarkerClick({
              id: this.props.id,
              marker: this.state.marker,
            });
          }
        }}
        {...this.props}
      />
    );
  }
}

export default Marker;
