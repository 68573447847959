import { AnyAction } from 'redux';

export const UPDATE_CLIENT_SEARCH_FILTER = 'admin/UPDATE_CLIENT_SEARCH_FILTER';
export const UPDATE_SORT_FILTER = 'admin/UPDATE_SORT_FILTER';

export const updateSearchClientsFilter = (name, value): AnyAction => ({
  type: UPDATE_CLIENT_SEARCH_FILTER,
  name,
  value,
});

export const updateSortFilter = (order): AnyAction => ({
  type: UPDATE_SORT_FILTER,
  order,
});
