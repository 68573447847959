import { Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

const ActiveSearchRequests = React.lazy(() => import('.'));

const MyActiveSearchRequestsRoutes: Route<BasicRouteProps>[] = [
  {
    path: '/steps',
    id: 'Active search requests',
    element: <ActiveSearchRequests />,
    meta: {
      whiteBackground: true,
      noPadding: false,
      noPaddingPhone: true,
      fixedSider: true,
    },
  },
];

export const toActiveSearchRequests = (): string => '/steps';

export default MyActiveSearchRequestsRoutes;
