import { Radio, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import DeletePresetAnswer from '@/components/SearchRequest/shared/DeclineSearchRequestButton/PresetAnswer/DeletePresetAnswer';
import { useGetMyPresetAnswersQuery } from '@/generated/graphql';

function PresetAnswers(): JSX.Element {
  const { t } = useTranslation();

  const { data, previousData } = useGetMyPresetAnswersQuery();
  //Dela upp

  const myPresetAnswers = (data ?? previousData)?.myPresetAnswers;

  return (
    <Box
      maxH={280}
      w={'100%'}
      py={1}
      pl={2}
      oY={'auto'}
      flex
      flexDirection={'column'}
      verticalSpacing={1}
    >
      {myPresetAnswers?.length > 0 && (
        <Typography.Text type={'secondary'} style={{ fontSize: '12px' }}>
          {t('My templates')}
        </Typography.Text>
      )}
      {myPresetAnswers?.map(({ name, message, id }) => (
        <Radio value={message} key={id} style={{ maxWidth: 500 }}>
          <Box flex flexDirection={'column'}>
            <Box flex horizontalSpacing={1}>
              <div>{name}</div>
              <DeletePresetAnswer id={id} />
            </Box>
            <Typography.Text type={'secondary'} style={{ maxWidth: '400px' }}>
              {message}
            </Typography.Text>
          </Box>
        </Radio>
      ))}

      <Typography.Text type={'secondary'} style={{ fontSize: '12px' }}>
        {t('Templates')}
      </Typography.Text>
      <Radio value={t('Option A')}>
        <Box flex flexDirection={'column'}>
          {t('Option A title')}
          <Typography.Text type={'secondary'}>{t('Option A')}</Typography.Text>
        </Box>
      </Radio>
      <Radio value={t('Option B')}>
        <Box flex flexDirection={'column'}>
          {t('Option B title')}
          <Typography.Text type={'secondary'}>{t('Option B')}</Typography.Text>
        </Box>
      </Radio>
      <Radio value={t('Option C')}>
        <Box flex flexDirection={'column'}>
          {t('Option C title')}
          <Typography.Text type={'secondary'}>{t('Option C')}</Typography.Text>
        </Box>
      </Radio>
    </Box>
  );
}

export default PresetAnswers;
