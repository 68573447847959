import { SearchProfileInput } from '@/generated/graphql';
import { MapObject } from '@/utils/misc';

export interface Agreement {
  id: string;
  name: string;
  status?: string | [string];
  text?: string;
  location?: {
    coordinates: [number, number];
  };
  keyMoneyDesired?: number;
  extra?: number;
  count?: null;
}

export interface Cluster {
  location: {
    coordinates: [number, number];
  };
  id: string;
  count: number;
}

export interface PaginationType {
  page: number;
  rowsPerPage: number;
}

export type SearchValue = Omit<SearchProfileInput, 'regionIds'> & {
  regionIds?: { label: string; key: string }[];
  id?: string;
  tooManyHits?: boolean;
};

export const isLeaseAgreement = (
  tbd: Agreement | Cluster | MapObject,
): tbd is Agreement =>
  typeof tbd === 'object' &&
  (!{}.hasOwnProperty.call(tbd, 'count') || tbd.count === null);

export const isCluster = (tbd: MapObject): tbd is Cluster =>
  typeof tbd === 'object' &&
  ({}.hasOwnProperty.call(tbd, 'count') || tbd.count !== null);

export type Unpacked<T> = T extends (infer U)[] ? U : T;
