import { Cart } from '@styled-icons/boxicons-regular/Cart';
import { Train } from '@styled-icons/boxicons-regular/Train';
import { Bus } from '@styled-icons/boxicons-solid/Bus';
import { Parking } from '@styled-icons/fa-solid/Parking';
import { DirectionsSubway } from '@styled-icons/material/DirectionsSubway';
import { FitnessCenter } from '@styled-icons/material/FitnessCenter';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GetLeaseAgreementViewQuery } from '@/generated/graphql';
import theme from '@/main/theme';
import media from '@/utils/media';

import { Text } from '../shared';

const iconStyle = {
  fontSize: '16px',
  width: '25px',
  height: '25px',
  marginRight: '8px',
  color: 'rgba(0,0,0,0.6)',
};
export const services = [
  {
    name: 'train_station',
    icon: <Train style={iconStyle} />,
  },
  {
    name: 'subway_station',
    icon: <DirectionsSubway style={iconStyle} />,
  },
  { name: 'gym', icon: <FitnessCenter style={iconStyle} /> },

  { name: 'parking', icon: <Parking style={iconStyle} /> },

  {
    name: 'supermarket',
    icon: <Cart style={iconStyle} />,
  },
  { name: 'bus_station', icon: <Bus style={iconStyle} /> },
];

export const icon = (typeOfPlace: string): JSX.Element => {
  const found = services.filter(obj => obj.name === typeOfPlace);

  return found[0].icon;
};
interface Props {
  nearbyServices: GetLeaseAgreementViewQuery['leaseAgreement']['nearbyServices'];
}
export default function NearbyServices({ nearbyServices }: Props): JSX.Element {
  const { t } = useTranslation();

  const formatDistance = (distance: number): string => {
    if (distance >= 1000) {
      return ` ${(Math.floor(distance / 100) * 100) / 1000} km`;
    }
    if (distance < 100) {
      return ` < 100 m`;
    }
    return ` ${Math.floor(distance / 10) * 10}m`;
  };
  const nearby = Object.keys(nearbyServices).filter(
    key =>
      nearbyServices[key] === '__typename' ||
      !!nearbyServices[key].distance ||
      !!nearbyServices[key].name,
  );
  if (Object.keys(nearby).length > 0) {
    return (
      <div className="max-w-full">
        <div className={'text-zinc-500 font-light text-base mt-8'}>
          {t('Nearby services')}
        </div>
        <div className="flex flex-wrap">
          {nearby.map(key => (
            <div
              className="flex justify-start items-start p-2 pl-0 w-[33%] tablet:w-[50%]"
              key={key + nearbyServices[key].name}
            >
              <Tooltip
                title={
                  <Text
                    style={{ color: theme.colors.white }}
                  >{`${nearbyServices[key].name}`}</Text>
                }
              >
                <Text>
                  <div className="flex items-start">
                    <div>{icon(key)}</div>
                    <div className="flex flex-wrap gap-1">
                      <div>{t(key)}</div>
                      <div> {formatDistance(nearbyServices[key].distance)}</div>
                    </div>
                  </div>
                </Text>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
}
