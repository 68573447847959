import { Button, Checkbox, Form, FormInstance, Input, Typography } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import {
  GetLeaseAgreementEditQuery,
  useGenerateNearbyServicesLazyQuery,
} from '@/generated/graphql';

import { services } from '../View/NearbyServices';

const { Text } = Typography;
interface Props {
  form: FormInstance;
  values: GetLeaseAgreementEditQuery['leaseAgreement'] | Record<string, never>;
}
export const placesArray = [
  'train_station',
  'subway_station',
  'parking',
  'supermarket',
  'gym',
  'bus_station',
];
export default function NearbyServicesForm({
  values,
  form,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const placesWithValues = (): string[] => {
    if (!values?.nearbyServices) {
      return [];
    }
    return Object.keys(values?.nearbyServices).filter(
      key =>
        !!values?.nearbyServices[key]?.distance ||
        !!values?.nearbyServices[key]?.name,
    );
  };
  const [places, setPlaces] = useState<CheckboxValueType[] | string[]>(
    placesWithValues(),
  );

  const [getNearbyServices, { loading }] = useGenerateNearbyServicesLazyQuery(
    {},
  );
  const resetFields = (): void => {
    form.resetFields([
      ...placesArray.map(place => ['nearbyServices', place, 'name']),
      ...placesArray.map(place => ['nearbyServices', place, 'distance']),
    ]);
  };

  const fillForm = async (): Promise<void> => {
    const address = form.getFieldValue('address');

    resetFields();
    const res = await getNearbyServices({
      variables: {
        address,
      },
    });

    Object.keys(res.data.generateNearbyServices).forEach(key => {
      if (
        key === '__typename' ||
        res.data.generateNearbyServices[key] === null
      ) {
        return;
      }

      form.setFieldValue(
        ['nearbyServices', key, 'name'],
        res.data.generateNearbyServices[key].name,
      );
      form.setFieldValue(
        ['nearbyServices', key, 'distance'],
        res.data.generateNearbyServices[key].distance,
      );
      setPlaces(prev => [...prev, key]);
    });
  };

  return (
    <Form.Item>
      <Box>
        <Text>{t('Nearby services')}</Text>
        <br />
        <Button
          style={{ paddingLeft: '0px' }}
          loading={loading}
          onClick={() => fillForm()}
          type={'link'}
        >
          {t('Generate')}
        </Button>

        <Form.Item
          label={<Text type={'secondary'}>{t('Pick places to include')}</Text>}
        >
          <Checkbox.Group
            value={places}
            defaultValue={placesWithValues()}
            onChange={values => setPlaces(values)}
          >
            <Box flex flexDirection="column">
              {services.map(service => (
                <Box horizontalSpacing={2} key={service.name}>
                  <Checkbox
                    style={{ marginBottom: '8px' }}
                    value={service.name}
                  >
                    <>{service.icon}</>
                    {t(service.name)}
                  </Checkbox>

                  {places.includes(service.name) && (
                    <Box flex gap={1} pb={2} key={service.name}>
                      <Form.Item
                        name={['nearbyServices', service.name, 'name']}
                        initialValue={
                          values?.nearbyServices?.[service.name]?.name ??
                          undefined
                        }
                      >
                        <Input type="text" placeholder={t('description')} />
                      </Form.Item>
                      <Form.Item
                        style={{ maxWidth: '100px' }}
                        name={['nearbyServices', service.name, 'distance']}
                        normalize={v => (v ? parseInt(v) : null)}
                        initialValue={
                          values?.nearbyServices?.[service.name]?.distance ??
                          undefined
                        }
                      >
                        <Input
                          type="number"
                          suffix={'m'}
                          placeholder={t('Distance')}
                        />
                      </Form.Item>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Checkbox.Group>
        </Form.Item>
      </Box>
    </Form.Item>
  );
}
