import { Spin } from 'antd';
import React, { useCallback } from 'react';

import Box from '@/components/Box';
import MessageComponent from '@/components/Chat/Messages/MessageComponent';
import TimeStamp from '@/components/Chat/Messages/TimeStamp';
import { parseMessages } from '@/components/Chat/Messages/util';
import { useGetMessagesByConversationQuery } from '@/generated/graphql';

interface Props {
  conversationId: string;
}

function ConversationPreview({ conversationId }: Props): JSX.Element {
  const { data, loading } = useGetMessagesByConversationQuery({
    variables: {
      conversationId,
    },
  });
  const parsedMessages = useCallback(() => {
    if (data?.conversation?.messages) {
      return parseMessages({
        messages: data.conversation.messages,
        reversed: true,
      });
    }
    return [];
  }, [data?.conversation?.messages]);

  if (loading) {
    return (
      <Box flex h={300} justifyContent={'center'} alignItems={'center'}>
        <Spin />
      </Box>
    );
  }

  return (
    <div className={'space-y-3'}>
      {parsedMessages().map(
        ({ message, showDate, showTimestamp, showName, showAvatar }) => (
          <React.Fragment key={message.id}>
            {showDate && <TimeStamp date={message.created} />}
            <div className={'w-full'}>
              {message && (
                <MessageComponent
                  block
                  reactions={false}
                  showMarkAsAnswered={false}
                  showTimestamp={showTimestamp}
                  showName={showName}
                  showAvatar={showAvatar}
                  message={message}
                  conversationId={conversationId}
                />
              )}
            </div>
          </React.Fragment>
        ),
      )}
    </div>
  );
}

export default ConversationPreview;
