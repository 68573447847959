import { Divider, Steps as AntSteps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StepsProps } from '@/components/ModalForm';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

const { Step } = AntSteps;

function Steps({ step, onChange }: StepsProps): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const { t } = useTranslation();
  const steps = [
    {
      title: t('basicData'),
    },
    {
      title: t('description'),
    },

    {
      title: t('Media'),
    },
  ];
  return (
    <>
      <AntSteps
        current={step}
        size={'small'}
        onChange={onChange}
        responsive={false}
        direction={isMobile ? 'horizontal' : 'vertical'}
        style={
          isMobile
            ? {}
            : {
                maxWidth: '180px',
                borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                marginRight: '16px',
              }
        }
      >
        {steps.map(item => {
          const stepProps = isMobile
            ? {}
            : {
                title: item.title,
              };

          return <Step key={item.title} {...stepProps} />;
        })}
      </AntSteps>
      {isMobile && <Divider />}
    </>
  );
}

export default Steps;
