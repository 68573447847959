import { Link, useMatches } from '@tanstack/react-location';
import { Breadcrumb } from 'antd';
import React from 'react';

import { AdminRouteProps } from '@/layout/Admin/routes';

function Breadcrumbs(): JSX.Element | null {
  const matches = useMatches<AdminRouteProps>();
  const breadcrumbs = [];
  matches.map(match => {
    if (
      match.route.meta?.breadcrumbs &&
      !breadcrumbs.map(crumb => crumb.pathname).includes(match.pathname)
    ) {
      const { breadcrumb, parent } = match.route.meta.breadcrumbs(match.params);
      if (parent) {
        breadcrumbs.push(parent);
      }
      breadcrumbs.push({
        pathname: match.pathname,
        breadcrumb,
      });
    }
  });

  if (breadcrumbs.length < 2) {
    return null;
  }

  return (
    <Breadcrumb
      style={{
        padding: '10px 16px',
        background: '#eee',
      }}
    >
      {breadcrumbs.map(({ pathname, breadcrumb }) => (
        <Breadcrumb.Item key={pathname}>
          <Link to={pathname}>{breadcrumb}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
