import { Modal } from 'antd';
import React, { useEffect } from 'react';

import V1 from '@/components/SubscriptionRequest/V1';
import V2 from '@/components/SubscriptionRequest/V2';
import {
  GetMySubscriptionRequestQuery,
  ModalEnum,
  SubscriptionVersion,
  useGetMySubscriptionRequestQuery,
  useViewSubscriptionRequestMutation,
} from '@/generated/graphql';
import useUser from '@/hooks/useUser';

export type MySubscriptionRequest =
  GetMySubscriptionRequestQuery['mySubscriptionRequest'];

function SubscriptionRequest(): JSX.Element {
  const [view] = useViewSubscriptionRequestMutation();
  const user = useUser();

  const { data, loading } = useGetMySubscriptionRequestQuery({});

  useEffect(() => {
    const openedBy = data?.mySubscriptionRequest?.openedByUserIds;
    if (
      data?.mySubscriptionRequest &&
      (!openedBy || !openedBy.includes(user.id))
    ) {
      view({
        variables: {
          id: data.mySubscriptionRequest.id,
        },
      });
    }
  }, [data?.mySubscriptionRequest]);

  if (loading) {
    return null;
  }

  if (!data) {
    return null;
  }

  if (user.activeModal !== ModalEnum.Subscriptionrequest) {
    return null;
  }

  if (data.mySubscriptionRequest == null) {
    return null;
  }

  const read = data.mySubscriptionRequest.readByUserIds.includes(user.id);

  if (data.mySubscriptionRequest.status === 'accepted' && read) {
    return null;
  }

  const { mySubscriptionRequest } = data;

  return (
    <Modal open footer={null} closable={false} width={900}>
      {mySubscriptionRequest.version === SubscriptionVersion.V2 ? (
        <V2 mySubscriptionRequest={mySubscriptionRequest} />
      ) : (
        <V1 mySubscriptionRequest={mySubscriptionRequest} />
      )}
    </Modal>
  );
}

export default SubscriptionRequest;
