import './utils/globals.css';
import 'virtual:fonts.css';
import './utils/global-style.scss';
import './utils/polyfill';
import 'moment/dist/locale/sv';
import 'antd/dist/reset.css';

import { ApolloProvider } from '@apollo/client';
import { Outlet, ReactLocation, Router } from '@tanstack/react-location';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import svSE from 'antd/es/locale/sv_SE';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import React from 'react';
import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import i18n from '@/i18n';
import routes, { RouteProps } from '@/layout/routes';
import apolloClient from '@/main/apollo';
import createStore from '@/state/store';
import browserHistory from '@/utils/browserHistory';

import theme from './main/theme';
const store = createStore();

dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

export type AppDispatch = typeof store.dispatch;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const location = new ReactLocation<RouteProps>({
  history: browserHistory,
});
const language = i18n.language ?? localStorage.getItem('lng') ?? 'sv';

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConfigProvider
          locale={language === 'sv' ? svSE : enUS}
          theme={{
            token: {
              colorPrimary: '#885ae4',
              fontFamily: 'BR Omny',
              borderRadiusSM: 4,
              borderRadius: 6,
            },
            components: {
              Result: {
                colorInfo: '#885ae4',
              },
              Alert: {
                colorInfo: '#885ae4',
              },
              Select: {
                fontWeightStrong: 400,
              },
              Tooltip: {
                colorBgBase: 'rgba(102, 64, 189, 0.87)',
              },
              Form: {
                margin: 16,
              },
              Table: {
                fontWeightStrong: 500,
              },
              Card: {
                fontWeightStrong: 500,
              },
              List: {
                paddingContentHorizontalLG: 0,
                paddingContentHorizontalSM: 0,
              },
            },
          }}
        >
          <Router routes={routes} location={location}>
            <Outlet />
          </Router>
        </ConfigProvider>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
