import { EditOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Button, ButtonProps, Form, message, Modal } from 'antd';
import React, { useState } from 'react';

import {
  AdInput,
  AdValuesFragment,
  useEditAdMutation,
} from '@/generated/graphql';
import AdForm from '@/views/PortallyPartner/Ads/AdForm';

interface Props extends ButtonProps {
  ad: AdValuesFragment;
}

function EditAd({ ad, ...rest }: Props): JSX.Element {
  const apollo = useApolloClient();
  const [form] = Form.useForm<AdInput>();
  const [open, setOpen] = useState(false);
  const [editAd, { loading, error }] = useEditAdMutation({
    onCompleted: () => {
      form.resetFields();
      apollo.reFetchObservableQueries();
      message.success('Annons redigerad');
      setOpen(false);
    },
    onError: () => {
      message.error(error.message);
    },
  });
  return (
    <>
      <Button
        {...rest}
        icon={<EditOutlined />}
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
        <AdForm
          initialValues={ad}
          loading={loading}
          onSubmit={values => {
            editAd({
              variables: {
                input: {
                  ...values,
                },
                id: ad.id,
              },
            });
          }}
          form={form}
        />
      </Modal>
    </>
  );
}

export default EditAd;
