import { Button, message } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { AnswerSearchRequestMutationFn, StatusEnum } from '@/generated/graphql';

interface Props {
  answerSearchRequest: AnswerSearchRequestMutationFn;
  searchRequestReferenceId: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

function Buttons({
  answerSearchRequest,
  searchRequestReferenceId,
  value,
  setValue,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box flex justifyContent={'flex-end'} gap={0.5} flexWrap="wrap">
      <Button
        onClick={async () => {
          await answerSearchRequest({
            variables: {
              message: '',
              searchRequestReferenceId,
              status: StatusEnum.Declined,
            },
          });
        }}
      >
        {t('Reject without message')}
      </Button>
      <Button
        disabled={value.length === 0}
        onClick={async () => {
          if (value.length !== 0) {
            await answerSearchRequest({
              variables: {
                message: value,
                searchRequestReferenceId,
                status: StatusEnum.Declined,
              },
            });
          } else {
            message.error(t('Message is required'));
          }
          setValue('');
        }}
        type={'primary'}
      >
        {t('Reject with message')}
      </Button>
    </Box>
  );
}

export default Buttons;
