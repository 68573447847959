import { DrawerProps, Typography } from 'antd';
import React, { useState } from 'react';

import PdfPreview from '@/components/PdfPreview';
import { translateUrl } from '@/components/UploadFile/util';
import { GetLeaseAgreementViewQuery } from '@/generated/graphql';

const { Link } = Typography;

interface Props {
  attachedFiles?: GetLeaseAgreementViewQuery['leaseAgreement']['documents'];
  drawerPlacement?: DrawerProps['placement'];
}
export default function AttachedFiles({
  attachedFiles,
  drawerPlacement = 'left',
}: Props): JSX.Element {
  const [pdf, setPdf] = useState(null);

  if (!attachedFiles.length) {
    return null;
  }
  return (
    <>
      <PdfPreview
        placement={drawerPlacement}
        pdf={pdf}
        onCancel={() => setPdf(null)}
      />
      <div className="flex flex-col">
        {attachedFiles.map(file => {
          if (file.mimetype === 'application/pdf') {
            return (
              <>
                <Link
                  key={file.id}
                  style={{ fontSize: '16px' }}
                  onClick={() =>
                    setPdf({
                      url: translateUrl(file.url),
                      name: file.name,
                    })
                  }
                >
                  {file.name}
                </Link>
              </>
            );
          }
          return (
            <a
              rel="noopener noreferrer"
              style={{ fontSize: '16px' }}
              target={'_blank'}
              key={file.id}
              href={file.url}
            >
              {file.name}
            </a>
          );
        })}
      </div>
    </>
  );
}
