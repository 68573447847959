import { CloseOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';

import Box from '@/components/Box';
import ClientModal from '@/components/ClientModal';
import { DownloadList } from '@/components/Document';
import Tag from '@/components/Tags/UsageCategoryTags';
import {
  AddLeaseAgreementInput,
  LeaseAgreement,
  LeaseAgreementInput,
  SearchParameters,
} from '@/generated/graphql';
import i18n from '@/i18n';
import { formatNumber } from '@/utils';
import formatDate from '@/utils/formatDate';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

export const inputLayout = {
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 8 },
    md: { span: 6 },
    lg: { span: 4 },
  },
};

export function selectValues(
  start: number,
  range: number,
  offset: number,
  unit = '',
  prepend = '',
): { label: string; value: string }[] {
  const list: { label: string; value: string }[] = [];
  let i;
  const suffix = unit === '' ? '' : ` ${i18n.t(unit)}`;
  const prefix = prepend === '' ? '' : `${i18n.t(prepend)} `;
  for (i = start; i < range + 1; i) {
    list[i] = {
      label: prefix + i.toString() + suffix,
      value: i,
    };
    i += offset;
  }
  return list;
}

function translateObject(value, option): string | JSX.Element | null {
  let name;

  switch (value.__typename) {
    case 'Address':
      return (
        <>
          {value.street} {value.streetNumber}
          <br />
          {value.zipCode} {value.city}
        </>
      );
    case 'extentionOfTerm':
      return value.value === 'EXTRAKOSTNAD'
        ? `${value.extra} ${i18n.t('currency')}`
        : i18n.t(value.value);

    case 'Client':
      name = value.parentCompany
        ? `${value.name} (${value.parentCompany})`
        : value.name;
      if (!name) {
        return null;
      }
      return <ClientModal name={name} id={value.id} />;
    /*
      if (user.role === 'admin') {
        return <Link to={toClient(value.id)}>{i18n.t(name)}</Link>;
      } else {
        return i18n.t(name);
      }
       */
  }
  if (option === 'ebit' || option === 'ebitda' || option === 'opex') {
    if (value.goal || value.result) {
      return (
        <Row>
          <Col span={8}>
            {`${i18n.t('goal')}: `}
            {formatNumber(value.goal)}
            {' kr'}
          </Col>
          <Col span={8}>
            {`${i18n.t('result')}: `}
            {formatNumber(value.result)}
            {' kr'}
          </Col>
          <Col span={8}>
            {`${i18n.t('deviation')}: `}
            {formatNumber(value.result - value.goal)}
            {' kr'}
          </Col>
        </Row>
      );
    }
  }
  if (option === 'documents') {
    return <DownloadList values={value} />;
  }
  if (option === 'usageCategory') {
    return (
      <Box flex flexWrap={'wrap'}>
        {value.map(category => (
          <Tag usageCategory={category} key={category} size={'small'} />
        ))}
      </Box>
    );
  }

  return null;
}

export function translate(
  values: Partial<LeaseAgreement | SearchParameters>,
  option,
): string | JSX.Element | null {
  const value = values[option];

  if (!value) {
    return null;
  }

  /* <CheckboxChecked style={{ color: '#52c41a' }} size={25} /> */

  if (typeof value === 'boolean') {
    return value ? 'Yes' : <CloseOutlined />;
  }
  if (typeof value == 'object') {
    if (option !== 'size') {
      return translateObject(value, option);
    }
  }
  switch (option) {
    case 'noticeOfTermination':
      return `${i18n.t(value)} ${i18n.t('months')}`;
    case 'leaseStart':
    case 'leaseEnd':
      return formatDate(value);
    case 'rent':
      return `${formatNumber(value)} kr/år`;
    case 'turnoverRent':
      return `${value} %`;
    case 'rentPerSqmAndYear':
      return `${formatNumber(value)} kr`;
    case 'size':
      if (typeof value === 'object') {
        if (!value.max && !value.min) {
          return null;
        }
        return `${formatNumber(value.min)} - ${formatNumber(value.max)} m²`;
      }

      return `${formatNumber(value)} m²`;
    case 'ceilingHeight':
    case 'facadeWidth':
      return `${value} m`;
    default:
      return i18n.t(value);
  }
}

export const booleanData = [
  'sunnySide',
  'corner',
  'greaseSeparators',
  'blackPlate',
  'exhaustDuct',
];
//public
//lokaltyp grunddata
export const agreementData = [
  {
    name: 'basicData',
    data: ['landlord', 'tenant', 'estate' /* 'usageCategory' */],
  },
  {
    name: 'Properties',
    data: [
      'size',
      'floors',
      'ceilingHeight',
      'facadeWidth',
      'sunnySide',
      'corner',

      'numberOfSeats',
      'parkingSpots',
    ],
  },
  {
    name: 'tech',
    data: ['greaseSeparators', 'blackPlate', 'exhaustDuct'],
  },
];

export const leaseAgreementData = [
  'landlord',
  'tenant',
  'estate',
  'usageCategory',
  'size',
  'floors',
  'ceilingHeight',
  'facadeWidth',
  'sunnySide',
  'corner',
  'greaseSeparators',
  'blackPlate',
  'exhaustDuct',
  'outdoorSeating',
  'numberOfSeats',
  'parkingSpots',
];

export const basicData = ['address', 'usageCategory', 'size'];
export const formData = ['floors', 'ceilingHeight', 'facadeWidth'];
export const locationData = ['landlord', 'estate', 'commerce'];
export const functionality = [
  'sunnySide',
  'corner',
  'outdoorSeating',
  'numberOfSeats',
  'parkingSpots',
];
export const tech = ['greaseSeparators', 'blackPlate', 'exhaustDuct'];

//private

//private
//export const agreementData = ['leaseStart', 'leaseEnd', 'noticeOfTermination'];

export const brandCategoryOptions = [
  'office tenant', // todo office tenant.
  'warehouse', // lager / logistik
  'mediaAndElectronics',
  'health', // vård & hälsa
  'beauty', // hälsa & Skönhet
  'toysAndHobby',
  'restaurantAndCafe',
  'foodAndGrocery',
  'fashion', // Kläder och accessoarer
  'sportsAndOutdoor',
  'service',
  'entertainment',
  'homeDecoration',
  'municipality',
  'gymAndTraining',
  'architecturalFirm',
  'bankingAndFinance',
  'coworkingOrOfficeHotel',
  'recruitment',
  'tenantRepresentative', //hyresgästombud
];
//accessoarer -

export const brandSubCategoryOptions = {
  accessories: [
    'Smycken, Klockor & Accessoarer',
    'Skor',
    'Väskor & Lädervaror',
  ],
  cinema: ['Fristående', 'Kedja'],
  mediaAndElectronics: [
    'Spel & Fritid',
    'Bibliotek',
    'Kultur & Teknikprodukter',
    'Böcker',
    'Telefoni',
  ],
  healthAndBeauty: [
    'Apotek & receptfria läkemedel',
    'Skönhet & välmående',
    'Parfymeri',
    'Optiker',
    'Frisör & Salong',
    'Salong',
    'Spaanläggning',
  ],
  toysAndHobby: [],
  restaurants: [
    'Brunch',
    'Frukost',
    'Lunch',
    'Middag',
    'Afrikanskt',
    'Amerikanskt',
    'Asiatiskt',
    'Brittiskt',
    'Buffé',
    'Cajun',
    'Crêpes',
    'Crossover',
    'Dumplings',
    'Ekologiskt',
    'Europeiskt',
    'Fisk',
    'Fondue',
    'Franskt',
    'Fusion',
    'Grekiskt',
    'Hamburgerrestaurang',
    'Husmanskost',
    'Hälsoinriktat',
    'Indiskt',
    'Internationellt',
    'Irländskt',
    'Italienskt',
    'Jamaicanskt',
    'Japanskt',
    'Karibiskt',
    'Kebab',
    'Kinesiskt',
    'Koreanskt',
    'Kreolskt',
    'Kubanskt',
    'Kött',
    'Latinamerikanskt',
    'Libanesiskt',
    'Lunchbuffé',
    'Medelhavet',
    'Mellanöstern',
    'Mexikanskt',
    'Mongoliskt',
    'Pakistanskt',
    'Pasta',
    'Persiskt',
    'Pizza',
    'Polskt',
    'Pubmat',
    'Raw food',
    'Ryskt',
    'Sallader',
    'Skaldjur',
    'Skandinaviskt',
    'Smårätter',
    'Snabbmat',
    'Spanskt',
    'Specialkoncept',
    'Steakhouse',
    'Sushi',
    'Svenskt',
    'Sydamerikanskt',
    'Tapas',
    'Thailändskt',
    'Tjeckiskt',
    'Turkiskt',
    'Tyskt',
    'Veganskt',
    'Vegetariskt',
    'Vietnamesiskt',
    'Vilt',
    'Wok',
  ],
  foodAndGrocery: ['Hemköp', 'Stormarknad'],
  fashion: ['Barn & graviditetsmodé', 'Dammode', 'Underkläder', 'Herrmode'],
  sportsAndOutdoor: ['Fitness & Gymklubb', 'Sportmode & Utrustning'],
  giftsAndSouvenirs: [],
  vehicle: ['Bilar', 'Båtar', 'Flygplan'],
  service: [
    'Bank',
    'Tandläkare',
    'Bibliotek',
    'Vårdcentral',
    'Resebyråer',
    'Arbetsförmedling',
    'Försäkringskassa',
    'Växlingskontor',
  ],
  departmentStore: [],
  telecom: [],
  entertainment: [
    'Bowling',
    'Spelhall',
    'Underhållning',
    'Lekplats',
    'Esxcape Rooms',
    'Gokart',
  ],
};

export const economy = ['rent', 'rentPerSqmAndYear', 'turnoverRent'];

export const document = ['documents'];
export const searchRequestSize = [
  //'size',
  'floors',
  'ceilingHeight',
  'facadeWidth',
];
export const searchRequestUsage = ['usageCategory'];

const normalizeRentalValues = (fd): Partial<LeaseAgreementInput> => {
  if (fd.rentalSettings) {
    return {
      ...fd,
      rentalSettings: {
        ...fd.rentalSettings,
        period: fd.rentalSettings.period
          ? {
              min: fd.rentalSettings.period[0]?.toString(),
              max: fd.rentalSettings.period[1]?.toString(),
            }
          : undefined,
      },
    };
  }
  return fd;
};

export const treatLeaseData = (fd): AddLeaseAgreementInput => {
  const values = {
    ...fd,
    landlordId: fd?.landlordId?.key || null,
    tenantId: fd?.tenantId?.key || null,
    estateId: fd?.estateId || null,
    video: fd.videoId
      ? {
          videoId: fd.videoId,
          thumbnailId: fd.thumbnailId,
        }
      : null,
    fee: fd.fee.feeType && fd.fee.amount ? fd.fee : null,
    projectId: fd.projectId || null,
  };
  delete values['videoId'];
  delete values['thumbnailId'];

  return values;
};
