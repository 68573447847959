import { Button } from 'antd';
import styled from 'styled-components';

const orange = '#f68c18';
const orangeHover = '#ffa94b';

export const OrangeButton = styled(Button)`
  &&& {
    color: #fff;
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
    font-weight: 700;
    background-color: ${orange};
    border-color: ${orange};

    &:hover {
      background-color: ${orangeHover};
      border-color: ${orangeHover};
    }
    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
    }
  }
`;

export const StyledIconButton = styled(Button)<{ $withText?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    ${p => p.$withText && 'margin-right: 8px;'};
  }
`;
export const StyledIconWrapper = styled.div`
  > svg {
    &:hover {
      //antd hover
      color: #afa7cc;
    }
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;
