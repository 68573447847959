import { MessageOutlined, UserOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { PaperPlane } from '@styled-icons/boxicons-regular/PaperPlane';
import { useMatch } from '@tanstack/react-location';
import { Avatar, Button, Drawer, Input, message as antMessage } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledIconButton } from '@/components/Buttons';
import VerifyAccount from '@/components/User/VerifyAccount';
import {
  GetLeaseAgreementViewQuery,
  MessageType,
  useAddNewMessageMutation,
} from '@/generated/graphql';
import useUser from '@/hooks/useUser';
import { LeaseAgreementRouteProps } from '@/views/LeaseAgreement/routes';

interface Props {
  leaseAgreement: GetLeaseAgreementViewQuery['leaseAgreement'];
  isAgreementOwner: boolean;
}

export default function ContactButton({
  leaseAgreement,
  isAgreementOwner,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { searchRequestReferenceId } =
    useMatch<LeaseAgreementRouteProps>().data;
  const user = useUser();
  const apollo = useApolloClient();
  const { tenant, landlord } = leaseAgreement;
  const [message, setMessage] = useState('');

  const [addMessage, { loading }] = useAddNewMessageMutation({
    onError: () => antMessage.error(t('error')),

    onCompleted: async () => {
      setDrawerOpen(false);
      setMessage('');
      antMessage.success(t('Message sent'));
      await apollo.reFetchObservableQueries();
    },
  });

  const isLandlord = !!landlord;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const sendMessage = async (): Promise<void> => {
    await addMessage({
      variables: {
        messageType: MessageType.OnNewMessage,
        input: {
          searchRequestReferenceId,
          reciverId:
            isLandlord || !tenant
              ? leaseAgreement.landlord?.id
              : leaseAgreement.tenant?.id,
          senderId: user.activeClientId,
          leaseAgreementId: leaseAgreement.id,
          message,
        },
      },
    });
  };

  if ((!landlord && !tenant) || isAgreementOwner) {
    return null;
  }
  const companyImage = landlord?.image?.url;

  const contactForm = !isAgreementOwner && (
    <div>
      <div className="flex flex-col mt-2">
        {user?.verified ? (
          <>
            <Input.TextArea
              placeholder={t('Write a message')}
              rows={5}
              onChange={e => {
                const { target } = e;
                setMessage(target.value);
              }}
              value={message}
            />
          </>
        ) : (
          <div className="flex flex-col items-center justify-center space-y-2 h-full pt-12">
            <VerifyAccount className="w-fit" type={'primary'} size="large" />
            <div className="text-zinc-400 text-base">
              {t('verifyAccountPrompt')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
  return (
    <>
      <Button
        icon={<MessageOutlined />}
        type="primary"
        className="fixed bottom-20 right-3 z-10"
        size="large"
        onClick={() => setDrawerOpen(true)}
      >{`${t('Contact')} ${landlord?.name}`}</Button>
      <Drawer
        open={drawerOpen}
        title={`${t('Contact')} ${landlord?.name}`}
        placement="bottom"
        height={'60%'}
        bodyStyle={{
          height: 'fit-content',
        }}
        footer={
          user.verified ? (
            <div className="w-full flex justify-end">
              <StyledIconButton
                $withText
                onClick={() => sendMessage()}
                type={'primary'}
                className="mt-3"
                icon={<PaperPlane size={17} />}
                loading={loading}
                disabled={!message}
                style={{ width: 'fit-content', alignSelf: 'flex-end' }}
              >
                {t('Send')}
              </StyledIconButton>
            </div>
          ) : null
        }
        onClose={() => setDrawerOpen(false)}
      >
        <div className="flex items-start justify-between gap-2 flex-wrap mb-5">
          {leaseAgreement.landlordContactPersons.length > 0 && (
            <div className="flex flex-col mt-5">
              {leaseAgreement.landlordContactPersons.map(
                ({ id, image, name }) => (
                  <div
                    className="my-2 first:mt-0 last:mb-0 flex items-start space-x-2"
                    key={id}
                  >
                    {image?.url && (
                      <Avatar
                        size={64}
                        src={image?.url}
                        icon={<UserOutlined />}
                        shape={'square'}
                        style={{ flexShrink: 0 }}
                      />
                    )}
                    <div className="flex flex-col">
                      <div>{name}</div>
                      <div className="text-zinc-400 text-sm font-normal">
                        {t('Contact person')}
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
          )}
          {companyImage && (
            <img
              src={companyImage}
              alt={leaseAgreement.landlord.name}
              className={
                'w-16  h-16 object-contain ml-auto border border-solid border-gray-300 rounded'
              }
              loading={'lazy'}
            />
          )}
        </div>

        {contactForm}
      </Drawer>
    </>
  );
}
