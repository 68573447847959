import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import React from 'react';

const matchSvg = (): JSX.Element => (
  <svg
    height="1em"
    width="1em"
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
    data-name="01-find replace"
    viewBox="0 0 24 24"
    x="0px"
    y="0px"
  >
    <title>Artboard 1</title>
    <path
      d="M10,8,8,10h8A7,7,0,1,1,9,3H19L17,1"
      fill="none"
      stroke="#ffffff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
    ></path>
    <line
      x1="8"
      y1="10"
      x2="10"
      y2="12"
      fill="none"
      stroke="#ffffff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
    ></line>
    <line
      x1="19"
      y1="3"
      x2="17"
      y2="5"
      fill="none"
      stroke="#ffffff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
    ></line>
    <line
      x1="14"
      y1="15"
      x2="22"
      y2="23"
      fill="none"
      stroke="#ffffff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
    ></line>
  </svg>
);
const matchSvgColored = (): JSX.Element => (
  <svg
    height="1em"
    width="1em"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    data-name="01-find replace"
    viewBox="0 0 24 24"
    x="0px"
    y="0px"
  >
    <title>Artboard 1</title>
    <path
      d="M10,8,8,10h8A7,7,0,1,1,9,3H19L17,1"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
    ></path>
    <line
      x1="8"
      y1="10"
      x2="10"
      y2="12"
      fill="none"
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
    ></line>
    <line
      x1="19"
      y1="3"
      x2="17"
      y2="5"
      fill="none"
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
    ></line>
    <line
      x1="14"
      y1="15"
      x2="22"
      y2="23"
      fill="none"
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2px"
    ></line>
  </svg>
);

function MatchIcon({
  ...props
}: Partial<CustomIconComponentProps>): JSX.Element {
  return <Icon component={matchSvgColored} {...props} />;
}

export default MatchIcon;
