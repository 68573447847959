import { LeftOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Avatar, Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import { Ellipsis } from '@/components/Chat/index.styled';
import Messages from '@/components/Chat/Messages';
import { Conversation } from '@/components/Chat/types';
import SearchRequestInformationModal from '@/components/SearchRequestInformationModal';
import { SearchRequestStatusEnum } from '@/generated/graphql';
import useUser from '@/hooks/useUser';
import InboxTitle from '@/views/Messages/Inbox/InboxTitle';

// max flow algorithm
// https://www.geeksforgeeks.org/ford-fulkerson-algorithm-for-maximum-flow-problem/

interface Props {
  conversation: Conversation;
  onGoBack: () => void;
}

const Container = styled.div`
  height: 100vh;
  padding-top: 70px;
  padding-bottom: 64px;
  width: 100vw;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  position: fixed;
  padding: 12px;
  z-index: 20;
  height: 63px;
  overflow: hidden;
  width: 100%;
  background: ${({ theme }) => theme.colors.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.inputBorderHover};
`;

function Mobile({ conversation, onGoBack }: Props): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();

  const opponent = conversation.opponents[0];
  const { searchRequestReference } = conversation;
  return (
    <>
      <Name>
        <Box flex horizontalSpacing={1} w={'100%'}>
          <Button
            onClick={onGoBack}
            type={'link'}
            ghost
            shape={'circle'}
            icon={<LeftOutlined />}
          />
          {opponent?.image?.url && (
            <Avatar
              shape={'square'}
              style={{ flexShrink: 0 }}
              src={opponent?.image?.url}
            />
          )}
          <Ellipsis>
            <InboxTitle
              conversation={conversation}
              clientId={user?.activeClientId}
              t={t}
            />
          </Ellipsis>
          <Box
            horizontalSpacing={0.1}
            flexShrink={0}
            style={{ marginLeft: 'auto' }}
          >
            {searchRequestReference && (
              <SearchRequestInformationModal
                id={searchRequestReference.id}
                disabled={
                  searchRequestReference.status ===
                  SearchRequestStatusEnum.Disabled
                }
                button={({ onClick }) => (
                  <Tooltip title={t('Show inquiry')}>
                    <Button
                      icon={<ZoomInOutlined />}
                      onClick={onClick}
                      type={'text'}
                    />
                  </Tooltip>
                )}
                inline
              />
            )}
          </Box>
        </Box>
      </Name>
      <Container>
        <Messages conversation={conversation} isMobile />
      </Container>
    </>
  );
}

export default Mobile;
