import { Typography } from 'antd';
import React from 'react';
import { TFunction } from 'react-i18next';

import PortallyIcon from '@/components/PortallyLogo/PortallyIcon';
import {
  ConversationTitleValuesFragment,
  SearchRequestStatusEnum,
} from '@/generated/graphql';
import theme from '@/main/theme';

const { Text } = Typography;

interface ReturnProps {
  title: string | JSX.Element;
  subTitle?: string;
  responsibleUser?: string;
  isSearchRequestTenant?: boolean;
}

export const getTitleAndSubtitleFromConversation = (
  conversation: ConversationTitleValuesFragment,
  clientId: string,
  t: TFunction,
  isSearchRequestTenant: boolean,
): ReturnProps => {
  const { searchRequest, leaseAgreement, searchRequestReference, opponents } =
    conversation;
  const opponent = opponents[0];
  const opponentName = opponent ? opponent.name : t('Deleted user');

  if (searchRequest && searchRequestReference) {
    const isMine = searchRequestReference?.clientId === clientId;

    if (isSearchRequestTenant) {
      return {
        title: opponentName,
        subTitle: searchRequest?.responsibleUser?.name,
      };
    }
    return {
      title: searchRequestReference.title,
      subTitle: isMine ? `${opponentName} ` : opponentName,
      responsibleUser: searchRequest?.responsibleUser?.name,
    };
  }

  if (leaseAgreement) {
    return {
      title: (
        <div className="flex items-center">
          <PortallyIcon
            size="16"
            className="mr-1"
            color={theme.colors.primaryPurple}
          />
          {leaseAgreement.name}
        </div>
      ),
      subTitle: opponentName,
    };
  }

  return {
    title: opponentName,
    subTitle: '',
  };
};

interface Props {
  conversation: ConversationTitleValuesFragment;
  clientId: string;
  t: TFunction;
  isSearchRequestTenant?: boolean;
}

function InboxTitle({
  conversation,
  clientId,
  t,
  isSearchRequestTenant = false,
}: Props): JSX.Element {
  const { searchRequestReference } = conversation;

  const { title, subTitle, responsibleUser } =
    getTitleAndSubtitleFromConversation(
      conversation,
      clientId,
      t,
      isSearchRequestTenant,
    );

  return (
    <span style={{ fontSize: '14px' }}>
      <Text
        strong
        type={
          searchRequestReference?.status === SearchRequestStatusEnum.Disabled
            ? 'danger'
            : undefined
        }
      >
        {title}
      </Text>
      {subTitle && (
        <Text
          style={{ fontWeight: 400 }}
          type={'secondary'}
          className="ml-1 text-xs"
        >
          {subTitle}
          {responsibleUser && (
            <Text type={'secondary'}> | {responsibleUser}</Text>
          )}
        </Text>
      )}
    </span>
  );
}

export default InboxTitle;
