import {
  CreditCardOutlined,
  DeleteOutlined,
  EditOutlined,
  GatewayOutlined,
  KeyOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { Marker } from '@react-google-maps/api';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { Button, Divider, DrawerProps, message, Modal, Tooltip } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NotFound from '@/components/Errors/NotFound';
import GoBack from '@/components/GoBackArrow';
import EditLeaseAgreement from '@/components/LeaseAgreementModals/EditLeaseAgreement';
import LeaseAgreementSize from '@/components/LeaseAgreementSize';
import StaticMap from '@/components/Map/StaticMap';
import { portallyPin } from '@/components/Markers';
import Spinner from '@/components/Spinner';
import UsageCategoryTag from '@/components/Tags/UsageCategoryTags';
import VideoPlayer from '@/components/VideoPlayer';
import Viewer from '@/components/Viewer';
import { useGetLeaseAgreementViewQuery } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';
import apollo from '@/main/apollo';
import theme from '@/main/theme';
import { formatNumber } from '@/utils';
import { LeaseAgreementRouteProps } from '@/views/LeaseAgreement/routes';
import { toMyLeaseAgreements } from '@/views/Management/routes';
import LeaseAgreementViews from '@/views/Statistics/LeaseAgreementStats/LeaseAgreementViews';

import DeleteLeaseAgreement from '../Form/DeleteLeaseAgreement';
import ContactAffix from './Affix/ContactAffix';
import ContactButton from './Affix/ContactButton';
import MyConversationsAffix from './Affix/MyConversationsAffix';
import AttachedFiles from './AttachedFiles';
import Images from './ImageSection';
import NearbyServices from './NearbyServices';

interface Props {
  preview?: boolean;
  drawerPlacement?: DrawerProps['placement'];
  id?: string;
}

export default function LeaseAgreement({
  preview = false,
  drawerPlacement,
  id,
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useWidthAndHeight();
  const [contactModalVisible, setContactModalVisible] = React.useState(false);
  const user = useUser();
  const isAdmin = user?.role === 'admin';
  const isAdminOrOwner = isAdmin || user?.activeClient.type === 'landlord';
  const navigate = useNavigate();
  const useMatchId = useMatch<LeaseAgreementRouteProps>().data.leaseAgreementId;
  const { loading, error, data, previousData } = useGetLeaseAgreementViewQuery({
    variables: { id: id ?? useMatchId },
    errorPolicy: 'all',
  });

  if (error) {
    return <NotFound />;
  }

  if (loading && !data && !previousData) {
    return <Spinner expand />;
  }
  const { leaseAgreement } = data ?? previousData;

  let lng = leaseAgreement.location.coordinates[0] ?? 0;
  let lat = leaseAgreement.location.coordinates[1] ?? 0;
  if (leaseAgreement.location.coordinates?.length) {
    lng = leaseAgreement.location.coordinates[0];
    lat = leaseAgreement.location.coordinates[1];
  }
  const isTenant = user?.activeClientId === leaseAgreement.tenantId;

  const isAgreementOwner =
    isTenant || user?.activeClientId === leaseAgreement.landlordId;

  let hasConversed = false;

  if (!isTenant) {
    hasConversed = leaseAgreement.conversations.some(conversation =>
      conversation.participants.some(
        participant => participant.id === user?.activeClientId,
      ),
    );
  }

  const map = (
    <div
      className={clsx(
        'h-full tablet:h-96 w-full rounded-xl overflow-hidden relative',
        {
          'h-96': preview,
        },
      )}
    >
      <StaticMap
        zoom={14}
        center={{ lat, lng }}
        height="100%"
        polygons={[]}
        style={{ width: '100%', zIndex: 0 }}
      >
        <Marker
          icon={portallyPin(false, 32, theme.colors.coral)}
          position={{
            lat,
            lng,
          }}
        />
      </StaticMap>
    </div>
  );

  return (
    <div
      className={clsx('p-4', {
        'bg-gray-100 mobile:p-0': !preview,
        'bg-white p-0': preview,
      })}
    >
      <div
        className={clsx(
          'max-w-screen-lg mx-auto p-6 pb-8 bg-white rounded-2xl',
          { '!p-0': preview },
        )}
      >
        {user?.activeClientId && <Viewer leaseAgreementId={id} />}
        {!preview && (
          <div className="mb-4">
            <GoBack />
          </div>
        )}
        <div
          className={clsx(
            'max-w-full max-h-[600px] tablet:max-w-screen-sm tablet:mx-auto grid grid-cols-3 gap-4',
          )}
        >
          <div
            className={clsx('col-span-2 tablet:col-span-3', {
              'col-span-3': preview,
            })}
          >
            <Images
              id={leaseAgreement.id}
              myStatus={leaseAgreement.myStatus}
              images={leaseAgreement.images}
              isAgreementOwner={isAgreementOwner}
            />
          </div>

          {leaseAgreement.location && !preview && (
            <div className="col-span-1 auto w-full rounded-xl tablet:hidden ">
              {map}
            </div>
          )}
        </div>
        <div className={'flex mt-8 space-x-6 tablet:mx-auto flex-col'}>
          <div
            className={clsx(
              'items-start w-full m-tablet:min-w-[600px] justify-between gap-3',
              {
                flex: !isTablet,
              },
            )}
          >
            <div id={'header'} className={'flex flex-col'}>
              <div className="flex flex-wrap items-baseline gap-2">
                <h1 className={'font-bold text-4xl mb-1'}>
                  {leaseAgreement.name}
                </h1>
                {(isAgreementOwner || isAdmin) && (
                  <div>
                    <EditLeaseAgreement
                      icon={<EditOutlined />}
                      type={isMobile ? undefined : 'text'}
                      id={leaseAgreement.id}
                      style={{ marginRight: '8px' }}
                    >
                      {t('Edit')}
                    </EditLeaseAgreement>
                    <Tooltip title={t('Delete')}>
                      <DeleteLeaseAgreement
                        integrationProvider={leaseAgreement.integrationProvider}
                        onCompleted={async () => {
                          await apollo.resetStore();
                          navigate({
                            to: toMyLeaseAgreements(),
                          });
                          message.success(t('Premises removed'));
                        }}
                        id={leaseAgreement.id}
                        danger
                        type={isMobile ? undefined : 'text'}
                        icon={<DeleteOutlined />}
                      >
                        {t('Delete')}
                      </DeleteLeaseAgreement>
                    </Tooltip>
                  </div>
                )}
              </div>
              <h2 className={'font-normal text-lg mb-0'}>
                {leaseAgreement.title}
              </h2>
              <div className={'text-zinc-500 text-lg font-normal'}>
                {leaseAgreement.address.zipCode} {leaseAgreement.address.city}
              </div>
              <div className={'flex align-start gap-2 mt-4 flex-wrap'}>
                {leaseAgreement.usageCategory?.map((category, index) => (
                  <UsageCategoryTag
                    inverted
                    usageCategory={category}
                    size="large"
                    key={index}
                  />
                ))}
              </div>

              <div
                id={'description'}
                className={clsx('space-y-5 flex flex-col max-w-screen-md mr-4')}
              >
                <div>
                  <Divider />
                  <div
                    id={'rentaccesssize'}
                    className={'flex gap-5 flex-wrap justify-between max-w-lg'}
                  >
                    <div>
                      <div className={'text-zinc-500 mb-2 space-x-2'}>
                        <span>{t('size')}</span>
                        <GatewayOutlined />{' '}
                      </div>

                      <div className={'text-base'}>
                        <LeaseAgreementSize
                          size={leaseAgreement.size}
                          sizeSpan={leaseAgreement.sizeSpan ?? undefined}
                        />
                      </div>
                    </div>

                    <div>
                      <div className={'text-zinc-500 mb-2 space-x-2'}>
                        <span>{t('Rent')}</span>
                        <CreditCardOutlined />
                      </div>
                      <div className={'text-base'}>
                        {leaseAgreement.fee
                          ? `${formatNumber(leaseAgreement.fee.amount)} ${t(
                              `${leaseAgreement.fee.feeType}`,
                            )}`
                          : t('Contact landlord')}
                      </div>
                    </div>
                    <div>
                      <div className={'text-zinc-500 mb-2 space-x-2'}>
                        <span>{t('Access')}</span>
                        <KeyOutlined />
                      </div>
                      <div className={'text-base'}>
                        {leaseAgreement.access
                          ? t(`${leaseAgreement.access}`)
                          : t('according_to_agreement')}
                      </div>
                    </div>
                  </div>
                </div>
                <Divider />
              </div>
              {leaseAgreement.description?.length ? (
                <div>
                  <div className={'text-zinc-500 mb-2 mt-8'}>
                    {t('description')}
                  </div>
                  <div
                    className={
                      'prose leading-relaxed break-words whitespace-pre-line'
                    }
                  >
                    {leaseAgreement.description}
                  </div>
                </div>
              ) : (
                <div />
              )}
              {leaseAgreement.areaDescription?.length ? (
                <div>
                  <div className={'text-zinc-500 mb-2 mt-8'}>{t('area')}</div>
                  <div
                    className={
                      'prose leading-relaxed break-words whitespace-pre-line'
                    }
                  >
                    {leaseAgreement.areaDescription}
                  </div>
                </div>
              ) : null}
              {leaseAgreement.video?.video?.url ? (
                <>
                  <div className={'text-zinc-500 mb-2 mt-8'}>
                    {t('Display')}
                  </div>

                  <VideoPlayer
                    style={{ objectFit: 'fill' }}
                    playing={!!leaseAgreement.video.thumbnail?.url}
                    light={
                      leaseAgreement.video.thumbnail?.url &&
                      encodeURI(leaseAgreement.video.thumbnail.url)
                    }
                    url={leaseAgreement.video.video?.url}
                  />
                </>
              ) : null}
              {leaseAgreement.documents?.length ? (
                <div>
                  <div className={'text-zinc-500 font-light text-base mt-8'}>
                    {t('Attached files')}
                  </div>
                  <AttachedFiles
                    attachedFiles={leaseAgreement.documents}
                    drawerPlacement={drawerPlacement}
                  />
                </div>
              ) : (
                <div />
              )}

              <NearbyServices nearbyServices={leaseAgreement.nearbyServices} />
            </div>
            {!preview && !isTablet && (
              <div className="sticky top-6 flex flex-col flex-shrink-0 h-fit m-tablet:max-w-[320px] bg-white m-mobile:w-full mobile:mt-6 mobile:bg-none">
                {!hasConversed && (
                  <div className="mb-2 top-6 flex flex-col flex-shrink-0 h-fit m-tablet:max-w-[320px] border border-solid border-zinc-200 p-7 rounded-2xl shadow bg-white m-mobile:w-full mobile:mt-6 mobile:bg-none">
                    <ContactAffix
                      isAgreementOwner={isAgreementOwner}
                      leaseAgreement={leaseAgreement}
                    />
                  </div>
                )}
                <MyConversationsAffix
                  leaseAgreementId={leaseAgreement.id}
                  matchingSearchRequests={leaseAgreement.matchingSearchRequests}
                  conversations={leaseAgreement.conversations}
                />
              </div>
            )}
          </div>
          {!preview && isTablet && !hasConversed && (
            <ContactButton
              leaseAgreement={leaseAgreement}
              isAgreementOwner={isAgreementOwner}
            />
          )}
        </div>

        {(preview || isTablet) && (
          <div className={'mt-2 w-full rounded-2xl overflow-hidden'}>{map}</div>
        )}
      </div>
      {isAdminOrOwner && (
        <div
          className={clsx(
            'max-w-screen-lg mx-auto p-6 pb-8 bg-white rounded-2xl mt-4',
            { '!p-0': preview },
          )}
        >
          <LeaseAgreementViews all leaseAgreementId={leaseAgreement.id} />
        </div>
      )}
      {leaseAgreement.vitecGuid && (
        <img
          src={`https://hitcounter.vitec.net/IncrementCounter.aspx?guid=${leaseAgreement.vitecGuid}&requestedFrom=Portally`}
        />
      )}
    </div>
  );
}
