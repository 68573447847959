import { ProjectOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetActiveSearchRequestNotificationsQuery,
  useSubscribeToMessagesSubscription,
} from '@/generated/graphql';
import useAppSelector from '@/hooks/useAppSelector';
import useUser from '@/hooks/useUser';
import { NavLink } from '@/layout/Customer/LeftMenu/index.styled';
import { toActiveSearchRequests } from '@/views/ActiveSearchRequests/routes';

function ActiveSearchRequests(): JSX.Element {
  const user = useUser();
  const filter = useAppSelector(
    state => state.customer.activeSearchRequestFilter,
  );
  const { data, previousData, refetch } =
    useGetActiveSearchRequestNotificationsQuery({
      variables: {
        filter: {
          responsibleUserIds:
            'responsibleUserIds' in filter
              ? filter.responsibleUserIds
              : [user?.id],
        },
      },
    });

  useSubscribeToMessagesSubscription({
    onSubscriptionData: async () => {
      await refetch();
    },
  });
  const { t } = useTranslation();
  return (
    <NavLink to={toActiveSearchRequests()}>
      <Badge
        count={(data ?? previousData)?.myActiveSearchRequests?.notifications}
      >
        <ProjectOutlined style={{ fontSize: '22px' }} />
      </Badge>
      <span className="text">{t('Answered search requests')}</span>
    </NavLink>
  );
}

export default ActiveSearchRequests;
