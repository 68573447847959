import {
  Navigate,
  Outlet,
  useMatches,
  useRouter,
} from '@tanstack/react-location';
import React, { Suspense } from 'react';
import styled, { css } from 'styled-components';

import DealRequest from '@/components/DealRequest/modal';
import ErrorBoundary from '@/components/ErrorBoundary';
import MetaTitle from '@/components/MetaTitle';
import Spinner from '@/components/Spinner';
import SubscriptionRequest from '@/components/SubscriptionRequest';
import TermsOfService from '@/components/TermsOfService';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useAuth from '@/hooks/useAuth';
import useUser from '@/hooks/useUser';
import { RouteMetaProps, RouteProps } from '@/layout/routes';
import { toLogin } from '@/layout/Unauthenticated/routes';
import Page500 from '@/views/Page500';

import LeftMenu from './LeftMenu';
import MobileNavigation from './MobileNavigation';

const DashboardCss = css`
  width: calc(100vw - ${({ theme }) => theme.dimensions.leftMenu});

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: calc(100vw - ${({ theme }) => theme.dimensions.leftMenuCollapsed});
  }
`;
const Content = styled.div<{ $whiteBackground: boolean }>`
  display: flex;
  flex-direction: row;
  background: ${p =>
    p.$whiteBackground ? p.theme.colors.white : p.theme.colors.light};
  width: 100vw;
`;

const Component = styled.div<{
  $noPadding: boolean;
  $isDashboard: boolean;
  $withNavbar: boolean;
  $isLandlord: boolean;
  $fixedSider: boolean;
}>`
  padding: ${p => (p.$noPadding ? '0' : '24px')};
  width: calc(100vw - ${({ theme }) => theme.dimensions.leftMenuCollapsed});
  ${p => p.$isDashboard && DashboardCss};
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    ${({ theme, $isDashboard, $isLandlord }) =>
      $isDashboard &&
      $isLandlord &&
      `padding-right: calc(${theme.dimensions.padding} - ${theme.dimensions.landlordCardMargin})`};
    ${({ theme, $fixedSider }) =>
      $fixedSider &&
      `padding-left: calc(${theme.dimensions.padding} + ${theme.dimensions.leftMenuCollapsed})`};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-bottom: ${p =>
      p.$withNavbar ? p.theme.dimensions.mobileNavOffset : 0};
    width: 100vw;
  }
`;

const defaultProps: RouteMetaProps = {
  whiteBackground: false,
  noPadding: false,
  fixedSider: false,
  noPaddingPhone: false,
  whiteBackgroundPhone: false,
};
function Customer(): JSX.Element {
  const user = useUser();
  const { isMobile } = useWidthAndHeight();
  const location = useRouter<RouteProps>();

  const matches = useMatches<RouteProps>();
  const meta = matches[matches.length - 1].route.meta;
  const {
    whiteBackground,
    noPadding,
    noPaddingPhone,
    fixedSider,
    whiteBackgroundPhone,
  } = meta ?? defaultProps;

  const isDashboard = location.state.location.pathname === '/';
  const isConversation = matches
    .map(({ params }) => params)
    .some(params => !!params?.conversationId);
  const withNavbar = !isConversation;

  return (
    <>
      <MetaTitle />
      <Content
        $whiteBackground={whiteBackground || (isMobile && whiteBackgroundPhone)}
      >
        <Suspense fallback={null}>
          {isMobile && withNavbar && <MobileNavigation user={user} />}
          {!isMobile && <LeftMenu user={user} fixed={fixedSider} />}
        </Suspense>

        <Component
          $noPadding={noPadding || (noPaddingPhone && isMobile)}
          $isDashboard={isDashboard}
          $withNavbar={withNavbar}
          $isLandlord={user?.activeClient.type === 'landlord'}
          $fixedSider={fixedSider}
        >
          <Suspense fallback={<Spinner expand />}>
            <ErrorBoundary>
              <DealRequest />
              <SubscriptionRequest />
              <TermsOfService />
              <Outlet />
            </ErrorBoundary>
          </Suspense>
        </Component>
      </Content>
    </>
  );
}

function Authenticated(): JSX.Element {
  const { authenticated, loading, error } = useAuth();

  if (authenticated) {
    return <Customer />;
  }

  if (loading) {
    return <Spinner expand />;
  }

  if (error) {
    return (
      <Suspense fallback={<Spinner expand />}>
        <Page500 />
      </Suspense>
    );
  }

  return <Navigate to={toLogin()} />;
}

export default Authenticated;
