import { WalletOutlined } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-location';
import { Menu, Tag } from 'antd';
import React from 'react';

import { useGetMySubscriptionRequestQuery } from '@/generated/graphql';
import { toSubscription } from '@/views/Subscription/routes';

interface Props {
  key: string | number;
  subscriptionType: string;
}

function SubscriptionMenuItem({ key, subscriptionType }: Props): JSX.Element {
  const { data, loading } = useGetMySubscriptionRequestQuery();
  const navigate = useNavigate();

  if (
    loading ||
    !data ||
    !data.mySubscriptionRequest ||
    subscriptionType === 'subscription' ||
    !subscriptionType
  ) {
    return null;
  }

  return (
    <Menu.Item
      key={key}
      onClick={() =>
        navigate({
          to: toSubscription(),
        })
      }
      icon={<WalletOutlined />}
    >
      Mina villkor
    </Menu.Item>
  );
}

export default SubscriptionMenuItem;
