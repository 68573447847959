import { PersonFill } from '@styled-icons/bootstrap/PersonFill';
import { Avatar, Button } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmojiAdd } from 'styled-icons/fluentui-system-regular';

import Attachments from '@/components/Chat/Messages/Attachments';
import { Conversation } from '@/components/Chat/types';
import MessageReaction from '@/components/MessageReaction';
import {
  ConversationFilter,
  ConversationSearchType,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useAppSelector from '@/hooks/useAppSelector';
import useUser from '@/hooks/useUser';
import { Unpacked } from '@/utils/types';

import LeaseAgreements from './LeaseAgreements';
import MarkAsAnswered from './MarkAsAnswered';
import Reactions from './Reactions';
import Read from './Read';
import { splitMessageIntoLinks } from './util';

const userMatches = (
  search: ConversationFilter['search'],
  userId: string | undefined,
): boolean =>
  search.type === ConversationSearchType.User && userId === search.key;
const messageMatches = (
  search: ConversationFilter['search'],
  message: string,
): boolean =>
  search.type === ConversationSearchType.Message && search.key !== ''
    ? new RegExp(search.key, 'i').test(message)
    : false;

interface Props {
  message: Unpacked<Conversation['messages']>;
  showAvatar: boolean;
  showName: boolean;
  showTimestamp: boolean;
  showMarkAsAnswered: boolean;
  conversationId: string;
  block?: boolean;
  reactions?: boolean;
  showRead?: boolean;
}

export default function MessageComponent({
  message,
  showAvatar,
  showName,
  showTimestamp,
  showMarkAsAnswered,
  conversationId,
  reactions = true,
  showRead,
}: Props): JSX.Element {
  const [hover, setHover] = useState(false);
  const { isMobile } = useWidthAndHeight();
  const { t } = useTranslation();
  const user = useUser();
  const mine = message.sender.id === user?.activeClientId;
  const date = dayjs(new Date(message.created));
  const { search } = useAppSelector(state => state.customer.conversationFilter);
  useEffect(() => {
    document?.addEventListener('copy', function (e) {
      const textOnly = document?.getSelection().toString();
      const clipdata = e.clipboardData;
      clipdata?.setData('text/plain', textOnly);
      clipdata?.setData('text/html', textOnly);
      e.preventDefault();
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => document.removeEventListener('copy', () => {});
  }, []);

  const rowProps = isMobile
    ? {}
    : {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
      };

  const images = message.attachments?.filter(
    attachment => attachment?.mimetype?.startsWith('image') ?? false,
  );
  const otherAttachments = message.attachments?.filter(
    attachment => !attachment?.mimetype?.startsWith('image') ?? false,
  );
  return (
    <div
      className={clsx(
        'flex flex-col space-y-1 px-2 justify-end w-full max-w-full',
        {
          'items-start': !mine,
          'items-end': mine,
          'mt-6': showName,
        },
      )}
    >
      {showName && !!message.message && (
        <div
          className={clsx('flex space-x-2 items-baseline text-sm', {
            'ml-12': !mine,
            'mr-12': mine,
          })}
        >
          <div
            className={clsx('text-zinc-600', {
              'text-purple-500': userMatches(search, message.senderUser.id),
            })}
          >
            {message.senderUser?.name || message.sender.name}
          </div>
          <span className={'text-zinc-400'}>
            {showTimestamp && date.format('HH:mm')}
          </span>
        </div>
      )}

      <div
        className={clsx('flex items-end max-w-xl', {
          'flex-row-reverse': !mine,
        })}
      >
        <div className={'flex flex-col'}>
          {!mine && isMobile && (
            <Button
              className={'ml-2 flex items-center'}
              onClick={() => setHover(!hover)}
              shape="round"
              icon={<EmojiAdd size={20} />}
            />
          )}
          {showMarkAsAnswered && (
            <div className={'mt-1 ml-1'}>
              <MarkAsAnswered id={conversationId} />
            </div>
          )}
        </div>

        <div
          className={clsx('flex flex-col space-y-1', {
            'ml-10': !mine && !showAvatar,
            'mr-10': mine && !showAvatar,
          })}
        >
          {message.message && (
            <div
              className={clsx(
                'p-4 text-sm rounded-3xl relative break-words whitespace-pre-line',
                {
                  'bg-gradient-to-br from-purple-600 to-purple-500 text-white':
                    mine && message.message,
                  'bg-zinc-100 text-black': !mine && message.message,
                  'rounded-bl-sm': !mine && showAvatar,
                  'rounded-br-sm': mine && showAvatar,
                  'bg-gradient-to-br from-coral-600 to-coral-500 text-white':
                    messageMatches(search, message.message),
                },
              )}
              key={`message${message.id}`}
              {...rowProps}
            >
              {reactions && hover && !mine && (
                <MessageReaction
                  key={`message${message.id}react`}
                  setHover={setHover}
                  reactions={message.reactions}
                  messageId={message.id}
                />
              )}
              {splitMessageIntoLinks(message.message, mine)}
              <Reactions
                messageId={message.id}
                key={`message${message.id}reaction`}
                reactions={message.reactions}
              />
            </div>
          )}

          <div
            className={clsx({
              'self-start': !mine,
              'self-end -mr-3': mine,
            })}
          >
            <Attachments attachments={otherAttachments} mine={mine} />
          </div>
          <LeaseAgreements
            mine={mine}
            t={t}
            leaseAgreements={message.leaseAgreements ?? []}
          />
          <Attachments attachments={images} mine={mine} />
        </div>

        {showAvatar && (
          <Avatar
            style={{
              alignSelf: 'flex-end',
              flexShrink: 0,
              marginLeft: mine ? '8px' : 0,
              marginRight: mine ? 0 : '8px',
            }}
            shape={'circle'}
            icon={<PersonFill />}
            src={message.senderUser?.image?.url}
          />
        )}
      </div>
      {mine && showRead && message.readTimestamp && (
        <div className={'mr-2 self-end'}>
          <Read timeStamp={message.readTimestamp} t={t} key={message.id} />
        </div>
      )}
    </div>
  );
}
