import styled from 'styled-components';

export const Title = styled.h1``;

export const SubTitle = styled.h2``;

export const Paragraph = styled.p<{ $secondary?: boolean }>`
  font-size: 16px;
`;

// color: ${({ theme, $secondary }) => $secondary ? theme.colors.typographySecondary : theme.colors.black};
//color: ${({ theme }) => theme.colors.primaryPurple};
//color: ${({ theme }) => theme.colors.primaryPurpleDark};
