import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import Spinner from '@/components/Spinner';
import { useGetLeaseAgreementViewsQuery } from '@/generated/graphql';

import Graph from '../UserStats/Graph';

interface Props {
  leaseAgreementId: string;
}

function LeaseAgreementViews({ leaseAgreementId }: Props): JSX.Element {
  const {
    loading: loadingFromWebsite,
    error: errorFromWebsite,
    data: dataFromWebsite,
    previousData: previousDataFromWebsite,
  } = useGetLeaseAgreementViewsQuery({
    variables: {
      leaseAgreementId: leaseAgreementId,
      fromWebsite: true,
    },
    errorPolicy: 'all',
  });

  const {
    loading: loadingFromApp,
    error: errorFromApp,
    data: dataFromApp,
    previousData: previousDataFromApp,
  } = useGetLeaseAgreementViewsQuery({
    variables: {
      leaseAgreementId: leaseAgreementId,
      fromWebsite: false,
    },
    errorPolicy: 'all',
  });

  if (
    (loadingFromWebsite && !dataFromWebsite && !previousDataFromWebsite) ||
    (loadingFromApp && !dataFromApp && !previousDataFromApp)
  ) {
    return <Spinner expand />;
  }

  if (errorFromWebsite || errorFromApp) {
    console.log(errorFromWebsite, 'erro from website');

    console.log(errorFromApp, ' erro from app');
    return <div>Error</div>;
  }
  const { numbers } = (dataFromWebsite ?? previousDataFromWebsite).viewStats;
  const { overTime: overTimeFromWebsite } = (
    dataFromWebsite ?? previousDataFromWebsite
  ).viewStats;

  const { overTime: overTimeFromApp } = (dataFromApp ?? previousDataFromApp)
    .viewStats;

  const graphDataFromWebsite = {
    labels: overTimeFromWebsite.map(ot => ot.date),
    values: overTimeFromWebsite.map(ot => ot.count),
  };

  const graphDataFromApp = {
    labels: overTimeFromApp.map(ot => ot.date),
    values: overTimeFromApp.map(ot => ot.count),
  };
  if (numbers.total === 0) {
    return null;
  }
  return (
    <div className="flex flex-col mt-8">
      <div className="text-xl font-semibold">{`Totalt ${numbers.total} visningar`}</div>
      <div className="flex flex-1 gap-2 items-baseline">
        <div className="w-full [&>canvas]:max-w-full">
          <div className="relative top-12 text-3xl left-0">
            {numbers.website > 0 && numbers.website}
          </div>
          <Graph
            chartType={'area'}
            data={[
              {
                title: 'Hemsidan',
                ...graphDataFromWebsite,
              },
            ]}
          />
        </div>
        <div className="w-full [&>canvas]:max-w-full">
          <div className="relative top-12 text-3xl left-0">
            {numbers.app > 0 && numbers.app}
          </div>
          <Graph
            chartType={'area'}
            data={[
              {
                title: 'Appen',
                ...graphDataFromApp,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
function AllLeaseAgreementViews({ leaseAgreementId }: Props): JSX.Element {
  const [filter, setFilter] = useState(30);

  const { loading, error, data, previousData } = useGetLeaseAgreementViewsQuery(
    {
      variables: {
        from: filter,
        leaseAgreementId: leaseAgreementId,
      },
      errorPolicy: 'all',
    },
  );

  if (loading && !data && !previousData) {
    return <Spinner expand />;
  }

  const { numbers } = (data ?? previousData).viewStats;
  const { overTime } = (data ?? previousData).viewStats;

  const graphData = {
    labels: overTime.map(ot => ot.date),
    values: overTime.map(ot => ot.count),
  };

  return (
    <div>
      <Form.Item>
        <Select
          defaultValue={filter}
          style={{ width: 180 }}
          onChange={value => {
            setFilter(value);
          }}
          options={[
            { value: 7, label: '7 dagar' },
            { value: 30, label: '30 dagar' },
            { value: 90, label: '90 dagar' },
            { value: null, label: 'Sedan publicering' },
          ]}
        />
      </Form.Item>
      {numbers.total > 0 ? (
        <div className="flex flex-col">
          <div className="flex flex-1 gap-2 items-baseline">
            <div className="w-full [&>canvas]:max-w-full">
              <div className="relative top-12 text-3xl left-0">
                {numbers.total > 0 && numbers.total}
              </div>
              <Graph
                chartType={'area'}
                data={[
                  {
                    title: 'Visningar',
                    ...graphData,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>Inga visningar för denna period</div>
      )}
    </div>
  );
}

interface LeaseAgreementViewsIndexProps {
  all: boolean;
  leaseAgreementId: string;
}
export default function LeaseAgreementViewsIndex({
  all,
  leaseAgreementId,
}: LeaseAgreementViewsIndexProps): JSX.Element {
  if (all) {
    return <AllLeaseAgreementViews leaseAgreementId={leaseAgreementId} />;
  }
  return <LeaseAgreementViews leaseAgreementId={leaseAgreementId} />;
}
