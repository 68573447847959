import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Tooltip,
  Typography,
} from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import { formatNumber } from '@/utils';

import Commission from './Commission';
const { Text, Title, Paragraph } = Typography;
interface Props {
  isLandlord: boolean;
  setAcceptedDealRequest: Dispatch<SetStateAction<boolean>>;
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
  portallyFee: number;
  form: FormInstance;
  showDealRequest?: boolean;
  setShowDealRequest?: Dispatch<SetStateAction<boolean>>;
}
export default function StepZero({
  isLandlord,
  setAcceptedDealRequest,
  portallyFee,
  form,
  setStep,
  step,
  showDealRequest = false,
  setShowDealRequest,
}: Props): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const { t } = useTranslation();
  return (
    <>
      {isMobile && (
        <Box flex justifyContent={'center'}>
          <Title level={5}>Hyresavtalet</Title>
        </Box>
      )}
      <Box
        px={4}
        pt={4}
        flex
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <>
          {isLandlord ? (
            <>
              <Title level={4}>Gratulerar till er nya hyresgäst!</Title>
              <Paragraph>
                Ange uppgifter nedan för att skapa en faktura avseende Portallys
                arvode enligt våra Förmedlingsvillkor
              </Paragraph>
            </>
          ) : (
            <>
              <Title level={4}>Gratulerar till er nya lokal!</Title>
              <Paragraph>
                Ange uppgifter nedan för att skapa en faktura avseende Portallys
                arvode enligt våra Förmedlingsvillkor
              </Paragraph>
            </>
          )}
        </>
        <Box py={4} minW={350}>
          <Form.Item
            name={'madeWith'}
            label={isLandlord ? t('Hyresgäst') : t('Hyresvärd')}
            rules={[{ required: true, message: t('required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'annualRent'}
            label={
              <Tooltip
                title={
                  'Genomsnittlig årshyra beräknas enligt Förmedlingsvillkoren exkl. eventuella rabatter samt hyrestillägg som fastighetsskatt och värme/kyla'
                }
              >
                {t('Genomsnittlig årshyra')} <QuestionCircleOutlined />
              </Tooltip>
            }
            rules={[{ required: true, message: t('required') }]}
          >
            <InputNumber
              className="w-full"
              prefix={'(SEK)'}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
            />
          </Form.Item>
        </Box>
      </Box>
      <Box
        flex
        justifyContent={'flex-end'}
        horizontalSpacing={1}
        alignItems={'flex-end'}
      >
        <Button
          onClick={() =>
            showDealRequest
              ? setShowDealRequest(false)
              : setAcceptedDealRequest(false)
          }
        >
          {showDealRequest ? t('Cancel') : t('goBack')}
        </Button>

        <Button
          type={'primary'}
          onClick={async () => {
            await form
              .validateFields(['annualRent', 'madeWith'])
              .then(() => setStep(step + 1))
              .catch(err => err);
          }}
        >
          {t('Next')}
        </Button>
      </Box>
    </>
  );
}
