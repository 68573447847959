import { useApolloClient } from '@apollo/client';
import { message, notification, Typography } from 'antd';
import React from 'react';
import { getI18n, useTranslation } from 'react-i18next';

import { MySubscriptionRequest } from '@/components/SubscriptionRequest';
import Commission from '@/components/SubscriptionRequest/V2/Commission';
import {
  GetLoggedInUserDocument,
  SubscriptionType,
  useAcceptSubscriptionRequestMutation,
} from '@/generated/graphql';
import { refetchUser } from '@/hooks/useAuth';

import Form from '../Form';

const { Title } = Typography;

interface Props {
  mySubscriptionRequest: MySubscriptionRequest;
}

function V2({ mySubscriptionRequest }: Props): JSX.Element {
  const { t } = useTranslation();
  const i18n = getI18n();
  const apolloClient = useApolloClient();
  const accepted = mySubscriptionRequest.status === 'accepted';

  const [answer, { loading: mLoading }] = useAcceptSubscriptionRequestMutation({
    onCompleted: () => {
      notification.success({
        message: t('Thank you for using Portally'),
        placement: 'topRight',
      });
      void refetchUser(apolloClient);
    },

    onError: () => message.error(t('error')),
  });

  return (
    <>
      <Title level={2}>Förmedlingsvillkor</Title>
      <Commission lng={i18n.language} />
      <Form
        answer={answer}
        loading={mLoading}
        id={mySubscriptionRequest.id}
        accepted={accepted}
        subscriptionType={SubscriptionType.Commission}
      />
    </>
  );
}

export default V2;
