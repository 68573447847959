import { Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

const News = React.lazy(() => import('.'));

const NewsRoutes: Route<BasicRouteProps>[] = [
  {
    path: '/news',
    id: 'News',
    element: <News />,
    meta: {
      noPadding: true,
      whiteBackground: true,
    },
  },
];

export const toNews = (): string => '/news';

export default NewsRoutes;
