import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTour, { ReactourStep } from 'reactour';

import PortallyIcon from '@/components/PortallyLogo/PortallyIcon';
import {
  ModalEnum,
  useCompleteTourMutation,
  useGetMySearchRequestsQuery,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';

const { Text, Paragraph } = Typography;

const createSteps = (t): { [key: string]: ReactourStep[] } => ({
  newUser: [
    {
      selector: '.tour-search-requests',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PortallyIcon />
          <Typography.Title level={5}>
            {t('tourWebsiteThanks')}
          </Typography.Title>
          <Paragraph>{t('tourWebsiteTips')}</Paragraph>

          <Paragraph strong>
            {t('tourWebsiteTitle1')}
            <br />
            <Text type={'secondary'}>{t('tourWebsiteText1')}</Text>
          </Paragraph>
          <Paragraph strong>
            {t('tourWebsiteTitle2')}
            <br />
            <Text type={'secondary'}>{t('tourWebsiteText2')}</Text>
          </Paragraph>
          <Paragraph>{t('tourWebsiteGoodLuck')}</Paragraph>
        </div>
      ),
    },
  ],
  tenant: [
    {
      content: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PortallyIcon />
          <Typography.Title level={5} style={{ margin: '5px auto' }}>
            {t('tutorialWelcomeTenant1')}
          </Typography.Title>
          <div>{t('tutorialWelcomeTenant2')}</div>
        </div>
      ),
    },
    {
      selector: '.tour-home',
      content: t('tutorialDashboard'),
    },
    {
      selector: '.tour-search-button',
      content: t('tutorialSearch'),
    },
    {
      selector: '.tour-search-requests',
      content: t('tutorialSearchRequest'),
    },
    {
      selector: '.tour-establishment',
      content: t('tutorialFavourites'),
    },
    {
      selector: '.tour-management',
      content: t('tutorialManagement'),
    },
    {
      selector: '.tour-settings',
      content: t('tutorialSettings'),
    },
    {
      selector: '.tour-support',
      content: t('tutorialSupport'),
    },
    {
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <PortallyIcon />
          <Typography.Title style={{ margin: '5px auto' }} level={5}>
            {t('tutorialGoodLuck')}
          </Typography.Title>
        </div>
      ),
    },
  ],
  landlord: [
    {
      content: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PortallyIcon />
          <Typography.Title level={5} style={{ margin: '5px auto' }}>
            {t('tutorialWelcomeLandlord1')}
          </Typography.Title>
          <div>{t('tutorialWelcomeLandlord2')}</div>
        </div>
      ),
    },
    {
      selector: '.tour-home',
      content: t('tutorialDashboard'),
    },
    {
      selector: '.tour-dashboard-search-request',
      content: t('tutorialSearchRequestLandlord'),
    },
    {
      selector: '.tour-filter',
      content: t('tutorialFilter'),
    },

    {
      selector: '.tour-management',
      content: t('tutorialManagement'),
    },
    {
      selector: '.tour-settings',
      content: t('tutorialSettings'),
    },
    {
      selector: '.tour-support',
      content: t('tutorialSupport'),
    },
    {
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <PortallyIcon />
          <Typography.Title style={{ margin: '5px auto' }} level={5}>
            {t('tutorialGoodLuck')}
          </Typography.Title>
        </div>
      ),
    },
  ],
  myEstates: [
    {
      content: (
        <>
          <Typography.Paragraph>{t('tourMyEstates1')}</Typography.Paragraph>
          <Typography.Paragraph>{t('tourMyEstates2')}</Typography.Paragraph>
          <Typography.Paragraph>{t('tourMyEstates3')}</Typography.Paragraph>
        </>
      ),
      selector: '.tour-my-estates',
      position: 'center',
    },
  ],
  management: [
    {
      content: (
        <>
          <Typography.Paragraph>{t('tourManagement1')}</Typography.Paragraph>
          <Typography.Paragraph>{t('tourManagement2')}</Typography.Paragraph>
          <Typography.Paragraph>{t('tourManagement3')}</Typography.Paragraph>
        </>
      ),
      selector: '.tour-my-facilities',
      position: 'center',
    },
  ],
  search: [
    {
      content: (
        <>
          <Typography.Paragraph>{t('tourSearch1')}</Typography.Paragraph>
          <Typography.Paragraph>{t('tourSearch3')}</Typography.Paragraph>
          <Typography.Paragraph>{t('tourSearch4')}</Typography.Paragraph>
        </>
      ),
      selector: '.tour-search-information',
      position: 'center',
    },
  ],
});

interface Props {
  name: string;
  icon?: boolean;
  visible?: boolean;
  setVisible?: Dispatch<SetStateAction<boolean>>;
  hidden?: boolean;
}

function Tour({
  name,
  icon,
  visible = false,
  setVisible,
  hidden = false,
}: Props): JSX.Element {
  const user = useUser();

  const completedTour = user.completedTours.includes(name);

  const tour =
    !completedTour && name === 'tenant' && user.fromWebsite ? 'newUser' : name;

  const isTenant = user.activeClient?.type === 'tenant';

  const { loading } = useGetMySearchRequestsQuery({
    skip: completedTour || tour !== 'newUser',
    variables: {
      limit: 10,
    },
  });

  const confirmedTos =
    user.activeClient &&
    user.activeClient.termsOfService &&
    user.activeClient.termsOfService.confirmed;

  const { t } = useTranslation();
  const steps = createSteps(t);

  const [isTourOpen, setTour] = useState(
    (!completedTour || visible) && isTenant,
  );

  const [action] = useCompleteTourMutation({
    variables: {
      tour: name,
    },
  });

  const { isMobile } = useWidthAndHeight();

  if (isMobile) {
    return null;
  }

  if (!confirmedTos) {
    return null;
  }

  if (user.activeModal != ModalEnum.None) {
    return null;
  }

  if (loading) {
    return null;
  }

  if (!steps?.[name] || steps?.[name]?.length === 0) {
    return null;
  }

  return (
    <>
      <ReactTour
        steps={steps[tour]}
        isOpen={isTourOpen || visible}
        className={tour}
        onRequestClose={async () => {
          setTour(false);
          setVisible && setVisible(false);
          action();
        }}
        showNumber={steps?.[tour].length > 1}
        lastStepNextButton={t('Finished')}
        maskSpace={0}
        rounded={8}
        startAt={0}
      />

      {hidden ? (
        <div></div>
      ) : icon ? (
        <Tooltip placement={'bottom'} title={'Tutorial'}>
          <QuestionCircleTwoTone
            onClick={() => !isTourOpen && setTour(true)}
            style={{ alignSelf: 'center', marginLeft: '10px' }}
          />
        </Tooltip>
      ) : (
        <Text onClick={() => !isTourOpen && setTour(true)}>Tutorial</Text>
      )}
    </>
  );
}

export default Tour;
