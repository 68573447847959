import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

const ContentWheel = lazy(() => import('.'));

export type ContentWheelRouteProps = AdminRouteBaseProps;

const ContentWheelRoutes: Route<ContentWheelRouteProps>[] = [
  {
    path: 'portally-presents',
    id: 'ContentWheel',
    element: <ContentWheel />,
    meta: {
      noFrame: true,
    },
  },
];

export default ContentWheelRoutes;
