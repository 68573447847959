import dayjs from 'dayjs';

import {
  LEASE_AGREEMENT_FILTER,
  UPDATE_ACTIVITY_LOG_FILTER,
  UPDATE_CRM_PAGINATION,
  UPDATE_EVENT_FILTER,
  UPDATE_GATE_SALES_FILTER,
  UPDATE_GATE_TABKEY,
  UPDATE_MAILLOG_FILTER,
  UPDATE_SEARCH_FILTER,
  UPDATE_SEARCH_REQUEST_FILTER,
  UPDATE_SEARCH_REQUEST_PAGINATION,
  UPDATE_USER_FILTER,
} from '@/state/admin/actions';
const now = new Date();

export const gateSalesFilter = {
  startDate: dayjs(new Date(now.getFullYear(), 0, 1)),
  endDate: dayjs(new Date(now.getFullYear(), 11, 31)),
  clientManagerId: undefined,
  division: undefined,
  weightedValues: false,
  step: undefined,
  period: 'week',
  client: undefined,
  source: undefined,
};

const initialState = {
  searchValues: {},
  userFilter: {
    name: '',
    roles: [] as string[],
    prospect: false,
    clientType: [],
    pagination: {
      page: 1,
      rowsPerPage: 10,
    },
    createdFrom: undefined,
    activityStatus: [],
    regionIds: [],
    size: [],
    usageCategories: [],
    userCategory: [],
  },
  leaseAgreementFilter: {
    hasInterestedClients: false,
    hasNoContactPerson: false,
    onlyVitec: false,
    onlyPrioritized: false,
    onlyAvailable: false,
    onlyNotAvailable: false,
    client: undefined,
    regionIds: [],
    streets: [],
    usageCategory: undefined,
    limit: 48,
    min: undefined,
    max: undefined,
  },
  searchRequestFilter: {
    from: undefined,

    to: undefined,
    client: undefined,
    regionIds: [],
    usageCategory: undefined,
    onlyDisabled: false,
    disabledReason: [],
    onlyActive: false,
    onlyFavourites: false,
    onlyPassive: false,
    min: 0,
    max: 100000,
    adminResponsibleUserIds: [],
    adminStatus: [],
  },
  searchRequestPagination: {
    page: 1,
    rowsPerPage: 10,
  },
  mailLogFilter: {
    email: '',
    template: '',
    mailWaiting: false,
    subject: '',
    from: undefined,
    to: undefined,
    delivered: true,
    pagination: {
      page: 1,
      rowsPerPage: 10,
    },
  },
  eventFilter: {
    type: undefined,
    client: undefined,
    responsibleConsultantId: undefined,
    done: undefined,
    from: dayjs().startOf('month').subtract(2, 'months'),
  },
  crmPagination: {
    page: 1,
    rowsPerPage: 10,
  },
  activityFilter: {
    type: [],
  },
  gateTabkey: '1',
  gateSalesFilter,
};

export type AdminState = typeof initialState;

export default function searchReducer(
  state = initialState,
  action,
): AdminState {
  switch (action.type) {
    case UPDATE_SEARCH_FILTER:
      return {
        ...state,
        searchValues: action.values,
      };

    case UPDATE_USER_FILTER:
      return {
        ...state,
        userFilter: {
          ...state.userFilter,
          [action.name]: action.value,
        },
      };
    case LEASE_AGREEMENT_FILTER:
      return {
        ...state,
        leaseAgreementFilter: {
          ...state.leaseAgreementFilter,
          [action.name]: action.value,
        },
      };
    case UPDATE_SEARCH_REQUEST_FILTER:
      return {
        ...state,
        searchRequestFilter: {
          ...state.searchRequestFilter,
          [action.name]: action.value,
        },
      };
    case UPDATE_CRM_PAGINATION:
      return {
        ...state,
        crmPagination: action.pagination,
      };
    case UPDATE_MAILLOG_FILTER:
      return {
        ...state,
        mailLogFilter: action.filter,
      };
    case UPDATE_GATE_TABKEY:
      return {
        ...state,
        gateTabkey: action.value,
      };
    case UPDATE_GATE_SALES_FILTER:
      return {
        ...state,
        gateSalesFilter: {
          ...state.gateSalesFilter,
          [action.name]: action.value,
        },
      };
    case UPDATE_ACTIVITY_LOG_FILTER:
      return {
        ...state,
        activityFilter: action.filter,
      };
    case UPDATE_EVENT_FILTER:
      return {
        ...state,
        eventFilter: action.eventFilter,
      };
    case UPDATE_SEARCH_REQUEST_PAGINATION:
      return {
        ...state,
        searchRequestPagination: action.pagination,
      };
    default:
      return state;
  }
}
