import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import AdminRoutes, { AdminRouteProps } from '@/layout/Admin/routes';
import CustomerRoutes, { CustomerRouteProps } from '@/layout/Customer/routes';
import UnauthenticatedRoutes, {
  UnauthenticatedRouteProps,
} from '@/layout/Unauthenticated/routes';
import Page404 from '@/views/Page404';
import PortallyPartnerRoutes, {
  PortallyPartnerRouteProps,
} from '@/views/PortallyPartner/routes';

export type RouteMetaProps = {
  whiteBackground?: boolean;
  whiteBackgroundPhone?: boolean;
  noPadding?: boolean;
  fixedSider?: boolean;
  noPaddingPhone?: boolean;
};

export type BasicRouteProps = MakeGenerics<{
  RouteMeta: RouteMetaProps;
}>;

export type RouteProps = UnauthenticatedRouteProps &
  BasicRouteProps &
  PortallyPartnerRouteProps &
  CustomerRouteProps &
  AdminRouteProps;

const routes: Route<RouteProps>[] = [
  ...PortallyPartnerRoutes,
  ...AdminRoutes,
  ...UnauthenticatedRoutes,
  ...CustomerRoutes,
  {
    path: '*',
    element: <Page404 />,
  },
];
export default routes;
