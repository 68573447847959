import { useApolloClient } from '@apollo/client';
import { message, notification, Typography } from 'antd';
import React from 'react';
import { getI18n, useTranslation } from 'react-i18next';

import { MySubscriptionRequest } from '@/components/SubscriptionRequest';
import Form from '@/components/SubscriptionRequest/Form';
import CommissionLandlord from '@/components/SubscriptionRequest/V1/CommissionLandlord';
import { useAcceptSubscriptionRequestMutation } from '@/generated/graphql';
import { refetchUser } from '@/hooks/useAuth';
import formatDate from '@/utils/formatDate';

interface Props {
  mySubscriptionRequest: MySubscriptionRequest;
}

function V1({ mySubscriptionRequest }: Props): JSX.Element {
  const { t } = useTranslation();
  const i18n = getI18n();
  const apolloClient = useApolloClient();
  const [answer, { loading }] = useAcceptSubscriptionRequestMutation({
    onCompleted: () => {
      notification.success({
        message: t('Thank you for using Portally'),
        placement: 'topRight',
      });
      void refetchUser(apolloClient);
    },
    onError: () => {
      message.error(t('Error'));
    },
  });

  const accepted = mySubscriptionRequest.status === 'accepted';
  return (
    <div>
      <>
        <CommissionLandlord lng={i18n.language} />
      </>
      {accepted && (
        <Typography.Paragraph type={'secondary'}>
          {t('Terms accepted')} {formatDate(mySubscriptionRequest.acceptedDate)}
        </Typography.Paragraph>
      )}
      <Form
        answer={answer}
        loading={loading}
        id={mySubscriptionRequest.id}
        accepted={accepted}
      />
    </div>
  );
}

export default V1;
