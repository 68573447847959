import { MakeGenerics, Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type TermsOfServiceAdminRouteProps = MakeGenerics<{
  Params: {
    termsOfServiceId?: string;
  };
  LoaderData: {
    termsOfServiceId: string;
  };
}> &
  AdminRouteBaseProps;

const List = lazy(() => import('.'));
const Edit = lazy(() => import('./EditTerms'));
const Add = lazy(() => import('./AddTermsOfService'));

const TermsOfServiceAdminRoutes: Route<TermsOfServiceAdminRouteProps>[] = [
  {
    path: 'terms',
    id: 'Terms',
    meta: {
      breadcrumbs: () => ({
        breadcrumb: 'Användarvillkor',
      }),
    },
    children: [
      {
        path: '/',
        id: 'TermsOfServiceAdminList',
        element: <List />,
      },
      {
        path: '/add',
        id: 'TermsOfServiceAdminAdd',
        element: <Add />,
        meta: {
          breadcrumbs: () => ({
            breadcrumb: 'Lägg till',
          }),
        },
      },
      {
        path: ':termsOfServiceId',
        id: 'TermsOfServiceAdminEdit',
        children: [
          {
            path: '/',
            element: <Edit />,
            loader: ({ params }) => ({
              termsOfServiceId: params.termsOfServiceId,
            }),
            meta: {
              breadcrumbs: () => ({
                breadcrumb: 'Redigera',
              }),
            },
          },
        ],
      },
    ],
  },
];

export const toTermsOfServiceAdmin = (): string => '/admin/terms/';
export const toTermsOfServiceAdminEdit = (termsOfServiceId: string): string =>
  `/admin/terms/${termsOfServiceId}`;
export const toTermsOfServiceAdminAdd = (): string => '/admin/terms/add';

export default TermsOfServiceAdminRoutes;
