import { Home } from '@styled-icons/entypo/Home';
import { Notifications as NotificationOutlined } from '@styled-icons/material-outlined/Notifications';
import {
  Dashboard,
  FavoriteBorder,
  Search,
} from '@styled-icons/material-rounded';
import { useMatchRoute } from '@tanstack/react-location';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MessageLink from '@/components/MessageLink';
import Notifications from '@/components/Notifications';
import { User } from '@/hooks/useUser';
import ActiveSearchRequests from '@/layout/Customer/LeftMenu/ActiveSearchRequests';
import { toActiveSearchRequests } from '@/views/ActiveSearchRequests/routes';
import { toEstablishment } from '@/views/Establishment/routes';
import { toHome } from '@/views/Home/routes';
import { toLeaseAgreementsProspects } from '@/views/LeaseAgreementProspects/routes';
import { toMyLeaseAgreements } from '@/views/Management/routes';
import { toNotifications } from '@/views/Notifications/routes';
import { toSearchRequestLandlord } from '@/views/SearchRequestLandlord/route';

import Account from './Account';
import {
  Grow,
  Inner,
  Logo,
  NavLink,
  StyledContainer,
  StyledSider,
} from './index.styled';
import LeaseAgreementProspects from './LeaseAgreementProspects';
import Support from './Support';

interface Props {
  user: User;
  fixed?: boolean;
}

function LeftMenu({ user, fixed }: Props): JSX.Element {
  const { t } = useTranslation();

  const match = useMatchRoute();

  const isLandlord = user?.activeClient?.type === 'landlord';
  const isPartner = user?.isPartner;
  const tools = isLandlord
    ? [
        {
          icon: Home,
          text: 'My facilities',
          to: toMyLeaseAgreements(),
          className: 'tour-management',
        },
        {
          component: LeaseAgreementProspects,
          text: 'Expressions of interest',
          to: toLeaseAgreementsProspects(),
        },
        {
          icon: Search,
          text: 'Search requests',
          to: toSearchRequestLandlord(),
          className: 'tour-prospect',
        },
        {
          component: ActiveSearchRequests,
          text: 'Answered search requests',
          to: toActiveSearchRequests(),
        },
      ]
    : [
        {
          icon: FavoriteBorder,
          text: 'Favourites',
          to: toEstablishment(),
          className: 'tour-establishment',
        },
        {
          icon: Home,
          text: 'My facilities',
          to: toMyLeaseAgreements(),
          className: 'tour-management',
        },
      ];

  return (
    <StyledSider $fixed={fixed}>
      <Inner>
        <StyledContainer $wide={!!match({ to: toHome() })} id="leftSidebar">
          <Logo to={toHome()} />

          <NavLink to={toHome()} className={'tour-home'}>
            <Dashboard />
            <span className="text">{t('Dashboard')}</span>
          </NavLink>
          <MessageLink />
          <Notifications>
            <NavLink to={toNotifications()} disabled className="dropdown">
              <NotificationOutlined />
              <span className="text">{t('Notifications')}</span>
            </NavLink>
          </Notifications>
          <Grow />
          {tools.map(item => {
            if (item.text === 'My facilities' && isPartner) {
              return null;
            }
            if (item.component) {
              return (
                <>
                  <item.component key={item.text} />
                  {item.text === 'Expressions of interest' && <Grow />}
                </>
              );
            } else {
              return (
                <NavLink
                  to={item.to}
                  key={item.text}
                  className={item.className}
                >
                  <div style={{ fontSize: '20px' }}>
                    <item.icon />
                  </div>
                  <span className="text">{t(item.text)}</span>
                </NavLink>
              );
            }
          })}
          <Grow />

          <Support key={'support'} />
          <Account key={'account'} />
        </StyledContainer>
      </Inner>
    </StyledSider>
  );
}

export default LeftMenu;
