import { PlusOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface Props {
  onClick: () => void;
  text: string;
  icon?: React.FunctionComponent;
  minHeight: string;
}

const Button = styled(AntButton)<{ $minHeight: string }>`
  border: 1px dashed #d9d9d9;
  height: 100%;
  min-height: ${({ $minHeight }) => $minHeight};
  cursor: pointer;
`;

const Wrapper = styled.div`
  .ant-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

function ButtonCard({
  text,
  onClick,
  icon: Icon = PlusOutlined,
  minHeight,
}: Props): JSX.Element {
  return (
    <Wrapper>
      <Button
        block
        ghost
        type={'primary'}
        onClick={onClick}
        $minHeight={minHeight}
      >
        <Icon /> {text}
      </Button>
    </Wrapper>
  );
}

export default ButtonCard;
