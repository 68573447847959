import styled from 'styled-components';

import Box from '@/components/Box';

export const Container = styled.div<{ $height: string }>`
  display: flex;
  flex-direction: column;
  height: ${({ $height }) => $height};
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: calc(100vh - 110px);
  }
`;

export const Header = styled.div`
  padding: 12px 24px 0 24px;
  display: flex;
  > img {
    max-height: 90px;
    width: auto;
    margin: -15px 15px -15px -15px;
  }
`;

export const Name = styled.h2`
  margin-bottom: 0;
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const Ellipsis = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
