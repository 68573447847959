import { useEffect, useState } from 'react';

interface WidthAndHeight {
  width: number;
  height: number;
  isMini: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
}

/*
  giant: 1200,
  desktop: 992,
  tablet: 768,
  phone: 576,
  smaller: 480,
*/

const getWidthAndHeight = (): WidthAndHeight => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    isMini: width <= 480,
    isMobile: width <= 768,
    isTablet: width <= 968,
    isLaptop: width <= 1024,
    isDesktop: width <= 1200,
  };
};

export default function useWidthAndHeight(): WidthAndHeight {
  const [widthAndHeight, setWidthAndHeight] = useState(getWidthAndHeight());

  useEffect(() => {
    const handleResize = (): void => setWidthAndHeight(getWidthAndHeight());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return widthAndHeight;
}
