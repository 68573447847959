import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

const Prospects = lazy(() => import('.'));
const Filter = lazy(() => import('./Filter'));

export type ProspectsRouteProps = AdminRouteBaseProps;

const ProspectsRoutes: Route<ProspectsRouteProps>[] = [
  {
    path: 'prospects',
    id: '',
    element: <Prospects />,
    meta: {
      sider: <Filter />,
    },
  },
];

export const toProspects = (): string => '/admin/prospects';

export default ProspectsRoutes;
