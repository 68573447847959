// get url from string

import dayjs from 'dayjs';
import React from 'react';

import Box from '@/components/Box';
import LinkPreview from '@/components/LinkPreview';
import { MessageValuesFragment } from '@/generated/graphql';
import { User } from '@/hooks/useUser';

const getUrls = (str: string): string[] => {
  const regex = /https?:\/\/(?![^<>]*>|[^"]*?")\S+/g;
  const urls = str.match(regex);

  return urls;
};

const isUrl = (str: string): boolean => {
  const regex = /^https?:\/\/[^\s]+$/i;
  return regex.test(str);
};

export const containsUrl = (str: string): boolean => {
  const regex = /https?:\/\/[^\s]+/g;
  return regex.test(str);
};

export const splitMessageIntoLinks = (
  message: string,
  mine: boolean,
): JSX.Element => {
  const messages: string[] = [];
  const urlsInMessage = getUrls(message);

  if (urlsInMessage?.length > 0) {
    let messageAcc = message;

    urlsInMessage.map((url, i) => {
      messageAcc = messageAcc.replace(url, `URL${i}`);
    });
    urlsInMessage.map((url, i) => {
      if (messageAcc) {
        const split = messageAcc.split(`URL${i}`);

        messages.push(split[0]);
        messages.push(url);

        if (split.length > 1) {
          split.shift();
          messageAcc = split.join('');
          if (i === urlsInMessage.length - 1) {
            messages.push(messageAcc);
          }
        } else {
          messageAcc = '';
        }
      }
    });
    const filteredMessages = messages.filter(m => !!m);
    return (
      <>
        {filteredMessages.map((message, i) => {
          if (isUrl(message)) {
            return (
              <span key={message + i} className="message-embed link">
                <LinkPreview
                  key={i}
                  url={message
                    .replace('&nbsp;', '')
                    .replace('</a>', '')
                    .replace('</p>', '')}
                  mine={mine}
                />
              </span>
            );
          }
          return (
            <span
              key={i + message}
              className={'message-embed'}
              dangerouslySetInnerHTML={{
                __html: message.replace(/(?:\r\n|\r|\n)/g, '<br />'),
              }}
            />
          );
        })}
      </>
    );
  } else {
    return (
      <span
        className={'message-embed'}
        dangerouslySetInnerHTML={{
          __html: message.replace(/(?:\r\n|\r|\n)/g, '<br />'),
        }}
      />
    );
  }
};

interface ParsedMessage {
  showAvatar: boolean;
  showName: boolean;
  showTimestamp: boolean;
  showDate: boolean;
  showRead: boolean;
  message: MessageValuesFragment;
  showMarkAsAnswered: boolean;
}

interface Props {
  messages: MessageValuesFragment[];
  reversed?: boolean;
}
export const parseMessages = ({
  messages,
  reversed = false,
}: Props): ParsedMessage[] => {
  const parsedMessages: ParsedMessage[] = [];
  let currentSender: string | undefined;

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    const nextMessage = i > 0 ? messages[i - 1] : undefined;
    const previousMessage =
      i < messages.length - 1 ? messages[i + 1] : undefined;
    const parsedMessage: ParsedMessage = {
      message,
      showAvatar: false,
      showDate: false,
      showName: false,
      showRead: false,
      showTimestamp: true,
      showMarkAsAnswered: false,
    };

    if (currentSender !== message.senderUser?.id) {
      currentSender = message.senderUser?.id;
      parsedMessage.showAvatar = true;
    }

    if (nextMessage?.senderUser?.id !== message.senderUser?.id) {
      parsedMessage.showAvatar = true;
    } else {
      parsedMessage.showAvatar = false;
    }
    if (previousMessage?.senderUser?.id !== message.senderUser?.id) {
      parsedMessage.showName = true;
    }

    const nextMessagedate = dayjs(nextMessage?.created);

    const nextMessageIsWithin3Minutes = nextMessagedate.isBefore(
      dayjs(message.created).add(3, 'minute'),
    );

    if (!nextMessageIsWithin3Minutes) {
      parsedMessage.showAvatar = true;
    }

    const previousMessagedate = dayjs(previousMessage?.created);
    const previousMessageIsWithin3Minutes = previousMessagedate.isAfter(
      dayjs(message.created).subtract(3, 'minute'),
    );

    if (previousMessage && !previousMessageIsWithin3Minutes) {
      parsedMessage.showName = true;
    }

    const previousMessageIsSameDay = previousMessagedate.isSame(
      dayjs(message.created),
      'day',
    );
    if (previousMessage && !previousMessageIsSameDay) {
      parsedMessage.showDate = true;
    }
    if (i === messages.length - 1) {
      parsedMessage.showDate = true;
      parsedMessage.showRead = true;
    }

    parsedMessages.push(parsedMessage);
  }
  if (reversed) {
    return parsedMessages.reverse();
  }

  return parsedMessages;
};
