import { Checkbox, Skeleton, Table } from 'antd';
import { LabeledValue } from 'antd/es/select';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteField } from '@/components/AutoCompleteField';
import InfiniteScroll from '@/components/InfiniteScroll';
import LeaseAgreementListPreview from '@/components/LeaseAgreementListPreview';
import LeaseAgreementSize from '@/components/LeaseAgreementSize';
import InfiniteScrollLoader from '@/components/Loaders/InfiniteScrollLoader';
import PortallyEmpty from '@/components/PortallyEmpty';
import {
  LeaseAgreementFilter,
  LeaseAgreementListPreviewValuesFragment,
  SearchRequestReferenceMatchValuesFragment,
  StreetAutocompleteDocument,
  useGetMySelectLeaseAgreementsQuery,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';

export interface SelectedLeaseAgreement {
  id: string;
  name: string;
}

interface Props {
  searchRequestReference?: SearchRequestReferenceMatchValuesFragment;
  setSelectedLeaseAgreements: Dispatch<
    SetStateAction<SelectedLeaseAgreement[]>
  >;
  selectedLeaseAgreements: SelectedLeaseAgreement[];
  className?: string;
}

function SelectLeaseAgreements({
  searchRequestReference,
  setSelectedLeaseAgreements,
  selectedLeaseAgreements,
  className,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const user = useUser();
  const [matching, setMatching] = useState(false);

  const [street, setStreet] = useState<LabeledValue | undefined>(undefined);
  const [limit, setLimit] = useState(15);

  const getInput = useCallback(() => {
    const variables: LeaseAgreementFilter = { clientId: user?.activeClientId };
    let regionIds: string[] = [];
    if (matching && searchRequestReference) {
      variables.size = searchRequestReference.searchParameters.size;
      regionIds = [
        ...regionIds,
        ...searchRequestReference.searchParameters.regionIds,
      ];
      variables.usageCategories =
        searchRequestReference.searchParameters.usageCategories;
    }
    if (street) {
      typeof street.value === 'string' && regionIds.push(street.value);
    }

    return { ...variables, regionIds };
  }, [matching, searchRequestReference?.searchParameters, street]);

  const { data, loading, previousData } = useGetMySelectLeaseAgreementsQuery({
    variables: {
      input: getInput(),
      bounds: undefined,
      zoom: 20,
      offset: 0,
      limit,
    },
  });

  const leaseAgreements = (data ?? previousData)?.leaseAgreements ?? {
    items: [],
    count: 0,
  };

  const initialLoad = loading && leaseAgreements.count === 0;

  return (
    <div
      className={clsx(
        'flex h-full flex-grow flex-col overflow-y-auto',
        className,
      )}
    >
      <div
        className={
          'flex flex-wrap-reverse gap-2 items-baseline justify-between'
        }
      >
        <div className={'max-w-xs w-full mb-2'}>
          <AutoCompleteField
            label={t('Search among my premises')}
            style={{ width: '100%' }}
            query={StreetAutocompleteDocument}
            onChange={valueObject => {
              const val = valueObject as LabeledValue;
              if (!val) {
                setStreet(undefined);
              } else {
                setStreet(val);
              }
            }}
            value={street}
            returnName={'streets'}
            clientId={user?.activeClientId}
            dropdownClassName={
              'prevent-active-request-drawer-close prevent-request-drawer-close'
            }
          />
        </div>
        {searchRequestReference && (
          <Checkbox
            defaultChecked={matching}
            onChange={e => setMatching(e.target.checked)}
          >
            {t('Only show spaces that match')}
          </Checkbox>
        )}
      </div>
      <div className={'flex min-h-0 flex-grow flex-col w-full overflow-y-auto'}>
        <InfiniteScroll
          loadMore={() => {
            !loading && setLimit(limit + 10);
          }}
          hasMore={leaseAgreements.count >= limit}
          useWindow={false}
          loader={<InfiniteScrollLoader key={'infinite-scroll-messages'} />}
          loading={loading}
        >
          {initialLoad && <Skeleton active />}
          {!initialLoad && (
            <Table<LeaseAgreementListPreviewValuesFragment>
              showHeader={false}
              locale={{
                emptyText: <PortallyEmpty description={t('No spaces found')} />,
              }}
              pagination={false}
              rowSelection={{
                selectedRowKeys: selectedLeaseAgreements.map(lease => lease.id),
                preserveSelectedRowKeys: true,
                hideSelectAll: true,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedLeaseAgreements(
                    selectedRows.map(row => ({ id: row.id, name: row.name })),
                  );
                },
              }}
              expandable={{
                expandedRowRender: (
                  leaseAgreement: LeaseAgreementListPreviewValuesFragment,
                ) => (
                  <LeaseAgreementListPreview leaseAgreement={leaseAgreement} />
                ),
              }}
              columns={[
                {
                  title: t('facility'),
                  dataIndex: 'name',
                  render: (value, row) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/lease-agreement/${row.id}`}
                    >
                      {value}
                    </a>
                  ),
                },
                {
                  title: t('size'),
                  dataIndex: 'size',
                  render: (size, row) => (
                    <LeaseAgreementSize
                      size={size}
                      sizeSpan={row.sizeSpan}
                      showNotSpecified
                    />
                  ),
                },
              ]}
              dataSource={leaseAgreements.items.map(item => ({
                key: item.id,
                ...item,
              }))}
            />
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default SelectLeaseAgreements;
