import { useMatches } from '@tanstack/react-location';
import { Link } from '@tanstack/react-location';
import { Menu } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AdminRouteProps } from '@/layout/Admin/routes';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import backgroundUrl from '@/layout/Portally_logo_coral.png';
import { toAdminHome } from '@/views/AdminHome/routes';
import { toClientList } from '@/views/Client/adminRoutes';
import { toDeals } from '@/views/Deals/routes';
import { toEmailLog } from '@/views/EmailLog/routes';
import { toEstatesAdmin } from '@/views/EstatesAdmin/routes';
import { toHome } from '@/views/Home/routes';
import { toIntegrationProviders } from '@/views/IntegrationProvider/routes';
import { toLeaseAgreementsProspectsAdmin } from '@/views/LeaseAgreementProspectsAdmin/routes';
import { toLeaseAgreementsAdmin } from '@/views/LeaseAgreementsAdmin/routes';
import { toLeaseAgreementViewsAdmin } from '@/views/LeaseAgreementViewsAdmin/routes';
import { toAdminOverview } from '@/views/Overview/routes';
import { toPortallyPartnersAdmin } from '@/views/PortallyPartners/routes';
import { toPreliminarySearchRequests } from '@/views/PreliminarySearchRequests/routes';
import { toProjectsAdmin } from '@/views/Projects/routes';
import { toProspects } from '@/views/Prospects/routes';
import { toRegionsAdmin } from '@/views/RegionsAdmin/routes';
import { toSaleClientsAdmin } from '@/views/SaleClientsAdmin/routes';
import { toSearchRequestsAdmin } from '@/views/SearchRequestsAdmin/routes';
import { toStatistics } from '@/views/Statistics/routes';
import { toSubscriptionRequests } from '@/views/SubscriptionRequest/routes';
import { toTermsOfServiceAdmin } from '@/views/TermsOfServiceAdmin/routes';
import { toUsers } from '@/views/Users/adminRoutes';

const SubMenu = Menu.SubMenu;
export const Logo = styled(Link)`
  position: absolute;
  top: 8px;
  right: 20px;
  background-image: url('${backgroundUrl}');
  width: 48px;
  background-size: 48px auto;
  height: 48px;
  background-repeat: no-repeat;
`;

const getSelectedKeys = (pathname: string): string[] => {
  if (pathname.includes(toClientList())) {
    return ['/admin/crm/'];
  }
  if (pathname.includes(toUsers())) {
    return ['/admin/users/'];
  }
  return [pathname];
};

function Navbar(): JSX.Element {
  const { t } = useTranslation();
  const matches = useMatches<AdminRouteProps>();
  const match = matches[matches.length - 1];

  return (
    <>
      <Menu selectedKeys={getSelectedKeys(match.pathname)} mode="horizontal">
        <SubMenu title={<span>Admin</span>}>
          <Menu.Item key={toAdminHome()}>
            <Link to={toAdminHome()}>Hem</Link>
          </Menu.Item>
          <Menu.Item key={toClientList()}>
            <Link to={toClientList()}>{t('CRM')}</Link>
          </Menu.Item>
          <Menu.Item key={toUsers()}>
            <Link to={toUsers()}>{t('users')}</Link>
          </Menu.Item>
          <Menu.Item key={toAdminOverview()}>
            <Link to={toAdminOverview()}>{'Överblick'}</Link>
          </Menu.Item>
          <Menu.Item key={toIntegrationProviders()}>
            <Link to={toIntegrationProviders()}>{'Integrationer'}</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu title={<span>Verktyg</span>}>
          <Menu.Item key={toLeaseAgreementsAdmin()}>
            <Link to={toLeaseAgreementsAdmin()}>{'Lokaler'}</Link>
          </Menu.Item>
          <Menu.Item key={toLeaseAgreementViewsAdmin()}>
            <Link to={toLeaseAgreementViewsAdmin()}>{'Visningar'}</Link>
          </Menu.Item>
          <Menu.Item key={toDeals()}>
            <Link to={toDeals()}>Affärer</Link>
          </Menu.Item>

          <Menu.Item key={toSearchRequestsAdmin()}>
            <Link to={toSearchRequestsAdmin()}>Lokalförfrågningar</Link>
          </Menu.Item>
          <Menu.Item key={toLeaseAgreementsProspectsAdmin()}>
            <Link to={toLeaseAgreementsProspectsAdmin()}>
              Intresseanmälningar
            </Link>
          </Menu.Item>
          <Menu.Item key={toPreliminarySearchRequests()}>
            <Link to={toPreliminarySearchRequests()}>
              Inkommande lokalförfrågningar
            </Link>
          </Menu.Item>
          <Menu.Item key={toSubscriptionRequests()}>
            <Link to={toSubscriptionRequests()}>Abonnemang</Link>
          </Menu.Item>
          <Menu.Item key={toPortallyPartnersAdmin()}>
            <Link to={toPortallyPartnersAdmin()}>{'Portally partners'}</Link>
          </Menu.Item>
          <Menu.Item key={toRegionsAdmin()}>
            <Link to={toRegionsAdmin()}>{'Regioner'}</Link>
          </Menu.Item>
          <Menu.Item key={toEstatesAdmin()}>
            <Link to={toEstatesAdmin()}>{'Fastigheter'}</Link>
          </Menu.Item>
          <Menu.Item key={toSaleClientsAdmin()}>
            <Link to={toSaleClientsAdmin()}>Säljstegen</Link>
          </Menu.Item>
          <Menu.Item key={toProjectsAdmin()}>
            <Link to={toProjectsAdmin()}>Projekt</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu title={<span>Rapporter</span>}>
          <Menu.Item key={toStatistics()}>
            <Link to={toStatistics()}>Statistik</Link>
          </Menu.Item>
          <Menu.Item key={toEmailLog()}>
            <Link to={toEmailLog()}>Email</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu title={<span>GATE46</span>}>
          <Menu.Item key={toProspects()}>
            <Link to={toProspects()}>{t('sales')}</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu title={<span>Inställningar</span>}>
          <Menu.Item key={toTermsOfServiceAdmin()}>
            <Link to={toTermsOfServiceAdmin()}>Användarvillkor</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>

      <Logo to={toHome()} />
    </>
  );
}

export default Navbar;
