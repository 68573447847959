import { ScriptableContext } from 'chart.js';

export const colorSet = [
  'rgba(255, 115, 105, 0.7)',
  'rgba(160, 140, 238, 0.7)',
  '#33c1cec4',
  '#445A8Ac4',
  '#c90076c4',
  '#f78e3dc4',
  '#948aecc4',
  '#3dbd7dc4',
  '#f46e65c4',
];

export interface Doughnut {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}

export interface LineGraph {
  labels: string[];
  datasets: {
    label: string;
    fill: boolean | string | number;
    labels: string[];
    steppedLine: boolean;
    lineTension: number;
    borderColor: string;
    borderWidth: number;
    BackgroundColor?: any;
    data: number[];
    pointHitRadius: number;
  }[];
}

export interface GraphData {
  values?: number[];
  labels?: string[];
  title?: string;
}

export const areaGraphData = (values: GraphData[]): LineGraph => {
  const returnVal = {
    //labels: labels.slice(Math.max(values.length - days, 0)),
    labels: values[0].labels,
    datasets: values.map(({ values, labels, title }, i) => ({
      label: title,
      fill: 'start',
      labels,
      steppedLine: false,
      lineTension: 0.3,
      borderColor: colorSet[1],
      backgroundColor: (context: ScriptableContext<'line'>) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 135);
        gradient.addColorStop(0, 'rgba(160, 140, 238, 0.9)');
        gradient.addColorStop(1, 'rgba(160, 140, 238, 0)');
        return gradient;
      },
      borderWidth: 3,
      data: values,
      pointHitRadius: 20,
    })),
  };
  return returnVal;
};

export const lineGraphData = (values: GraphData[]): LineGraph => ({
  //labels: labels.slice(Math.max(values.length - days, 0)),
  labels: values[0].labels,
  datasets: values.map(({ values, labels, title }, i) => ({
    label: title,
    fill: false,
    labels,
    steppedLine: false,
    lineTension: 0.3,
    borderColor: colorSet[i],
    borderWidth: 3,
    data: values,
    pointHitRadius: 20,
  })),
});

export const doughnutOptions = {
  cutoutPercentage: 70,
  responsive: true,
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
};
export const pieOptions = {
  cutoutPercentage: 0,
  borderColor: 'white',
  borderWidth: '2px',
  plugins: {
    datalabels: {
      display: true,
      color: 'black',
      formatter: function (value) {
        return `${value}%`;
      },
    },
  },
  responsive: true,
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
};
export const lineOptions = {
  hover: {
    intersect: false,
    animationDuration: 0,
  },
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const index = tooltipItem.index;
        return ` ${dataset.labels[index]} : ${dataset.data[index]}`;
      },
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
        lineWidth: 9,
      },
      ticks: { display: false },
    },
    y: {
      display: false, // Hide the y-axis numbers
      min: -1, // Set the minimum value of the y-axis scale

      angleLinse: {
        display: false,
      },
      grid: {
        color: 'blue',
        drawBorder: false,
        display: false,
        lineWidth: 4,
      },
    },
  },
  aspectRatio: 3,
  //maintainAspectRatio: false,
  title: {
    display: true,
    text: '',
    fontSize: 20,
    responsive: true,
  },
  legend: {
    display: true,
  },
};
