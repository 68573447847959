import React, { useEffect } from 'react';

import useAppSelector from '@/hooks/useAppSelector';
import theme from '@/main/theme';

import { useUnreadMessagesStore } from '../MessageLink';

interface Props {
  title?: string;
}

function MetaTitle({ title }: Props): JSX.Element {
  const metaTitle = title
    ? `${theme.header.metaTitlePrefix}${title}`
    : theme.header.defaultMetaTitle;
  const { unreadConversations, unreadPartnerConversations } =
    useUnreadMessagesStore();

  const unreadNotifications = useAppSelector(
    state => state.customer.unreadNotifications,
  );

  useEffect(() => {
    const total =
      unreadConversations + unreadPartnerConversations + unreadNotifications;
    const overFive = total > 5;
    const number = total && total !== 0 ? `(${overFive ? '5+' : total}) ` : '';
    document.title = `${number}${metaTitle}`;
    return () => {
      document.title = `${number}${theme.header.defaultMetaTitle}`;
    };
  }, [unreadConversations, unreadPartnerConversations, unreadNotifications]);

  return null;
}

export default React.memo(MetaTitle);
