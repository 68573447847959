import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

const List = lazy(() => import('./List'));

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type IntegrationProviderRouteProps = AdminRouteBaseProps;

const IntegrationProviderRoutes: Route<IntegrationProviderRouteProps>[] = [
  {
    path: '/integration-provider/',
    id: 'IntegrationProvider',
    children: [
      {
        path: '/',
        id: 'IntegrationProviders',
        element: <List />,
      },
    ],
  },
];

export const toIntegrationProviders = (): string =>
  `/admin/integration-provider/`;

export default IntegrationProviderRoutes;
