import { Route } from '@tanstack/react-location';
import React from 'react';

const Settings = React.lazy(() => import('./Settings'));
import { BasicRouteProps } from '@/layout/routes';

export type UserRouteProps = BasicRouteProps;

const UserRoutes: Route<UserRouteProps>[] = [
  {
    path: '/settings',
    id: 'settings',
    element: <Settings />,
    meta: {
      noPaddingPhone: true,
      whiteBackgroundPhone: true,
    },
  },
];

export const toUserSettings = (): string => '/settings';

export default UserRoutes;
