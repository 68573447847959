import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

const Messages = React.lazy(() => import('.'));

export type MessagesRouteProps = MakeGenerics<
  {
    Params: {
      conversationId?: string;
    };
    LoaderData: {
      conversationId: string;
    };
  } & BasicRouteProps
>;

const MessagesRoutes: Route<MessagesRouteProps>[] = [
  {
    path: '/messages',
    id: 'Messages',
    children: [
      {
        path: '/',
        element: <Messages />,
        meta: {
          noPaddingPhone: true,
          whiteBackgroundPhone: true,
        },
      },
      {
        path: ':conversationId',
        element: <Messages />,
        meta: {
          noPaddingPhone: true,
          whiteBackgroundPhone: true,
        },
        loader: ({ params }) => ({
          conversationId: params.conversationId,
        }),
      },
    ],
  },
];

export const toMessages = (id?: string): string =>
  id ? `/messages/${id}` : '/messages';

export default MessagesRoutes;
