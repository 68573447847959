import { Navigate, useMatch } from '@tanstack/react-location';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';
import Spinner from '@/components/Spinner';
import { useLoginWithTokenMutation } from '@/generated/graphql';
import useAuth from '@/hooks/useAuth';
import { AuthenticateRouteProps } from '@/views/Authenticate/routes';
import { toHome } from '@/views/Home/routes';
import { toSearchRequestTenant } from '@/views/SearchRequestTenant/routes';
import InvalidLinkError from '@/views/Unauthenticated/InvalidLinkError';

const CenteredContainer = styled(Box).attrs({
  w: '100%',
  h: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flex: true,
})``;

interface Props {
  redirect: string;
}
function Authenticate({ redirect = toHome() }: Props): JSX.Element {
  const { authenticated, loading, error } = useAuth();

  if (error) {
    return <InvalidLinkError />;
  }

  if (loading) {
    return <Spinner expand />;
  }

  if (authenticated) {
    return <Navigate to={redirect} />;
  }

  return <div />;
}

function Proxy(): JSX.Element {
  const { token } = useMatch<AuthenticateRouteProps>().data;

  const [loginWithToken, { loading, data, error }] = useLoginWithTokenMutation({
    variables: {
      token,
    },
    onCompleted: async data => {
      localStorage.setItem('auth-token', data?.loginWithToken.token);
    },
  });

  useEffect(() => {
    loginWithToken({
      variables: {
        token,
      },
    });
  }, [token]);

  if (error) {
    return (
      <CenteredContainer>
        <InvalidLinkError />
      </CenteredContainer>
    );
  }
  if (loading || !data?.loginWithToken.token) {
    return (
      <CenteredContainer>
        <Spinner expand />
      </CenteredContainer>
    );
  }
  const { searchRequestReferenceId } = data.loginWithToken;

  return (
    <Authenticate
      redirect={
        searchRequestReferenceId &&
        toSearchRequestTenant({ searchRequestReferenceId })
      }
    />
  );
}

export default Proxy;
