import {
  ClockCircleOutlined,
  EyeInvisibleOutlined,
  PhoneOutlined,
  PlusOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Button, Card, message, Modal, Spin, Tabs } from 'antd';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Archive } from 'styled-icons/bootstrap';
import { EmailOutline, PhoneOutline } from 'styled-icons/evaicons-outline';

import Chat from '@/components/Chat';
import AddLeaseAgreementModal from '@/components/LeaseAgreementModals/AddLeaseAgreementModal';
import MatchIcon from '@/components/MatchIcon';
import Message from '@/components/Message';
import PortallyIcon from '@/components/PortallyLogo/PortallyIcon';
import {
  GetMyLeaseAgreementProspectsQuery,
  LeaseAgreement,
  LeaseAgreementProspectStatus,
  useEditLeaseAgreementProspectStatusMutation,
  useGetConversationsQuery,
  useGetMyLeaseAgreementProspectsQuery,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';
import theme from '@/main/theme';
import formatDate from '@/utils/formatDate';
import { Unpacked } from '@/utils/types';

import { LeaseAgreementProspectTitle } from '../Home/Landlord/MyLeaseAgreementProspects';
import ProspectInformation from './ProspectInformation';

interface TitleProps {
  archived?: number;
  rest?: number;
  unread?: number;
  t: TFunction;
  onChange: (activeKey: Tab) => void;
}

type Tab = 'active' | 'archived';

function Title({ archived, rest, t, onChange, unread }: TitleProps) {
  return (
    <div className="flex flex-col pt-1">
      <LeaseAgreementProspectTitle />

      <div>
        <Tabs
          items={[
            {
              key: 'active',
              label: (
                <div className="flex items-center">
                  {t('Expressions of interest')} {rest ? `(${rest})` : null}
                </div>
              ),
            },
            {
              key: 'archived',
              label: (
                <div>
                  {t('Archived')} {archived ? `(${archived})` : null}
                </div>
              ),
            },
          ]}
          onChange={key => onChange(key as Tab)}
        />
      </div>
    </div>
  );
}

function LeaseAgreementProspects() {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  const apollo = useApolloClient();
  const user = useUser();
  const [key, setKey] = useState<'active' | 'archived'>('active');

  const [openDrawer, setOpenDrawer] = React.useState<{
    open: boolean;
    prospect?: Unpacked<
      GetMyLeaseAgreementProspectsQuery['leaseAgreementProspects']['prospects']
    >;
  }>({ open: false, prospect: undefined });

  const [selectedProspect, setSelectedProspect] = useState<
    | {
        conversationId: string;
        archived: boolean;
        leaseAgreementName: LeaseAgreement['name'];
      }
    | undefined
  >(undefined);

  const onChange = (key: 'active' | 'archived') => {
    setKey(key);
  };

  const [editLeaseAgreementProspectStatus, { error: m2Error }] =
    useEditLeaseAgreementProspectStatusMutation({
      onError: () => {
        message.error(t('error'));
      },
    });

  //hämta allt filtrera frontend.
  const { data, previousData, loading, error } =
    useGetMyLeaseAgreementProspectsQuery({
      variables: {
        filter: {
          clientId: user?.activeClientId,
          status: [
            LeaseAgreementProspectStatus.Unopened,
            LeaseAgreementProspectStatus.Opened,
            LeaseAgreementProspectStatus.Accepted,
            LeaseAgreementProspectStatus.Declined,
            LeaseAgreementProspectStatus.AcceptedAndDeclined,
          ],

          adminStatus: [
            LeaseAgreementProspectStatus.Accepted,
            LeaseAgreementProspectStatus.AcceptedAndDeclined,
          ],
        },
      },
    });

  const {
    data: dataA,
    loading: loadingA,
    error: errorA,
    refetch: refetchA,
    previousData: previousDataA,
  } = useGetConversationsQuery({
    variables: {
      filter: {
        archived: key === 'archived',

        limit: 8,
        leaseAgreementProspect: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (
    (loading && !data && !previousData) ||
    (loadingA && !previousDataA && !dataA)
  ) {
    return (
      <Card
        title={<Title t={t} onChange={key => onChange(key)} />}
        className="overflow-y-auto h-full flex flex-col min-h-0 flex-1 mobile-card"
        bodyStyle={{
          height: '100%',
        }}
      >
        <div className="flex flex-col justify-center h-full items-center">
          <Spin />
        </div>
      </Card>
    );
  }

  if (error || (!data && !previousData)) {
    return <Message error={error} />;
  }

  const { leaseAgreementProspects } = data ?? previousData;
  const { conversations } = (dataA ?? previousDataA).conversations;

  const list = [
    ...conversations,
    ...leaseAgreementProspects.prospects.filter(prospect => {
      if (key === 'archived') {
        return (
          prospect.status === LeaseAgreementProspectStatus.Declined ||
          prospect.status === LeaseAgreementProspectStatus.AcceptedAndDeclined
        );
      } else {
        return (
          prospect.status === LeaseAgreementProspectStatus.Accepted ||
          prospect.status === LeaseAgreementProspectStatus.Opened ||
          prospect.status === LeaseAgreementProspectStatus.Unopened
        );
      }
    }),
  ].sort((a, b) => {
    if (a.created && b.created) {
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    }
  });

  return (
    <Card
      title={
        <Title
          rest={0}
          archived={0}
          t={t}
          onChange={onChange}
          unread={
            leaseAgreementProspects.prospects.filter(
              prospect =>
                prospect.status === LeaseAgreementProspectStatus.Unopened,
            ).length +
            conversations.reduce(
              (acc, curr) => acc + curr.conversation.unread.count,
              0,
            )
          }
        />
      }
      className="overflow-y-auto h-full flex flex-col min-h-0 flex-1 mobile-card drop-shadow"
      bodyStyle={{
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <div className="overflow-y-auto">
        {leaseAgreementProspects.prospects.length === 0 &&
          conversations.length === 0 && (
            <div className="h-full w-full flex flex-col items-center justify-center">
              <div className="flex flex-col items-center space-y-3 text-zinc-400 text-sm font-normal">
                {key === 'archived' ? (
                  <>
                    <Archive size={24} />
                    <div>{t('archivedEmpty')}</div>
                  </>
                ) : (
                  <>
                    <MatchIcon className="text-3xl" />
                    <div>{t('prospectsEmpty')}</div>
                    <AddLeaseAgreementModal
                      button={({ onClick }) => (
                        <Button
                          type="primary"
                          onClick={onClick}
                          icon={<PlusOutlined />}
                        >
                          {t('addLeaseAgreement')}
                        </Button>
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        <div className="grid grid-cols-4 desktop:grid-cols-3 laptop:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1 gap-3 h-full pb-1">
          {list.map(listItem => {
            if (listItem.__typename === 'LeaseAgreementProspect') {
              return (
                <div
                  key={listItem.id}
                  className={clsx(
                    'm-mobile:max-w-sm relative cursor-pointer border border-solid hover:bg-zinc-50 border-zinc-300 rounded-xl p-3 h-full',
                    {
                      'transition-all shadow-sm bg-purple-50 hover:bg-purple-100':
                        listItem.status === 'unopened',
                    },
                  )}
                  onClick={() => {
                    setOpenDrawer({ open: true, prospect: listItem });
                    if (
                      listItem.status !==
                        LeaseAgreementProspectStatus.Accepted &&
                      listItem.status !== LeaseAgreementProspectStatus.Declined
                    ) {
                      editLeaseAgreementProspectStatus({
                        variables: {
                          id: listItem.id,
                          status: LeaseAgreementProspectStatus.Opened,
                        },
                        onCompleted: () => {
                          apollo.reFetchObservableQueries();
                        },
                      });
                    }
                  }}
                >
                  <img
                    src={listItem.leaseAgreement.image?.url}
                    className="rounded-xl w-full h-[240px] object-cover drop-shadow"
                  />

                  <div className="text-zinc-800 mt-4 flex flex-col flex-grow">
                    <div className="mb-1 text-base">
                      <div>
                        <PhoneOutlined
                          className="mr-2"
                          style={{ color: theme.colors.coral }}
                        />

                        {listItem.leaseAgreement.name}
                      </div>
                      {!listItem.leaseAgreement.landlordContactPersons
                        .map(contactPerson => contactPerson.id)
                        .includes(user?.id) && (
                        <div className="text-sm text-zinc-600">
                          {t('responsible')}:{' '}
                          {listItem.leaseAgreement.landlordContactPersons
                            .map(user => user.name)
                            .join(', ')}
                        </div>
                      )}
                    </div>

                    <div className="text-zinc-500 flex gap-2 items-center">
                      <ShoppingOutlined size={16} /> {listItem.company}
                    </div>
                    <div className="text-zinc-500 flex gap-2 items-center">
                      <ClockCircleOutlined size={16} />
                      {`${formatDate(listItem.created)}`}
                    </div>

                    {listItem.status ===
                      LeaseAgreementProspectStatus.Unopened && (
                      <div
                        className={clsx(
                          'text-red-500 italic gap-2 flex items-center',
                        )}
                      >
                        <EyeInvisibleOutlined size={16} />{' '}
                        {t('status_unopened')}
                      </div>
                    )}

                    {listItem.status ===
                      LeaseAgreementProspectStatus.Accepted && (
                      <>
                        <div className="text-zinc-500 flex gap-2 items-center">
                          <PhoneOutline size={16} />
                          {listItem.phone}
                        </div>
                        <div className="text-zinc-500 flex gap-2 items-center">
                          <EmailOutline size={16} />
                          <a href={`mailto:${listItem.email}`}>
                            {' '}
                            {listItem.email}
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );
            }
            if (listItem.__typename === 'Message') {
              return (
                <div
                  onClick={() =>
                    setSelectedProspect({
                      conversationId: listItem.conversation.id,
                      archived: listItem.conversation.archived,
                      leaseAgreementName:
                        listItem.conversation.leaseAgreement.name,
                    })
                  }
                >
                  <div
                    key={listItem.conversation.id}
                    className={clsx(
                      'm-mobile:max-w-sm relative cursor-pointer border border-solid hover:bg-zinc-50 border-zinc-300 rounded-xl p-3 h-full',
                      {
                        'transition-all shadow-sm bg-purple-50 hover:bg-purple-100':
                          listItem.conversation.unread.count > 0,
                      },
                    )}
                  >
                    <img
                      src={listItem.conversation.leaseAgreement.image?.url}
                      className="rounded-xl w-full h-[240px] object-cover drop-shadow"
                    />
                    <div className="text-zinc-800 mt-4 flex flex-col flex-grow">
                      <div className="mb-1 text-base">
                        <div>
                          <PortallyIcon
                            size="16px"
                            className="mr-2"
                            color={theme.colors.primaryPurple}
                          />

                          {listItem.conversation.leaseAgreement.name}
                        </div>

                        {!listItem.conversation.leaseAgreement.landlordContactPersons
                          .map(contactPerson => contactPerson.id)
                          .includes(user?.id) && (
                          <div className="text-sm text-zinc-600">
                            {t('responsible')}:{' '}
                            {listItem.conversation.leaseAgreement.landlordContactPersons
                              .map(user => user.name)
                              .join(', ')}
                          </div>
                        )}
                      </div>

                      <div className="text-zinc-500 flex gap-2 items-center">
                        <ShoppingOutlined size={16} />{' '}
                        {
                          listItem.conversation.participants.filter(
                            participant =>
                              participant.id !== user?.activeClientId,
                          )[0].name
                        }
                      </div>
                      <div className="text-zinc-500 flex gap-2 items-center">
                        <ClockCircleOutlined size={16} />
                        {`${formatDate(listItem.created)}`}
                      </div>
                      {listItem.conversation.unread.count > 0 && (
                        <div
                          className={clsx(
                            'text-red-500 italic gap-2 flex items-center',
                          )}
                        >
                          <EyeInvisibleOutlined size={16} />{' '}
                          {t('status_unopened')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <Chat
          type={{
            type: 'drawer',
            props: {
              placement: 'left',
              width: isMobile ? '100%' : 700,
              open: !!selectedProspect?.conversationId,
              onClose: () => {
                setSelectedProspect(undefined);
              },
            },
          }}
          conversationId={selectedProspect?.conversationId}
        />
      </div>
      <Modal
        title={t('Expression of interest')}
        footer={null}
        open={openDrawer.open}
        onCancel={() => setOpenDrawer({ open: false, prospect: undefined })}
      >
        <ProspectInformation
          id={openDrawer?.prospect?.id}
          onClose={() => setOpenDrawer({ open: false, prospect: undefined })}
        />
      </Modal>
    </Card>
  );
}

export default LeaseAgreementProspects;
