import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';

import ShakingIcon from '@/components/ShakingIcon';
import theme from '@/main/theme';

interface Props {
  status: string;
  t: TFunction;
}

function AnswerRequestedIcon({ status, t }: Props): JSX.Element {
  if (status === 'answer_requested') {
    return (
      <>
        <Tooltip title={t('answerRequestedTooltip')}>
          <ShakingIcon>
            <ExclamationCircleTwoTone twoToneColor={theme.colors.red} />
          </ShakingIcon>
        </Tooltip>
      </>
    );
  }
  return null;
}

export default AnswerRequestedIcon;
