import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import MessageComponent from '@/components/Chat/Messages/MessageComponent';
import TimeStamp from '@/components/Chat/Messages/TimeStamp';
import { parseMessages } from '@/components/Chat/Messages/util';
import { Conversation } from '@/components/Chat/types';
import { useViewConversationMutation } from '@/generated/graphql';
import { GetActiveSearchRequestNotificationsDocument } from '@/generated/graphql';
import useAppSelector from '@/hooks/useAppSelector';
import useUser from '@/hooks/useUser';

import Form from './Form';
import Read from './Read';

const Container = styled.div`
  overscroll-behavior: contain;

  padding: 16px;
  overflow-y: auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    overscroll-behavior: auto;
    padding: 0;
  }

  flex: 1;
  display: flex;
  flex-direction: column-reverse;
`;

interface Props {
  conversation: Conversation;
  isMobile?: boolean;
  isDrawer?: boolean;
}

function Messages({
  conversation,
  isMobile = false,
  isDrawer = false,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();

  const [viewConversation] = useViewConversationMutation();

  const filter = useAppSelector(
    state => state.customer.activeSearchRequestFilter,
  );

  const parsedMessages = useCallback(
    () => parseMessages({ messages: conversation?.messages ?? [] }),
    [conversation?.messages],
  );

  useEffect(() => {
    viewConversation({
      variables: {
        conversationId: conversation.id,
      },
      refetchQueries: [
        {
          query: GetActiveSearchRequestNotificationsDocument,
          variables: {
            filter: {
              responsibleUserIds:
                'responsibleUserIds' in filter
                  ? filter.responsibleUserIds
                  : [user?.id],
            },
          },
        },
      ],
    });
  }, [conversation.id, conversation.messages, viewConversation]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = (): void => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo(0, messagesEndRef.current.scrollHeight, {
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation.messages.length, conversation.id]);

  return (
    <>
      <Container key={conversation.id}>
        {parsedMessages().map(
          ({
            showDate,
            showTimestamp,
            showName,
            showAvatar,
            showRead,
            message,
            showMarkAsAnswered,
          }) => (
            <React.Fragment key={message.id}>
              <div className={'flex flex-col space-y-1'}>
                {message && (
                  <MessageComponent
                    showMarkAsAnswered={
                      showMarkAsAnswered && conversation.unanswered
                    }
                    showTimestamp={showTimestamp}
                    showName={showName}
                    showAvatar={showAvatar}
                    message={message}
                    conversationId={conversation.id}
                    showRead={showRead}
                  />
                )}
              </div>
              {showDate && <TimeStamp date={message.created} />}
            </React.Fragment>
          ),
        )}
      </Container>

      {!isDrawer && (
        <Form
          conversationId={conversation.id}
          answered={conversation?.messages.some(
            message => message.sender.id === user?.activeClientId,
          )}
          searchRequestReference={conversation?.searchRequestReference}
          isMobile={isMobile}
        />
      )}
    </>
  );
}

export default Messages;
