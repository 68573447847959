import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { AdminState } from '@/state/admin/reducers';
import { ClientState } from '@/state/client/reducers';
import { CustomerState } from '@/state/customer/reducers';

const useAppSelector: TypedUseSelectorHook<{
  customer: CustomerState;
  client: ClientState;
  admin: AdminState;
}> = useSelector;

export default useAppSelector;
