import { MakeGenerics, Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type UserAdminRouteProps = MakeGenerics<{
  Params: {
    userId?: string;
  };
  LoaderData: {
    userId: string;
  };
}> &
  AdminRouteBaseProps;

const Users = lazy(() => import('./List'));
const Add = lazy(() => import('./AddUser'));
const Filter = lazy(() => import('./Filter'));
const View = lazy(() => import('./View'));
const Edit = lazy(() => import('./EditUser'));

const UserAdminRoutes: Route<UserAdminRouteProps>[] = [
  {
    path: 'users',
    id: 'Users',
    meta: {
      breadcrumbs: () => ({
        breadcrumb: 'Lista',
      }),
    },
    children: [
      {
        path: '/',
        id: 'UserList',
        element: <Users />,
        meta: {
          sider: <Filter />,
        },
      },
      {
        path: '/add',
        id: 'UserAdd',
        element: <Add />,
        meta: {
          breadcrumbs: () => ({
            breadcrumb: 'Lägg till',
          }),
        },
      },
      {
        path: ':userId',
        id: 'User',

        children: [
          {
            path: '/',
            id: 'UserView',
            element: <View />,
            loader: ({ params }) => ({
              userId: params.userId,
            }),
            meta: {
              breadcrumbs: () => ({
                breadcrumb: 'Användare',
              }),
            },
          },
          {
            path: '/edit',
            id: 'UserEdit',
            element: <Edit />,
            loader: ({ params }) => ({
              userId: params.userId,
            }),
            meta: {
              breadcrumbs: params => ({
                breadcrumb: 'Redigera',
                parent: {
                  breadcrumb: 'Användare',
                  pathname: `/admin/users/${params?.userId}`,
                },
              }),
            },
          },
        ],
      },
    ],
  },
];

export const toUsers = (): string => '/admin/users/';
export const toUserView = (userId: string): string => `/admin/users/${userId}`;
export const toUserEdit = (userId: string): string =>
  `/admin/users/${userId}/edit`;
export const toUserAdd = (): string => '/admin/users/add';

export default UserAdminRoutes;
