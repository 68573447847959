import { useGoogleMap } from '@react-google-maps/api';
import { useEffect } from 'react';

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { centerRegions } from '@/state/customer/actions';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

function Panner(): null {
  const dispatch = useAppDispatch();
  const regionBounds = useAppSelector(state => state.customer.centerRegions);

  const map = useGoogleMap();

  useEffect(() => {
    if (regionBounds !== undefined) {
      const northEast = new window.google.maps.LatLng(
        regionBounds.north,
        regionBounds.east,
      );
      const southWest = new window.google.maps.LatLng(
        regionBounds.south,
        regionBounds.west,
      );

      map.fitBounds(new window.google.maps.LatLngBounds(southWest, northEast));
      map.setZoom(map.getZoom() > 15 ? 15 : map.getZoom());

      dispatch(centerRegions(undefined));
    }
  }, [centerRegions, map, regionBounds]);

  return null;
}

export default Panner;
