import {
  CheckCircleOutlined,
  DeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Popover } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import {
  SearchRequestReferenceListValuesFragment,
  StatusEnum,
} from '@/generated/graphql';
import theme from '@/main/theme';

interface Props {
  status: StatusEnum;
  searchRequests: SearchRequestReferenceListValuesFragment['searchRequests'];
}

const icons = {
  [StatusEnum.Accepted]: (
    <CheckCircleOutlined style={{ color: theme.colors.green }} />
  ),
  [StatusEnum.Declined]: <DeleteOutlined style={{ color: theme.colors.red }} />,
};

function AnsweredBy({ status, searchRequests }: Props): JSX.Element {
  const { t } = useTranslation();

  const answeredBy = searchRequests.searchRequests.filter(
    searchRequest =>
      searchRequest.status === status && searchRequest.responsibleUser,
  );

  if (answeredBy.length === 0) {
    return <></>;
  }

  return (
    <Popover
      title={
        <Box flex horizontalSpacing={0.5}>
          <div>{icons[status]}</div>
          <div>
            {t(
              status === StatusEnum.Accepted
                ? t('Answers')
                : `status_${status}`,
            )}
          </div>
        </Box>
      }
      content={
        <Box maxW={200}>
          {answeredBy.map(
            ({ responsibleUser }) =>
              responsibleUser && (
                <div key={responsibleUser.id}>{responsibleUser.name}</div>
              ),
          )}
        </Box>
      }
    >
      <Box flex alignItems={'center'} horizontalSpacing={1}>
        {icons[status]}
        <Avatar.Group size={'small'} style={{ flexWrap: 'wrap' }}>
          {answeredBy.map(
            ({ responsibleUser }) =>
              responsibleUser && (
                <Avatar
                  key={responsibleUser.id}
                  src={responsibleUser.image?.url}
                  icon={<UserOutlined />}
                />
              ),
          )}
        </Avatar.Group>
      </Box>
    </Popover>
  );
}

export default AnsweredBy;
