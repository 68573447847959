import { Button, FormInstance, Typography } from 'antd';
import { TFunction } from 'i18next';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import { formatNumber } from '@/utils';

import Commission from './Commission';
const { Title, Text } = Typography;
const Label = styled.div`
  flex: 0 0 150px;
  color: ${p => p.theme.colors.typographySecondary};
  margin-right: 5px;
`;

const Value = styled.div`
  flex: 1;
`;
interface StepTwoProps {
  form: FormInstance;
  t: TFunction;
  portallyFee?: number;
  isLandlord: boolean;
  loading: boolean;
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
}

export default function StepTwo({
  form,
  t,
  portallyFee,
  isLandlord,
  loading,
  setStep,
  step,
}: StepTwoProps): JSX.Element {
  const { isMobile } = useWidthAndHeight();

  const getFee = (fee: number): string => {
    if (fee < 10000) {
      return formatNumber(10000);
    }
    return formatNumber(fee);
  };
  const billingInfo = [
    {
      label: 'companyNameSecondary',
      value: form.getFieldValue(['invoiceDetails', 'company']),
    },
    {
      label: 'address',
      value: form.getFieldValue(['invoiceDetails', 'billing']),
    },

    { label: 'email', value: form.getFieldValue(['invoiceDetails', 'email']) },
    {
      label: 'Reference',
      value: form.getFieldValue(['invoiceDetails', 'reference']),
    },
    {
      label: 'Marking',
      value: form.getFieldValue(['invoiceDetails', 'marking']),
    },
  ];

  const data = [
    {
      label: isLandlord ? 'Hyresgäst' : 'Hyresvärd',
      value: form.getFieldValue('madeWith'),
    },
    {
      label: 'Annual rent',
      value: ` ${formatNumber(form.getFieldValue('annualRent'))} kr`,
    },

    {
      label: 'Portally fee',
      value: `${getFee(portallyFee)} kr`,
    },
  ];
  const borderStyle = { border: '1px solid #d9d9d9', borderRadius: '8px' };
  const comment = form.getFieldValue('comment');
  return (
    <Box flex flexDirection={'column'}>
      {isMobile && (
        <Box flex justifyContent={'center'}>
          <Title level={5}>Granska och Skicka</Title>
        </Box>
      )}
      <Box p={4}>
        <Title level={5}>Hyresavtalet</Title>
        <div className="p-1">
          {data.map(({ label, value }) => (
            <Box
              style={{ overflow: 'auto' }}
              flex
              key={label}
              className="first:border-none border-t border-l-0 py-3 border-r-0 border-b-0 border-solid border-zinc-200"
            >
              <Label className="text-zinc-400">{t(label)}</Label>
              <Value>{value}</Value>
            </Box>
          ))}
        </div>

        <Text type="secondary" style={{ fontSize: '12px' }}>
          <Commission />
        </Text>

        <Box mt={2}>
          <Title level={5}>Faktureringsuppgifter</Title>
          <div className="p-1">
            {billingInfo.map(({ label, value }) => (
              <Box
                style={{ overflow: 'auto' }}
                flex
                key={label}
                className="first:border-none border-t border-l-0 py-3 border-r-0 border-b-0 border-solid border-zinc-200"
              >
                <Label className="text-zinc-400">{t(label)}</Label>
                <Value>{value}</Value>
              </Box>
            ))}
          </div>
        </Box>
        {comment && (
          <Box mt={2}>
            <Title level={5}>Kommentar</Title>
            <Box>{form.getFieldValue('comment')}</Box>
          </Box>
        )}
      </Box>
      <Box flex justifyContent={'flex-end'} horizontalSpacing={1}>
        <Button
          onClick={() => {
            setStep(step - 1);
          }}
        >
          {t('goBack')}
        </Button>
        <Button loading={loading} type="primary" htmlType="submit">
          {t('Send')}
        </Button>
      </Box>
    </Box>
  );
}
