import { UserOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { PaperPlane } from '@styled-icons/boxicons-regular/PaperPlane';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { Avatar, Input, message as antMessage, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledIconButton } from '@/components/Buttons';
import VerifyAccount from '@/components/User/VerifyAccount';
import {
  GetLeaseAgreementViewQuery,
  MessageType,
  useAddNewMessageMutation,
} from '@/generated/graphql';
import useAppDispatch from '@/hooks/useAppDispatch';
import useUser from '@/hooks/useUser';
import { updateSearchRequestScrollPosition } from '@/state/customer/actions';
import { LeaseAgreementRouteProps } from '@/views/LeaseAgreement/routes';
import { toMessages } from '@/views/Messages/routes';
import { toSearchRequestTenant } from '@/views/SearchRequestTenant/routes';

interface Props {
  leaseAgreement: GetLeaseAgreementViewQuery['leaseAgreement'];
  isAgreementOwner: boolean;
}

export default function ContactAffix({
  leaseAgreement,
  isAgreementOwner,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { searchRequestReferenceId } =
    useMatch<LeaseAgreementRouteProps>().data;
  const user = useUser();
  const apollo = useApolloClient();
  const dispatch = useAppDispatch();

  const [addMessage, { loading }] = useAddNewMessageMutation({
    onCompleted: async ({ newMessage }) => {
      if (newMessage) {
        if (searchRequestReferenceId) {
          dispatch(
            updateSearchRequestScrollPosition(searchRequestReferenceId, 0),
          );
          navigate({
            to: toSearchRequestTenant({
              searchRequestReferenceId,
              conversationId: newMessage.conversationId,
            }),
          });
        } else {
          navigate({
            to: toMessages(newMessage.conversationId),
          });
        }

        await apollo.reFetchObservableQueries();
      }
    },
    onError: () => antMessage.error(t('error')),
  });
  const { tenant, landlord } = leaseAgreement;
  const [message, setMessage] = useState('');

  const isLandlord = !!landlord;

  const sendMessage = async (): Promise<void> => {
    await addMessage({
      variables: {
        messageType: MessageType.OnNewMessage,
        input: {
          searchRequestReferenceId,
          reciverId:
            isLandlord || !tenant
              ? leaseAgreement.landlord?.id
              : leaseAgreement.tenant?.id,
          senderId: user.activeClientId,
          leaseAgreementId: leaseAgreement.id,
          leaseAgreementIds: [leaseAgreement.id],
          message,
        },
      },
    });
  };

  if (!landlord && !tenant) {
    return null;
  }
  const companyImage = landlord?.image?.url;
  const contactForm = !isAgreementOwner && (
    <div>
      <h5 className="flex items-center align-center">
        {`${t('Contact')} ${landlord?.name}`}
      </h5>
      <div className="flex flex-col mt-2">
        {user?.verified ? (
          <>
            <Input.TextArea
              placeholder={t('Write a message')}
              rows={3}
              onChange={e => {
                const { target } = e;
                setMessage(target.value);
              }}
              value={message}
            />
            <StyledIconButton
              $withText
              onClick={() => sendMessage()}
              type={'primary'}
              className="mt-3"
              icon={<PaperPlane size={17} />}
              loading={loading}
              disabled={!message}
              style={{ width: 'fit-content', alignSelf: 'flex-end' }}
            >
              {t('Send')}
            </StyledIconButton>
          </>
        ) : (
          <div className="flex flex-col space-y-1">
            <div className="text-zinc-400">{t('verifyAccountPrompt')}</div>
            <VerifyAccount type={'primary'} />
          </div>
        )}
      </div>
    </div>
  );
  return (
    <>
      <div>
        {contactForm}
        {leaseAgreement.landlordContactPersons.length > 0 && (
          <div className="flex flex-col mt-5">
            {leaseAgreement.landlordContactPersons.map(
              ({ id, image, name }) => (
                <div
                  className="my-2 first:mt-0 last:mb-0 flex items-start space-x-2"
                  key={id}
                >
                  {image?.url && (
                    <Avatar
                      size={64}
                      src={image?.url}
                      icon={<UserOutlined />}
                      shape={'square'}
                      style={{ flexShrink: 0 }}
                    />
                  )}
                  <div className="flex flex-col">
                    <div>{name}</div>
                    <div className="text-zinc-400 text-sm font-normal">
                      {t('Contact person')}
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        )}

        {companyImage && (
          <div className={'mt-4'}>
            <img
              src={companyImage}
              alt={leaseAgreement.landlord.name}
              className={'w-full h-36 object-contain ml-auto'}
              loading={'lazy'}
            />
          </div>
        )}
      </div>
    </>
  );
}
