import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import MinMax from '@/components/MinMax';
import { SizeFilter } from '@/components/SearchFormComponents';
const { Title } = Typography;

interface Props {
  min: number | undefined;
  max: number | undefined;
  editing: boolean;
}

function BudgetSpan({ min, max, editing }: Props): JSX.Element {
  const { t } = useTranslation();
  if (!min && !max && !editing) {
    return null;
  }

  return (
    <Box>
      <Title level={5} style={{ fontWeight: 500 }}>
        {t('budgetSpan')}
      </Title>
      {editing ? (
        <SizeFilter name={'budgetSpan'} hidden={false} edit searchRequest />
      ) : (
        <MinMax max={max} min={min} />
      )}
    </Box>
  );
}

export default BudgetSpan;
