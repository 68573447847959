import { Empty } from 'antd';
import React from 'react';

import PortallyIcon from '@/components/PortallyLogo/PortallyIcon';
import theme from '@/main/theme';

interface Props {
  description: React.ReactNode;
  small?: boolean;
}

function PortallyEmpty({ description, small = false }: Props): JSX.Element {
  return (
    <Empty
      imageStyle={{ height: small ? '25px' : '40px' }}
      image={<PortallyIcon color={theme.colors.coral} />}
      description={description}
    />
  );
}

export default PortallyEmpty;
