import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { useTranslation } from 'react-i18next';

import UploadFile from '@/components/UploadFile';
import DragAndDropper from '@/components/UploadFile/DragAndDropper';
import { GetLeaseAgreementEditQuery } from '@/generated/graphql';

interface Props {
  values: GetLeaseAgreementEditQuery['leaseAgreement'] | Record<string, never>;
  form: FormInstance;
}

function Media({ values }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        label={t('images')}
        name={'imageIds'}
        initialValue={values['imageIds'] || []}
      >
        <DragAndDropper
          initialValues={
            values['images']?.filter(({ id }) => !id.startsWith('vitec')) || []
          }
        />
      </Form.Item>
      <Form.Item
        label={t('video')}
        name={['videoId']}
        initialValue={values['video'] ? values['video']['videoId'] : undefined}
      >
        <UploadFile
          initialValues={values['video'] ? values['video']['video'] : undefined}
          listType={'text'}
          accept={'video/*'}
        />
      </Form.Item>
      <Form.Item dependencies={['videoId']} noStyle>
        {({ getFieldValue }) => {
          const videoId = getFieldValue('videoId');
          if (!videoId) {
            return null;
          }
          return (
            <Form.Item
              label={'Thumbnail'}
              name={'thumbnailId'}
              initialValue={
                values['video'] ? values['video']['thumbnailId'] : undefined
              }
            >
              <UploadFile
                initialValues={
                  values['video'] ? values['video']['thumbnail'] : undefined
                }
                accept={'image/*'}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        label={t('documents')}
        name={'documentIds'}
        initialValue={values['documentIds'] || []}
      >
        <UploadFile
          initialValues={
            values['documents']?.filter(({ id }) => !id.startsWith('vitec')) ||
            []
          }
          multiple
          accept={'*'}
          listType={'text'}
        />
      </Form.Item>
      <Form.Item label={t('Links')} name={'links'}>
        <Form.List initialValue={values['links'] ?? []} name="links">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                    justifyContent: 'space-between',
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'url']}
                    rules={[
                      { required: true, message: t('required') },
                      {
                        validator: (_, value) => {
                          const expression =
                            // eslint-disable-next-line no-useless-escape
                            /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
                          const regex = new RegExp(expression);
                          if (regex.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="www.placeholder.com" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    rules={[
                      {
                        required: true,

                        message: t('required'),
                      },
                    ]}
                  >
                    <Input placeholder={t('name')} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t('Ny länk')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
}

export default Media;
