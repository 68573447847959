import { Marker } from '@styled-icons/foundation/Marker';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledIconButton } from '@/components/Buttons';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useAppDispatch from '@/hooks/useAppDispatch';
import {
  centerRegions,
  setSelectedMarker,
  updateCenter,
} from '@/state/customer/actions';

interface Props {
  coordinates: [number, number];
  leaseAgreementId: string;
}

function SeeOnMap({ coordinates, leaseAgreementId }: Props): JSX.Element {
  const [hover, setHover] = React.useState(false);
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();

  const dispatch = useAppDispatch();
  if (isMobile) {
    return null;
  }
  return (
    <StyledIconButton
      className="top-2 right-2 absolute z-10"
      icon={<Marker size={14} />}
      size={'small'}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        const [lng, lat] = coordinates;
        const bounds = new window.google.maps.LatLngBounds();
        const center = new window.google.maps.LatLng(lat, lng);
        bounds.extend(center);
        dispatch(centerRegions(bounds.toJSON()));
        dispatch(updateCenter(false));
        dispatch(setSelectedMarker(leaseAgreementId));
      }}
    >
      <div
        style={{
          maxWidth: hover ? '300px' : 0,
          overflow: 'hidden',
          transition: 'all 0.25s cubic-bezier(0.55, 0, 0.55, 0.2), color 0s',
          fontSize: '12px',
        }}
      >
        {t('See on map')}
      </div>
    </StyledIconButton>
  );
}

export default SeeOnMap;
