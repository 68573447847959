import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';
const LeaseAgreementProspects = lazy(() => import('.'));
export type LeaseaAgreementProspectsAdminRouteProps = AdminRouteBaseProps;

const LeaseAgreementProspectsAdminRoutes: Route<LeaseaAgreementProspectsAdminRouteProps>[] =
  [
    {
      path: '/lease-agreement-prospects',
      id: 'LeaseAgreementsProspectsAdmin',
      element: <LeaseAgreementProspects />,
    },
  ];

export const toLeaseAgreementsProspectsAdmin = (): string =>
  '/admin/lease-agreement-prospects';

export default LeaseAgreementProspectsAdminRoutes;
