import {
  DashboardFilled,
  FileTextOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { useMatches, useNavigate } from '@tanstack/react-location';
import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonOutline } from 'styled-icons/material-rounded';

import { refetchUser } from '@/hooks/useAuth';
import useUser from '@/hooks/useUser';
import SubscriptionMenuItem from '@/layout/SubscriptionMenuItem';
import { toAdminHome } from '@/views/AdminHome/routes';
import { toClientSettings } from '@/views/Client/routes';
import AddColleagueModal from '@/views/Client/Settings/MyColleagues/AddColleagueModal';
import { toSubscription } from '@/views/Subscription/routes';
import { toTermsOfService } from '@/views/TermsOfService/routes';
import { toUserSettings } from '@/views/Users/routes';

import { DropdownNav } from './index.styled';

export default function Account(): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const [visible, setVisible] = useState(false);
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const matches = useMatches();

  const signOut = async (): Promise<void> => {
    localStorage.removeItem('auth-token');
    refetchUser(apolloClient);
  };

  const accountMenu = [
    {
      icon: UserOutlined,
      text: t('Account settings'),
      to: toUserSettings(),
    },
    ...((user?.activeClientId && [
      {
        icon: SettingOutlined,
        text: t('Client settings'),
        to: toClientSettings(),
      },
    ]) ||
      []),
    {
      icon: FileTextOutlined,
      text: t('Terms of service'),
      to: toTermsOfService(),
    },
    {
      icon: null,
      text: 'subscription',
      action: null,
    },
    {
      icon: LogoutOutlined,
      text: t('Logout'),
      action: signOut,
    },
    ...((user?.activeClientId && [
      {
        icon: UserAddOutlined,
        text: t('Add colleague'),
        action: () => setVisible(true),
      },
    ]) ||
      []),
  ];

  if (user?.role === 'admin' || user?.role === 'consultant') {
    accountMenu.push({
      icon: DashboardFilled,
      text: 'Admin Panel',
      to: toAdminHome(),
    });
  }
  const current = matches[matches.length - 1];
  const pathname = current?.pathname;

  const userMenuDropdown = (
    <Menu selectedKeys={[pathname]}>
      {accountMenu.map(item =>
        item.text === 'subscription' ? (
          user?.activeSubscription?.type === 'commission' ? (
            <SubscriptionMenuItem
              subscriptionType={user.activeSubscription?.type}
              key={toSubscription()}
            />
          ) : null
        ) : (
          <Menu.Item
            key={item.text}
            icon={<item.icon />}
            onClick={() => {
              if (item.action) {
                item.action();
              } else {
                navigate({
                  to: item.to,
                });
              }
            }}
          >
            {item.text}
          </Menu.Item>
        ),
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={userMenuDropdown}
        trigger={['click']}
        placement="topLeft"
        arrow
        align={{
          offset: [20, 11],
        }}
      >
        <DropdownNav as="span" className="dropdown tour tour-settings">
          <PersonOutline />
          <span className="text">{t('Account')}</span>
        </DropdownNav>
      </Dropdown>
      <AddColleagueModal visible={visible} setVisible={setVisible} />
    </>
  );
}
