import { useMatch } from '@tanstack/react-location';
import { Drawer, message } from 'antd';
import { TFunction } from 'next-i18next';
import React from 'react';

import {
  useGetLeaseAgreementsByIdsQuery,
  useGetLeaseAgreementViewQuery,
} from '@/generated/graphql';
import { SearchRequestTenantRouteProps } from '@/views/SearchRequestTenant/routes';

import GroupedMarkerPreview from './GroupedMarkerPreview';
import { PreviewDrawerFooter } from './PreviewDrawerFooter';
import SingleMarkerPreview from './SingleMarkerPreview';

interface Props {
  id: string | null;
  onClose: () => void;
  t: TFunction;
}

function MarkerPreview({ id, onClose, t }: Props): JSX.Element {
  const isGroup = !!id?.split(':')?.length && id.split(':').length > 1;

  const { searchRequestReferenceId } =
    useMatch<SearchRequestTenantRouteProps>().data;
  const isInSearchRequestView = !!searchRequestReferenceId;

  const { data: leaseAgreementsByIdData, loading: leaseAgreementsLoading } =
    useGetLeaseAgreementsByIdsQuery({
      variables: {
        ids: id?.split(':') ? id.split(':') : [],
      },
      skip: !id || !isGroup,
      onError: () => {
        void message.error(t('error'));
      },
    });
  const { data: leaseAgreementByIdData, loading } =
    useGetLeaseAgreementViewQuery({
      variables: {
        id: id ?? '',
      },
      skip: !id || isGroup,
      onError: () => {
        void message.error(t('error'));
        onClose();
      },
    });
  const drawerPlacement = isInSearchRequestView
    ? ('right' as const)
    : ('left' as const);

  return (
    <Drawer
      open={!!id}
      maskStyle={{ backgroundColor: 'transparent' }}
      title={
        !isGroup
          ? leaseAgreementByIdData?.leaseAgreement?.name
          : leaseAgreementsByIdData?.leaseAgreementsByIds.length
          ? leaseAgreementsByIdData.leaseAgreementsByIds[0].name
          : ''
      }
      placement={drawerPlacement}
      size={'large'}
      onClose={() => onClose()}
      footer={
        <PreviewDrawerFooter
          leaseAgreement={leaseAgreementByIdData?.leaseAgreement}
          t={t}
          searchRequestReferenceId={searchRequestReferenceId}
        />
      }
      rootClassName={'no-padding-ant-drawer mobile:max-w-[100vw]'}
    >
      {!isGroup && (
        <SingleMarkerPreview
          data={leaseAgreementByIdData}
          loading={loading}
          drawerPlacement={drawerPlacement}
        />
      )}
      {isGroup && (
        <GroupedMarkerPreview
          loading={leaseAgreementsLoading}
          drawerPlacement={drawerPlacement}
          data={leaseAgreementsByIdData}
          searchRequestReferenceId={searchRequestReferenceId}
          t={t}
        />
      )}
    </Drawer>
  );
}

export default React.memo(MarkerPreview);
