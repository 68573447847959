import { ReloadOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PortallyIcon from '@/components/PortallyLogo/PortallyIcon';
import theme from '@/main/theme';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh?: () => Promise<ApolloQueryResult<any>>;
}

function CardError({ refresh }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Result
      status={'error'}
      icon={<PortallyIcon color={theme.colors.red} />}
      subTitle={t('unexpectedError')}
      extra={
        refresh && (
          <Button onClick={() => refresh()} icon={<ReloadOutlined />}>
            {t('Reload')}
          </Button>
        )
      }
    />
  );
}

export default CardError;
