import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

const Events = lazy(() => import('.'));
const Filter = lazy(() => import('./Filter'));
export type RouteProps = AdminRouteBaseProps;

const AdminOverviewRoutes: Route<RouteProps>[] = [
  {
    path: '/overview',
    id: '',
    element: <Events />,
    meta: {
      sider: <Filter />,
    },
  },
];

export const toAdminOverview = (): string => '/admin/overview';

export default AdminOverviewRoutes;
