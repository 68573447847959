import { Heart } from '@styled-icons/bootstrap/Heart';
import { message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  RefetchStatusDocument,
  useAddStatusMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';
import theme from '@/main/theme';

const DefaultContainer = styled.div`
  position: absolute;
  z-index: 3;
  color: white;
  border-radius: 8px;
  transition: 200ms;
  background: rgba(0, 0, 0, 0.6);
  &:hover {
    box-shadow: none;
    cursor: pointer;
    &:first-child {
      color: white;
    }
  }
`;

const ListItemContainer = styled(DefaultContainer)`
  top: 6px;
  left: 6px;
  padding: 1px 4px;
  border-radius: 4px;
  > svg {
    position: relative;
    bottom: 1px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 4px 4px;
    > svg {
      position: relative;
      top: 1px;
    }
  }
`;

const LeaseAgreementViewContainer = styled(DefaultContainer)`
  top: 12px;
  padding: 8px;
  left: 12px;
  > svg {
    position: relative;
    top: 2px;
  }
`;

interface IconProps {
  size: number;
  hover: boolean;
}
interface Props {
  selectedLeaseAgreement: {
    myStatus?: string;
    id: string;
  };
  leaseAgreementView?: boolean;
}
function RateLeaseAgreement({
  selectedLeaseAgreement,
  leaseAgreementView = false,
}: Props): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const { t } = useTranslation();
  const user = useUser();
  const [hover, setHover] = useState(false);
  const [action] = useAddStatusMutation({
    onCompleted: data => {
      if (data.changeStatus.status === 'interesting') {
        message.success(t('Saved'));
      }
    },
    refetchQueries: [
      {
        query: RefetchStatusDocument,
      },
    ],
  });

  const handleConfirm = async (): Promise<void> => {
    await action({
      variables: {
        input: {
          clientId: user.activeClientId,
          leaseAgreementId: selectedLeaseAgreement.id,
          status:
            selectedLeaseAgreement.myStatus === 'interesting'
              ? 'notInterested'
              : 'interesting',
        },
      },
    });
  };
  const interested = selectedLeaseAgreement.myStatus === 'interesting';
  const Icon = ({ size, hover }: IconProps): JSX.Element => (
    <>
      <Heart
        style={{
          color: interested || hover ? theme.colors.red : theme.colors.white,
        }}
        size={size}
      />
    </>
  );
  if (leaseAgreementView) {
    return (
      <LeaseAgreementViewContainer
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => handleConfirm()}
      >
        <Icon hover={hover} size={36} />
      </LeaseAgreementViewContainer>
    );
  }
  return (
    <ListItemContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => handleConfirm()}
    >
      <Icon hover={hover} size={isMobile ? 24 : 16} />
    </ListItemContainer>
  );
}

export default RateLeaseAgreement;
