import {
  CameraOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import { Button, Carousel, Image, Tooltip } from 'antd';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import HiddenImagePreview from '@/components/HiddenImagePreview';
import PdfPreview from '@/components/PdfPreview';
import { translateUrl } from '@/components/UploadFile/util';
import { DocumentValuesFragment } from '@/generated/graphql';

interface Props {
  attachments: DocumentValuesFragment[];
  mine: boolean;
}

function Attachments({ attachments, mine }: Props) {
  const { t } = useTranslation();
  const [selectedPreviewImage, setSelectedPreviewImage] = useState({
    visible: false,
    previewImage: '',
  });
  const [selectedPdfPreview, setSelectedPdfPreview] = useState(null);
  const onClick = (file: DocumentValuesFragment) => {
    if (file.mimetype.includes('image')) {
      setSelectedPreviewImage({
        previewImage: translateUrl(file.url),
        visible: true,
      });

      return;
    }
    if (file.mimetype === 'application/pdf') {
      setSelectedPdfPreview({
        url: translateUrl(file.url),
        name: file.name,
      });
    } else {
      const newWindow = window.open(
        translateUrl(file.url),
        '_blank',
        'noreferrer',
      );
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  };
  if (attachments.length === 0) {
    return null;
  }

  const images = attachments.filter(attachment =>
    attachment.mimetype.includes('image'),
  );
  const rest = attachments.filter(
    attachment => !attachment.mimetype.includes('image'),
  );

  return (
    <div className={'flex flex-wrap gap-1'}>
      <HiddenImagePreview
        imageUrls={images.map(file => translateUrl(file.url))}
        visible={selectedPreviewImage.visible}
        selectedImage={selectedPreviewImage.previewImage}
        onVisibleChange={() =>
          setSelectedPreviewImage(prev => ({ ...prev, visible: false }))
        }
      />
      <div className={'flex flex-col rounded-xl overflow-hidden w-full'}>
        <div
          className={clsx('max-w-[500px] grid grid-cols-2 gap-2 rounded-xl', {
            'grid-cols-1': images.length === 1,
          })}
        >
          {images.map(i => (
            <div className={'relative'} key={i.url}>
              <div
                className={
                  'aspect-[3/2] border border-solid border-gray-200 rounded-xl overflow-hidden'
                }
              >
                <Image
                  className={'rounded-xl object-contain'}
                  src={i.url}
                  onClick={() =>
                    setSelectedPreviewImage({
                      visible: true,
                      previewImage: translateUrl(i.url),
                    })
                  }
                  preview={{
                    visible: false,
                    className: 'rounded-xl',
                    maskClassName: 'rounded-xl',
                  }}
                />
              </div>

              <Tooltip title={t('Open in new tab')}>
                <Button
                  icon={<SelectOutlined />}
                  type={'link'}
                  onClick={() => window.open(translateUrl(i.url))}
                  className={'absolute text-white bottom-1 z-10'}
                />
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
      {rest.map(attachment => (
        <button
          key={attachment.id}
          onClick={() => onClick(attachment)}
          className={clsx(
            'text-black bg-gradient-to-br from-white to-gray-100 flex items-center p-2  hover:bg-gradient-to-bl text-sm space-x-1 rounded hover:text-purple-500 transition-all border border-solid border-zinc-200 cursor-pointer',
          )}
        >
          {attachment.mimetype === 'application/pdf' ? (
            <FilePdfOutlined />
          ) : attachment.mimetype.includes('image') ? (
            <CameraOutlined />
          ) : (
            <DownloadOutlined />
          )}

          <span
            className={'truncate max-w-sm mobile:max-w-[170px] whitespace-pre'}
          >
            {attachment.name.split('.')[0]}
          </span>
        </button>
      ))}
      <PdfPreview
        pdf={selectedPdfPreview}
        onCancel={() => setSelectedPdfPreview(null)}
      />
    </div>
  );
}

export default Attachments;
