import styled from 'styled-components';

import theme from '@/main/theme';
const Container = styled.div<{ size: 'small' | 'middle'; shadow?: boolean }>`
  width: fit-content;
  padding: ${p => (p.size === 'small' ? '6px 12px' : '8px 12px')};
  border-radius: 8px;
  display: flex;
  white-space: pre;

  justify-content: center;
  align-items: center;
  ${p => p.size === 'small' && 'font-size: 12px'};
  ${p => p.size === 'middle' && 'font-size: 14px'};
  ${p => p.shadow && 'box-shadow: rgb(0 0 0 / 18%) 1px 2px 4px'};
  font-weight: 500;
`;

export const SizeTagContainer = styled(Container)`
  color: ${theme.colors.black};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.inputBorder};
`;

export const StepTagContainer = styled(Container)<{ $color: string }>`
  background: ${theme.colors.white};
  border: 1px solid ${({ $color }) => $color};
  color: ${({ $color }) => $color};
`;
