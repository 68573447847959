import { useMatch } from '@tanstack/react-location';
import { Drawer, DrawerProps, Spin } from 'antd';
import { TFunction } from 'next-i18next';
import React, { useState } from 'react';

import {
  GetLeaseAgreementsByIdsQuery,
  useGetLeaseAgreementViewQuery,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import { SearchRequestTenantRouteProps } from '@/views/SearchRequestTenant/routes';

import LeaseAgreementCard from '../LeaseAgreementList/LeaseAgreementCard';
import { PreviewDrawerFooter } from './PreviewDrawerFooter';
import SingleMarkerPreview from './SingleMarkerPreview';

interface Props {
  data: GetLeaseAgreementsByIdsQuery | undefined;
  loading: boolean;
  searchRequestReferenceId: string;
  t: TFunction;
  drawerPlacement?: DrawerProps['placement'];
}

function GroupedMarkerPreview({
  data,
  loading,
  searchRequestReferenceId,
  t,
  drawerPlacement = 'right',
}: Props): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const [id, setId] = useState<null | string>(null);

  const { data: leaseAgreementByIdData, loading: leaseAgreementLoading } =
    useGetLeaseAgreementViewQuery({
      variables: {
        id: id ?? '',
      },
      skip: !id,
    });

  return (
    <>
      {loading || !data ? (
        <div className={'h-full w-full flex justify-center items-center'}>
          <Spin />
        </div>
      ) : (
        <div className={'grid grid-cols-2 gap-2 max-w-full'}>
          {data.leaseAgreementsByIds.map(leaseAgreement => (
            <div
              key={leaseAgreement.id}
              className={'h-full'}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setId(leaseAgreement.id);
              }}
            >
              <LeaseAgreementCard
                key={leaseAgreement.id}
                leaseAgreement={leaseAgreement}
                isMobile={isMobile}
              />
            </div>
          ))}
        </div>
      )}
      <Drawer
        open={!!id}
        onClose={() => setId('')}
        title={leaseAgreementByIdData?.leaseAgreement?.name}
        placement={drawerPlacement}
        size={'large'}
        footer={
          <PreviewDrawerFooter
            leaseAgreement={leaseAgreementByIdData?.leaseAgreement}
            t={t}
            searchRequestReferenceId={searchRequestReferenceId}
          />
        }
      >
        <SingleMarkerPreview
          loading={leaseAgreementLoading}
          data={leaseAgreementByIdData}
          drawerPlacement={drawerPlacement}
        />
      </Drawer>
    </>
  );
}

export default GroupedMarkerPreview;
