import { DeleteOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Button, ButtonProps, message, Modal, Popover, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Buttons from '@/components/SearchRequest/shared/DeclineSearchRequestButton/Buttons';
import Content from '@/components/SearchRequest/shared/DeclineSearchRequestButton/Content';
import {
  GetConversationsDocument,
  GetSearchRequestStatsDocument,
  StatusEnum,
  useAnswerSearchRequestMutation,
} from '@/generated/graphql';
import useUser from '@/hooks/useUser';

interface Props extends ButtonProps {
  searchRequestReferenceId: string;
  icon?: boolean;
  onCompleted: () => void;
  modal?: boolean;
  quick?: boolean;
}

const Container = styled.div`
  .ant-drawer {
    overflow: hidden;
  }
`;

export default function DeclineSearchRequestButton({
  searchRequestReferenceId,
  icon = false,
  onCompleted,
  modal = false,
  quick = false,
  ...buttonProps
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');
  const user = useUser();
  const { usageCategories, regionIds, size } =
    user?.searchRequestSettings ?? {};
  const [open, setOpen] = useState<boolean>(false);
  const apollo = useApolloClient();
  useEffect(() => {
    setValue('');
  }, [searchRequestReferenceId]);

  const [answerSearchRequest, { loading: answerLoading }] =
    useAnswerSearchRequestMutation({
      refetchQueries: [
        {
          query: GetConversationsDocument,
        },
        {
          query: GetSearchRequestStatsDocument,
          variables: {
            usageCategories,
            regionIds,
            size,
          },
        },
      ],
      onCompleted: async () => {
        setOpen(false);
        message.success(t('Inquiry rejected'), 2.5);
        onCompleted();
        await apollo.reFetchObservableQueries();
      },
    });

  if (quick) {
    return (
      <Button
        danger
        icon={<DeleteOutlined />}
        loading={answerLoading}
        {...buttonProps}
        onClick={e => {
          e.stopPropagation();
          answerSearchRequest({
            variables: {
              searchRequestReferenceId,
              status: StatusEnum.Declined,
              message: value,
            },
          });
        }}
      />
    );
  }

  if (modal) {
    return (
      <>
        <Modal
          footer={
            <Buttons
              value={value}
              setValue={setValue}
              answerSearchRequest={answerSearchRequest}
              searchRequestReferenceId={searchRequestReferenceId}
            />
          }
          open={open}
          className={'prevent-request-drawer-close'}
          onCancel={() => setOpen(false)}
          wrapClassName={'prevent-request-drawer-close'}
        >
          <Content value={value} setValue={setValue} />
        </Modal>
        <Button
          danger
          onClick={() => setOpen(true)}
          {...buttonProps}
          icon={<DeleteOutlined />}
          loading={answerLoading}
        >
          {t('Reject')}
        </Button>
      </>
    );
  }

  return (
    <Popover
      placement={'topRight'}
      trigger="click"
      open={open}
      className={'prevent-request-drawer-close'}
      overlayClassName={'prevent-request-drawer-close'}
      onOpenChange={change => setOpen(change)}
      content={
        <Container>
          <Content value={value} setValue={setValue} />
          <Buttons
            value={value}
            setValue={setValue}
            answerSearchRequest={answerSearchRequest}
            searchRequestReferenceId={searchRequestReferenceId}
          />
        </Container>
      }
    >
      {icon && (
        <Tooltip title={t('Reject')}>
          <Button
            danger
            type={'link'}
            icon={<DeleteOutlined />}
            loading={answerLoading}
          />
        </Tooltip>
      )}
      {!icon && (
        <Button
          danger
          {...buttonProps}
          icon={<DeleteOutlined />}
          loading={answerLoading}
        >
          {t('Reject')}
        </Button>
      )}
    </Popover>
  );
}
