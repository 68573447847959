import { Form, Select, SelectProps } from 'antd';
import React from 'react';

import { useGetProjectsByClientQuery } from '@/generated/graphql';

interface Props extends SelectProps {
  projectId?: string;
  clientId: string;
}

function SelectProject({ projectId, clientId, ...props }: Props): JSX.Element {
  const { data, loading, error } = useGetProjectsByClientQuery({
    variables: {
      clientId,
    },
  });
  if (!data || loading || error) {
    return null;
  }

  if (data.projects.length === 0) {
    return null;
  }

  return (
    <Form.Item name={'projectId'} label={'Projekt'} initialValue={projectId}>
      <Select
        {...props}
        allowClear
        options={data.projects.map(project => ({
          label: project.name,
          value: project.id,
        }))}
      />
    </Form.Item>
  );
}

export default SelectProject;
