import { FormInstance } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { GetLeaseAgreementEditQuery } from '@/generated/graphql';
import BasicData from '@/views/LeaseAgreement/Form/BasicData';
import FormData from '@/views/LeaseAgreement/Form/FormData';
import Media from '@/views/LeaseAgreement/Form/Media';

interface Props {
  form: FormInstance;
  values: GetLeaseAgreementEditQuery['leaseAgreement'] | Record<string, never>;
  step: number;
  add?: boolean;
}

const ConcealedDiv = styled.div<{ concealed: boolean }>`
  display: ${props => (props.concealed ? 'none' : 'block')};
`;

function LeaseForm({ form, values, step, add }: Props): JSX.Element {
  return (
    <>
      <ConcealedDiv concealed={step != 0}>
        <BasicData form={form} values={values} />
      </ConcealedDiv>
      <ConcealedDiv concealed={step !== 1}>
        <FormData form={form} values={values} />
      </ConcealedDiv>
      {/* <ConcealedDiv concealed={step !== 2}>
        <Properties form={form} values={values} />
      </ConcealedDiv> */}
      <ConcealedDiv concealed={add ? step < 2 : step !== 2}>
        <Media form={form} values={values} />
      </ConcealedDiv>
    </>
  );
}

export default LeaseForm;
