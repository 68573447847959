import { Form, FormInstance, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';

interface Props {
  form: FormInstance;
}
export default function AddColleagueForm({ form }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box w={300} maxW={300}>
      <Form layout={'vertical'} form={form}>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('required'),
            },
          ]}
          label={t('firstName')}
          name="firstName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('required'),
            },
          ]}
          label={t('lastName')}
          name="lastName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          required
          rules={[
            {
              required: true,
              type: 'email',
              message: t('emailValid'),
            },
          ]}
          label={t('email')}
          name={'email'}
        >
          <Input />
        </Form.Item>
      </Form>
    </Box>
  );
}
