import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';
import Container from '@/layout/Unauthenticated';

const SharedSearchRequest = React.lazy(() => import('.'));

export type SharedSearchRequestRouteProps = MakeGenerics<{
  Params: {
    searchRequestReferenceId?: string;
    token?: string;
  };
  LoaderData: {
    searchRequestReferenceId: string;
    token: string;
  };
}> &
  BasicRouteProps;

const SharedSearchRequestRoutes: Route<SharedSearchRequestRouteProps>[] = [
  {
    path: '/shared',
    id: 'Shared search request',
    element: <Container redirect={false} modal={false} />,

    children: [
      {
        path: ':searchRequestReferenceId/:token',
        element: <SharedSearchRequest />,
        loader: ({ params }) => {
          const { searchRequestReferenceId, token } = params;

          return {
            searchRequestReferenceId,
            token,
          };
        },
      },
      {
        path: ':searchRequestReferenceId',
        element: <SharedSearchRequest />,
        loader: ({ params }) => ({
          searchRequestReferenceId: params.searchRequestReferenceId,
        }),
      },
    ],
  },
];

export default SharedSearchRequestRoutes;
