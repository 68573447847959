import { EyeOutlined } from '@ant-design/icons';
import { Button, Divider, message, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Archive } from 'styled-icons/bootstrap';
import { EmailOutline, PhoneOutline } from 'styled-icons/evaicons-outline';

import LeaseAgreementListPreview from '@/components/LeaseAgreementListPreview';
import Message from '@/components/Message';
import {
  LeaseAgreementProspectStatus,
  useEditLeaseAgreementProspectStatusMutation,
  useGetMyLeaseAgreementProspectQuery,
} from '@/generated/graphql';

import NotInterestingButton from './NotInterestingButton';
interface Props {
  onClose: () => void;
  id?: string;
}

export default function ProspectInformation({
  id,
  onClose,
}: Props): JSX.Element {
  if (!id) {
    return null;
  }
  const { t } = useTranslation();

  const { data, loading, error } = useGetMyLeaseAgreementProspectQuery({
    variables: {
      id,
    },
  });
  const [editLeaseAgreementProspect] =
    useEditLeaseAgreementProspectStatusMutation({
      onError: () => {
        message.error(t('error'));
      },
    });
  if (error) {
    return <Message error={error} />;
  }
  if (loading || !data) {
    return (
      <div className="w-full h-48 flex items-center justify-center">
        <Spin />
      </div>
    );
  }
  const { leaseAgreementProspect: prospect } = data;

  return (
    <div>
      <div className="text-zinc-500 text-sm mb-4">
        {t('Expression of interest from')} {prospect.company}
      </div>
      <div>{t('message')}:</div>
      <div className="mt-2 p-4 text-sm rounded-3xl relative break-words whitespace-pre-line text-black bg-zinc-100 rounded-bl-none">
        {prospect.message}
      </div>
      <div className="flex mt-4">
        {prospect.showContactInfo && prospect.status !== 'declined' && (
          <div>
            <div>{t('contactInfo')}:</div>
            <div className="flex flex-col">
              <div className="text-zinc-500 flex gap-2 items-center">
                <PhoneOutline size={16} />
                {prospect.phone}
              </div>
              <div className="text-zinc-500 flex gap-2 items-center">
                <EmailOutline size={16} />
                <a href={`mailto:${prospect.email}`}> {prospect.email}</a>
              </div>
            </div>
          </div>
        )}
      </div>
      <Divider />
      <div className="border border-solid border-zinc-300 rounded-xl p-4">
        <LeaseAgreementListPreview
          showSize
          leaseAgreement={prospect.leaseAgreement}
        />
      </div>

      <div className="flex justify-end gap-2 mt-4">
        {prospect.status !== 'declined' && (
          <NotInterestingButton id={id} onClose={onClose} />
        )}

        {(!prospect.showContactInfo || prospect.status === 'declined') && (
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={async () =>
              await editLeaseAgreementProspect({
                variables: {
                  id: prospect.id,
                  status: LeaseAgreementProspectStatus.Accepted,
                },
                optimisticResponse: () => ({
                  editLeaseAgreementProspectStatus: {
                    id,
                    status: LeaseAgreementProspectStatus.Accepted,
                    showContactInfo: true,
                  },
                }),
              })
            }
          >
            {t('Show contact information')}
          </Button>
        )}
      </div>
    </div>
  );
}
