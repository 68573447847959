import { useApolloClient } from '@apollo/client';
import { Form, Switch } from 'antd';
import React from 'react';

import Message from '@/components/Message';
import { useToggleActiveAdMutation } from '@/generated/graphql';
interface Props {
  active: boolean;
  adId: string;
}
export default function ToggleActiveAd({ adId, active }: Props): JSX.Element {
  const apollo = useApolloClient();

  const [toggleActiveAd, { loading, error }] = useToggleActiveAdMutation({
    onCompleted: async () => {
      await apollo.reFetchObservableQueries();
    },
  });

  return (
    <>
      <Switch
        loading={loading}
        checked={active}
        onChange={async checked =>
          toggleActiveAd({ variables: { id: adId, active: checked } })
        }
      />
      {error && <Message error={error} />}
    </>
  );
}
