import {
  BarChartOutlined,
  MailOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { Button, Divider, Drawer, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import GoBackArrow from '@/components/GoBackArrow';
import { useGetPortallyPartnerQuery } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';
import theme from '@/main/theme';
import { toHome } from '@/views/Home/routes';
import AdCard from '@/views/PortallyPartner/Ads/AdCard';
import AddAd from '@/views/PortallyPartner/Ads/AddAd';
import {
  PortallyPartnerRouteProps,
  toPortallyPartnerStatistics,
} from '@/views/PortallyPartner/routes';
import ToggleActivePartner from '@/views/PortallyPartner/ToggleActivePartner';
import RemoveSection from '@/views/PortallyPartner/UploadPartnerInformation/RemoveSection';
import UploadSection from '@/views/PortallyPartner/UploadPartnerInformation/UploadSection';

import Page404 from '../Page404';
import ContactModal from './ContactModal';
import IncrementPartnerView from './IncrementPartnerView';
import Section from './Section';

const { Option } = Select;

const PartnerTitle = styled.h1`
  font-size: 48px;
  padding: 8px;
  color: ${p => p.theme.colors.primaryPurpleDark};
  margin-bottom: 0px;
`;

const SelectWrapper = styled.div`
  &&& {
    .ant-select-selector {
      height: 50px;
      font-size: 20px;
      display: flex;
      align-items: center;
    }
  }
`;

const Container = styled(Box)`
  background-color: white;
  border: 1px solid #fff;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 18%) 1px 2px 4px;
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 1050px) {
    border: none;
    box-shadow: none;
    .header {
      padding: 0 !important;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default function PortallyPartner(): JSX.Element {
  const user = useUser();
  const { isMobile } = useWidthAndHeight();

  const navigate = useNavigate();
  const slug = useMatch<PortallyPartnerRouteProps>().data.slug ?? '';

  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] = useState('');
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isAdmin = user?.role === 'admin';

  const aspectRatio = 16 / 9;
  const height = 400;
  const width = Math.round(height * aspectRatio);

  const { data, loading, error } = useGetPortallyPartnerQuery({
    variables: {
      slug,
      width,
      height,
    },
  });

  if (loading && !data) {
    return <></>;
  }

  if (error) {
    return <Page404 />;
  }

  const portallyPartner = data?.portallyPartner;

  if (!portallyPartner) {
    return <></>;
  }

  const { client, sections, ads, contactPerson } = portallyPartner;

  const Content = (): JSX.Element => {
    if (sections.length !== 0 && selectedSection.length !== 0) {
      const section = sections.find(
        section =>
          selectedSection.length === 0 || section.title === selectedSection,
      );
      return (
        <Section
          header={section.title}
          text={section.text}
          displayResource={section.displayResource}
        />
      );
    }
    if (sections.length !== 0 && selectedSection.length === 0) {
      const section = sections.find(
        section =>
          selectedSection.length === 0 || section.title === selectedSection,
      );
      return (
        <Section
          header={section.title}
          text={section.text}
          displayResource={section.displayResource}
        />
      );
    }
    return <></>;
  };

  const AdminButtons = (): JSX.Element => (
    <Box m={2} gap={2}>
      <Button
        type="primary"
        shape="circle"
        size={'large'}
        icon={<MenuUnfoldOutlined />}
        onClick={() => setDrawerOpen(true)}
      />
      <Button
        shape="circle"
        size={'large'}
        onClick={() => {
          navigate({
            to: toPortallyPartnerStatistics(portallyPartner.slug),
          });
        }}
        icon={<BarChartOutlined />}
      />
    </Box>
  );

  return (
    <>
      <div
        style={{
          padding: '24px',
          background: theme.colors.light,
          minHeight: isMobile
            ? `calc(100vh - 32px - ${theme.dimensions.mobileNavOffset})`
            : '100vh',
        }}
      >
        <IncrementPartnerView />
        <Drawer
          title="Admin"
          placement={'left'}
          width={500}
          onClose={() => setDrawerOpen(false)}
          visible={drawerOpen}
          extra={
            <ToggleActivePartner slug={slug} active={portallyPartner.active} />
          }
        >
          <div>
            <h2 className={'font-semibold text-lg'}>Sidor</h2>
            <UploadSection />
            <div className={'flex flex-col space-y-2'}>
              {sections.map(section => (
                <div
                  key={section.id}
                  className={
                    'flex flex-col justify-between p-4 bg-white rounded-lg border border-solid border-zinc-300 shadow-md space-y-2'
                  }
                >
                  <div className={'flex flex-col'}>
                    <div className={'text-base mb-2 font-semibold'}>
                      {section.title}
                    </div>
                    <div
                      className={'text-sm text-zinc-500'}
                      dangerouslySetInnerHTML={{
                        __html: section.text,
                      }}
                    />
                  </div>
                  <img
                    src={section.displayResource.url}
                    className={'w-full object-contain rounded-lg'}
                  />
                  <div className={'flex justify-end'}>
                    <RemoveSection sectionId={section.id}>Radera</RemoveSection>
                  </div>
                </div>
              ))}
            </div>
            <Divider />
            <h2 className={'font-semibold text-lg mt-5 mb-3'}>Annonser</h2>

            <div className={'mb-4'}>
              <div className={'text-base mb-2 text-zinc-500'}>
                Lägg till en ny annons
              </div>
              <AddAd partnerId={portallyPartner.id} />
            </div>

            <div className={'space-y-4'}>
              {ads.map(ad => (
                <AdCard ad={ad} key={ad.id} />
              ))}
            </div>
          </div>
        </Drawer>
        {isAdmin && <AdminButtons />}
        {user && <GoBackArrow to={toHome()} />}
        <Container flex flexDirection="column" px={8} py={4}>
          <Box className={'header'} flex flexWrap="wrap">
            <Box p={1} flex flexDirection="column">
              {client?.image?.url ? (
                <img
                  style={{ height: '60px', width: 'auto' }}
                  src={client.image?.url}
                />
              ) : (
                <PartnerTitle>{client.name}</PartnerTitle>
              )}
            </Box>
            <Box
              p={1}
              flex
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              {sections.length > 1 && (
                <SelectWrapper>
                  {sections.length > 0 && (
                    <Select
                      bordered={false}
                      onChange={val => setSelectedSection(val)}
                      size="large"
                      style={{ width: '300px' }}
                      defaultValue={sections[0] ? sections[0].title : undefined}
                    >
                      {sections.map(section => (
                        <Option value={section.title} key={section.title}>
                          {section.title}
                        </Option>
                      ))}
                    </Select>
                  )}
                </SelectWrapper>
              )}

              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '24px',
                  marginLeft: '8px',
                  padding: '24px',
                  borderRadius: '22px',
                }}
                size={'large'}
                type="primary"
                onClick={() => setMessageModalOpen(true)}
                icon={<MailOutlined style={{ marginTop: 4 }} />}
              >
                {t('getInTouch')}
              </Button>
            </Box>
          </Box>
          {sections.length > 0 && <Content />}
        </Container>
        <ContactModal
          slug={slug}
          contactPerson={contactPerson}
          visible={messageModalOpen}
          setVisible={setMessageModalOpen}
        />
      </div>
    </>
  );
}
