import { SearchOutlined } from '@ant-design/icons';
import React, { useCallback, useState } from 'react';

import SearchRequestModal from '@/components/SearchRequest/SearchRequestModal';

import { Border, getNavigationColor, NoLinkItem } from '.';

interface Props {
  menuOpen: boolean;
}

function CreateSearchRequestLink({ menuOpen }: Props): JSX.Element {
  const [visible, setVisible] = useState(false);

  const color = useCallback(() => getNavigationColor(menuOpen), [menuOpen]);
  return (
    <>
      <SearchRequestModal
        visible={visible}
        onCancel={() => setVisible(false)}
      />
      <NoLinkItem
        $open={menuOpen}
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <SearchOutlined />
      </NoLinkItem>
    </>
  );
}

export default CreateSearchRequestLink;
