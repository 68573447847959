import React from 'react';

import { SizeTagContainer as Container } from '../index.styled';

interface Props {
  children: React.ReactNode;
  size?: 'small' | 'middle';
}

export default function SizeTag({
  children,
  size = 'middle',
}: Props): JSX.Element {
  if (!children) {
    return null;
  }
  return <Container size={size}>{children}</Container>;
}
