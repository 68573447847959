import { MessageOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConversationPreviewModal from '@/components/ConversationPreview/ConversationPreviewModal';
import SearchRequestDrawer from '@/components/LeaseAgreementList/SearchRequestDrawer';
import MatchIcon from '@/components/MatchIcon';
import { GetLeaseAgreementViewQueryResult } from '@/generated/graphql';
import useUser from '@/hooks/useUser';

interface Props {
  matchingSearchRequests: GetLeaseAgreementViewQueryResult['data']['leaseAgreement']['matchingSearchRequests'];
  conversations: GetLeaseAgreementViewQueryResult['data']['leaseAgreement']['conversations'];
  leaseAgreementId: string;
}

export default function MyConversationsAffix({
  matchingSearchRequests,
  conversations,
  leaseAgreementId,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [activeConversationId, setActiveConversationId] = useState<
    string | null
  >(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [clicked, setClicked] = useState(false);
  const user = useUser();
  const activeConversations = conversations.map(conv => ({
    ...conv,
    participant: conv.participants.find(p => p.id != user?.activeClientId),
  }));
  const isAgreementOwner = user?.activeClient.type === 'landlord';

  if (!isAgreementOwner) {
    return null;
  }
  return (
    <div className="top-6 flex flex-col flex-shrink-0 h-fit m-tablet:max-w-[320px] border border-solid border-zinc-200 p-4 rounded-2xl shadow bg-white m-mobile:w-full mobile:mt-6 mobile:bg-none">
      {matchingSearchRequests.count !== 0 && (
        <>
          <div className="flex align-center text-zinc-500 space-x-2 mb-2">
            <div
              className=" hover:cursor-pointer  text-lg text-[#1677ff] hover:text-[#69b1ff] flex"
              onClick={() => {
                setClicked(true);
                setOpenDrawer(true);
              }}
            >
              <MatchIcon />
              <div className="pl-0 text-base">{`Matchande lokalförfrågningar (${matchingSearchRequests.count})`}</div>
            </div>

            {clicked && (
              <SearchRequestDrawer
                title={t('Matching search requests')}
                leaseAgreementId={leaseAgreementId}
                onClose={() => {
                  setOpenDrawer(false);
                  setClicked(false);
                }}
                open={openDrawer}
              />
            )}
          </div>
          <Divider className="py-2 my-2" />
        </>
      )}

      <div className="flex align-center text-zinc-500 space-x-2 mb-2">
        <MessageOutlined />

        <div className="text-md">{t('Active conversations')}</div>
      </div>
      <div className="p-4 pl-6">
        {activeConversations.length > 0 ? (
          activeConversations.map(({ participant, id }) => (
            <div key={id} className="flex align-center space-x-2 mb-2">
              <div className="flex justify-center flex-col">
                <div className="text-zinc-500 mr-2 mt-1">
                  {participant.name}
                </div>
                <Button
                  icon={<ZoomInOutlined />}
                  type={'link'}
                  style={{ paddingLeft: 0 }}
                  onClick={() => setActiveConversationId(id)}
                >
                  {t('Show conversation')}
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div>{t('No active conversations')}</div>
        )}
      </div>
      <ConversationPreviewModal
        conversationId={activeConversationId}
        onClose={() => setActiveConversationId(null)}
      />
    </div>
  );
}
