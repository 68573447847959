import React from 'react';

import CompactLinkPreview from '@/components/LinkPreview/CompactLinkPreview';
import { translateUrl } from '@/components/UploadFile/util';
import { useGetLinkPreviewQuery } from '@/generated/graphql';
import theme from '@/main/theme';

interface Props {
  url: string;
  mine: boolean;
}

function LinkPreview({ url, mine }: Props): JSX.Element {
  const { data, error } = useGetLinkPreviewQuery({
    variables: {
      url,
    },
  });

  if (
    error ||
    !data ||
    !data?.linkPreview?.title ||
    !data?.linkPreview?.description
  ) {
    return (
      <a
        href={url}
        target={'_blank'}
        rel={'noopener noreferrer'}
        style={{
          color: mine ? theme.colors.white : undefined,
        }}
      >
        {url}
      </a>
    );
  }
  if (!data.linkPreview?.image) {
    return (
      <a
        href={url}
        target={'_blank'}
        rel={'noopener noreferrer'}
        className="underline"
        style={{
          color: mine ? theme.colors.white : undefined,
        }}
      >
        {data.linkPreview.title}
      </a>
    );
  }
  return (
    <div
      onClick={() =>
        window.open(translateUrl(url), '_blank', 'noopener noreferrer')
      }
      className={'w-full'}
    >
      <CompactLinkPreview
        title={data.linkPreview.title}
        description={data.linkPreview.description.slice(0, 100)}
        image={data.linkPreview.image}
        mine={mine}
      />
    </div>
  );
}

export default LinkPreview;
