import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { BasicRouteProps } from '@/layout/routes';

export type SubscriptionRouteProps = BasicRouteProps;
const Subscription = lazy(() => import('.'));

const SubscriptionRoutes: Route<SubscriptionRouteProps>[] = [
  {
    path: 'subscription',
    id: 'Subscription',
    element: <Subscription />,
    meta: {
      noPaddingPhone: true,
      whiteBackgroundPhone: true,
    },
  },
];

export const toSubscription = (): string => '/subscription';

export default SubscriptionRoutes;
