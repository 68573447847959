import { Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { GetInvoiceDetailsQuery } from '@/generated/graphql';
import useUser from '@/hooks/useUser';
interface Props {
  invoiceDetails: GetInvoiceDetailsQuery['client']['invoiceDetails'];
  required?: boolean;
}
export default function InvoiceDetailsForm({
  invoiceDetails,
  required = false,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const user = useUser();

  return (
    <>
      <Form.Item
        rules={required ? [{ required, message: t('required') }] : []}
        help={'Avser det bolag som står som mottagare av fakturan'}
        label={t('companyNameSecondary')}
        name={['invoiceDetails', 'company']}
        initialValue={user?.role === 'admin' ? null : user.activeClient.name}
      >
        <Input type={'text'} />
      </Form.Item>
      <Form.Item
        initialValue={invoiceDetails?.billing ?? undefined}
        rules={[{ required, message: t('required') }]}
        label={t('Billing address')}
        name={['invoiceDetails', 'billing']}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item
        initialValue={invoiceDetails?.email ?? undefined}
        rules={required ? [{ required, message: t('required') }] : []}
        label={t('email')}
        name={['invoiceDetails', 'email']}
        help={'Avser den epostadress som fakturan skall skickas till'}
      >
        <Input type={'email'} />
      </Form.Item>
      <Form.Item
        initialValue={invoiceDetails?.reference ?? undefined}
        rules={required ? [{ required, message: t('required') }] : []}
        label={t('Reference')}
        name={['invoiceDetails', 'reference']}
        help={'Avser den person hos er som står som referens på fakturan'}
      >
        <Input type={'text'} />
      </Form.Item>
      <Form.Item
        initialValue={invoiceDetails?.marking ?? undefined}
        label={t('Marking')}
        name={['invoiceDetails', 'marking']}
        help={'Den märkning ni önskar på fakturan'}
      >
        <Input type={'text'} />
      </Form.Item>
    </>
  );
}
