import { Form, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import SelectUsageCategories from '@/components/SelectUsageCategories';
import Tag from '@/components/Tags/UsageCategoryTags';

const { Title } = Typography;

interface Props {
  usageCategories: string[];
  editing: boolean;
}

function UsageCategory({ usageCategories, editing }: Props): JSX.Element {
  const { t } = useTranslation();

  if (usageCategories?.length < 1 && !editing) {
    return null;
  }
  return (
    <Box>
      <Title level={5} style={{ fontWeight: 500 }}>
        {t('usageCategory')}
      </Title>
      {editing ? (
        <Form.Item
          initialValue={usageCategories}
          name={'usageCategories'}
          rules={[
            {
              required: true,
              message: t('usageCategoryRequired'),
            },
          ]}
        >
          <SelectUsageCategories
            allowClear
            placeholder={t('Choose')}
            mode={'multiple'}
            showSearch={false}
            showArrow
          />
        </Form.Item>
      ) : (
        <Box flex flexWrap={'wrap'} gap={0.5}>
          {usageCategories.map(value => (
            <Tag usageCategory={value} key={value} inverted />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default UsageCategory;
