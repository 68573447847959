import { Badge } from 'antd';
import React from 'react';

import MatchIcon from '@/components/MatchIcon';
import {
  useGetUnreadProspectsQuery,
  useSubscribeToNotificationsSubscription,
} from '@/generated/graphql';
import useAppSelector from '@/hooks/useAppSelector';
import useUser from '@/hooks/useUser';
import { NavLink } from '@/layout/Customer/LeftMenu/index.styled';
import { toLeaseAgreementsProspects } from '@/views/LeaseAgreementProspects/routes';

function LeaseAgreementProspects(): JSX.Element {
  const user = useUser();
  const filter = useAppSelector(
    state => state.customer.activeSearchRequestFilter,
  );
  const { data, previousData, refetch } = useGetUnreadProspectsQuery({});

  useSubscribeToNotificationsSubscription({
    onSubscriptionData: async () => {
      await refetch();
    },
  });

  return (
    <NavLink to={toLeaseAgreementsProspects()}>
      <Badge count={(data ?? previousData)?.leaseAgreementProspects.unread}>
        <MatchIcon style={{ fontSize: '22px' }} />
      </Badge>
      <span className="text text-xs">Intresseanmälningar</span>
    </NavLink>
  );
}

export default LeaseAgreementProspects;
