import { Route } from '@tanstack/react-location';
import React from 'react';

import Customer from '@/layout/Customer/index';
import { BasicRouteProps } from '@/layout/routes';
import MyActiveSearchRequestsRoutes from '@/views/ActiveSearchRequests/routes';
import AuthenticateRoutes, {
  AuthenticateRouteProps,
} from '@/views/Authenticate/routes';
import ClientRoutes from '@/views/Client/routes';
import EstablishmentRoute from '@/views/Establishment/routes';
import HomeRoutes from '@/views/Home/routes';
import LeaseAgreementRoute, {
  LeaseAgreementRouteProps,
} from '@/views/LeaseAgreement/routes';
import LeaseAgreementProspectsRoutes from '@/views/LeaseAgreementProspects/routes';
import MyLeaseAgreementsRoutes, {
  MyLeaseAgreementsRouteProps,
} from '@/views/Management/routes';
import MessagesRoutes, { MessagesRouteProps } from '@/views/Messages/routes';
import NewsRoutes from '@/views/News/routes';
import NotificationsRoute from '@/views/Notifications/routes';
import Page404 from '@/views/Page404';
import SearchRequestLandlordRoute, {
  SearchRequestLandlordRouteProps,
} from '@/views/SearchRequestLandlord/route';
import SearchRequestTenantRoutes from '@/views/SearchRequestTenant/routes';
import SubscriptionRoutes from '@/views/Subscription/routes';
import TermsOfServiceRoute from '@/views/TermsOfService/routes';
import UserRoutes from '@/views/Users/routes';

export type CustomerRouteProps = SearchRequestLandlordRouteProps &
  MyLeaseAgreementsRouteProps &
  LeaseAgreementRouteProps &
  MessagesRouteProps &
  BasicRouteProps &
  AuthenticateRouteProps;

const CustomerRoutes: Route<CustomerRouteProps>[] = [
  ...AuthenticateRoutes,
  {
    path: '/',
    id: 'Customer',
    element: <Customer />,
    children: [
      ...HomeRoutes,
      ...LeaseAgreementProspectsRoutes,
      ...SearchRequestLandlordRoute,
      ...LeaseAgreementRoute,
      ...MyLeaseAgreementsRoutes,
      ...MyActiveSearchRequestsRoutes,
      ...ClientRoutes,
      ...MessagesRoutes,
      ...NotificationsRoute,
      ...NewsRoutes,
      ...EstablishmentRoute,
      ...SearchRequestTenantRoutes,
      ...UserRoutes,
      ...TermsOfServiceRoute,
      ...SubscriptionRoutes,
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default CustomerRoutes;
