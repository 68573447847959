import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { createClient } from 'graphql-ws';

import { StrictTypedTypePolicies } from '@/generated/graphql';
import Bugsnag from '@/main/bugsnag';

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (
    operation.variables &&
    !{}.hasOwnProperty.call(operation.variables, 'files') &&
    !{}.hasOwnProperty.call(operation.variables, 'file')
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const omitTypename = (key, value): any =>
      key === '__typename' ? undefined : value;

    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }
  return forward(operation);
});

const getAuthToken = (): string =>
  // get the authentication token from local storage if it exists
  localStorage.getItem('auth-token') || '';
const authLink = setContext((_, { headers }) =>
  // return the headers to the context so httpLink can read them
  ({
    headers: {
      ...headers,
      'X-Auth-Token': getAuthToken(),
    },
  }),
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const messages = graphQLErrors.map(
      ({ message, locations, path }) =>
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    );

    Bugsnag.notify(new Error(messages.join(', ')), event => {
      event.severity = 'warning';
      event.addMetadata('queryDetails', {
        graphQLErrors,
      });
    });
  }
  if (networkError) {
    Bugsnag.notify(
      new Error(
        `[Network error]: ${
          typeof networkError === 'object' ? networkError.message : networkError
        }`,
      ),
      event => {
        event.severity = 'warning';
        event.addMetadata('queryDetails', {
          networkError,
        });
      },
    );
  }
});

const httpLink = new HttpLink({
  uri: `${import.meta.env.VITE_WEB_URL}/api`,
});

// Create a WebSocket link:

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_WS_URL,
    connectionParams: () => {
      const authToken = getAuthToken();
      return {
        authToken,
      };
    },
    shouldRetry: () => true,
  }),
);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const apiLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const link = ApolloLink.from([authLink, cleanTypeName, errorLink, apiLink]);

const typePolicies: StrictTypedTypePolicies = {
  LeaseAgreement: {
    fields: {
      address: {
        merge: true,
      },
    },
  },
  ActiveSearchRequestData: {
    merge: true,
  },
  SearchRequest: {
    fields: {
      searchParameters: {
        merge: true,
      },
    },
    merge: true,
  },
  SearchRequestReference: {
    merge: true,
  },
  Message: {
    fields: {
      reactions: {
        merge: (prev, next) => next,
      },
    },
  },
  Conversation: {
    fields: {
      unread: {
        merge: true,
      },
    },
  },
};

export const cache = new InMemoryCache({
  typePolicies,
});

const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
});

//client.onResetStore(() => cache.writeData({ data }));

export default client;
