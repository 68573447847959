import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { polygonColors } from '@/components/Map/mapstyle';
import { clearPolygon } from '@/components/Map/util';

interface Props {
  polygons?: google.maps.LatLngLiteral[][];
  setPolygons?: (polygons: google.maps.LatLngLiteral[][]) => void;
}

const Clear = styled.div`
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  max-height: 150px;
  display: flex;
  z-index: 999;
`;

function ClearPolygons({ polygons, setPolygons }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Clear>
      {polygons &&
        polygons.map((polygon, i) => (
          <Tooltip title={t('Remove polygon')} placement={'left'} key={i}>
            <Button
              icon={<DeleteOutlined />}
              style={{
                background:
                  polygonColors.length > i
                    ? polygonColors[i]
                    : 'rgb(132 138 119)',
                margin: '5px',
                color: '#FFF',
                borderColor: 'none',
              }}
              onClick={() => {
                clearPolygon(i, polygons, polygons => setPolygons(polygons));
              }}
            />
          </Tooltip>
        ))}
    </Clear>
  );
}

export default ClearPolygons;
