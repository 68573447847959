import { ZoomInOutlined } from '@ant-design/icons';
import { ButtonProps, Popover, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Information from '@/components/SearchRequest/shared/Information';
import { useGetSearchRequestLandlordQuery } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import theme from '@/main/theme';

import Box, { Ellipsis } from '../Box';
import Message from '../Message';

interface Props {
  id: string;
  inline?: boolean;
  title?: string;
  disabled?: boolean;

  button?: React.FunctionComponent<ButtonProps>;
}

const { Text, Link } = Typography;

function SearchRequestInformationModal({
  id,
  inline = false,
  title,
  disabled = true,
  button: SearchButton,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  const [visible, setVisible] = React.useState(false);
  const { data, error } = useGetSearchRequestLandlordQuery({
    variables: {
      id,
    },
  });

  if (error) {
    return <Message error={error} />;
  }

  return (
    <>
      {data?.searchRequestReference && (
        <Information
          searchRequestReference={data?.searchRequestReference}
          type={isMobile ? 'drawer' : 'modal'}
          open={visible}
          onCancel={() => setVisible(false)}
        />
      )}

      {SearchButton && <SearchButton onClick={() => setVisible(!visible)} />}
      {!SearchButton && (
        <Box
          flex
          {...(inline ? {} : { justifyContent: 'space-between' })}
          flexWrap={'wrap'}
          style={{ width: '100%' }}
        >
          {title && (
            <>
              {!disabled ? (
                <Text style={{ color: theme.colors.red }}>
                  {t('The tenant has ended their search')}
                </Text>
              ) : (
                <Popover content={<Box>{title}</Box>} placement={'bottom'}>
                  <Ellipsis>{title}</Ellipsis>
                </Popover>
              )}
            </>
          )}

          <Link
            onClick={() => setVisible(!visible)}
            style={title ? { marginLeft: 'auto' } : {}}
          >
            <ZoomInOutlined style={{ marginLeft: '4px' }} /> {t('Show inquiry')}
          </Link>
        </Box>
      )}
    </>
  );
}

export default SearchRequestInformationModal;
