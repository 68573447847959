import { Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

const Notifications = React.lazy(() => import('.'));

export type NotificationsProps = BasicRouteProps;

const NotificationsRoute: Route<NotificationsProps>[] = [
  {
    path: '/notifications',
    id: 'Notifications',
    element: <Notifications />,
    meta: {
      noPaddingPhone: true,
      whiteBackgroundPhone: true,
    },
  },
];

export const toNotifications = (): string => '/notifications';

export default NotificationsRoute;
