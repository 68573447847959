import { useApolloClient } from '@apollo/client';
import { useMatch } from '@tanstack/react-location';
import { Button, Checkbox, Form, Input, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import EditorField from '@/components/EditorField';
import Message from '@/components/Message';
import UploadFile from '@/components/UploadFile';
import { useAddPartnerSectionMutation } from '@/generated/graphql';
import { PortallyPartnerRouteProps } from '@/views/PortallyPartner/routes';

export default function UploadText(): JSX.Element {
  const { slug } = useMatch<PortallyPartnerRouteProps>().data;

  const [form] = Form.useForm();
  const apollo = useApolloClient();
  const { t } = useTranslation();
  const [addPartnerSection, { loading, error }] = useAddPartnerSectionMutation({
    onCompleted: () => {
      apollo.reFetchObservableQueries();
      form.resetFields();
      message.success('Sparad');
    },
  });

  return (
    <Form
      onFinish={async fd => {
        await addPartnerSection({
          variables: {
            slug,
            sectionInput: {
              ...fd,
            },
          },
        });
      }}
      layout={'vertical'}
      form={form}
    >
      <Form.Item
        required
        name={'title'}
        label={t('title')}
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <Input placeholder="Titel" />
      </Form.Item>
      <Form.Item
        required
        name={'Text'}
        label={'text'}
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <EditorField />
      </Form.Item>
      <Form.Item
        name={'displayResourceId'}
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <UploadFile accept={'*'} listType={'picture-card'} />
      </Form.Item>
      <Form.Item name={'targetGroup'}>
        <Checkbox.Group
          options={[
            {
              label: t('landlord'),
              value: 'landlord',
            },
            {
              label: t('tenant'),
              value: 'tenant',
            },
          ]}
        />
      </Form.Item>
      <Form.Item>
        <Box flex justifyContent="flex-end">
          <Button
            loading={loading}
            key="submit"
            type="primary"
            htmlType="submit"
          >
            Ladda upp
          </Button>
        </Box>
        {error && <Message error={error} />}
      </Form.Item>
    </Form>
  );
}
