import { Modal, Select, Typography } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { getI18n } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import theme from '@/main/theme';
const { Text } = Typography;
const { Option } = Select;
const Locale = styled.div<{ $selected: boolean }>`
  cursor: pointer;
  background-color: #fff;
  transition: all 0.12s ease-in;
  :hover {
    border: 1px solid ${theme.colors.primaryPurple} !important;
  }
  width: 128px;
  height: 64px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border: 1px solid #d9d9d9;
  border: ${p => p.$selected && `1px solid ${theme.colors.primaryPurple}`};
  background: ${p => p.$selected && '#ebe6f5'};
`;
interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
function LocaleChanger({ visible, setVisible }: Props): JSX.Element | null {
  const i18n = getI18n();
  const { isMobile } = useWidthAndHeight();
  const language = i18n.language;
  const onChangeLanguage = async (lang: string): Promise<void> => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('lng', lang);
  };

  if (isMobile) {
    return (
      <Select
        style={{ width: '100%' }}
        onChange={lng => i18n.changeLanguage(lng)}
        value={language}
        size={'large'}
      >
        <Option value={'sv'}>
          <div>Svenska</div>
          <div>
            <Typography.Text type={'secondary'}>SV</Typography.Text>
          </div>
        </Option>
        <Option value={'en'}>
          <div>English</div>
          <div>
            <Typography.Text type={'secondary'}>EN</Typography.Text>
          </div>
        </Option>
      </Select>
    );
  }
  return (
    <>
      <Text>{language === 'sv' ? 'Svenska' : 'English'}</Text>
      <Modal
        open={visible}
        closable={false}
        width={350}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <Box flex justifyContent={'center'} horizontalSpacing={2}>
          <Box onClick={async () => await onChangeLanguage('sv')}>
            <Locale $selected={language === 'sv'}>
              <Box flex flexDirection={'column'}>
                <div>Svenska</div>
                <div>
                  <Typography.Text type={'secondary'}>SV</Typography.Text>
                </div>
              </Box>
            </Locale>
          </Box>
          <Box onClick={async () => await onChangeLanguage('en')}>
            <Locale $selected={language === 'en'}>
              <Box flex flexDirection={'column'}>
                <div>English</div>
                <div>
                  <Typography.Text type={'secondary'}>EN</Typography.Text>
                </div>
              </Box>
            </Locale>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default LocaleChanger;

/*
const menu = (
  <Menu>
    <Menu.Item
      onClick={async () => {
        await i18n.changeLanguage('sv');
        localStorage.setItem('lng', 'sv');
      }}
    >
      <ReactCountryFlag countryCode="SE" style={{ marginRight: '4px' }} />
      Svenska
    </Menu.Item>
    <Menu.Item
      onClick={async () => {
        await i18n.changeLanguage('en');
        localStorage.setItem('lng', 'en');
      }}
    >
      <ReactCountryFlag countryCode="GB" style={{ marginRight: '4px' }} />{' '}
      English
    </Menu.Item>
  </Menu>
);
*/
/*
function LocaleChanger(): JSX.Element {
  const i18 = getI18n();
  const { isMobile } = useWidthAndHeight();

  return (
    <Select
      bordered={isMobile}
      style={{ width: '100%' }}
      onChange={lng => i18n.changeLanguage(lng as string)}
      value={i18.language}
      size={isMobile ? 'large' : 'middle'}
    >
      <Option value={'sv'}>
        <ReactCountryFlag countryCode="SE" style={{ marginRight: '4px' }} />
        Svenska
      </Option>
      <Option value={'en'}>
        <ReactCountryFlag countryCode="GB" style={{ marginRight: '4px' }} />{' '}
        English
      </Option>
    </Select>
  );

  /*
  return (
    <Dropdown overlay={menu}>
      <GlobalOutlined style={{ fontSize: '18px' }} />
    </Dropdown>
  );

}

export default LocaleChanger;
*/
