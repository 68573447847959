import React from 'react';
import { useTranslation } from 'react-i18next';

import UsageCategories from '@/components/LeaseAgreementList/UsageCategories';
import { AdValuesFragment } from '@/generated/graphql';
import DeleteAdButton from '@/views/PortallyPartner/Ads/DeleteAdButton';
import EditAd from '@/views/PortallyPartner/Ads/EditAd';
import ToggleActiveAd from '@/views/PortallyPartner/Ads/ToggleActiveAd';

interface Props {
  ad: AdValuesFragment;
}

function AdCard({ ad }: Props) {
  const { t } = useTranslation();
  return (
    <div
      key={ad.id}
      className={
        'max-w-full border border-solid border-zinc-300 shadow rounded-lg h-full flex flex-col p-4'
      }
    >
      <div
        className={
          'rounded-lg border border-solid border-zinc-300 overflow-hidden'
        }
      >
        <div className={'p-2 bg-gray-100 flex flex-wrap gap-2'}>
          <div className={'bg-red-400 rounded-full w-2 h-2'} />
          <div className={'bg-yellow-400 rounded-full w-2 h-2'} />
          <div className={'bg-green-400 rounded-full w-2 h-2'} />
        </div>
        <div className={'p-2'}>
          <img
            src={ad.image.url}
            className={'object-contain max-w-full rounded-lg'}
          />
        </div>
      </div>
      {ad.mobileImage && (
        <div
          className={
            'w-48 h-64 border-2 border-solid rounded-lg border-black mt-3 flex flex-col justify-between'
          }
        >
          <div>
            <div className={'flex items-center justify-center'}>
              <div
                className={'w-20 h-1 mb-1 bg-black rounded-bl-lg rounded-br-lg'}
              ></div>
            </div>
            <div className={'p-2'}>
              <img
                src={ad.mobileImage.url}
                className={'object-contain max-w-full rounded-lg'}
              />
            </div>
          </div>
          <div className={'flex items-center justify-center'}>
            <div className={'w-8 h-0.5 mb-1 bg-black'}></div>
          </div>
        </div>
      )}
      <div className={'mt-auto'}>
        <h3 className={'font-semibold mt-2'}>{t(ad.placement)}</h3>
        <p className={'font-normal'}>{ad.link}</p>
        <UsageCategories usageCategories={ad.usageCategories} inverted />
        <div className="mt-2">
          {ad.regions.map(region => region.name).join(', ')}
        </div>
        <div className={'flex justify-end space-x-2 items-center mt-3'}>
          <span className={'text-zinc-500'}>Aktiverad</span>{' '}
          <ToggleActiveAd adId={ad.id} active={ad.active} />
          <EditAd ad={ad} />
          <DeleteAdButton adId={ad.id} />
        </div>
      </div>
    </div>
  );
}

export default AdCard;
