import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { BasicRouteProps } from '@/layout/routes';

import LeaseAgreementProspects from '.';

export type LeaseaAgreementProspectsRouteProps = BasicRouteProps;

const LeaseAgreementProspectsRoutes: Route<LeaseaAgreementProspectsRouteProps>[] =
  [
    {
      path: '/prospects',
      id: 'LeaseAgreementsProspects',
      element: <LeaseAgreementProspects />,
      meta: {
        noPaddingPhone: true,
      },
    },
  ];

export const toLeaseAgreementsProspects = (): string => '/prospects';

export default LeaseAgreementProspectsRoutes;
