import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import Container from '@/layout/Unauthenticated';
const EndSearchRequest = React.lazy(() => import('.'));

export type EndSearchRequestRouteProps = MakeGenerics<{
  Params: {
    searchRequestReferenceId?: string;
    token?: string;
  };
  LoaderData: {
    searchRequestReferenceId: string;
    token: string;
  };
}>;

const EndSearchRequestRoutes: Route<EndSearchRequestRouteProps>[] = [
  {
    path: '/end-search/:searchRequestReferenceId/:token',
    element: <Container />,
    children: [
      {
        path: '/',
        element: <EndSearchRequest />,
        loader: ({ params }) => ({
          searchRequestReferenceId: params.searchRequestReferenceId,
          token: params.token,
        }),
      },
    ],
  },
];

export const toEndSearchRequest = (): string => '';

export default EndSearchRequestRoutes;
