import { Attachment } from '@styled-icons/icomoon/Attachment';
import { Form, List, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import UploadFile from '@/components/UploadFile';
import { SearchRequestReferenceInformationValuesFragment } from '@/generated/graphql';

const { Text } = Typography;

interface Props {
  documents: SearchRequestReferenceInformationValuesFragment['documents'];
  editing: boolean;
}

function Documents({ documents, editing }: Props): JSX.Element {
  const { t } = useTranslation();

  if ((!documents || documents.length == 0) && !editing) {
    return null;
  }

  return (
    <Box>
      <div>
        <Text type={'secondary'}>{t('documents')}</Text>
      </div>
      {editing ? (
        <Form.Item
          name="documentIds"
          initialValue={documents?.map(d => d.id) ?? []}
        >
          <UploadFile
            multiple
            accept={'*'}
            listType={'text'}
            initialValues={documents ?? []}
          />
        </Form.Item>
      ) : (
        <List
          size={'small'}
          dataSource={documents}
          renderItem={item => (
            <div key={item.url}>
              <a href={item.url} target={'_blank'} rel={'noreferrer'}>
                <Attachment size={14} /> {item.name}
              </a>
            </div>
          )}
        />
      )}
    </Box>
  );
}

export default Documents;
