import { DrawingManager } from '@react-google-maps/api';
import React from 'react';

import { addPolygon } from '@/components/Map/util';

interface Props {
  polygons: google.maps.LatLngLiteral[][];
  setPolygons: (polygons: google.maps.LatLngLiteral[][]) => void;
  drawing: boolean;
}

function Drawing({ polygons, setPolygons, drawing }: Props): JSX.Element {
  return (
    <DrawingManager
      onPolygonComplete={e =>
        addPolygon(e, polygons, polygons => setPolygons(polygons))
      }
      options={{
        drawingMode: drawing
          ? window['google'].maps.drawing.OverlayType.POLYGON
          : null,
        drawingControlOptions: {
          drawingModes: [window['google'].maps.drawing.OverlayType.POLYGON],
        },
      }}
    />
  );
}

export default Drawing;
