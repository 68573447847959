import {
  FileDoneOutlined,
  HeartOutlined,
  SearchOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client/react/hooks/useLazyQuery';
import { Attachment } from '@styled-icons/entypo/Attachment';
import { Office } from '@styled-icons/icomoon/Office';
import { NotificationsActive } from '@styled-icons/material-outlined/NotificationsActive';
import { Link } from '@tanstack/react-location';
import { Avatar, Drawer } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Search } from 'styled-icons/material-rounded';
import { Forum, RecordVoiceOver } from 'styled-icons/material-rounded';

import MatchIcon from '@/components/MatchIcon';
import LandlordModal from '@/components/SearchRequest/Landlord';
import {
  GetLeaseAgreementViewDocument,
  GetMySearchRequestByReferenceIdDocument,
  NotificationValuesFragment,
  SearchRequestCardValuesFragment,
  StatusEnum,
} from '@/generated/graphql';
import theme from '@/main/theme';
import DetailedView from '@/views/ActiveSearchRequests/DetailedView';
import LeaseAgreement from '@/views/LeaseAgreement/View';

import { PreviewDrawerFooter } from '../Markers/PreviewDrawerFooter';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  b {
    font-weight: 600;
  }
`;

export const SquaredIcon = (
  background: string,
  color: string,
  Icon: () => JSX.Element,
): JSX.Element => (
  <Avatar
    shape={'square'}
    icon={<Icon />}
    style={{
      background,
      color,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  />
);

const Icons = {
  newLeaseAgreementProspect: SquaredIcon('#ff7369', 'white', () => (
    <MatchIcon />
  )),

  newReaction: SquaredIcon('#abefb8', 'white', () => <SmileOutlined />),
  newMatch: SquaredIcon('#ff7369', 'white', () => <MatchIcon />),
  newSearchHit: SquaredIcon('#336699', 'white', () => <SearchOutlined />),
  closedSearchRequest: SquaredIcon('#e6e0ff', '#8a7ebe', () => (
    <FileDoneOutlined />
  )),
  newMessage: SquaredIcon(theme.colors.lightBlue, 'white', () => (
    <Forum size={'20'} />
  )),
  promoted: SquaredIcon('#ccf2e3', '#4bd49e', () => (
    <RecordVoiceOver size={'20'} />
  )),
  'status:interesting': SquaredIcon('#ff4d4f', 'white', () => (
    <HeartOutlined />
  )),
  newSearchRequest: SquaredIcon('#e6e0ff', '#8a7ebe', () => (
    <Search size={'20'} />
  )),
  reminderPromotion: SquaredIcon('#d3adf7', 'white', () => (
    <NotificationsActive size={'20'} />
  )),
  reminderRequest: SquaredIcon('#d3adf7', 'white', () => (
    <NotificationsActive size={'20'} />
  )),
  newAttachment: SquaredIcon(theme.colors.lightBlue, 'white', () => (
    <Attachment size={'20'} />
  )),
  newMatchingLeaseagreement: SquaredIcon('#e6e0ff', '#8a7ebe', () => (
    <Office size={'20'} />
  )),
};

const Icon = styled.span<{ type: string }>`
  margin-right: 16px;
  align-self: center;
`;

interface Props {
  notification: NotificationValuesFragment;
}

const Text = styled.div<{ strong?: boolean }>`
  font-weight: ${p => (p.strong ? '600' : '300')};
  font-size: 14px;
`;

const Small = styled.div`
  color: #666;
  font-size: 12px;
`;

function NotificationContent({ notification }: Props): JSX.Element {
  const {
    read,
    timestamp,
    message,
    link,
    type,
    leaseAgreementId,
    searchRequestReferenceId,
  } = notification;
  const i18n = getI18n();
  const { t } = useTranslation();

  const [searchRequest, setSearchRequest] = useState<{
    visible: boolean;
    searchRequest: SearchRequestCardValuesFragment | undefined;
  }>({
    visible: false,
    searchRequest: undefined,
  });

  const [openLeaseAgreementDrawer, setOpenLeaseAgreementDrawer] = useState({
    open: false,
    id: '',
  });

  const [getSearchRequestById, { data }] = useLazyQuery(
    GetMySearchRequestByReferenceIdDocument,
    {
      onCompleted: data => {
        setSearchRequest({
          searchRequest: data.mySearchRequestByReferenceId,
          visible: true,
        });
      },
    },
  );

  const [getLeaseAgreementById, { data: leaseAgreementData }] = useLazyQuery(
    GetLeaseAgreementViewDocument,
    {
      onCompleted: data => {
        setOpenLeaseAgreementDrawer({ open: true, id: data.leaseAgreement.id });
      },
    },
  );

  if (type === 'newSearchRequest' || type === 'closedSearchRequest') {
    return (
      <>
        <div
          onClick={() => {
            getSearchRequestById({
              variables: { id: searchRequestReferenceId },
            });
          }}
        >
          <Content>
            <Icon type={type}>{Icons[type]}</Icon>
            <div>
              <Text strong={read === false}>
                <span
                  style={{ fontWeight: 400 }}
                  dangerouslySetInnerHTML={{
                    __html: message[i18n.language],
                  }}
                />
              </Text>
              <Small>
                <span>
                  {dayjs(new Date(timestamp)).locale(i18n.language).fromNow()}
                </span>
              </Small>
            </div>
          </Content>
        </div>
        <>
          {data?.mySearchRequestByReferenceId?.status ===
          StatusEnum.Accepted ? (
            <DetailedView
              selectedSearchRequest={
                searchRequest.visible
                  ? data.mySearchRequestByReferenceId
                  : undefined
              }
              onCancel={() =>
                setSearchRequest({
                  searchRequest: undefined,
                  visible: false,
                })
              }
              placement="right"
            />
          ) : (
            <LandlordModal
              id={searchRequestReferenceId}
              open={searchRequest.visible}
              onCancel={() => {
                setSearchRequest({
                  searchRequest: undefined,
                  visible: false,
                });
              }}
            />
          )}
        </>
      </>
    );
  }

  if (type === 'newMatchingLeaseagreement') {
    return (
      <>
        <div
          onClick={() =>
            getLeaseAgreementById({ variables: { id: leaseAgreementId } })
          }
        >
          <Content>
            <Icon type={type}>{Icons[type]}</Icon>
            <div>
              <Text strong={read === false}>
                <span
                  style={{ fontWeight: 400 }}
                  dangerouslySetInnerHTML={{ __html: message[i18n.language] }}
                />
              </Text>
              <Small>
                <span>
                  {dayjs(new Date(timestamp)).locale(i18n.language).fromNow()}
                </span>
              </Small>
            </div>
          </Content>
        </div>
        <Drawer
          open={openLeaseAgreementDrawer.open}
          placement={'right'}
          size={'large'}
          onClose={() =>
            setOpenLeaseAgreementDrawer({
              open: false,
              id: openLeaseAgreementDrawer.id,
            })
          }
          rootClassName={'no-padding-ant-drawer mobile:max-w-[100vw]'}
          title={leaseAgreementData?.leaseAgreement?.name || ''}
          footer={
            <PreviewDrawerFooter
              leaseAgreement={leaseAgreementData?.leaseAgreement}
              searchRequestReferenceId={searchRequestReferenceId}
              t={t}
            />
          }
        >
          <LeaseAgreement preview id={openLeaseAgreementDrawer.id} />
        </Drawer>
      </>
    );
  }

  return (
    <Link to={`/${link}`} style={{ color: 'inherit' }}>
      <Content>
        <Icon type={type}>{Icons[type]}</Icon>
        <div>
          <Text strong={read === false}>
            <span
              style={{ fontWeight: 400 }}
              dangerouslySetInnerHTML={{ __html: message[i18n.language] }}
            />
          </Text>
          <Small>
            <span>
              {dayjs(new Date(timestamp)).locale(i18n.language).fromNow()}
            </span>
          </Small>
        </div>
      </Content>
    </Link>
  );
}

export default NotificationContent;
