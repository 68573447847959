import { Spin } from 'antd';
import React from 'react';

function InfiniteScrollLoader(): JSX.Element {
  return (
    <div
      key={'infiniteloader'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        clear: 'both',
        marginTop: '24px',
      }}
    >
      <Spin style={{ textAlign: 'center' }} />
    </div>
  );
}

export default InfiniteScrollLoader;
