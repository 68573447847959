import { UnlockOutlined } from '@ant-design/icons';
import {
  Button,
  ButtonProps,
  Drawer,
  Form as AntForm,
  FormInstance,
  Input,
  message,
  Modal,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { useVerifyAccountMutation } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';

interface FormInstanceProps {
  company: string;
  email: string;
}

interface FormProps {
  form: FormInstance<FormInstanceProps>;
  onFinish: (values: FormInstanceProps) => void;
}

function Form({ form, onFinish }: FormProps): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();

  return (
    <>
      <Box mb={2}>
        <Typography.Text type={'secondary'}>
          {t('Verify your account to get full access to Portally')}
        </Typography.Text>
      </Box>
      <AntForm<FormInstanceProps>
        onFinish={values => {
          onFinish(values);
        }}
        form={form}
        layout={'vertical'}
        initialValues={{
          company: user.activeClient.name,
          email: user.email,
        }}
      >
        <AntForm.Item
          label={t('Company')}
          name={'company'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          name={'email'}
          label={'Email'}
          rules={[
            {
              required: true,
              message: t('required'),
            },
            {
              type: 'email',
            },
          ]}
        >
          <Input />
        </AntForm.Item>
      </AntForm>
    </>
  );
}

type Props = ButtonProps;
function VerifyAccount({ ...rest }: Props): JSX.Element {
  const { isMobile } = useWidthAndHeight();

  const [verifyAccount, { loading }] = useVerifyAccountMutation({
    onCompleted: () => {
      message.success(t('emailSent'));
    },
    onError: () => {
      message.error(t('error'));
    },
  });
  const onFinish = (values: FormInstanceProps): void => {
    const { company, email } = values;

    verifyAccount({
      variables: {
        company,
        email,
      },
    });
    setOpen(false);
  };
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [form] = useForm<FormInstanceProps>();
  return (
    <>
      <Button icon={<UnlockOutlined />} {...rest} onClick={() => setOpen(true)}>
        {t('Verify account')}
      </Button>
      {isMobile ? (
        <Drawer
          open={open}
          placement="bottom"
          onClose={() => setOpen(false)}
          title={t('Verify account')}
          footer={
            <div className="flex w-full justify-end">
              <Button
                icon={<UnlockOutlined />}
                type={'primary'}
                onClick={() => form.submit()}
                loading={loading}
                disabled={loading}
              >
                {t('Verify account')}
              </Button>
            </div>
          }
        >
          <Form form={form} onFinish={onFinish} />
        </Drawer>
      ) : (
        <Modal
          footer={
            <Button
              icon={<UnlockOutlined />}
              type={'primary'}
              onClick={() => form.submit()}
              loading={loading}
              disabled={loading}
            >
              {t('Verify account')}
            </Button>
          }
          open={open}
          onCancel={() => setOpen(false)}
          title={t('Verify account')}
        >
          <Form form={form} onFinish={onFinish} />
        </Modal>
      )}
    </>
  );
}

export default VerifyAccount;
