import { NotificationsOff } from '@styled-icons/ionicons-outline/NotificationsOff';
import { message, Popover } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledIconButton } from '@/components/Buttons';
import { useMarkConversationAsAnsweredMutation } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

interface Props {
  id: string;
}

function MarkAsAnswered({ id }: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  const [markAsAnswered, { loading }] = useMarkConversationAsAnsweredMutation({
    onCompleted: () => {
      message.success(t('answeredSuccess'));
    },
    onError: () => {
      message.error(t('error'));
    },
  });
  return (
    <Popover placement={'right'} content={t('answeredTooltip')}>
      <StyledIconButton
        onClick={() =>
          markAsAnswered({
            variables: {
              id,
            },
          })
        }
        shape="round"
        loading={loading}
        icon={<NotificationsOff size={isMobile ? 20 : 15} />}
      />
    </Popover>
  );
}

export default MarkAsAnswered;
