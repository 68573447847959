import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';

import Authenticate from '.';

export type AuthenticateRouteProps = BasicRouteProps &
  MakeGenerics<
    {
      Params: {
        token?: string;
      };
      LoaderData: {
        token: string;
      };
    } & BasicRouteProps
  >;

const AuthenticateRoutes: Route<AuthenticateRouteProps>[] = [
  {
    path: '/authenticate/:token',
    id: 'AuthenticateRoutes',
    element: <Authenticate />,
    loader: ({ params }) => ({
      token: params.token,
    }),
  },
];

export default AuthenticateRoutes;
