import { Button, Drawer, Form, message, Modal } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { useAddUserMyColleaguesMutation } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import apollo from '@/main/apollo';

import AddColleagueForm from './AddColleagueForm';
interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
export default function AddColleagueModal({
  visible,
  setVisible,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isMobile } = useWidthAndHeight();
  const [action, { loading }] = useAddUserMyColleaguesMutation({
    onCompleted: async () => {
      await apollo.reFetchObservableQueries();

      message.success(t('Invite sent'));
      form.resetFields();
      setVisible(false);
    },
    onError: error => {
      if (error.message === 'User already exists') {
        message.error(t('userEmailExists'));
      } else {
        message.error(t('error'));
      }
    },
  });

  const handleOk = async (): Promise<void> => {
    await form.validateFields().then(async fd => {
      await action({
        variables: {
          input: { ...fd },
        },
      }).catch(err => console.error(err));
    });
  };
  const footer = [
    <Button onClick={() => setVisible(false)} key={'cancel'}>
      {t('Cancel')}
    </Button>,
    <Button
      type={'primary'}
      key={'invite'}
      onClick={() => handleOk()}
      loading={loading}
      disabled={loading}
    >
      {t('Invite')}
    </Button>,
  ];
  return isMobile ? (
    <Drawer
      placement={'bottom'}
      height={'100%'}
      closable={false}
      footer={
        <Box flex justifyContent="flex-end" gap={1}>
          {footer}
        </Box>
      }
      title={t('Invite colleague')}
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <AddColleagueForm form={form} />
    </Drawer>
  ) : (
    <Modal
      width={350}
      footer={footer}
      title={t('Invite colleague')}
      open={visible}
      onCancel={() => setVisible(false)}
    >
      <AddColleagueForm form={form} />
    </Modal>
  );
}
