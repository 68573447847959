import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';

interface Props {
  edit?: boolean;
  hidden: boolean;
  searchRequest?: boolean;
  name?: string;
}

export function SizeFilter({
  edit = false,
  hidden,
  searchRequest = false,
  name = 'size',
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box py={searchRequest ? 0 : 1} verticalSpacing={1}>
      {!searchRequest && <div>{t(name)}</div>}

      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name={[name, 'min']}
            normalize={value => (value ? parseInt(value) : null)}
            rules={[
              {
                type: 'number',
                min: 0,
                message: t('sizeMin'),
              },
              {
                required: !edit && !hidden,
                message: t('sizeMinRequired'),
              },
            ]}
          >
            <Input
              style={{ width: '100%' }}
              min={0}
              placeholder="Min"
              type={'number'}
              suffix={name === 'size' && 'm²'}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[name, 'max']}
            normalize={value => (value ? parseInt(value) : null)}
            rules={[
              {
                type: 'number',
                min: 0,
                message: t('sizeMin'),
              },
              {
                required: !edit && !hidden,
                message: t('sizeMaxRequired'),
              },
            ]}
          >
            <Input
              style={{ width: '100%' }}
              min={0}
              placeholder="Max"
              type={'number'}
              suffix={name === 'size' && 'm²'}
            />
          </Form.Item>
        </Col>
      </Row>
    </Box>
  );
}

export default SizeFilter;
