import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type PreliminarySearchRequestRouteProps = AdminRouteBaseProps;

const PreliminarySearchRequests = lazy(() => import('.'));
const Filter = lazy(() => import('./Filter'));

const PreliminarySearchRequestRoutes: Route<PreliminarySearchRequestRouteProps>[] =
  [
    {
      path: '/preliminary-search-requests',
      id: 'Preliminary search requests',
      element: <PreliminarySearchRequests />,
      meta: {
        sider: <Filter />,
      },
    },
  ];

export const toPreliminarySearchRequests = (): string =>
  '/admin/preliminary-search-requests';

export default PreliminarySearchRequestRoutes;
