import { ArrowLeftOutlined, ZoomInOutlined } from '@ant-design/icons';
import {
  type DrawerProps as AntDrawerProps,
  Button,
  Drawer as AntDrawer,
  Spin,
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Messages from '@/components/Chat/Messages';
import Form from '@/components/Chat/Messages/Form';
import CardError from '@/components/Errors/CardError';
import SearchRequestInformationModal from '@/components/SearchRequestInformationModal';
import {
  GetConversationQueryResult,
  SearchRequestStatusEnum,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useScrollPrevention from '@/hooks/useScrollPrevention';
import useUser from '@/hooks/useUser';
import InboxTitle from '@/views/Messages/Inbox/InboxTitle';

import ArchiveConversation from '../Messages/ArchiveConversation';

export type DrawerProps = AntDrawerProps;

interface Props {
  conversationId: string;
  loading: boolean;
  error: GetConversationQueryResult['error'];
  data: GetConversationQueryResult['data'];
  previousData: GetConversationQueryResult['previousData'];
  chatProps: DrawerProps;
}

function Drawer({
  conversationId,
  loading,
  data,
  error,
  previousData,
  chatProps,
}: Props): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();
  const { open, onClose, width = '100%', ...rest } = chatProps;
  useScrollPrevention(open);
  const isLoading = loading && !data && !previousData;
  const conversation =
    !data && previousData?.conversation?.id != conversationId
      ? data?.conversation
      : data?.conversation ?? previousData?.conversation;
  const { isMobile } = useWidthAndHeight();
  return (
    <AntDrawer
      push={false}
      open={open}
      onClose={onClose}
      destroyOnClose
      width={width}
      {...rest}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
      className="[&_.ant-drawer-header-title]:items-start"
      title={
        conversation && (
          <InboxTitle
            conversation={conversation}
            clientId={user?.activeClientId}
            t={t}
          />
        )
      }
      closeIcon={<ArrowLeftOutlined className="mt-0.5" />}
      extra={
        <div className="flex gap-2">
          {conversation?.searchRequestReference && (
            <SearchRequestInformationModal
              id={conversation.searchRequestReference.id}
              disabled={
                conversation.searchRequestReference.status ===
                SearchRequestStatusEnum.Disabled
              }
              button={({ onClick }) => (
                <Button
                  icon={<ZoomInOutlined />}
                  onClick={onClick}
                  type={'text'}
                >
                  {!isMobile && t('Show inquiry')}
                </Button>
              )}
              inline
            />
          )}
          <ArchiveConversation
            archived={conversation?.archived}
            id={conversationId}
            iconOnly={isMobile}
          />
        </div>
      }
      footer={
        !error && (
          <Form
            conversationId={conversationId}
            answered={conversation?.messages.some(
              message => message.sender.id === user?.activeClientId,
            )}
            searchRequestReference={conversation?.searchRequestReference}
            isMobile={isMobile}
          />
        )
      }
    >
      {isLoading ? (
        <Spin />
      ) : (
        <div>
          {conversation && (
            <Messages conversation={conversation} isDrawer isMobile />
          )}
        </div>
      )}
      {error && <CardError />}
    </AntDrawer>
  );
}

export default Drawer;
