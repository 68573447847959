import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tag from '@/components/Tags/UsageCategoryTags';

interface UsageCategoriesProps {
  usageCategories?: string[];
  shadow?: boolean;
  inverted?: boolean;
  size?: 'small' | 'middle' | 'large';
}

export default function UsageCategories({
  usageCategories,
  shadow = false,
  inverted = false,
  size = 'middle',
}: UsageCategoriesProps): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const count = usageCategories.length;
  if (!count) {
    return null;
  }

  if (count > 3) {
    const toShow = usageCategories.slice(0, 2);
    const usageCategoriesLeft = usageCategories.slice(2, count);
    return (
      <div
        className="mb-1 flex flex-wrap-reverse gap-0.5"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {toShow.map(category => (
          <Tag
            key={category}
            size={size}
            usageCategory={category}
            shadow={shadow}
            inverted={inverted}
          />
        ))}
        <Tooltip
          placement={'top'}
          open={open}
          title={
            <div className="flex flex-col">
              {usageCategoriesLeft.map(category => (
                <div key={category}>{t(category)}</div>
              ))}
            </div>
          }
        >
          <Tag
            size={size}
            usageCategory={'...'}
            shadow={shadow}
            inverted={inverted}
          />
        </Tooltip>
      </div>
    );
  }
  return (
    <div className="flex flex-wrap-reverse gap-0.5">
      {usageCategories.map(category => (
        <Tag
          key={category}
          size={size}
          usageCategory={category}
          shadow={shadow}
          inverted={inverted}
        />
      ))}
    </div>
  );
}
