import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DeclineSearchRequestButton from '@/components/SearchRequest/shared/DeclineSearchRequestButton';
import ShareSearchRequestButton from '@/components/SearchRequest/shared/ShareSearchRequestButton';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';

interface Props {
  searchRequestReferenceId: string;
  step: number;
  onCancel: () => void;
  answerLoading: boolean;
  handleSubmit: () => Promise<void>;
  message: string;
  onGoBack: () => void;
  sharedSearchRequest?: boolean;
  declined?: boolean;
}

function Buttons({
  searchRequestReferenceId,
  step,
  answerLoading,
  handleSubmit,
  message,
  onCancel,
  onGoBack,
  sharedSearchRequest = false,
  declined = false,
}: Props): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  const goBackButtonContent = (): string =>
    step === 0 ? t('Close') : t('goBack');

  const goForwardButtonContent = (): string =>
    step > 0 ? t('Send') : t('Answer');
  return (
    <div
      className={
        'prevent-request-drawer-close flex justify-end gap-2 wrap-reverse w-full'
      }
    >
      <Button
        key="back"
        onClick={() => {
          if (step === 0) {
            onCancel();
          } else {
            onGoBack();
          }
        }}
      >
        {goBackButtonContent()}
      </Button>
      {!sharedSearchRequest && !isMobile && (
        <ShareSearchRequestButton
          searchRequestReferenceId={searchRequestReferenceId}
        />
      )}
      {!declined && (
        <DeclineSearchRequestButton
          onCompleted={() => {
            onCancel();
          }}
          quick={user?.isPartner}
          searchRequestReferenceId={searchRequestReferenceId}
          modal={isMobile}
        >
          {t('Reject')}
        </DeclineSearchRequestButton>
      )}
      <Button
        key="submit"
        type="primary"
        onClick={() => handleSubmit()}
        loading={answerLoading}
        disabled={step == 1 && message == ''}
      >
        {goForwardButtonContent()}
      </Button>
    </div>
  );
}

export default Buttons;
