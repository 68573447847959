import { Link } from '@tanstack/react-location';
import { Drawer } from 'antd';
import React, { useState } from 'react';
import { TFunction } from 'react-i18next';

import CompactLinkPreview from '@/components/LinkPreview/CompactLinkPreview';
import { MessageValuesFragment } from '@/generated/graphql';
import { toLeaseAgreement } from '@/views/LeaseAgreement/routes';
import LeaseAgreement from '@/views/LeaseAgreement/View/';

interface Props {
  leaseAgreements: MessageValuesFragment['leaseAgreements'];
  mine: boolean;
  t: TFunction;
  className?: string;
}

function LeaseAgreements({
  leaseAgreements,
  mine,
  className,
  t,
}: Props): JSX.Element {
  const [openLeaseAgreementDrawer, setOpenLeaseAgreementDrawer] = useState({
    open: false,
    id: '',
  });

  if (leaseAgreements.length === 0) {
    return null;
  }

  return (
    <div className={`space-y-3 ${className}`}>
      <Drawer
        open={openLeaseAgreementDrawer.open}
        placement={mine ? 'left' : 'right'}
        size={'large'}
        onClose={() => setOpenLeaseAgreementDrawer({ open: false, id: '' })}
        rootClassName={'no-padding-ant-drawer mobile:max-w-[100vw]'}
        title={
          leaseAgreements.find(
            lease => lease.id === openLeaseAgreementDrawer.id,
          )?.name || ''
        }
        footer={
          <Link
            to={toLeaseAgreement(openLeaseAgreementDrawer.id)}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >
            {t('Open in new tab')}
          </Link>
        }
      >
        {openLeaseAgreementDrawer.id && (
          <LeaseAgreement preview id={openLeaseAgreementDrawer.id} />
        )}
      </Drawer>

      {leaseAgreements.map(lease => (
        <div
          key={lease.id}
          onClick={() => {
            setOpenLeaseAgreementDrawer({ open: true, id: lease.id });
          }}
        >
          <CompactLinkPreview
            title={lease.name}
            image={lease.image?.url}
            fee={lease.fee ? `${lease.fee.amount} ${t(lease.fee.feeType)}` : ''}
            description={
              lease.title
                ? lease.title
                : lease.description?.length > 100
                ? `${lease.description.slice(0, 100)}...`
                : lease.description
            }
            size={lease.size}
            sizeSpan={lease.sizeSpan}
            mine={mine}
          />
        </div>
      ))}
    </div>
  );
}

export default LeaseAgreements;
