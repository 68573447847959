import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type StatisticsRouteProps = AdminRouteBaseProps;

const Statistics = lazy(() => import('.'));

const StatisticsRoutes: Route<StatisticsRouteProps>[] = [
  {
    path: 'statistics',
    id: 'AdminStatistics',
    element: <Statistics />,
  },
];

export const toStatistics = (): string => '/admin/statistics';

export default StatisticsRoutes;
