import { AnyAction } from 'redux';

import { ActivityLogFilterInput } from '@/generated/graphql';
import { PaginationType } from '@/utils/types';

export const UPDATE_SEARCH_FILTER = 'admin/UPDATE_SERACH_FILTER';
export const UPDATE_USER_FILTER = 'admin/UPDATE_USER_FILTER';
export const UPDATE_CRM_PAGINATION = 'admin/UPDATE_CRM_PAGINATION';
export const UPDATE_MAILLOG_FILTER = 'admin/UPDATE_MAILLOG_FILTER';
export const UPDATE_SEARCH_REQUEST_FILTER =
  'admin/UPDATE_SEARCH_REQUEST_FILTER';
export const UPDATE_GATE_SALES_FILTER = 'admin/UPDATE_GATE_SALES_FILTER';
export const UPDATE_GATE_TABKEY = 'admin/UPDATE_GATE_TABKEY';
export const UPDATE_ACTIVITY_LOG_FILTER = 'admin/UPDATE_ACTIVITY_LOG_FILTER';
export const UPDATE_EVENT_FILTER = 'admin/UPDATE_EVENT_FILTER';
export const LEASE_AGREEMENT_FILTER = 'admin/LEASEAGREEMENT_FILTER';
export const UPDATE_SEARCH_REQUEST_PAGINATION =
  'admin/UPDATE_SEARCH_REQUEST_PAGINATION';

export const updateLeaseAgreementFilter = (name, value): AnyAction => ({
  type: LEASE_AGREEMENT_FILTER,
  name,
  value,
});

export const updateSearchRequestFilter = (name, value): AnyAction => ({
  type: UPDATE_SEARCH_REQUEST_FILTER,
  name,
  value,
});

export const updateUserSearchFilter = (name, value): AnyAction => ({
  type: UPDATE_USER_FILTER,
  name,
  value,
});

export const updateSearchFilter = (values): AnyAction => ({
  type: UPDATE_SEARCH_FILTER,
  values,
});

export const updateCRMPagination = (pagination): AnyAction => ({
  type: UPDATE_CRM_PAGINATION,
  pagination,
});

export const updateMailLogFilter = (filter): AnyAction => ({
  type: UPDATE_MAILLOG_FILTER,
  filter,
});
export const updateGateSalesFilter = (name, value): AnyAction => ({
  type: UPDATE_GATE_SALES_FILTER,
  name,
  value,
});

export const updateGateTabkey = (value): AnyAction => ({
  type: UPDATE_GATE_TABKEY,
  value,
});

export const updateActivityLogFilter = (
  filter: Partial<ActivityLogFilterInput>,
): AnyAction => ({
  type: UPDATE_ACTIVITY_LOG_FILTER,
  filter,
});

export const updateEventFilter = (eventFilter): AnyAction => ({
  type: UPDATE_EVENT_FILTER,
  eventFilter,
});

export const updateSearchRequestPagination = (
  pagination: PaginationType,
): AnyAction => ({
  type: UPDATE_SEARCH_REQUEST_PAGINATION,
  pagination,
});
