import { useMatch } from '@tanstack/react-location';
import React, { useEffect } from 'react';

import { useViewPartnerMutation } from '@/generated/graphql';
import { PortallyPartnerRouteProps } from '@/views/PortallyPartner/routes';

export default function IncrementPartnerView(): JSX.Element {
  const { slug } = useMatch<PortallyPartnerRouteProps>().data;

  const [view] = useViewPartnerMutation({
    fetchPolicy: 'no-cache',
    variables: { slug },
  });

  useEffect(() => {
    view();
  }, []);
  return <></>;
}
