import { PaperClipOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadFile } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { checkMaxUploadSize, uploadFile } from '@/components/UploadFile/util';
import {
  DocumentValuesFragment,
  useUploadFileMutation,
} from '@/generated/graphql';

interface Props {
  values: UploadFile<DocumentValuesFragment>[];
  onChange: (values: UploadFile<DocumentValuesFragment>[]) => void;
  fileList: UploadFile<DocumentValuesFragment>[];
  setFileList: (fileList: UploadFile<DocumentValuesFragment>[]) => void;
}

const UploadToConversation = ({
  values,
  onChange,
  fileList,
  setFileList,
}: Props): JSX.Element => {
  const [uploadFileMutation] = useUploadFileMutation();

  const { t } = useTranslation();
  useEffect(() => {
    onChange(fileList.filter(v => v.status === 'done'));
  }, [fileList]);

  return (
    <div className="clearfix">
      <Upload
        customRequest={args => {
          const { file, onError, onSuccess, onProgress } = args;

          uploadFile(file, uploadFileMutation, percent =>
            onProgress({ percent } as any),
          )
            .then(upload => {
              onSuccess(
                {
                  id: upload.data.file.id,
                  url: upload.data.file.url,
                },
                file as any,
              );
            })
            .catch(onError);
        }}
        onChange={values => {
          setFileList(values.fileList);
        }}
        fileList={fileList.filter(
          v => v.status !== 'done' || values.map(v => v.uid).includes(v.uid),
        )}
        beforeUpload={file => checkMaxUploadSize(file, t)}
        multiple
        showUploadList={false}
      >
        <Button
          icon={<PaperClipOutlined />}
          loading={!fileList.every(file => file.status === 'done')}
        >
          {t('Upload file')}
        </Button>
      </Upload>
    </div>
  );
};

export default React.memo(UploadToConversation);
