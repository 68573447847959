import { LaptopOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-location';
import { Button, Result, Spin } from 'antd';
import { UploadFile } from 'antd/lib';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import CardError from '@/components/Errors/CardError';
import { GoBackArrowButton } from '@/components/GoBackArrow';
import Message from '@/components/Message';
import { SelectedLeaseAgreement } from '@/components/SelectLeaseAgreements';
import {
  DocumentValuesFragment,
  SearchRequestStatusEnum,
  StatusEnum,
  useGetSearchRequestLandlordQuery,
} from '@/generated/graphql';
import useUser from '@/hooks/useUser';
import { toHome } from '@/views/Home/routes';

import PromoteAndAnswer from './PromoteAndAnswer';
import SearchRequestInformation from './SearchRequestInformation';

interface Props {
  step: number;
  id: string;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  selectedLeaseAgreements: SelectedLeaseAgreement[];
  setSelectedLeaseAgreements: Dispatch<
    SetStateAction<SelectedLeaseAgreement[]>
  >;
  setSelectedAttachments: Dispatch<
    SetStateAction<UploadFile<DocumentValuesFragment>[]>
  >;
  selectedAttachments: UploadFile<DocumentValuesFragment>[];
  setSearchRequestStatus: Dispatch<
    SetStateAction<StatusEnum | 'disabled' | undefined>
  >;
  sharedSearchRequest?: boolean;
}

function View({
  step,
  id,
  message,
  setMessage,
  selectedLeaseAgreements,
  setSelectedLeaseAgreements,
  setSearchRequestStatus,
  sharedSearchRequest,
  setSelectedAttachments,
  selectedAttachments,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const navigate = useNavigate();

  const { data, previousData, loading, error } =
    useGetSearchRequestLandlordQuery({
      variables: { id },
      skip: !id,
      onCompleted: data => {
        if (
          data.searchRequestReference.status ===
          SearchRequestStatusEnum.Disabled
        ) {
          setSearchRequestStatus('disabled');
        } else {
          setSearchRequestStatus(data.searchRequestReference.myStatus);
        }
      },
    });

  useEffect(() => {
    setSearchRequestStatus(data?.searchRequestReference.myStatus);
  }, [data?.searchRequestReference?.myStatus, user?.activeClientId]);

  if (loading && !data && !previousData) {
    return (
      <Box
        flex
        flexDirection={'column'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        h={300}
      >
        <Spin />
      </Box>
    );
  }

  if (error) {
    return <CardError />;
  }

  const searchRequestReference = (data ?? previousData)?.searchRequestReference;

  if (!searchRequestReference) {
    return <></>;
  }

  const status = searchRequestReference.myStatus;

  if (status === 'accepted') {
    return (
      <>
        <Result
          status={'success'}
          title={
            sharedSearchRequest
              ? t('Search request is already answered')
              : t('AnsweredSingular')
          }
          extra={
            sharedSearchRequest && (
              <Button
                onClick={() => {
                  navigate({
                    to: toHome(),
                  });
                }}
                type={'primary'}
                icon={<LaptopOutlined />}
              >
                {t('Back home')}
              </Button>
            )
          }
        />
        <SearchRequestInformation
          key={`${searchRequestReference.id}info`}
          step={step}
          loading={loading}
          error={error}
          searchRequest={searchRequestReference}
        />
      </>
    );
  }
  return (
    <>
      {searchRequestReference.status === SearchRequestStatusEnum.Disabled &&
        sharedSearchRequest && (
          <Box flex horizontalSpacing={1} alignItems={'baseline'}>
            <GoBackArrowButton onClick={() => navigate({ to: toHome() })}>
              {t('Back home')}
            </GoBackArrowButton>
          </Box>
        )}
      <SearchRequestInformation
        key={`${searchRequestReference.id}info`}
        step={step}
        loading={loading}
        error={error}
        searchRequest={searchRequestReference}
        setSelectedLeaseAgreements={setSelectedLeaseAgreements}
        selectedLeaseAgreements={selectedLeaseAgreements}
        message={message}
        setMessage={setMessage}
        setSelectedAttachments={setSelectedAttachments}
        selectedAttachments={selectedAttachments}
        sharedSearchRequest={sharedSearchRequest}
      />
      {step > 1 && (
        <Result
          icon={<LoadingOutlined spin />}
          title={`${t('Answering search request')}...`}
        />
      )}
      {error && <Message error={error} />}
    </>
  );
}

export default View;
