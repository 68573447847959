import Icon from '@ant-design/icons';
import React from 'react';

interface Props {
  color?: string;
}
export default function MiniPortallyIcon({ color }: Props): JSX.Element {
  const fill = color ? color : 'currentColor';
  return (
    <Icon
      component={() => (
        <svg
          id="Lager_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -30 283.46 190.36"
          width="15px"
          height="15px"
          fill="#fff"
        >
          <path
            d="M299.611 118.679a114.18 114.18 0 0 0-121.814 112.744h0v160.21c0 3.603 2.645 4.434 6.097 1.688 13.429-10.557 29.653-24.06 57.519-46.433H293.212a114.206 114.206 0 0 0 6.399-228.159Zm-7.281 155.448a41.747 41.747 0 0 1-41.747-41.747c0-23.053 20.155-41.848 42.679-41.848a40.916 40.916 0 0 1 40.941 40.916C334.203 254.073 315.383 274.127 292.33 274.127Z"
            transform="translate(-150.25 -193.63)"
            fill={fill}
            stroke={fill}
          />
        </svg>
      )}
    />
  );
}
