import { Popover } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';

interface RegionProps {
  regions: { name: string }[];
  breakpoint?: number;
}

function Regions({ regions, breakpoint = 3 }: RegionProps): JSX.Element {
  const count = regions.length;
  const { t } = useTranslation();
  if (!count) {
    return null;
  }

  const toShow = regions.slice(0, breakpoint);
  const left = regions.slice(breakpoint, count);
  if (count > 3) {
    return (
      <div>
        <Popover
          content={
            <Box verticalSpacing={1}>
              {left.map(elem => (
                <div key={elem.name}>{elem.name}</div>
              ))}
            </Box>
          }
        >
          {toShow.map((elem, i) => (
            <span key={elem.name}>{`${elem.name}${
              i !== breakpoint && ', '
            }`}</span>
          ))}
          <span>{` ${t('and')} ${left.length} ${t('others')}`}</span>
        </Popover>
      </div>
    );
  }

  return (
    <div>
      {regions.map((elem, i) => {
        const ending = count === i + 1 ? '' : ', ';
        return <span key={elem.name}>{`${elem.name}${ending}`}</span>;
      })}
    </div>
  );
}

export default Regions;
