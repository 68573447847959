import { Button, Form as AntForm, Typography } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import {
  AcceptSubscriptionRequestMutationFn,
  SubscriptionType as GQLSubscriptionType,
} from '@/generated/graphql';

import { SubscriptionType } from './V2/CommissionCard';
interface Props {
  id: string;
  accepted: boolean;
  subscriptionType?: SubscriptionType;
  invoiceDetailsNeeded?: boolean;
  setPicked?: Dispatch<SetStateAction<boolean>>;
  answer: AcceptSubscriptionRequestMutationFn;
  loading: boolean;
}

function Form({
  id,
  accepted,
  answer,
  subscriptionType,
  invoiceDetailsNeeded = false,
  setPicked,
  loading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  //Kan bara vara subscription eller v1. v1 behöver inte typ.
  return (
    <AntForm
      layout={'vertical'}
      onFinish={() => {
        answer({
          variables: {
            id,
            type: subscriptionType as GQLSubscriptionType,
          },
        });
      }}
    >
      <Box flex justifyContent={'space-between'} alignItems={'center'}>
        <Typography.Text>
          {t('Contact us')}{' '}
          <a href={'mailto:support@portally.com'}>support@portally.com</a>
        </Typography.Text>
        {invoiceDetailsNeeded && !accepted ? (
          <Button type="primary" onClick={() => setPicked(true)}>
            {t('Choose')}
          </Button>
        ) : (
          <Button
            htmlType={'submit'}
            type={'primary'}
            loading={loading}
            style={{ marginLeft: '8px' }}
          >
            {accepted ? t('Close') : t('Accept terms')}
          </Button>
        )}
      </Box>
    </AntForm>
  );
}

export default Form;
