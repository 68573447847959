import { MakeGenerics, Route } from '@tanstack/react-location';
import React from 'react';

import { BasicRouteProps } from '@/layout/routes';
const SearchRequestLandlord = React.lazy(() => import('.'));

export type SearchRequestLandlordRouteProps = MakeGenerics<
  {
    Params: {
      searchRequestId?: string;
    };
    LoaderData: {
      searchRequestId: string;
    };
  } & BasicRouteProps
>;

const SearchRequestLandlordRoute: Route<SearchRequestLandlordRouteProps>[] = [
  {
    path: '/search-inquiries',
    id: 'Search requests',

    children: [
      {
        path: '/',
        element: <SearchRequestLandlord />,
        meta: {
          noPaddingPhone: true,
          whiteBackgroundPhone: true,
        },
      },
      {
        path: ':searchRequestId',
        element: <SearchRequestLandlord />,
        meta: {
          noPaddingPhone: true,
          whiteBackgroundPhone: true,
        },
        loader: ({ params }) => ({
          searchRequestId: params.searchRequestId,
        }),
      },
    ],
  },
];

export const toSearchRequestLandlord = (searchRequestId?: string): string =>
  searchRequestId
    ? `/search-inquiries/${searchRequestId}`
    : '/search-inquiries';

export default SearchRequestLandlordRoute;
