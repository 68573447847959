import { PlusOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Archive } from '@styled-icons/bootstrap/Archive';
import { Button, message, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledIconButton } from '@/components/Buttons';
import { useArchiveConversationMutation } from '@/generated/graphql';

interface Props {
  id: string;
  archived: boolean;
  iconOnly?: boolean;
}

function ArchiveConversation({ id, archived, iconOnly }: Props): JSX.Element {
  const { t } = useTranslation();

  const apollo = useApolloClient();
  const [archiveConversation, { loading }] = useArchiveConversationMutation({
    variables: {
      id,
      archived: !archived,
    },
    onCompleted: async res => {
      res.setArchived.archived
        ? message.success(t('Conversation archived'))
        : message.success(t('Sent to inbox'));

      await apollo.reFetchObservableQueries();
    },
    onError: () => {
      message.error(t('error'));
    },
  });

  const withText = !iconOnly;

  if (archived) {
    return (
      <Tooltip title={t('Add to inbox')} open={withText ? false : undefined}>
        <Button
          loading={loading}
          type={'text'}
          icon={<PlusOutlined />}
          onClick={() => archiveConversation()}
          style={withText ? undefined : { width: '40px' }}
        >
          {withText ? t('Add to inbox') : ''}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={t('Archive')} visible={withText ? false : undefined}>
      <StyledIconButton
        loading={loading}
        type={'text'}
        $withText={withText}
        icon={<Archive size={16} />}
        onClick={() => archiveConversation()}
        style={withText ? undefined : { width: '40px' }}
      >
        {withText ? t('Archive') : ''}
      </StyledIconButton>
    </Tooltip>
  );
}

export default ArchiveConversation;
