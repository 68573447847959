import { Form, Input, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;
interface Props {
  fieldName: string | string[];
  value: string;
  title: string;
  editing?: boolean;
}

function EditableTextField({
  fieldName,
  value,
  title,
  editing,
}: Props): JSX.Element {
  if (!value && !editing) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <Text className="mb-2 text-sm" type={'secondary'}>
        {title}
      </Text>

      {editing ? (
        <Form.Item name={fieldName} initialValue={value}>
          <Input.TextArea
            autoSize={{
              minRows: 3,
            }}
          />
        </Form.Item>
      ) : (
        <div
          style={{ whiteSpace: 'pre-line' }}
          className="prose leading-relaxed"
        >
          {value}
        </div>
      )}
    </div>
  );
}

export default EditableTextField;
