import { useEffect } from 'react';

import { useViewLeaseAgreementMutation } from '@/generated/graphql';

interface Props {
  leaseAgreementId: string;
}

function Viewer({ leaseAgreementId }: Props): null {
  const [action] = useViewLeaseAgreementMutation({
    variables: { leaseAgreementId },
  });

  useEffect(() => {
    if (leaseAgreementId) {
      void action();
    }
  }, [action]);

  return null;
}

export default Viewer;
