import React from 'react';

import Box from '@/components/Box';
import Comment from '@/components/Comment';
import Message from '@/components/Message';
import { useGetSearchRequestCommentsLandlordQuery } from '@/generated/graphql';
import formatDate from '@/utils/formatDate';

import DeleteComment from './DeleteComment';

interface Props {
  searchRequestId: string;
}

function Comments({ searchRequestId }: Props): JSX.Element {
  const { data, loading, error, previousData } =
    useGetSearchRequestCommentsLandlordQuery({
      variables: {
        searchRequestId,
      },
    });

  if (loading && !data && !previousData) {
    return null;
  }

  if (error) {
    return <Message error={error} />;
  }
  const { searchRequestCommentsLandlord } = data ?? previousData;

  if (searchRequestCommentsLandlord.length === 0) {
    return null;
  }

  return (
    <Box p={1}>
      {searchRequestCommentsLandlord.map(
        ({ id: commentId, user, comment, created }) => (
          <Comment
            key={commentId}
            actions={[<DeleteComment id={commentId} key={'delete-comment'} />]}
            avatarUrl={user?.image?.url}
            datetime={formatDate(created)}
            author={user.name}
            content={comment}
          />
        ),
      )}
    </Box>
  );
}

export default Comments;
