import { MakeGenerics, Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type RegionsAdminRouteProps = MakeGenerics<{
  Params: {
    regionId?: string;
  };
  LoaderData: {
    regionId: string;
  };
}> &
  AdminRouteBaseProps;

const Regions = lazy(() => import('.'));
const Add = lazy(() => import('./AddRegionView'));
const Edit = lazy(() => import('./EditRegionView'));

const RegionsAdminRoutes: Route<RegionsAdminRouteProps>[] = [
  {
    path: '/regions',
    id: 'RegionsAdmin',
    meta: {
      breadcrumbs: () => ({
        breadcrumb: 'Regioner',
      }),
    },
    children: [
      {
        path: '/',
        element: <Regions />,
      },
      {
        path: ':regionId/edit',
        id: 'RegionsAdminEdit',
        element: <Edit />,
        meta: {
          breadcrumbs: () => ({
            breadcrumb: 'Redigera',
          }),
        },
        loader: ({ params }) => ({ regionId: params.regionId }),
      },
      {
        path: '/add',
        id: 'RegionsAdminAdd',
        meta: {
          breadcrumbs: () => ({
            breadcrumb: 'Lägg till',
          }),
        },
        element: <Add />,
      },
    ],
  },
];

export const toRegionsAdmin = (): string => '/admin/regions/';
export const toRegionsAdminAdd = (): string => '/admin/regions/add';
export const toRegionsAdminEdit = (regionId: string): string =>
  `/admin/regions/${regionId}/edit`;

export default RegionsAdminRoutes;
