import { Bounds } from '@/generated/graphql';

export const compareBoundsEqual = (a: Bounds, b: Bounds | null): boolean =>
  b &&
  a.north === b.north &&
  a.south === b.south &&
  a.west === b.west &&
  a.east === b.east;

export const boundsSweden = {
  east: 26.963166,
  north: 69.60923,
  south: 53.536937,
  west: 6.660432,
};
