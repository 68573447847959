import { EyeOutlined } from '@ant-design/icons';
import { CoffeeTogo } from '@styled-icons/boxicons-regular/CoffeeTogo';
import { ShoppingBag } from '@styled-icons/boxicons-regular/ShoppingBag';
import { Show } from '@styled-icons/boxicons-regular/Show';
import { Store } from '@styled-icons/boxicons-regular/Store';
import { Landscape } from '@styled-icons/boxicons-solid/Landscape';
import { Tools } from '@styled-icons/entypo/Tools';
import { Hospital } from '@styled-icons/fa-regular/Hospital';
import { Industry } from '@styled-icons/fa-solid/Industry';
import { Warehouse } from '@styled-icons/fa-solid/Warehouse';
import { PeopleCommunity } from '@styled-icons/fluentui-system-filled/PeopleCommunity';
import { DotsCircleHorizontal } from '@styled-icons/heroicons-outline/DotsCircleHorizontal';
import { OfficeBuilding } from '@styled-icons/heroicons-outline/OfficeBuilding';
import { Restaurant } from '@styled-icons/ionicons-outline/Restaurant';
import { Barbell } from '@styled-icons/ionicons-sharp/Barbell';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const icons = {
  office: (
    <OfficeBuilding style={{ verticalAlign: 'inherit', display: 'block' }} />
  ),
  coWork: (
    <PeopleCommunity style={{ verticalAlign: 'inherit', display: 'block' }} />
  ),
  shop: <ShoppingBag style={{ verticalAlign: 'inherit', display: 'block' }} />,
  shopPopup: <Store style={{ verticalAlign: 'inherit', display: 'block' }} />,
  projectSpace: (
    <Tools style={{ verticalAlign: 'inherit', display: 'block' }} />
  ),
  warehouse: (
    <Warehouse style={{ verticalAlign: 'inherit', display: 'block' }} />
  ),
  industry: <Industry style={{ verticalAlign: 'inherit', display: 'block' }} />,
  plotLand: (
    <Landscape style={{ verticalAlign: 'inherit', display: 'block' }} />
  ),
  restaurant: (
    <Restaurant style={{ verticalAlign: 'inherit', display: 'block' }} />
  ),
  gym: <Barbell style={{ verticalAlign: 'inherit', display: 'block' }} />,
  event: <Show style={{ verticalAlign: 'inherit', display: 'block' }} />,
  showroom: (
    <EyeOutlined style={{ verticalAlign: 'inherit', display: 'block' }} />
  ),
  healthcare: (
    <Hospital style={{ verticalAlign: 'inherit', display: 'block' }} />
  ),
  other: (
    <DotsCircleHorizontal
      style={{ verticalAlign: 'inherit', display: 'block' }}
    />
  ),
  cafe: <CoffeeTogo style={{ verticalAlign: 'inherit', display: 'block' }} />,
};

export const categoryIcons = (
  size?: 'small' | 'middle' | 'large',
): {
  [key: string]: JSX.Element;
} => {
  const iconSize = size === 'small' ? 14 : 16;
  return {
    office: <OfficeBuilding size={iconSize} />,
    coWork: <PeopleCommunity size={iconSize} />,
    shop: <ShoppingBag size={iconSize} />,
    shopPopup: <Store size={iconSize} />,
    warehouse: <Warehouse size={iconSize} />,
    industry: <Industry size={iconSize} />,
    plotLand: <Landscape size={iconSize} />,
    restaurant: <Restaurant size={iconSize} />,
    cafe: <CoffeeTogo size={iconSize} />,
    gym: <Barbell size={iconSize} />,
    showroom: <Show size={iconSize} />,
    projectSpace: <Tools size={iconSize} />,
    healthcare: <Hospital size={iconSize} />,
    other: <DotsCircleHorizontal size={iconSize} />,
  };
};

interface Props {
  usageCategory: string;
  size?: 'small' | 'middle' | 'large';
  shadow?: boolean;
  inverted?: boolean;
}

interface IconProps {
  usageCategory: string;
}

export const UsageCategoryIcon = ({
  usageCategory,
}: IconProps): JSX.Element | null => {
  const icon = categoryIcons()[usageCategory];

  const noIcon = !icon;
  if (noIcon) {
    return null;
  }

  return icon;
};
function UsageCategoryTag({
  usageCategory,
  size = 'middle',
  shadow = false,
  inverted = false,
}: Props): JSX.Element {
  const { t } = useTranslation();

  switch (size) {
    case 'large':
      return (
        <div
          className={clsx(
            `p-3 flex items-center justify-center border border-solid border-purple-600 border-opacity-50 bg-white rounded-xl`,
            { 'bg-purple-100 border-none': inverted },

            { 'shadow-md': shadow },
          )}
        >
          <div className={`w-5 h-5 text-purple-600 mr-2`}>
            {icons[usageCategory]}
          </div>
          <div className={'text-purple-600 text-sm font-normal'}>
            {t(usageCategory)}
          </div>
        </div>
      );
    case 'middle':
      return (
        <div
          className={clsx(
            `p-2 text-sm flex items-center justify-center border border-solid border-purple-600 border-opacity-50 bg-white rounded-xl`,
            { 'shadow-md': shadow },
            { 'bg-purple-100 border-none': inverted },
          )}
        >
          <div className={`w-4 h-4 text-purple-600 mr-2 mt-0.5`}>
            {icons[usageCategory]}
          </div>
          <div className={'text-purple-600 text-xs font-normal'}>
            {t(usageCategory)}
          </div>
        </div>
      );
    case 'small':
      return (
        <div
          className={clsx(
            `py-1 px-2 text-sm flex items-center justify-center border border-solid border-purple-600 border-opacity-50 bg-white rounded-md`,
            { 'bg-purple-100 border-none': inverted },
            { 'shadow-md': shadow },
          )}
        >
          <div className={`w-4 h-4 text-purple-600  mr-2 mt-0.5`}>
            {icons[usageCategory]}
          </div>
          <div className={'text-purple-600 text-xs font-normal'}>
            {t(usageCategory)}
          </div>
        </div>
      );
  }
}

export default React.memo(UsageCategoryTag);
