import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'tailwindcss/colors';

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import theme from '@/main/theme';
import { setSelectedMarker as setMarker } from '@/state/customer/actions';
import { MapObject } from '@/utils/misc';
import { Agreement, Cluster, isCluster, isLeaseAgreement } from '@/utils/types';

import Marker from './Marker';
import MarkerPreview from './MarkerPreview';
import { groupMarkers } from './util';

export interface Items {
  [name: string]: MapObject[];
}

const getGoogleClusterInlineSvg = function (
  color: string,
  scale: number,
): string {
  const encoded = window.btoa(
    `<svg xmlns="http://www.w3.org/2000/svg"
    width="${scale * 10}px"
    height="${scale * 10}px"
    viewBox="-30 -30 60 60">
    <circle r="18" fill="${color}"/>
    <circle r="21" fill-opacity="0.8" fill="${color}"/>
    <circle r="24" fill-opacity="0.5" fill="${color}"/>
    <circle r="27" fill-opacity="0.20" fill="${color}"/>
    <circle r="30" fill-opacity="0.20" fill="${color}"/>
  </svg>`,
  );

  return `data:image/svg+xml;base64,${encoded}`;
};
export function portallyPin(
  active: boolean,
  pinWidth: number,
  pinColor: string,
) {
  const color = active ? '#000000' : pinColor || '#000000';
  const width = active ? 35 : pinWidth;

  const encoded = window?.btoa(
    `<svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -30 283.46 190.36"
          width="${width}px"
          height="${width}px"
          fill="${color}"
        >
          <path
            d="M299.611 118.679a114.18 114.18 0 0 0-121.814 112.744h0v160.21c0 3.603 2.645 4.434 6.097 1.688 13.429-10.557 29.653-24.06 57.519-46.433H293.212a114.206 114.206 0 0 0 6.399-228.159Zm-7.281 155.448a41.747 41.747 0 0 1-41.747-41.747c0-23.053 20.155-41.848 42.679-41.848a40.916 40.916 0 0 1 40.941 40.916C334.203 254.073 315.383 274.127 292.33 274.127Z"
            transform="translate(-150.25 -193.63)"
            fill="${color}"
            stroke="#fff"
            stroke-width="10"
          />
        </svg>`,
  );

  return {
    url: `data:image/svg+xml;base64,${encoded}`,
    anchor: window.google ? new window.google.maps.Point(1, width) : undefined,
  } as const;
}

interface MarkerProps {
  id: string;
  position: google.maps.LatLngLiteral;
  selected: boolean;
  clickHandler: (prop: { id: string }) => void;
}

export const getListOfMarkers = <T extends MapObject>(items: {
  [key: string]: T[];
}): (Agreement | Cluster)[] => {
  const listOfItems: (Agreement | Cluster)[] = [];

  const leaseAgreementIds: string[] = [];
  Object.keys(items).forEach(name => {
    items[name].forEach((item): void => {
      if (!leaseAgreementIds.includes(item.id)) {
        leaseAgreementIds.push(item.id);
        if (isLeaseAgreement(item) || isCluster(item)) {
          listOfItems.push(item);
        }
      }
    });
  });

  return listOfItems;
};

interface ClusterMarkerProps {
  id: string;
  position: google.maps.LatLngLiteral;
  count: number;
}

class ClusterMarker extends React.Component<ClusterMarkerProps> {
  shouldComponentUpdate(nextProps): boolean {
    const { position } = this.props;
    // Without this hack, the markers re-render all the time
    return (
      position.lat !== nextProps.position.lat ||
      position.lng !== nextProps.position.lng
    );
  }

  render(): JSX.Element {
    const { id, position, count } = this.props;
    const scale = count > 100 ? 7 : count > 50 ? 6 : count > 10 ? 5 : 5;

    const color =
      count > 100
        ? '#ff4d4f'
        : count > 50
        ? '#40a9ff'
        : count > 10
        ? '#f759ab'
        : '#73d13d';

    return (
      <Marker
        type="cluster"
        id={id}
        position={position}
        icon={{
          url: getGoogleClusterInlineSvg(color, scale),
          anchor:
            window.google &&
            window.google &&
            new window.google.maps.Point(scale * 5, scale * 5),
        }}
        label={{
          text: count.toString(),
          color: '#000',
          fontWeight: '700',
          fontSize: '14px',
        }}
      />
    );
  }
}

interface MarkersProps {
  previewSize?: 'small' | 'default';
}

function Markers({ previewSize = 'default' }: MarkersProps): JSX.Element {
  const { t } = useTranslation();
  const items = useAppSelector(state => state.customer.leaseAgreementMarkers);

  const dispatch = useAppDispatch();
  const listOfItems: MapObject[] = groupMarkers(getListOfMarkers(items));
  const selectedMarker = useAppSelector(state => state.customer.selectedMarker);
  const setSelectedMarker = (id: string) => {
    dispatch(setMarker(id));
  };
  return (
    <>
      <MarkerPreview
        id={selectedMarker}
        onClose={() => setSelectedMarker('')}
        t={t}
      />
      {listOfItems
        .map(obj => {
          if (!obj.location) {
            return null;
          }

          const [lng, lat] = obj.location.coordinates;

          if (isLeaseAgreement(obj)) {
            return (
              <Marker
                key={obj.id}
                onMarkerClick={({ id }) => {
                  setSelectedMarker(id);
                }}
                type="single"
                icon={portallyPin(
                  selectedMarker == obj.id.toString(),
                  26,
                  theme.colors.coral,
                )}
                position={{ lat, lng }}
                id={obj.id}
              />
            );
          } else {
            const { count, id } = obj;

            if (count === 1) {
              return (
                <Marker
                  key={obj.id}
                  onMarkerClick={({ id }) => {
                    setSelectedMarker(id);
                  }}
                  type="single"
                  icon={portallyPin(
                    selectedMarker === obj.id,
                    26,
                    theme.colors.coral,
                  )}
                  position={{ lat, lng }}
                  id={obj.id}
                />
              );
            }
            if (count === -1) {
              return (
                <Marker
                  key={obj.id}
                  onMarkerClick={({ id }) => {
                    setSelectedMarker(id);
                  }}
                  type="single"
                  icon={portallyPin(
                    selectedMarker == obj.id ||
                      obj.id.split(':').includes(selectedMarker),
                    32,
                    colors.pink['400'],
                  )}
                  position={{ lat, lng }}
                  id={obj.id}
                />
              );
            }

            // return <GMarker position={{ lat, lng }} key={obj.id}></GMarker>;

            return (
              <ClusterMarker
                key={id}
                id={id}
                position={{ lat, lng }}
                count={count}
              />
            );
          }
        })
        .filter(Boolean)}
    </>
  );
}

export default React.memo(Markers);
