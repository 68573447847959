import { Button, Modal } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { getI18n, useTranslation } from 'react-i18next';

import {
  GetLoggedInUserDocument,
  ModalEnum,
  useAcceptTermsMutation,
  useGetCurrentTermsOfServiceQuery,
} from '@/generated/graphql';
import useUser from '@/hooks/useUser';

function TermsOfService(): JSX.Element | null {
  const user = useUser();
  const [action, { loading }] = useAcceptTermsMutation({
    refetchQueries: [
      {
        query: GetLoggedInUserDocument,
      },
    ],
  });
  const i18n = getI18n();
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language);

  const {
    data,
    loading: queryLoading,
    error,
  } = useGetCurrentTermsOfServiceQuery({});

  if (queryLoading) {
    return null;
  }

  if (user.activeModal != ModalEnum.Terms) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!data.getCurrentTermsOfService) {
    return null;
  }

  if (user.role == 'admin') {
    return null;
  }

  if (
    user.activeClient &&
    user.activeClient.termsOfService &&
    user.activeClient.termsOfService.confirmed &&
    dayjs(user.activeClient.termsOfService.confirmedDate) >
      dayjs(data.getCurrentTermsOfService.publishedDate).startOf('day')
  ) {
    return null;
  }

  if (!user.activeClient) {
    return null;
  }

  return (
    <Modal
      open={true}
      closable={false}
      width={'70%'}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => action().catch(() => console.error('Oops errors!'))}
        >
          {t('Confirm')}
        </Button>,
      ]}
    >
      {language === 'en' ? (
        <ReactCountryFlag
          countryCode="SE"
          style={{ cursor: 'pointer', float: 'right' }}
          onClick={() => {
            i18n.changeLanguage('sv');
            setLanguage('sv');
          }}
        />
      ) : (
        <ReactCountryFlag
          countryCode="GBR"
          style={{ cursor: 'pointer', float: 'right' }}
          onClick={() => {
            i18n.changeLanguage('en');
            setLanguage('en');
          }}
        />
      )}
      <p>
        <strong>{t('termsConfirmText')}</strong>
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: data.getCurrentTermsOfService.content[language],
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: data.getCurrentTermsOfService?.privacy[language],
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: data.getCurrentTermsOfService?.cookiePolicy[language],
        }}
      />
    </Modal>
  );
}

export default TermsOfService;
