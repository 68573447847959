import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';

export type SubscriptionRequestRouteProps = AdminRouteBaseProps;

const SubscriptionRequests = lazy(() => import('.'));

const SubscriptionRequestRoutes: Route<SubscriptionRequestRouteProps>[] = [
  {
    path: '/subscriptions',
    id: '',
    element: <SubscriptionRequests />,
  },
];

export const toSubscriptionRequests = (): string => '/admin/subscriptions';

export default SubscriptionRequestRoutes;
