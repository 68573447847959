import {
  AlignCenterOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DollarOutlined,
  FileOutlined,
  HomeOutlined,
  MailOutlined,
  RiseOutlined,
  SearchOutlined,
  ToolOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Business } from '@styled-icons/boxicons-solid/Business';
import { Globe } from '@styled-icons/entypo/Globe';
import { Airplane } from '@styled-icons/ionicons-outline/Airplane';
import { Web } from '@styled-icons/material/Web';
import { Building2 } from '@styled-icons/remix-fill/Building2';
import { useMatches, useNavigate } from '@tanstack/react-location';
import { Menu, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import MatchIcon from '@/components/MatchIcon';
import {
  Mask,
  Navbar as MobileNav,
  OverlayMenu,
  StyledMenu,
} from '@/layout/Customer/MobileNavigation';
import { toAdminHome } from '@/views/AdminHome/routes';
import { toClientList } from '@/views/Client/adminRoutes';
import { toDeals } from '@/views/Deals/routes';
import { toEmailLog } from '@/views/EmailLog/routes';
import { toEstatesAdmin } from '@/views/EstatesAdmin/routes';
import { toHome } from '@/views/Home/routes';
import { toLeaseAgreementsProspectsAdmin } from '@/views/LeaseAgreementProspectsAdmin/routes';
import { toAdminOverview } from '@/views/Overview/routes';
import { toPreliminarySearchRequests } from '@/views/PreliminarySearchRequests/routes';
import { toRegionsAdmin } from '@/views/RegionsAdmin/routes';
import { toSaleClientsAdmin } from '@/views/SaleClientsAdmin/routes';
import { toSearchRequestsAdmin } from '@/views/SearchRequestsAdmin/routes';
import { toStatistics } from '@/views/Statistics/routes';
import { toSubscriptionRequests } from '@/views/SubscriptionRequest/routes';
import { toUsers } from '@/views/Users/adminRoutes';

const { Text } = Typography;
const Item = styled.div<{ $open?: boolean; $active?: boolean }>`
  width: 25%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  color: ${({ theme, $active }) =>
    $active ? theme.colors.primaryPurpleDark : 'rgba(0,0,0,0.65)'};
  color: ${({ theme, $open }) => $open && theme.colors.coral};
`;

const menus = {
  admin: [
    {
      to: toAdminHome(),
      text: 'Hem',
      icon: HomeOutlined,
    },
    {
      to: toClientList(),
      text: 'Företag',
      icon: Business,
    },
    {
      to: toUsers(),
      text: 'Användare',
      icon: UserOutlined,
    },
    {
      to: toAdminOverview(),
      text: 'Överblick',
      icon: CalendarOutlined,
    },
  ],
  reports: [
    {
      to: toStatistics(),
      text: 'Statistik',
      icon: RiseOutlined,
    },
    {
      to: toEmailLog(),
      text: 'Maillog',
      icon: MailOutlined,
    },
  ],
  tools: [
    {
      to: toRegionsAdmin(),
      text: 'Regioner',
      icon: Globe,
    },
    {
      to: toEstatesAdmin(),
      text: 'Fastigheter',
      icon: Building2,
    },
    {
      to: toSearchRequestsAdmin(),
      text: 'Lokalförfrågningar',
      icon: SearchOutlined,
    },
    {
      to: toLeaseAgreementsProspectsAdmin(),
      text: 'Intresseanmälningar',
      icon: MatchIcon,
    },
    {
      to: toPreliminarySearchRequests(),
      text: 'Inkommande lokalförfrågningar',
      icon: Airplane,
    },
    {
      to: toSubscriptionRequests(),
      text: 'Abonnemangsförfrågnignar',
      icon: WalletOutlined,
    },
    {
      to: toDeals(),
      text: 'Affärer',
      icon: DollarOutlined,
    },
    {
      to: toSaleClientsAdmin(),
      text: 'Säljstegen',
      icon: AlignCenterOutlined,
    },
  ],
};

const navigation = [
  {
    pathnames: menus.admin.map(m => m.to),
    key: 'admin',
    text: 'Admin',
    icon: DashboardOutlined,
  },
  {
    pathnames: menus.tools.map(m => m.to),
    key: 'tools',
    text: 'Verktyg',
    icon: ToolOutlined,
  },
  {
    pathnames: menus.reports.map(m => m.to),
    key: 'reports',
    text: 'Rapporter',
    icon: FileOutlined,
  },
];

function MobileNavigation(): JSX.Element {
  const [menuOpen, setMenuOpen] = useState(false);

  const [activeKey, setActiveKey] = useState('');
  const matches = useMatches();
  const navigate = useNavigate();
  const color = useCallback(() => 'inherit', [menuOpen]);
  const pathname = matches[matches.length - 1].pathname;

  return (
    <>
      <Mask open={menuOpen} />
      <OverlayMenu open={menuOpen}>
        <StyledMenu>
          {activeKey &&
            menus[activeKey].map(item => (
              <Menu.Item
                style={{ borderRadius: '6px', marginTop: 0 }}
                key={item.text}
                onClick={() => {
                  navigate({
                    to: item.to,
                  });
                  setMenuOpen(false);
                  setActiveKey('');
                }}
                icon={<item.icon size={14} />}
                title={item.text}
              >
                {item.text}
              </Menu.Item>
            ))}
        </StyledMenu>
      </OverlayMenu>
      <MobileNav $menuOpen={menuOpen} key={'admin-mobile-nav'}>
        {navigation.map(item => (
          <Item
            key={item.key}
            $open={item.key === activeKey}
            $active={item.pathnames.some(path =>
              path === toAdminHome()
                ? path === pathname
                : pathname.startsWith(path),
            )}
            onClick={() => {
              if (menuOpen && activeKey === item.key) {
                setMenuOpen(false);
                setActiveKey('');
              } else {
                setActiveKey(item.key);
                setMenuOpen(true);
              }
            }}
          >
            <item.icon size={22} />
            <Text style={{ fontSize: '10px', color: 'inherit' }}>
              {item.text}
            </Text>
          </Item>
        ))}
        <Item
          onClick={() => navigate({ to: toHome() })}
          style={{ borderLeft: '1px solid #cccccc50' }}
        >
          <Web style={{ color: color() }} size={'22px'} />
          <Text style={{ fontSize: '10px', color: color() }}>
            {'Dashboard'}
          </Text>
        </Item>
      </MobileNav>
    </>
  );
}

export default MobileNavigation;
