import React from 'react';
import styled from 'styled-components';

import Box from '@/components/Box';

import AddComment from './AddComment';
import Comments from './Comments';

interface Props {
  searchRequestId?: string;
}
const StyledBox = styled(Box)`
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
  border-top: 1px solid rgba(55, 53, 47, 0.09);
`;
function CommentSection({ searchRequestId }: Props): JSX.Element {
  if (!searchRequestId) {
    return null;
  }
  return (
    <StyledBox py={1.5}>
      <Comments searchRequestId={searchRequestId} />
      <AddComment searchRequestId={searchRequestId} />
    </StyledBox>
  );
}

export default CommentSection;
