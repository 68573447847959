import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { Title } = Typography;

const Container = styled.div`
  max-height: 450px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  line-height: 1.4;
  font-size: 16px;
  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: block;
    justify-content: flex-end;
    margin: 0;
  } ;
`;

interface Props {
  header?: string;
  text: string;
}

function Description({ header, text }: Props): JSX.Element {
  return (
    <Container>
      <div>{header && <Title level={2}>{header}</Title>}</div>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Container>
  );
}

export default Description;
