import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  size?: number;
  sizeSpan?: {
    from: number;
    to: number;
  };
  showNotSpecified?: boolean;
}

export const getLeaseAgreementSizeString = ({
  size,
  sizeSpan,
}: Props): string => {
  if (sizeSpan) {
    return `${sizeSpan.from} - ${sizeSpan.to} m²`;
  }
  if (size) {
    return `${size} m²`;
  }
  return '';
};

function LeaseAgreementSize({
  size,
  sizeSpan,
  showNotSpecified = false,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const sizeString = getLeaseAgreementSizeString({ size, sizeSpan });

  if (sizeString) {
    return <>{sizeString}</>;
  }

  if (showNotSpecified) {
    return t('Not specified');
  }
  return null;
}

export default LeaseAgreementSize;
