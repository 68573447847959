import { Link } from '@tanstack/react-location';
import styled from 'styled-components';

import { getNavigationColor } from '@/layout/Customer/MobileNavigation';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PortallyLogoWhite from '@/layout/Portally_logo_white.png?url';

export const Grow = styled.div`
  flex: 1;
`;

export const DropdownNav = styled.span`
  font-size: 11px;
  color: ${p => p.theme.colors.light};
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  cursor: pointer;
  width: 100%;

  svg {
    display: block;
    width: 26px;
    margin-bottom: 5px;
  }

  &.active {
    opacity: 1;
    color: ${p => p.theme.colors.purple};
    background: ${p => p.theme.colors.light};
  }

  &:hover:not(.active):not(.dropdown) {
    background: #3f374f;
    color: ${p => p.theme.colors.light};
  }

  &.dropdown {
    width: 80px;
  }

  &.dropdown:hover:not(.active) {
    opacity: 1;
    svg,
    .text {
      opacity: 1;
    }
  }

  .ant-badge {
    color: inherit;
  }
`;
export const NavLink = styled(Link)`
  font-size: 11px;
  color: ${p => p.theme.colors.light};
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;

  svg {
    display: block;
    width: 26px;
    margin-bottom: 5px;
  }

  &.active {
    opacity: 1;
    color: ${p => p.theme.colors.purple};
    background: ${p => p.theme.colors.light};
    .active-icon {
      display: block !important;
    }
    .inactive-icon {
      display: none !important;
    }
  }

  &:hover:not(.active):not(.dropdown) {
    background: #3f374f;
    color: ${p => p.theme.colors.light};
  }

  &.dropdown {
    width: 80px;
  }

  &.dropdown:hover:not(.active) {
    opacity: 1;

    svg,
    .text {
      opacity: 1;
    }
  }

  .ant-badge {
    color: inherit;
  }
`;
export const NavLinkMobile = styled(Link)<{ $menuOpen?: boolean }>`
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 25%;
  svg {
    display: block;
    width: 22px;
    height: 22px;
  }
  > * {
    color: ${p => getNavigationColor(p.$menuOpen)};
  }
  .active-icon {
    display: none;
  }
  &.active {
    .inactive-icon {
      display: none;
    }
    .active-icon {
      display: block;
    }
    > * {
      color: ${p => p.theme.colors.primaryPurpleDark};
    }

    .border {
      background: ${p =>
        p.$menuOpen ? p.theme.colors.primaryPurpleDark : p.theme.colors.white};
    }
  }

  &.dropdown {
    width: 80px;
  }

  &.dropdown:hover:not(.active) {
    opacity: 1;

    svg,
    .text {
      opacity: 1;
    }
  }
`;

export const Logo = styled(Link)`
  background-image: url('${PortallyLogoWhite}');
  background-size: 50px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 66px;
  display: inline-block;
  margin-bottom: 16px;
`;

export const StyledContainer = styled.div<{ $wide: boolean }>`
  display: flex;
  height: 100%;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 0;

  width: ${({ $wide, theme }) =>
    $wide ? theme.dimensions.leftMenu : theme.dimensions.leftMenuCollapsed};
  flex-shrink: 0;
  .text {
    display: ${({ $wide }) => ($wide ? 'block' : 'none')};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    .text {
      display: none;
    }
    width: ${({ theme }) => theme.dimensions.leftMenuCollapsed};
  }

  transition: width 0.2s ease;
`;

export const StyledSider = styled.div<{ $fixed: boolean }>`
  z-index: ${({ $fixed }) => ($fixed ? 3 : 1)};
  background: ${p => p.theme.colors.dark};
  height: 100vh;

  position: ${({ $fixed }) => ($fixed ? 'fixed' : 'sticky')};

  top: 0px;
`;

export const Inner = styled.div`
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
`;
