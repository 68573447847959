import { useApolloClient } from '@apollo/client';
import { Form, message, Modal, Steps } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ModalEnum,
  SubscriptionVersion,
  useAddDealMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import useUser from '@/hooks/useUser';

import Landing from './Landing';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepZero from './StepZero';

const { Step } = Steps;

interface Props {
  showDealRequest?: boolean;
  setShowDealRequest?: Dispatch<SetStateAction<boolean>>;
}
function DealForm({
  showDealRequest = false,
  setShowDealRequest,
}: Props): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const user = useUser();

  const apollo = useApolloClient();
  const [form] = Form.useForm();
  const [portallyFee, setPortallyFee] = useState(undefined);
  const [step, setStep] = useState(0);
  const [acceptedDealRequest, setAcceptedDealRequest] = useState(
    !user?.hasDealRequest,
  );

  const setInitialState = (): void => {
    setPortallyFee(undefined);
    setStep(0);
    setShowDealRequest(false);
  };

  const { t } = useTranslation();
  const [addDeal, { loading }] = useAddDealMutation({
    onCompleted: async () => {
      setInitialState();
      message.success(
        'Tack för ditt svar! Kontakta oss på support@portally.com om du skulle ha några frågor kring fakturan eller någonting annat.',
        5,
      ),
        await apollo.reFetchObservableQueries();
    },
  });

  const isLandlord = user?.activeClient.type === 'landlord';

  if (
    user.activeModal != ModalEnum.Dealrequest &&
    user.activeModal != ModalEnum.None
  ) {
    return null;
  }

  const portallyfee =
    isLandlord && user?.activeSubscription?.version === SubscriptionVersion.V1
      ? 0.03
      : 0.06;

  return (
    <Modal
      closable={false}
      footer={null}
      onCancel={() => setShowDealRequest && setShowDealRequest(false)}
      open={showDealRequest}
    >
      <Form
        layout={'vertical'}
        onFieldsChange={() =>
          setPortallyFee(
            Math.round(form.getFieldValue('annualRent') * portallyfee),
          )
        }
        form={form}
        onFinish={async (values): Promise<void> => {
          const { invoiceDetails } = values;
          await addDeal({
            variables: {
              input: {
                ...invoiceDetails,
                userId: user.id,
                clientId: user.activeClientId,
                madeWith: values.madeWith,
                annualRent: Number(values.annualRent),
              },
            },
          });
        }}
      >
        {!acceptedDealRequest && (
          <Landing
            isLandlord={isLandlord}
            setAcceptedDealRequest={setAcceptedDealRequest}
            setShowDealRequest={setShowDealRequest}
          />
        )}
        <>
          <Form.Item noStyle name={['invoiceDetails', 'company']} />
          <Form.Item noStyle name={'madeWith'} />
          <Form.Item noStyle name={'annualRent'} />
          <Form.Item noStyle name={['invoiceDetails', 'billing']} />
          <Form.Item noStyle name={['invoiceDetails', 'marking']} />
          <Form.Item noStyle name={['invoiceDetails', 'reference']} />
          <Form.Item noStyle name={['invoiceDetails', 'email']} />
          <Form.Item noStyle name={'comment'} />
          {acceptedDealRequest && (
            <>
              {!isMobile && (
                <Steps progressDot current={step}>
                  <Step description={t('Hyresavtalet')} />
                  <Step description={t('Billing information')} />
                  <Step description={t('Granska och Skicka')} />
                </Steps>
              )}
            </>
          )}
          {acceptedDealRequest && step === 0 && (
            <StepZero
              showDealRequest={showDealRequest}
              setShowDealRequest={setShowDealRequest}
              form={form}
              isLandlord={isLandlord}
              setAcceptedDealRequest={setAcceptedDealRequest}
              portallyFee={portallyFee}
              setStep={setStep}
              step={step}
            />
          )}
          {step === 1 && <StepOne form={form} step={step} setStep={setStep} />}
          {step === 2 && (
            <StepTwo
              setStep={setStep}
              step={step}
              loading={loading}
              isLandlord={isLandlord}
              portallyFee={portallyFee}
              form={form}
              t={t}
            />
          )}
        </>
      </Form>
    </Modal>
  );
}
export default DealForm;
