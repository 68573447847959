import { Tabs } from 'antd';
import { Dictionary, groupBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import {
  FilterProps,
  StyledTag,
} from '@/components/SearchRequestFilter/shared';
import { GetFilterCountiesQuery, RegionTypeEnum } from '@/generated/graphql';
import { Unpacked } from '@/utils/types';

import { nextRegions } from './util';
const { TabPane } = Tabs;

const Container = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.primaryPurple};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: none;
  margin-top: -8px !important;
  grid-column: 1 / -1;
`;

const NameMap = {
  '6133cfea7bdebdc5ba6798e7': {
    [RegionTypeEnum.DistrictArea]: 'Göteborg',
    [RegionTypeEnum.Municipality]: 'Kommuner',
  },
  '6133cfea7bdebdc5ba6798e4': {
    [RegionTypeEnum.DistrictArea]: 'Malmö',
    [RegionTypeEnum.Municipality]: 'Kommuner',
  },
};

const Grid = styled(Box)`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
`;
function ExtendedRegions({
  searchRequestSettings,
  setSearchRequestSettings,
  regions,
  parentId,
  cityId,
}: FilterProps & {
  regions: Unpacked<
    GetFilterCountiesQuery['getRegions']['regions']
  >['children'];
  parentId: string;
  cityId: string;
}): JSX.Element {
  const { t } = useTranslation();

  const groupedRegions = groupBy(regions, 'regionType');

  const keys = Object.keys(groupedRegions)
    .sort(key => (key === 'district_area' ? -1 : 1))
    .filter(region => region === 'municipality' || region === 'district_area');

  return (
    <Container w={'100%'} p={2}>
      <Tabs defaultActiveKey={keys[0]}>
        {keys.map(key => (
          <TabPane
            tab={(NameMap[parentId] && NameMap[parentId][key]) ?? t(key)}
            key={key}
          >
            <Grid>
              <StyledTag
                $centered
                checked={
                  key === 'municipality'
                    ? groupedRegions[key].every(region =>
                        searchRequestSettings.regionIds.includes(region.id),
                      )
                    : searchRequestSettings.regionIds.includes(cityId)
                }
                onChange={checked => {
                  const { regionIds } = searchRequestSettings;
                  const next = nextRegions(
                    checked,
                    key,
                    parentId,
                    cityId,
                    groupedRegions,
                    regions,
                    regionIds,
                  );

                  setSearchRequestSettings({
                    ...searchRequestSettings,
                    regionIds: next,
                  });
                }}
              >
                {key === 'municipality'
                  ? t('Select all')
                  : `Hela ${NameMap[parentId][key]}`}
              </StyledTag>
              {groupedRegions[key].map(({ id, name }) => (
                <StyledTag
                  key={id}
                  $centered
                  checked={searchRequestSettings.regionIds.includes(id)}
                  onChange={checked => {
                    const { regionIds } = searchRequestSettings;
                    let next = checked
                      ? [
                          ...regionIds.filter(regionId =>
                            key === 'municipality'
                              ? regionId !== parentId
                              : regionId !== cityId && regionId !== parentId,
                          ),
                          id,
                        ]
                      : regionIds.filter(t => t !== id);

                    if (
                      !regions
                        .map(region => region.id)
                        .some(id => next.includes(id))
                    ) {
                      next = [...next, parentId];
                    }

                    setSearchRequestSettings({
                      ...searchRequestSettings,
                      regionIds: next,
                    });
                  }}
                >
                  {name}
                </StyledTag>
              ))}
            </Grid>
          </TabPane>
        ))}
      </Tabs>
    </Container>
  );
}

export default ExtendedRegions;
