import { Link } from '@tanstack/react-location';
import { Popover, Tooltip, Typography } from 'antd';
import React from 'react';

import Box from '@/components/Box';
import { AdminLeaseAgreementListValuesFragment } from '@/generated/graphql';
import theme from '@/main/theme';
import formatDate from '@/utils/formatDate';
import { toClient } from '@/views/Client/adminRoutes';
const { Text } = Typography;
interface Props {
  info: AdminLeaseAgreementListValuesFragment;
}

export default function AdminInfo({ info }: Props): JSX.Element {
  const { fromVitec = false, interestedUsers, landlord, views, created } = info;
  const systemViews = info.views.views
    .filter(view => !!view.user)
    .map(view => `${view.user.name} - ${view.user.activeClient?.name}`);
  const uniqueUsers = [...new Set(systemViews)];
  return (
    <Box flex flexDirection="column">
      <Box flex>
        {' '}
        <Tooltip title={'Vitec lokal'}>
          <span
            style={{
              color: fromVitec ? theme.colors.green : theme.colors.red,
            }}
          >
            V
          </span>
        </Tooltip>
        <span style={{ margin: '0 4px' }}>·</span>
      </Box>

      {landlord?.name && (
        <Link to={toClient(landlord.id)}>
          {landlord?.name && landlord.name}
        </Link>
      )}
      {created && `Skapad ${formatDate(created)}`}
      {interestedUsers && interestedUsers.length !== 0 && (
        <Popover
          content={
            <Box flex flexDirection="column">
              {interestedUsers.map(user => (
                <div key={`${user.name}${user.activeClient.name}`}>
                  {user.name} - {user.activeClient.name}
                </div>
              ))}
            </Box>
          }
        >
          <Text style={{ color: theme.colors.primaryPurple }}>
            Intresseanmälningar
          </Text>
        </Popover>
      )}
      {views.count !== 0 && (
        <Popover
          placement={'top'}
          content={
            <div className="flex flex-col space-y-1">
              {views.count !== systemViews.length && (
                <div className="text-gray-500">
                  {views.count - systemViews.length} visningar från hemsidan
                </div>
              )}
              {uniqueUsers.map((userName, i) => (
                <div key={userName + i}>{userName}</div>
              ))}
            </div>
          }
        >
          <Text style={{ color: theme.colors.primaryPurple }}>
            {views.count} visningar
          </Text>
        </Popover>
      )}
    </Box>
  );
}
