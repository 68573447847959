import { Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { AdminRouteBaseProps } from '@/layout/Admin/routes';
const Views = lazy(() => import('.'));

export type LeaseAgreementViewsAdminRouteProps = AdminRouteBaseProps;

const LeaseAgreementViewsAdminRoutes: Route<LeaseAgreementViewsAdminRouteProps>[] =
  [
    {
      path: '/lease-agreement-views',
      id: 'LeaseAgreementViewsAdmin',
      element: <Views />,
    },
  ];

export const toLeaseAgreementViewsAdmin = (): string =>
  '/admin/lease-agreement-views';

export default LeaseAgreementViewsAdminRoutes;
