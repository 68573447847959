import { AnyAction } from 'redux';

import { initialCenter } from '@/components/Map';
import {
  ActiveSearchRequestFilter,
  ConversationSearchType,
  GetSearchResultQuery,
} from '@/generated/graphql';
import {
  CENTER_REGIONS,
  SET_COMMERCE,
  SET_LEASEAGREEMENT_MARKERS,
  SET_LIMIT,
  SET_POLYGONS,
  SET_REGION_POLYGONS,
  SET_SEARCH_STEP,
  SET_SELECTED_MARKER,
  SET_SHOW_RESULTS,
  UPDATE_ACTIVE_SEARCH_REQUEST_FILTER,
  UPDATE_BOUNDS,
  UPDATE_CONVERSATION_FILTER,
  UPDATE_DASHBOARD_NAVIGATION_VIEW,
  UPDATE_DASHBOARD_SEARCH_REQUEST_TAB_KEY,
  UPDATE_MESSAGE_DRAFT,
  UPDATE_SEARCH_CENTER,
  UPDATE_SEARCH_REQUEST_SCROLL_POSITION,
  UPDATE_SEARCH_REQUEST_STATE,
  UPDATE_SEARCH_VALUE,
  UPDATE_SHOW_ALL_SEARCH_REQUESTS,
  UPDATE_SHOW_ARCHIVED,
  UPDATE_UNREAD_NOTIFICATIONS,
  UPDATE_ZOOM,
} from '@/state/customer/actions';
import { boundsSweden } from '@/utils/bounds';
import { Bounds, MapObject } from '@/utils/misc';
import { SearchValue } from '@/utils/types';

const initialState = {
  filters: {},
  bounds: boundsSweden,
  leaseAgreementMarkers: {},
  commerce: [],
  zoom: {
    adminSearch: 5,
    search: 4,
    establishment: 5,
    estates: 5,
    management: 5,
  },
  unreadNotifications: 0,
  limit: 24,
  showResults: true,
  center: {
    adminSearch: initialCenter,
    search: initialCenter,
    establishment: initialCenter,
    estates: initialCenter,
    management: initialCenter,
  },
  regionPolygons: [],
  search: false,
  step: -1,
  searchValue: {
    default: {
      concealed: true,
      //standardAgreement: true,
      geometry: {},
    },
  },
  messageDrafts: {},
  polygons: [],
  centerRegions: undefined,
  selectedMarker: undefined,
  usageCategory: '',
  searchRequestFilter: {
    usageCategory: '',
    regionIds: [],
  },

  conversationFilter: {
    personalized: true,
    search: {
      key: '',
      label: '',
      type: ConversationSearchType.Message,
    },
  },
  dashboard: {
    navigationView: undefined,
    tabKey: 'activeSearchRequests',
  },
  searchRequests: {},
  showArchived: false,
  activeSearchRequestFilter: {},
  showAllSearchRequests: false,
};

export interface ConversationFilter {
  search: {
    key: string;
    type: ConversationSearchType;
    label: string;
  };
  personalized: boolean;
}

interface SearchRequestSearchFilter {
  regionIds: {
    key: string;
    label: string;
  }[];
  size: {
    min?: number;
    max?: number;
  };
  polygons: google.maps.LatLngLiteral[][];
  usageCategories: string[];
}

export type SearchRequestViewType = 'card' | 'list';
export type AnswerTab = 'accepted' | 'declined' | 'opened' | 'offers';

interface DashboardState {
  navigationView?: SearchRequestViewType;
  tabKey: string;
}

export interface SearchRequestState {
  limit: number;
  searchFilter: SearchRequestSearchFilter;
  scrollPosition: number;
  tab: AnswerTab;
}

export interface CustomerState {
  bounds: Bounds;
  leaseAgreementMarkers: {
    [key: string]: MapObject[];
  };
  commerce: GetSearchResultQuery['leaseAgreements']['commerce'];
  regionPolygons: GetSearchResultQuery['leaseAgreements']['regionPolygons'];
  polygons: google.maps.LatLngLiteral[][];
  zoom: {
    adminSearch: number;
    search: number;
    establishment: number;
    estates: number;
    management: number;
  };
  limit: number;

  unreadNotifications: number;
  showResults: boolean;
  center: {
    adminSearch: google.maps.LatLngLiteral;
    search: google.maps.LatLngLiteral;
    establishment: google.maps.LatLngLiteral;
    estates: google.maps.LatLngLiteral;
    management: google.maps.LatLngLiteral;
  };

  search: boolean;
  step: number;
  searchValue: {
    default: SearchValue;
    [key: string]: SearchValue;
  };
  messageDrafts: {
    [key: string]: string;
  };
  centerRegions: Bounds | undefined;
  selectedMarker: string | undefined;
  searchRequestFilter: {
    usageCategory: string;
    regionIds: string[];
  };
  conversationFilter: ConversationFilter;
  dashboard: DashboardState;
  searchRequests: {
    [key: string]: SearchRequestState;
  };
  activeSearchRequestFilter: ActiveSearchRequestFilter;
  showArchived: boolean;
  showAllSearchRequests: boolean;
}

export default function customerPanelReducer(
  state: CustomerState = initialState as CustomerState,
  action: AnyAction,
): CustomerState {
  switch (action.type) {
    case CENTER_REGIONS:
      return {
        ...state,
        centerRegions: action.bounds,
      };

    case SET_REGION_POLYGONS:
      return {
        ...state,
        regionPolygons: action.regionPolygons,
      };

    case SET_POLYGONS:
      return {
        ...state,
        polygons: action.polygons,
      };

    case UPDATE_BOUNDS:
      return {
        ...state,
        bounds: action.bounds,
      };

    case UPDATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: {
          ...state.searchValue,
          [action.value?.id || 'default']: action.value,
        },
      };

    case UPDATE_ZOOM:
      return {
        ...state,
        zoom: {
          ...state.zoom,
          [action.key]: action.zoom,
        },
      };

    case SET_LEASEAGREEMENT_MARKERS:
      return {
        ...state,
        leaseAgreementMarkers: {
          ...state.leaseAgreementMarkers,
          [action.name]: action.items,
        },
      };
    case SET_COMMERCE:
      return {
        ...state,
        commerce: action.commerce,
      };
    case UPDATE_MESSAGE_DRAFT:
      return {
        ...state,
        messageDrafts: {
          ...state.messageDrafts,
          [action.conversationId]: action.message,
        },
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };
    case SET_SHOW_RESULTS:
      return {
        ...state,
        showResults: action.showResults,
      };
    case SET_SEARCH_STEP:
      return {
        ...state,
        step: action.step,
      };
    case UPDATE_SEARCH_CENTER:
      return {
        ...state,
        center: {
          ...state.center,
          [action.key]: action.center,
        },
      };
    case SET_SELECTED_MARKER:
      return {
        ...state,
        selectedMarker: action.selectedMarker,
      };

    case UPDATE_CONVERSATION_FILTER:
      return {
        ...state,
        conversationFilter: action.conversationFilter,
      };
    case UPDATE_DASHBOARD_NAVIGATION_VIEW:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          navigationView: action.navigationView,
        },
      };
    case UPDATE_DASHBOARD_SEARCH_REQUEST_TAB_KEY:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          tabKey: action.tabKey,
        },
      };
    case UPDATE_SEARCH_REQUEST_STATE:
      return {
        ...state,
        searchRequests: {
          ...state.searchRequests,
          [action.searchRequestReferenceId]: action.state,
        },
      };
    case UPDATE_SEARCH_REQUEST_SCROLL_POSITION:
      return {
        ...state,
        searchRequests: {
          ...state.searchRequests,
          [action.searchRequestReferenceId]: {
            ...state.searchRequests[action.searchRequestReferenceId],
            scrollPosition: action.scrollPosition,
          },
        },
      };
    case UPDATE_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: action.unreadNotifications,
      };
    case UPDATE_SHOW_ARCHIVED:
      return {
        ...state,
        showArchived: action.showArchived,
      };
    case UPDATE_ACTIVE_SEARCH_REQUEST_FILTER:
      return {
        ...state,
        activeSearchRequestFilter: action.activeSearchRequestFilter,
      };

    case UPDATE_SHOW_ALL_SEARCH_REQUESTS:
      return {
        ...state,
        showAllSearchRequests: action.showAllSearchRequests,
      };

    default:
      return state;
  }
}
