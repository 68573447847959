import { Form, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import SelectAccess from '@/components/SearchFormComponents/SelectAccess';
import { SearchRequestReferenceInformationValuesFragment } from '@/generated/graphql';

const { Title } = Typography;

interface AccessDatesProps {
  access: SearchRequestReferenceInformationValuesFragment['access'];
}

const AccessDate = ({ access }: AccessDatesProps): JSX.Element => {
  if (access) {
    return (
      <Typography.Text>
        {access.quarter} {access.year}
      </Typography.Text>
    );
  }

  return null;
};

interface Props extends AccessDatesProps {
  editing: boolean;
}

function Access({ editing, access }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box>
      <Title level={5} style={{ fontWeight: 500 }}>
        {t('Desired access date')}
      </Title>
      <>
        {editing ? (
          <Form.Item
            name={'access'}
            initialValue={access ? [access.year, access.quarter] : undefined}
          >
            <SelectAccess label={false} />
          </Form.Item>
        ) : (
          <AccessDate access={access} />
        )}
      </>
    </Box>
  );
}

export default Access;
