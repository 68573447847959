import { PlusOutlined } from '@ant-design/icons';
import { Modal, Typography, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload/interface';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getBase64 } from '@/components/UploadFile/index';
import { checkMaxUploadSize, uploadFile } from '@/components/UploadFile/util';
import { useUploadFileMutation } from '@/generated/graphql';

import { DragAndDropProps, State } from './types';
const { Dragger } = Upload;

const Container = styled.div`
  .ant-upload.ant-upload-select {
    display: flex;
    width: 100%;
    height: 66px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px dashed #d9d9d9;
    background-color: #fafafa;
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  }
  .ant-upload-list-item-name {
    line-height: 1.5715;
  }
`;

const DragContainer = styled.div<{ $isDragging: boolean; $first: boolean }>`
  .ant-upload-list-item {
    ${p => p.$first && `border: 1px solid ${p.theme.colors.green}`};
    ${p =>
      p.$isDragging &&
      `border: 1px solid ${p.theme.colors.primaryPurple}; background-color: rgba(0,0,0,0.02);`}
  }
`;

function DragAndDropper({
  initialValues,
  onChange,
}: DragAndDropProps): JSX.Element {
  const { t } = useTranslation();
  const [uploadFileMutation, { loading }] = useUploadFileMutation();
  const [disabled, setDisabled] = React.useState(false);

  const [state, setState] = React.useState<State>({
    previewVisible: false,
    previewImage: '',
    fileList: initialValues
      ? initialValues.map(v => ({
          ...v,
          status: 'done',
          size: v.size,
          type: 'image',
          thumbUrl: v.url,
          name: v.name,
          uid: v.id,
          originFileObj: v as any,
          response: {
            id: v.id,
          },
          url: v.url,
        }))
      : [],
  });
  const PictureContainer = (): JSX.Element => (
    <>
      <p>
        <PlusOutlined style={{ fontSize: '18px' }} />
      </p>
      <div>{t('uploadImage')}</div>
    </>
  );

  const handleCancel = (): void =>
    setState({ ...state, previewVisible: false });

  const handlePreview = async (file): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const handleChange = ({ fileList, file }: UploadChangeParam): void => {
    const { status } = file;

    setDisabled(true);
    setState({
      ...state,
      fileList: fileList.map(file => ({
        ...file,
        url: file.response?.url ? file.response.url : file.url,
      })),
    });
    if (status === 'done' || status === 'removed') {
      const items = fileList
        .filter(file => file.status === 'done')
        .map(file => file.response.id);
      onChange(items);
      setDisabled(false);
    }
  };

  return (
    <div className="clearfix">
      <Typography.Paragraph type={'secondary'}>
        {t('dragAndDropHelp')}
      </Typography.Paragraph>
      <DragDropContext
        onDragEnd={r => {
          const currentIndex = r.source.index;
          const nextIndex = r.destination.index;
          if (nextIndex == currentIndex) {
            return;
          }

          const element = state.fileList[currentIndex];
          // remove element at currentIndex then insert element at nextIndex
          const newList = [
            ...state.fileList.slice(0, currentIndex),
            ...state.fileList.slice(currentIndex + 1),
          ];
          newList.splice(nextIndex, 0, element);

          /*
          const next = [...state.fileList];
          next[currentIndex] = state.fileList[nextIndex];
          next[nextIndex] = state.fileList[currentIndex];

           */
          setState({ ...state, fileList: newList });
          onChange(newList.map(file => file.response.id));
        }}
      >
        <Droppable
          droppableId={'column-0'}
          isDropDisabled={loading || disabled}
        >
          {({ droppableProps, placeholder, innerRef }) => (
            <Container {...droppableProps} ref={innerRef}>
              <Dragger
                customRequest={args => {
                  const { file, onError, onSuccess, onProgress } = args;
                  if (typeof file !== 'string' && file.size) {
                    uploadFile(file, uploadFileMutation, percent =>
                      onProgress({ percent } as any),
                    )
                      .then(resp => {
                        if (resp?.data?.file) {
                          onSuccess(
                            {
                              id: resp.data.file.id,
                              url: resp.data.file.url,
                            },
                            file as any,
                          );
                        }
                      })
                      .catch(onError);
                  }
                }}
                listType={'picture'}
                fileList={state.fileList}
                beforeUpload={file => checkMaxUploadSize(file, t)}
                itemRender={(PictureCard, file) => {
                  const index = state.fileList.indexOf(file);
                  const first = index === 0;
                  return (
                    <>
                      <Draggable
                        key={file.response?.id ?? file.uid}
                        isDragDisabled={loading || disabled}
                        index={index}
                        draggableId={file.response?.id ?? file.uid}
                      >
                        {(
                          { draggableProps, dragHandleProps, innerRef },
                          snapshot,
                        ) => (
                          <DragContainer
                            {...draggableProps}
                            {...dragHandleProps}
                            ref={innerRef}
                            $isDragging={snapshot.isDragging}
                            $first={first}
                          >
                            {PictureCard}
                            {first && !snapshot.isDragging && (
                              <Typography.Text type={'secondary'}>
                                {t('Display image')}
                              </Typography.Text>
                            )}
                          </DragContainer>
                        )}
                      </Draggable>
                    </>
                  );
                }}
                onChange={handleChange}
                onPreview={handlePreview}
                accept={'image/*'}
                multiple
              >
                <PictureContainer />
              </Dragger>
              {placeholder}
            </Container>
          )}
        </Droppable>
      </DragDropContext>

      <Modal open={state.previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="preview" style={{ width: '100%' }} src={state.previewImage} />
      </Modal>
    </div>
  );
}

export default React.memo(DragAndDropper);
