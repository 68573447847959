import { PlayArrow } from '@styled-icons/material/PlayArrow';
import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import styled from 'styled-components';

const Wrapper = styled.div`
  && > video {
    outline: none;
  }
  && > img {
    object-fit: fill;
  }
`;
const Arrow = styled(PlayArrow)`
  color: white;
  height: 50px;
  width: 50px;
  transition: ease-in-out 150ms;

  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

function VideoPlayer(props: ReactPlayerProps): JSX.Element {
  return (
    <ReactPlayer
      controls
      wrapper={Wrapper}
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload',
          },
        },
      }}
      playIcon={<Arrow />}
      width="100%"
      heigth="100%"
      {...props}
    />
  );
}

export default VideoPlayer;
