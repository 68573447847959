import { useGoogleMap } from '@react-google-maps/api';
import React from 'react';

import { ResultBounds } from '@/generated/graphql';

interface Props {
  bounds?: ResultBounds;
  updateReady?: boolean;
}

function FitBounds({ bounds, updateReady = true }: Props): JSX.Element {
  const map = useGoogleMap();
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function

    if (bounds && updateReady) {
      const northEast = new window.google.maps.LatLng(
        bounds.north,
        bounds.east,
      );
      const southWest = new window.google.maps.LatLng(
        bounds.south,
        bounds.west,
      );

      map.fitBounds(new window.google.maps.LatLngBounds(southWest, northEast));
      setTimeout(function () {
        map.fitBounds(bounds);
      }, 1);
    }
  }, [bounds?.south, bounds?.east, bounds?.west, bounds?.north, updateReady]);
  return null;
}

export default FitBounds;
