import { MakeGenerics, Route } from '@tanstack/react-location';
import React, { lazy } from 'react';

import { BasicRouteProps } from '@/layout/routes';
const ApiKey = lazy(() => import('./ApiKey'));
import Container from '@/layout/Unauthenticated';
import RequestApiKey from '@/views/IntegrationProvider/RequestApiKey';
export type IntegrationApiKeyRouteProps = MakeGenerics<{
  Params: {
    token?: string;
  };
  LoaderData: {
    token: string;
  };
}> &
  BasicRouteProps;

const IntegrationApiKeyRoutes: Route<IntegrationApiKeyRouteProps>[] = [
  {
    path: 'auth/api',
    id: 'IntegrationProviderApiKey',
    element: <Container redirect={false} />,
    children: [
      {
        path: '/',
        element: <RequestApiKey />,
      },
      {
        path: ':token',
        element: <ApiKey />,
        loader: ({ params }) => ({
          token: params.token,
        }),
      },
    ],
  },
];

export const toUnauthenticatedIntegration = (): string => '';

export default IntegrationApiKeyRoutes;
