import React from 'react';

import useUser from '@/hooks/useUser';

export default function Commission(): JSX.Element {
  const user = useUser();
  const { activeSubscription } = user;
  if (activeSubscription.type === 'commission') {
    return (
      <div>
        *Portallys arvode baseras på{' '}
        {activeSubscription.version === 'v1' ? '3%' : '6%'} av en genomsnittlig
        årshyra
      </div>
    );
  }

  return <div>*Portallys arvode baseras på 3% av en genomsnittlig årshyra</div>;
}
