import { Tag } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

import Box from '@/components/Box';
import { GetCountiesQuery, GetFilterCountiesQuery } from '@/generated/graphql';
import { User } from '@/hooks/useUser';
const { CheckableTag } = Tag;

const centeredStyle = css`
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const checkedStockholmStyle = css`
  color: ${({ theme }) => theme.colors.primaryPurple} !important;
  border-bottom: none !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: ${({ theme }) => theme.colors.primaryPurple} !important;
  background: ${({ theme }) => theme.colors.white} !important;
  :hover {
    border-bottom: none !important;
  }
  ::after {
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const StyledTag = styled(CheckableTag)<{
  $centered?: boolean;
  $checkedStockholm?: boolean;
}>`
  border: 1px solid #d1cedb !important;
  :hover {
    border: 1px solid #aba1cf !important;
  }
  padding: 8px !important;
  font-size: 13px !important;

  @media (max-width: ${p => p.theme.breakpoints.mobile}) {
    padding: 16px !important;
  }
  width: 100% !important;
  white-space: pre-line !important;
  ${({ $centered }) => $centered && centeredStyle};
  ${({ $checkedStockholm }) => $checkedStockholm && checkedStockholmStyle}
`;

export const Container = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-right: 8px;
  }
`;

export const Label = styled.div`
  font-weight: 500;
  margin-bottom: 8px;
`;

export interface FilterProps {
  searchRequestSettings: User['searchRequestSettings'];
  setSearchRequestSettings: Dispatch<
    SetStateAction<User['searchRequestSettings']>
  >;
  label?: boolean;
}

export interface RegionProps {
  regions: GetFilterCountiesQuery['getRegions']['regions'];
}
