import { MessageOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Link, useMatch } from '@tanstack/react-location';
import { message, Tooltip } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';

import StartConversationButton from '@/components/StartConversationButton';
import { GetLeaseAgreementViewQuery } from '@/generated/graphql';
import useUser from '@/hooks/useUser';
import { toLeaseAgreementSearchRequest } from '@/views/LeaseAgreement/routes';

import VerifyAccount from '../User/VerifyAccount';
interface DrawerFooterPreviewProps {
  leaseAgreement: GetLeaseAgreementViewQuery['leaseAgreement'] | undefined;
  searchRequestReferenceId: string;
  t: TFunction;
}
export function PreviewDrawerFooter({
  leaseAgreement,
  searchRequestReferenceId,
  t,
}: DrawerFooterPreviewProps) {
  const user = useUser();
  const apollo = useApolloClient();

  if (!leaseAgreement || !leaseAgreement?.landlord?.name) {
    return <></>;
  }
  if (!searchRequestReferenceId) {
    return <></>;
  }

  const hasConversed = leaseAgreement.conversations.some(conversation =>
    conversation.participants.some(
      participant => participant.id === user?.activeClientId,
    ),
  );
  if (hasConversed) {
    return (
      <div className={'flex space-x-2 justify-between items-center'}>
        <Link
          to={toLeaseAgreementSearchRequest({
            leaseAgreementId: leaseAgreement.id,
            searchRequestReferenceId,
          })}
          rel={'noopener noreferrer'}
          target={'_blank'}
        >
          {t('Open in new tab')}
        </Link>
      </div>
    );
  }

  return (
    <div className={'flex space-x-2 justify-between items-center'}>
      {user?.verified ? (
        <StartConversationButton
          type={'primary'}
          leaseAgreementId={leaseAgreement.id}
          reciver={{
            id: leaseAgreement.landlord.id,
            name: leaseAgreement.landlord.name,
          }}
          searchRequestReferenceId={searchRequestReferenceId}
          icon={<MessageOutlined />}
          onFinish={async () => {
            message.success(t('Message sent'));
            await apollo.reFetchObservableQueries();
          }}
        >{`${t('Contact')} ${
          leaseAgreement.landlord.name
        }`}</StartConversationButton>
      ) : (
        <Tooltip title={t('Verify account to contact landlord')}>
          <VerifyAccount type="primary" />
        </Tooltip>
      )}
      <Link
        to={toLeaseAgreementSearchRequest({
          leaseAgreementId: leaseAgreement.id,
          searchRequestReferenceId,
        })}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        {t('Open in new tab')}
      </Link>
    </div>
  );
}
