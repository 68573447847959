import { Button, Drawer, Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Box from '@/components/Box';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import { formItemLayout as verticalLayout } from '@/views/LeaseAgreement/Form/util';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const Wrapper = styled.div<{ vertical: boolean }>`
  ${p =>
    p.vertical &&
    `display: flex;
       .ant-steps-vertical > .ant-steps-item {
        flex-grow: 0;
       }
    `};
`;

export interface StepsProps {
  step: number;
  onChange?: (step: number) => void;
}

interface SecondarySubmit {
  submitText: string;
  handleOk: (fd: any) => Promise<object>;
}

interface FormProps {
  title?: React.ReactNode;
  submitText?: string;
  secondarySubmitText?: string;
  button: React.FunctionComponent;
  setStep: Dispatch<SetStateAction<number>>;
  steps: React.FunctionComponent<StepsProps>;
  //eslint-disable-next-line
  handleOk: (fd: any) => Promise<object>;
  secondarySubmit?: SecondarySubmit;
  render: (props: { form: FormInstance }) => JSX.Element;
  loading: boolean;
  step: number;
  finalStep: number;
  finalStepInfo: React.FunctionComponent<{ form?: FormInstance }>;
  visible: boolean;
  onCancel: (submit?: boolean) => void;
  vertical?: boolean;
  destroyOnClose?: boolean;
  width?: string;
}

function ModalForm({
  title,
  button: ActionButton,
  handleOk,
  render,
  loading,
  step,
  finalStep,
  finalStepInfo: Info,
  setStep,
  steps: Steps,
  visible,
  onCancel,
  submitText,
  vertical,
  destroyOnClose = false,
  width = '760px',
  secondarySubmit,
}: FormProps): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();

  const [form] = Form.useForm();

  const Submit = (): JSX.Element => (
    <Button
      key="submit"
      type="primary"
      loading={loading}
      disabled={loading}
      onClick={() => {
        form.validateFields().then(fd => {
          setStep(step + 1);
          handleOk(fd).then(() => {
            form.resetFields();
            onCancel(true);
          });
        });
      }}
    >
      {submitText ? submitText : step !== undefined ? t('Send') : t('Save')}
    </Button>
  );
  const Exit = (): JSX.Element => (
    <Button key="back" onClick={() => onCancel()}>
      {t('Cancel')}
    </Button>
  );
  const Back = (): JSX.Element => (
    <Button onClick={() => setStep(step - 1)}>{t('goBack')}</Button>
  );

  const SecondarySubmit = (): JSX.Element =>
    secondarySubmit ? (
      <Button
        loading={loading}
        disabled={loading}
        onClick={() => {
          form.validateFields().then(fd => {
            setStep(step + 1);
            secondarySubmit.handleOk(fd).then(() => {
              form.resetFields();
              onCancel(true);
            });
          });
        }}
      >
        {secondarySubmit.submitText}
      </Button>
    ) : null;

  const Next = (): JSX.Element => (
    <Button
      type={'primary'}
      onClick={() => form.validateFields().then(() => setStep(step + 1))}
    >
      {t('Next')}
    </Button>
  );

  let footer;

  footer = [<Back key={'Back'} />, <Next key={'Next'} />];

  if (step >= finalStep) {
    footer = [
      <Back key={'Back'} />,
      <SecondarySubmit key={'secondary-submit'} />,
      <Submit key={'Submit'} />,
    ];
  }

  if (step === 0) {
    footer =
      finalStep === 0
        ? [<Exit key={'Back'} />, <Submit key={'Submit'} />]
        : [<Exit key={'Back'} />, <Next key={'Next'} />];
  }

  //1107
  return (
    <>
      <Drawer
        closable={!isMobile}
        title={title}
        autoFocus={false}
        visible={visible}
        onClose={() => onCancel()}
        placement={isMobile ? 'bottom' : 'right'}
        height={'100%'}
        footer={
          <Box flex gap={1} justifyContent="flex-end">
            {footer}
          </Box>
        }
        forceRender
        destroyOnClose={destroyOnClose}
        width={width}
      >
        <Wrapper vertical={vertical && !isMobile}>
          <Steps
            step={step}
            onChange={step => form.validateFields().then(() => setStep(step))}
          />
          <div style={{ flexGrow: 1 }}>
            {step >= finalStep && <Info form={form} />}
            <Form layout={vertical ? 'vertical' : 'horizontal'} form={form}>
              {render({ form })}
            </Form>
          </div>
        </Wrapper>
      </Drawer>
      <ActionButton />
    </>
  );
}

export default ModalForm;
