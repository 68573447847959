import { MakeGenerics, Route } from '@tanstack/react-location';
import React, { lazy } from 'react';
import { Suspense } from 'react';

import Spinner from '@/components/Spinner';
import Customer from '@/layout/Customer';
import { BasicRouteProps } from '@/layout/routes';
const Statistics = lazy(() => import('./Statistics'));

import View from '.';
export type PortallyPartnerRouteProps = MakeGenerics<{
  Params: {
    slug?: string;
  };
  LoaderData: {
    slug: string;
  };
}> &
  BasicRouteProps;

const PortallyPartnerRoutes: Route<PortallyPartnerRouteProps>[] = [
  {
    path: '/partner',
    id: '',

    children: [
      {
        path: ':slug',
        children: [
          {
            path: '/',
            id: 'PartnerView',
            loader: ({ params }) => ({ slug: params.slug }),
            element: (
              <Suspense fallback={<Spinner expand />}>
                <View />
              </Suspense>
            ),
          },
          {
            path: '/statistics',
            id: 'PartnerStatistics',
            element: <Customer />,
            loader: ({ params }) => ({ slug: params.slug }),
            children: [
              {
                path: '/',
                id: 'PartnerStatisticsView',
                element: <Statistics />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const toPortallyPartner = (slug: string): string => `/partner/${slug}`;
export const toPortallyPartnerStatistics = (slug: string): string =>
  `/partner/${slug}/statistics`;

export default PortallyPartnerRoutes;
