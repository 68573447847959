import { Button, Form, FormInstance, Input, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectCounties from '@/components/SelectCounties';
import SelectUsageCategories from '@/components/SelectUsageCategories';
import UploadFile from '@/components/UploadFile';
import { AdInput, AdPlacement, AdValuesFragment } from '@/generated/graphql';

interface Props {
  onSubmit: (values: AdInput) => void;
  loading?: boolean;
  initialValues?: AdValuesFragment;
  form: FormInstance<AdInput>;
}

const { Option } = Select;

function AdForm({ onSubmit, loading, initialValues, form }: Props) {
  const { t } = useTranslation();

  const options: AdPlacement[] = [
    AdPlacement.DashboardLandlord,
    AdPlacement.DashboardTenant,
    AdPlacement.SearchRequestView,
    AdPlacement.Website,
    AdPlacement.WebsiteLeaseAgreementSearch,
    AdPlacement.WebsiteLeaseAgreementView,
  ];
  return (
    <Form
      layout="vertical"
      initialValues={{
        ...initialValues,
        imageId: initialValues?.image?.id,
      }}
      onFinish={async fd => onSubmit(fd)}
      form={form}
    >
      <Form.Item
        name={'imageId'}
        label={'Bild'}
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <UploadFile multiple={false} initialValues={initialValues?.image} />
      </Form.Item>
      <Form.Item name={'mobileImageId'} label={'Bild mobil'}>
        <UploadFile
          multiple={false}
          initialValues={initialValues?.mobileImage}
        />
      </Form.Item>

      <Form.Item
        label={t('Placement')}
        name={'placement'}
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <Select>
          {options.map(option => (
            <Option key={option} value={option}>
              {t(option)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={'link'} label={'Länk'}>
        <Input />
      </Form.Item>
      <Form.Item name={'usageCategories'} label={'Kategorier'}>
        <SelectUsageCategories mode={'multiple'} showSearch={false} />
      </Form.Item>
      <Form.Item
        name={'regionIds'}
        label={'Region'}
        help="OBS använd enbart län"
      >
        <SelectCounties mode={'multiple'} showSearch={false} />
      </Form.Item>
      <Form.Item>
        <Button
          style={{ float: 'right' }}
          loading={loading}
          type="primary"
          onClick={() => form.submit()}
        >
          Spara
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AdForm;
