import { Carousel, Image } from 'antd';
import clsx from 'clsx';
import React, { useState } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import arrowLeft from '@/assets/svg/arrow-left.svg?url';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import arrowRight from '@/assets/svg/arrow-right.svg?url';
import HiddenImagePreview from '@/components/HiddenImagePreview';
import RateLeaseAgreement from '@/components/RateLeaseAgreement';
import { translateUrl } from '@/components/UploadFile/util';
import { GetLeaseAgreementViewQuery } from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';

interface Props {
  id: string;
  myStatus: string;
  images: GetLeaseAgreementViewQuery['leaseAgreement']['images'];
  isAgreementOwner: boolean;
}

const getImageDimensions = (
  width: number,
): { width: string; height: string } => {
  if (width > 1800) {
    return {
      width: '912px',
      height: '453px',
    };
  }
  if (width < 768) {
    return {
      width: '100%',
      height: '300px',
    };
  }
  if (width < 788) {
    return {
      width: '650px',
      height: '433px',
    };
  }
  if (width < 860) {
    return {
      width: '680px',
      height: '453px',
    };
  }

  return {
    width: '680px',
    height: '453px',
  };
};

const Container = styled.div`
  .ant-carousel .slick-next::before {
    background-image: url('${arrowRight}');
  }

  .ant-carousel .slick-prev::before {
    background-image: url('${arrowLeft}');
  }
  .ant-carousel .slick-next:hover::before,
  .ant-carousel .slick-prev:hover::before {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .ant-carousel .slick-next::before,
  .ant-carousel .slick-prev::before {
    content: '';
    background-position-x: 18px;
    background-position-y: 20px;
    background-size: 35%;
    background-repeat: no-repeat;
    transition: background-color 0.15s ease-in;
    position: relative;
    bottom: 20px;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
    background-color: rgba(0, 0, 0, 0.15);

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    left: 0px;
  }
  .ant-carousel .slick-next::before {
    right: 0;
    background-position-x: 21px;
    background-position-y: 20px;
  }

  .slick-arrow.slick-next {
    font-size: 0;
    right: 40px;
    color: black;
  }

  .slick-arrow.slick-prev {
    font-size: 0;
    left: 0;
    z-index: 2;
    color: black;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    box-shadow: none;
  }

  border-radius: ${({ theme }) => theme.dimensions.borderRadiusCard};
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    border-radius: 0px;
  }
`;

export default function ImageSection({
  images,
  myStatus,
  id,
  isAgreementOwner,
}: Props): JSX.Element {
  const { isMobile } = useWidthAndHeight();
  const [selectedPreviewImage, setSelectedPreviewImage] = useState({
    visible: false,
    previewImage: '',
  });

  return (
    <Container className={'relative h-full'}>
      {!isAgreementOwner && (
        <RateLeaseAgreement
          leaseAgreementView
          selectedLeaseAgreement={{ id, myStatus }}
        />
      )}

      <Carousel
        dotPosition={isMobile ? 'bottom' : 'bottom'}
        arrows={!isMobile}
        className={
          'aspect-[3/2] h-full drop-shadow-lg rounded-xl overflow-hidden'
        }
      >
        {images.map(i => (
          <>
            <Image
              key={i.id}
              className={'object-cover'}
              src={i.url}
              onClick={() =>
                setSelectedPreviewImage({
                  visible: true,
                  previewImage: i.url,
                })
              }
              preview={{
                visible: false,
                className: 'rounded-xl',
                maskClassName: 'rounded-xl',
              }}
            />
          </>
        ))}
      </Carousel>

      <HiddenImagePreview
        imageUrls={images.map(file => translateUrl(file.url))}
        visible={selectedPreviewImage.visible}
        selectedImage={selectedPreviewImage.previewImage}
        onVisibleChange={() =>
          setSelectedPreviewImage(prev => ({ ...prev, visible: false }))
        }
      />
    </Container>
  );
}
