import { FieldTimeOutlined } from '@ant-design/icons';
import { Archive } from '@styled-icons/bootstrap/Archive';
import { message, Popconfirm, Tabs, Tooltip, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@/components/Box';
import { StyledIconButton } from '@/components/Buttons';
import Chat from '@/components/Chat';
import {
  DisableSearchRequestReasonEnum,
  GetMyActiveSearchRequestsQueryResult,
  SearchRequestCardValuesFragment,
  SearchRequestStep,
  useUpdateSearchRequestStepMutation,
} from '@/generated/graphql';
import useWidthAndHeight from '@/hooks/getWidthAndHeight';
import theme from '@/main/theme';
import formatDate from '@/utils/formatDate';
import CommentSection from '@/views/ActiveSearchRequests/CommentSection';
import CommentsAndData from '@/views/ActiveSearchRequests/DetailedView/CommentsAndData';
import Information from '@/views/ActiveSearchRequests/DetailedView/Information';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

interface Props {
  selectedSearchRequest: SearchRequestCardValuesFragment | undefined;
  refetch: GetMyActiveSearchRequestsQueryResult['refetch'];
  onCancel: () => void;
}

function View({
  selectedSearchRequest,
  refetch,
  onCancel,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useWidthAndHeight();
  const [tabKey, setTabKey] = React.useState<'chat' | 'conversation'>('chat');
  useEffect(() => {
    setTabKey('chat');
  }, [selectedSearchRequest?.id]);

  const [updateSearchRequestStep, { loading }] =
    useUpdateSearchRequestStepMutation({
      onCompleted: async () => {
        await refetch();
        onCancel();
      },
      onError: () => {
        message.error(t('error'));
      },
    });

  const { title, status, disabledReason } =
    selectedSearchRequest?.searchRequestReference ?? {};

  return (
    <>
      {status === 'disabled' && (
        <div className="flex justify-start">
          <Tooltip
            overlayStyle={{ maxWidth: '300px' }}
            title={
              <span style={{ whiteSpace: 'pre-line' }}>
                {DisableSearchRequestReasonEnum.Stationary ===
                disabledReason.reason
                  ? `${t('disabled_closed')}\n${t('disabled_stationary')}`
                  : DisableSearchRequestReasonEnum.AdminNeverLoggedIn ===
                      disabledReason.reason ||
                    DisableSearchRequestReasonEnum.AdminNoActivity ===
                      disabledReason.reason ||
                    DisableSearchRequestReasonEnum.Dislike ===
                      disabledReason.reason
                  ? `${t('disabled_closed')}\n${t('disabled_unknown')}`
                  : `${t('disabled_closed')}\n${t('disabled_found')}`}
              </span>
            }
          >
            <Title level={5} style={{ color: theme.colors.red }}>
              {t('has ended their search', {
                company:
                  selectedSearchRequest.searchRequestReference.client.name,
              })}
            </Title>
          </Tooltip>
        </div>
      )}
      <Box
        flex
        justifyContent={'space-between'}
        horizontalSpacing={1}
        alignItems={'flex-start'}
      >
        <Title level={5} style={{ marginBottom: '0px' }}>
          {title}
        </Title>
        {selectedSearchRequest?.step !== SearchRequestStep.Archived && (
          <Popconfirm
            overlayClassName={'prevent-active-request-drawer-close'}
            title={t('Are you sure?')}
            onConfirm={() =>
              updateSearchRequestStep({
                variables: {
                  id: selectedSearchRequest?.id,
                  update: {
                    step: SearchRequestStep.Archived,
                  },
                },
              })
            }
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <Tooltip open={isMobile ? undefined : false} title={t('Archive')}>
              <StyledIconButton
                icon={<Archive size={14} />}
                loading={loading}
                danger
                $withText={!isMobile}
              >
                {!isMobile && t('Archive')}
              </StyledIconButton>
            </Tooltip>
          </Popconfirm>
        )}
      </Box>
      <Box mb={2}>
        <Text type={'secondary'}>
          <FieldTimeOutlined /> {t('AnsweredSingular')}{' '}
          {formatDate(selectedSearchRequest?.answeredDate)}
        </Text>
      </Box>

      <CommentsAndData
        selectedSearchRequest={selectedSearchRequest}
        refetch={refetch}
      />
      <Box my={2}>
        <CommentSection searchRequestId={selectedSearchRequest?.id} />
      </Box>
      <Tabs
        activeKey={tabKey}
        onChange={key => setTabKey(key as 'chat' | 'conversation')}
      >
        {selectedSearchRequest?.conversationId && (
          <TabPane
            key={'chat'}
            tabKey={'chat'}
            tab={t('Conversation')}
            className={'prevent-active-request-drawer-close'}
          >
            <Chat
              type={{
                type: 'flat',
                props: {
                  ignoreMobile: true,
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onGoBack: () => {},
                  withTitle: false,
                  centeredTabs: false,
                  height: '500px',
                },
              }}
              conversationId={selectedSearchRequest?.conversationId}
            />
          </TabPane>
        )}
        <TabPane
          key={'conversation'}
          tabKey={'conversation'}
          tab={t('Inquiry')}
          className={'prevent-active-request-drawer-close'}
        >
          <Box
            p={2}
            style={{
              border: `1px solid ${theme.colors.inputBorder}`,
              borderRadius: '6px',
            }}
          >
            <Title level={4}>
              {selectedSearchRequest?.searchRequestReference.title}
            </Title>
            {selectedSearchRequest?.id && (
              <Information
                searchRequestReferenceId={
                  selectedSearchRequest.searchRequestReference.id
                }
              />
            )}
          </Box>
        </TabPane>
      </Tabs>
    </>
  );
}

export default View;
